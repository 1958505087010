import { ReactNode } from "react";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIDialog, UIDialogActions, UIDialogContent, UIDialogContentText, UIDialogTitle } from "../../_app/components";

export interface Props {
  show: boolean;
  title: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  cancelLabel: string;
  submitLabel: string;
  children: ReactNode;
}

export const FiltersDialog = (props: Props) => {
  const classes = useStyles();

  return (
    <UIDialog open={props.show} onClose={props.handleCancel}>
      <div className={classes.dialog}>
        <UIDialogTitle>{props.title}</UIDialogTitle>
        <UIDialogContent>
          <UIDialogContentText>{props.children}</UIDialogContentText>
        </UIDialogContent>
        <UIDialogActions className={classes.buttons}>
          <UIButton onClick={props.handleCancel} data-cy="cancel-filter-dialog-button">
            {props.cancelLabel}
          </UIButton>
          <UIButton variant="contained" color="primary" onClick={props.handleSubmit} data-cy="confirm-filter-dialog-button">
            {props.submitLabel}
          </UIButton>
        </UIDialogActions>
      </div>
    </UIDialog>
  );
};

const useStyles = createStylesheet((theme) => ({
  dialog: {
    margin: theme.spacing(1),
  },
  buttons: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

export default FiltersDialog;
