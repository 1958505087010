import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpandMore as ExpandMoreIcon, Menu as MenuIcon } from "../../components/icons";
import { useAccountsForParent, useMyAccounts } from "../../../account/hooks";
import SwitchAccountsDialogLegacy from "../../../user-level/components/SwitchAccountsDialogLegacy";
import { useLeafUserLevel, useLowestUserLevel, useUserLevelMap } from "../../../user-level/hooks";
import HierarchyBreadcrumbs from "../../../user-level/components/HierarchyBreadcrumbs";
import MasqueradingChip from "./MasqueradingChip";
import { useStore } from "../../hooks";
import { getLastParent } from "../../../context/utils";
import { useIdentityUser } from "../../../auth/hooks";
import { useUserData } from "../../../user/hooks";
import { createStylesheet, withStyles } from "../../utils/styles";
import { UIAppBar, UIIconButton, UIMenu, UIMenuItem, UIMenuProps, UIToolbar, UITypography } from "../../components";

interface MainProps {
  handleDrawerToggle: () => void;
}

const StyledMenu = withStyles(
  (props: UIMenuProps) => (
    <UIMenu
      id="menu-appbar"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ),
  {
    paper: {
      width: "249px",
      marginTop: "35px",
    },
  },
);

const StyledMenuItem = withStyles(UIMenuItem, (theme) => ({
  root: {
    fontSize: "14px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

function ToolbarMenu({ handleDrawerToggle }: MainProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: identityUser } = useIdentityUser();
  const { data: groups } = useMyAccounts();

  const parentAccounts = getLastParent(state.contextHierarchy)?.userAccessibleAccounts;
  const parentAccountId = parentAccounts?.[0].id;
  const { userLevel } = useUserData();

  const { data: accountsResp } = useAccountsForParent(
    {
      parent: parentAccountId || "",
      limit: 25,
    },
    { enabled: Boolean(parentAccountId) },
  );
  const accountsLength = accountsResp?.pages?.[0]?.list?.length;
  const userLevels = useUserLevelMap();
  const lowestUserLevel = useLowestUserLevel();
  const leafLevel = useLeafUserLevel();
  const showSwitchGroups = Boolean(identityUser && groups && groups.length > 1);
  const open = Boolean(anchorEl);
  const showSwitchAccounts = Boolean(
    identityUser &&
      userLevel !== lowestUserLevel?.id &&
      ((userLevel < leafLevel && accountsLength) ||
        (userLevel === leafLevel && accountsLength && accountsLength > 1) ||
        (parentAccounts?.length && parentAccounts?.length > 1)),
  );

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToMyDetailsPage = () => {
    handleClose();
    navigate("/profile");
  };

  const goToSelectGroup = () => {
    handleClose();
    navigate("/groups");
  };

  const goToSwitchAccounts = () => {
    handleClose();
    setIsDialogOpen(true);
  };

  const handleContactSupport = () => {
    handleClose();
    navigate("/contact-support");
  };

  const handleLogout = () => {
    handleClose();
    navigate("/logout");
  };

  return (
    <UIAppBar position="fixed" className={classes.appBar} data-cy="app-bar">
      <UIToolbar className={classes.toolbar}>
        <UIIconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </UIIconButton>
        <HierarchyBreadcrumbs />
        <div>
          <UITypography
            data-cy="menu-appbar"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            variant="subtitle2"
            color="textPrimary"
            className={classes.userName}
            noWrap
          >
            <span data-cy="menu-username">
              | {identityUser?.profile.given_name} {identityUser?.profile.family_name}
            </span>
            <MasqueradingChip lite placement="left-start" className={classes.masquerade} />
            <ExpandMoreIcon style={{ color: "#524B65" }} />
          </UITypography>
          <StyledMenu anchorEl={anchorEl} onClose={handleClose} open={open}>
            <StyledMenuItem onClick={goToMyDetailsPage} data-cy="my-details-link">
              My Details
            </StyledMenuItem>
            {showSwitchGroups && (
              <StyledMenuItem onClick={goToSelectGroup} data-cy="groups-link">
                Select {userLevels?.[10]?.name}
              </StyledMenuItem>
            )}
            {showSwitchAccounts && (
              <StyledMenuItem onClick={goToSwitchAccounts} data-cy="accounts-link">
                Switch {userLevels?.[30]?.name}s
              </StyledMenuItem>
            )}
            <StyledMenuItem onClick={handleContactSupport} data-cy="contact-support-link">
              Contact Support
            </StyledMenuItem>
            <StyledMenuItem onClick={handleLogout} data-cy="logout-link">
              Log Out
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </UIToolbar>
      <SwitchAccountsDialogLegacy
        title={"Switch " + userLevels?.[30]?.name + "s"}
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
      />
    </UIAppBar>
  );
}

const useStyles = createStylesheet((theme) => ({
  appBar: {
    backgroundColor: theme.palette.grey[100],
    flexGrow: 1,
    maxHeight: "64px",
    overflow: "hidden",
  },
  menuButton: {
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  userName: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "bold",
  },
  masquerade: { marginLeft: "5px !important" },
}));

export default ToolbarMenu;
