import Public from "../layouts/Public";
import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon, EmojiPeople as EmojiPeopleIcon } from "../components/icons";
import { createStylesheet } from "../utils/styles";
import { UIButton, UITypography } from "../components";

export const Page404 = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Public className={classes.ctr}>
      <EmojiPeopleIcon color="primary" className={classes.icon} />
      <UITypography variant="h1" gutterBottom color="secondary" fontWeight={900}>
        Oops!
      </UITypography>
      <UITypography variant="h3" gutterBottom align="center">
        The page you were looking for was not found
      </UITypography>
      <UIButton variant="contained" color="secondary" onClick={() => navigate("/")} startIcon={<ArrowBackIcon />}>
        Go Back Home
      </UIButton>
    </Public>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(4),
  },
  icon: {
    fontSize: 150,
  },
}));

export default Page404;
