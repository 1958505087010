import { useEffect, useState } from "react";
import { useBillingAddress, useBillingAddressUpdate, useBillSettings, useBillSettingsUpdate } from "../hooks";
import BillingAddress from "../components/AccountDetails/BillingAddress";
import BillingType from "../components/AccountDetails/BillingType";
import { useFeedbackAlerts } from "../../_app/hooks";
import Main from "../../_app/layouts/Main";
import { permissionCodes } from "../../permission/hooks";
import { useUserLevelMap } from "../../user-level/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIGrid } from "../../_app/components";

export const AccountDetails = () => {
  const classes = useStyles();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const userLevels = useUserLevelMap();
  const [onlyEmail, setOnlyEmail] = useState(false);
  const userLevelName = userLevels?.[30]?.name;

  const { data, isLoading: isBillingAddressLoading, isError: isAddressError, isSuccess: isAddressSuccess } = useBillingAddress();
  const {
    data: billSettings,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
    isSuccess: isSettingsSuccess,
  } = useBillSettings();
  const { mutate: executeBillingAddressUpdate, isLoading: isBillingAddressUpdating } = useBillingAddressUpdate();
  const {
    mutate: executeBillSettingsUpdate,
    isLoading: isSettingsUpdating,
    isSuccess: settingsUpdateSuccess,
  } = useBillSettingsUpdate();

  useEffect(() => {
    if (settingsUpdateSuccess)
      setFeedbackAlertSuccess(onlyEmail ? "Billing notification email address was changed." : "Billing type changed.");
    // eslint-disable-next-line
  }, [settingsUpdateSuccess]);

  return (
    <Main
      title={`${userLevelName} Details`}
      data-cy="account-details-page"
      isLoading={isBillingAddressLoading || isBillingAddressUpdating}
      accessPermission={permissionCodes.ACCOUNT_MANAGE}
      needSelectedAccount={true}
    >
      <div className={classes.spacer} />
      {isAddressError && isSettingsError ? (
        <UIGrid>
          <UIAlert severity="info">Account details cannot be amended for sub-accounts.</UIAlert>
        </UIGrid>
      ) : (
        <>
          {isAddressSuccess && <BillingAddress address={data} handleUpdate={executeBillingAddressUpdate} />}
          {isSettingsSuccess && (
            <BillingType
              billSettings={billSettings}
              handleUpdate={executeBillSettingsUpdate}
              setOnlyEmail={setOnlyEmail}
              userLevelName={userLevelName}
              isLoading={isSettingsLoading || isSettingsUpdating}
            />
          )}
        </>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  spacer: {
    display: "block",
    width: "100%",
    height: theme.spacing(1.5),
  },
}));

export default AccountDetails;
