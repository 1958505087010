import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import UIConfirm from "../../_app/components/UIConfirm";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { AccountLevel } from "../../account/utils";
import SubmitButtons from "../../form/components/SubmitButtons";
import { Permission } from "../../permission/api";
import { simplifyPermissions } from "../../permission/utils";
import EditUserDetailsForm, { userDetailsSchema } from "../EditUserDetailsForm";
import { UserCreateUpdateRequest } from "../types";
import EditAccountDetailsForm from "./EditAccountDetailsForm";
import EditUserPermissionForm from "./EditUserPermissionForm";
import RoleSelector from "./selectors/RoleSelector";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIGrid } from "../../_app/components";

export interface Props {
  user: any;
  userId?: string;
  newUser?: boolean;
  hasEmailConfirmation?: boolean;
  isSubmitting: boolean;
  handleSubmit: (data: UserCreateUpdateRequest) => void;
  handleCancel: () => void;
  submitLabel?: string;
}

export const EditUserForm = ({
  user,
  userId,
  newUser = false,
  isSubmitting = false,
  handleSubmit = () => null,
  handleCancel = () => null,
  submitLabel = "Submit",
}: Props) => {
  const classes = useStyles();
  const [permissions, setPermissions] = useState([] as Permission[]);
  const { getAccountByLevel, currentAccountLevel, selectedAccount } = useAccountContext();

  const userFormSchema = userDetailsSchema(newUser);
  const [submitWarning, setSubmitWarning] = useState(false);

  const methods = useForm({
    defaultValues: {
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userLevel: user?.userLevel || AccountLevel.Account.id,
      accounts: user?.accounts?.map((account: any) => account.id) || [],
      emailConfirm: undefined,
      parentAccount: user?.userLevel == AccountLevel.SubAccount.id ? user?.parentInvoicingAccount || "" : "",
      role: user?.role || "",
    },
    resolver: zodResolver(userFormSchema),
    mode: "all",
  });

  useEffect(
    function setDefaultAccountInfoForNewUser() {
      if (newUser && currentAccountLevel) {
        methods.setValue("userLevel", currentAccountLevel?.id);
        methods.setValue("accounts", currentAccountLevel ? [selectedAccount?.id] : []);
        if (currentAccountLevel === AccountLevel.SubAccount) {
          methods.setValue("parentAccount", getAccountByLevel(AccountLevel.Account.value)?.id);
        }
      }
    },
    // eslint-disable-next-line
    [],
  );

  const hasManageContactsPermissionChecked = (permissions: any) => {
    return permissions.some((permission: any) => {
      return (
        (permission.code === "ACCOUNT_CONTACTS" && permission.value) ||
        (permission.permissions && hasManageContactsPermissionChecked(permission.permissions))
      );
    });
  };

  const onSubmit = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const formData = methods.getValues();
      const data: UserCreateUpdateRequest = {
        userDetails: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
        },
        accountIds: formData.accounts,
        roleId: formData.role ? parseInt(formData.role) : undefined,
        permissions: simplifyPermissions(permissions),
      };

      handleSubmit(data);
    }
  };

  return (
    <>
      <UIGrid container className={classes.ctr} data-cy="edit-user-form">
        <FormProvider {...methods}>
          <EditUserDetailsForm classes={classes} newUser={newUser} />
          {user?.userLevel !== AccountLevel.Asset.id && <EditAccountDetailsForm classes={classes} />}
          <RoleSelector classes={classes} />
        </FormProvider>
        <EditUserPermissionForm
          userId={userId}
          roleId={methods.watch("role")}
          levelId={methods.watch("userLevel")}
          propagateData={setPermissions}
        />
      </UIGrid>

      <SubmitButtons
        isSubmitting={isSubmitting}
        submitLabel={submitLabel}
        onSubmit={hasManageContactsPermissionChecked(permissions) ? () => setSubmitWarning(true) : onSubmit}
        onCancel={handleCancel}
        disableSubmit={Object.keys(methods.formState.errors).length > 0}
      />
      <UIConfirm
        open={submitWarning}
        setOpen={setSubmitWarning}
        onConfirm={onSubmit}
        title="Are you sure you want to grant the Manage Contacts permission?"
      >
        <UIAlert severity="warning">
          Users with access to Manage Contacts can change all the authorised contacts on the account thereby taking full control
          of the account. This includes changing who is authorised to place orders and disconnect services.
        </UIAlert>
      </UIConfirm>
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    width: "100%",
  },
  card: {
    padding: theme.spacing(5),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  noAccountNotice: {
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  chipWrap: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default EditUserForm;
