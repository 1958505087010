import { type JSX, ReactNode, useState } from "react";
import { Clear as ClearIcon } from "../../../_app/components/icons";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIBox, UIButton, UIGrid, UILoader, UITypography } from "../../../_app/components";

interface Props {
  title: ReactNode;
  value: ReactNode;
  editable?: boolean;
  isLoading?: boolean;
  action?: JSX.Element;
  onUpdate?: any;
  onCancel?: any;
  children?: JSX.Element;
}

export const DetailItem = ({
  title,
  value,
  editable = false,
  isLoading = false,
  action,
  onUpdate,
  onCancel,
  children,
}: Props) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleUpdate = () => {
    onUpdate();
    setIsEditing(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsEditing(false);
  };

  return (
    <>
      <UIGrid size={{ xs: 5 }} className={classes.gridItem}>
        <UITypography variant="caption" data-cy="attr-key" className={classes.title}>
          {title}
        </UITypography>
      </UIGrid>

      <UIGrid size={{ xs: 7 }} className={classes.gridItem}>
        <>
          {isEditing && <>{Boolean(children) && children}</>}
          {!isEditing && (
            <>
              {isLoading ? (
                <UILoader color="inherit" size={18} />
              ) : typeof value === "string" ? (
                <UITypography variant="subtitle2" component="span" data-cy="attr-val" className={classes.value}>
                  {value}
                </UITypography>
              ) : (
                value
              )}
            </>
          )}
          {Boolean(action) && (
            <UIBox display="flex" justifyContent="flex-end">
              {action}
            </UIBox>
          )}
          {editable && (
            <UIBox display="flex" justifyContent="flex-end">
              {isEditing ? (
                <UIBox display="flex">
                  <UIButton data-cy="cancel-btn" isIconButton color="primary" onClick={handleCancel}>
                    <ClearIcon />
                  </UIButton>
                  <UIButton
                    data-cy={`${title}-save-btn`}
                    variant="contained"
                    color="primary"
                    onClick={handleUpdate}
                    className={classes.saveButton}
                  >
                    Save
                  </UIButton>
                </UIBox>
              ) : (
                <UIButton
                  data-cy={`${title}-edit-btn`}
                  variant="text"
                  color="primary"
                  className={classes.button}
                  onClick={() => setIsEditing(true)}
                  disabled={isLoading}
                >
                  Edit
                </UIButton>
              )}
            </UIBox>
          )}
        </>
      </UIGrid>
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  title: {
    color: `${theme.palette.grey[400]}`,
  },
  gridItem: {
    height: "47px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:not(:nth-last-of-type(-n+2))": {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  value: {
    maxHeight: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  button: {
    justifyContent: "flex-end",
  },
  saveButton: {
    margin: "auto",
    height: "30px",
    marginRight: "8px",
  },
}));

export default DetailItem;
