import { useQuery } from "react-query";
import { BarControllerChartOptions, ChartOptions, CoreScaleOptions, Scale, Tick, TooltipItem, TooltipModel } from "chart.js";
import { AjaxResponse } from "../_app/api";
import {
  getCallCost,
  getCallCount,
  getCallDuration,
  getCostByProduct,
  getCostByUsageType,
  getCostUser,
  getDataUser,
  getNonGeographic,
  getServiceChargesFrequency,
  getServiceCostByProduct,
  getServiceCostByType,
  getUsageBreakdown,
  getUsageSummary,
} from "./api";
import { PeriodParam, Query } from "../filter/types";
import { UsageSummaryRecord } from "./types";

export function useUsageSummary(period?: PeriodParam, options = {}) {
  return useQuery<UsageSummaryRecord[], AjaxResponse>(["usageSummary", { period }], () => getUsageSummary(period), {
    ...options,
  });
}

export function useUsageBreakdown(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["usageBreakdown", queries], () => getUsageBreakdown(queries), {
    ...options,
  });
}

export function useServiceChargesFrequency(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["serviceChargesFrequency", queries], () => getServiceChargesFrequency(queries), {
    ...options,
  });
}

export function useCostByUsageType(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["costByUsageType", queries], () => getCostByUsageType(queries), {
    ...options,
  });
}

export function useServiceCostByType(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["serviceCostByType", queries], () => getServiceCostByType(queries), {
    ...options,
  });
}

export function useCostByProduct(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["costByProduct", queries], () => getCostByProduct(queries), {
    ...options,
  });
}

export function useServiceCostByProduct(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["serviceCostByProduct", queries], () => getServiceCostByProduct(queries), {
    ...options,
  });
}

export function useCostUser(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["topCostUser", queries], () => getCostUser(queries), {
    ...options,
  });
}

export function useDataUser(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["topDataUser", queries], () => getDataUser(queries), {
    ...options,
  });
}

export function useCallCount(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["callCount", queries], () => getCallCount(queries), { ...options });
}

export function useCallDuration(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["callDuration", queries], () => getCallDuration(queries), { ...options });
}

export function useCallCost(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>(["callCost", queries], () => getCallCost(queries), { ...options });
}

export function useNonGeographic(options = {}) {
  return useQuery<any, AjaxResponse>("topNonGeographic", getNonGeographic, {
    ...options,
  });
}

export function useLinesSpend(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>("topLinesSpend", () => getDataUser(queries), {
    ...options,
  });
}

export function useDestinations(queries: Query[], options = {}) {
  return useQuery<any, AjaxResponse>("topDestinations", () => getDataUser(queries), {
    ...options,
  });
}

/* ChartJS Hooks */

export function useVerticalBarChartOptions(
  unit: string = "",
  yAxisLabelCallback?: Function,
  tooltipLabelCallback?: Function,
  overrides = {},
) {
  const yAxisLabel =
    yAxisLabelCallback ||
    function (value: any) {
      return unit + value;
    };
  const tooltipLabel =
    tooltipLabelCallback ||
    function (context: any) {
      return unit + context?.formattedValue;
    };
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          padding: 36,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        position: "nearest",
        backgroundColor: "#ffffff",
        titleColor: "#000000",
        bodyColor: "#000000",
        displayColors: false,
        padding: 15,
        borderColor: "#000000",
        caretSize: 0,
        borderWidth: 1,
        callbacks: {
          label: tooltipLabel,
          title: function (context: any) {
            return context?.[0]?.dataset?.label || "";
          },
        },
      },
    },
    interaction: {
      mode: "point",
    },
    scales: {
      y: {
        ticks: {
          callback: yAxisLabel,
        },
      },
    },
    ...overrides,
  } as BarControllerChartOptions;
}

export function useHorizontalBarChartOptions(
  unit: string = "",
  xAxisLabelCallback?: Function,
  tooltipLabelCallback?: Function,
  stacked = false,
  overrides = {},
) {
  const xAxisLabel =
    xAxisLabelCallback ||
    function (value: any) {
      return unit + value;
    };
  const tooltipLabel =
    tooltipLabelCallback ||
    function (context: any) {
      return unit + context?.formattedValue;
    };
  return {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          padding: 36,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        position: "nearest",
        backgroundColor: "#ffffff",
        titleColor: "#000000",
        bodyColor: "#000000",
        displayColors: false,
        padding: 15,
        borderColor: "#000000",
        caretSize: 0,
        borderWidth: 1,
        callbacks: {
          label: tooltipLabel,
          title: function (context: any) {
            return context?.[0]?.dataset?.label;
          },
        },
      },
    },
    skipNull: true,
    interaction: {
      mode: "point",
    },
    scales: {
      y: {
        stacked: stacked,
      },
      x: {
        stacked: stacked,
        ticks: {
          callback: xAxisLabel,
        },
      },
    },
    ...overrides,
  } as BarControllerChartOptions;
}

export function useLineChartOptions(
  unit: string = "",
  xAxisLabelCallback?: (
    this: Scale<CoreScaleOptions>,
    tickValue: string | number,
    index: number,
    ticks: Tick[],
  ) => string | number | string[] | number[] | null | undefined,
  tooltipLabelCallback?: (this: TooltipModel<"line">, tooltipItem: TooltipItem<"line">) => string | void | string[],
  stacked = false,
  overrides = {},
) {
  const xAxisLabel =
    xAxisLabelCallback ||
    function (value: any) {
      return unit + value;
    };
  const tooltipLabel =
    tooltipLabelCallback ||
    function (context: any) {
      return [context.label, context.dataset.label + " " + unit + context.formattedValue];
    };
  return {
    type: "line",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          padding: 35,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        position: "nearest",
        backgroundColor: "#ffffff",
        titleColor: "#000000",
        bodyColor: "#000000",
        padding: { left: 25, right: 25, top: 15, bottom: 15 },
        bodyFont: { weight: "bold" },
        caretSize: 0,
        displayColors: false,
        borderColor: "#000000",
        borderWidth: 1,
        callbacks: {
          label: tooltipLabel,
          title: () => "",
        },
      },
    },
    interaction: {
      mode: "point",
    },
    scales: {
      xAxes: {
        stacked: true,
      },
      yAxes: {
        stacked: false,
        ticks: {
          callback: xAxisLabel,
        },
      },
    },
    ...overrides,
  } as ChartOptions<"line">;
}
