import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRequestAutoSwitch } from "../hooks";
import { useFeedbackAlerts, useMemoizedCallback } from "../../_app/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import Main from "../../_app/layouts/Main";
import { genericError } from "../../_app/utils/text";
import {
  UIAlert,
  UIButton,
  UICard,
  UICheckbox,
  UIFormControlLabel,
  UILoader,
  UIRadio,
  UIRadioGroup,
  UITypography,
} from "../../_app/components";
import { createStylesheet } from "../../_app/utils/styles";

export const SimSwitch = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [isHandling, setIsHandling] = useState(false);
  const [type, setType] = useState("PAC");
  const [sendSms, setSendSms] = useState(true);

  useEffect(() => {
    if ((!assets || !assets?.length) && !filters) {
      setFeedbackAlertError("No mobile numbers selected");
      navigate("/mobile-management");
    }
  }, [assets, filters, navigate, setFeedbackAlertError]);

  const { mutate: makeRequest, data: requestId, isLoading, isSuccess, error } = useRequestAutoSwitch();

  const onSuccess = useMemoizedCallback(
    (requestId: string) => {
      setIsHandling(true);
      setTimeout(() => {
        navigate("/mobile-management/sim-switch/results", {
          state: { requestId, search: location.search },
        });
      }, 2000);
    },
    [assets],
  );

  useEffect(() => {
    if (error) {
      setFeedbackAlertError(error.data.message || genericError());
    }
  }, [error, navigate, onSuccess, setFeedbackAlertError]);

  useEffect(() => {
    if (isSuccess && requestId) onSuccess(requestId);
  }, [isSuccess, requestId, onSuccess]);

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const target = ev.target;
    switch (target?.name) {
      case "auto-switch-type":
        return setType(target.value);
      case "send-sms":
        return setSendSms(target.checked);
    }
  };

  const onApply = () => {
    if (isLoading) return;
    const params = {
      assetIds: assets?.map((a: any) => a.id),
      filters,
      operations: [
        {
          requestType: type,
          sendSms: Boolean(sendSms),
        },
      ],
    };
    makeRequest(params);
  };

  const renderLabel = (label: string, sublabel: string) => {
    return (
      <div className={classes.label}>
        <UITypography>{label}</UITypography>
        <UITypography variant="subtitle2">{sublabel}</UITypography>
      </div>
    );
  };

  const renderError = () => {
    if (assets?.length) return null;
    if (filters) return null;
    return <UIAlert severity="error">No mobile numbers selected</UIAlert>;
  };

  return (
    <Main
      title="Auto Switch"
      data-cy="sim-switch-page"
      needSelectedAccount={true}
      featureFlag={featureFlagsMap.ASSETS}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      {renderError() || isHandling ? (
        <div className={classes.handling}>
          <UILoader size={50} />
          <UITypography variant="subtitle1">We are handling your request</UITypography>
        </div>
      ) : (
        <div className={classes.ctr}>
          <UICard hasBorder className={classes.card} padding="16px">
            <UIRadioGroup name="auto-switch-type" data-cy="auto-switch-type" value={type} onChange={onChange} color="primary">
              <UIFormControlLabel
                value="PAC"
                control={<UIRadio color="primary" />}
                label={renderLabel("Request PAC Code", "Switch providers and take your number with you")}
              />
              <UIFormControlLabel
                value="STAC"
                control={<UIRadio color="primary" />}
                label={renderLabel("Request STAC Code", "Switch providers and don't take your number with you")}
              />
              <UIFormControlLabel
                value="ETF"
                control={<UIRadio color="primary" />}
                label={renderLabel("Request Switching Information", "Learn how much you'd get charged if you switched providers")}
              />
            </UIRadioGroup>
          </UICard>
          <UICard hasBorder className={classes.card} padding="16px">
            <UIFormControlLabel
              label={renderLabel(
                "Send SMS to End User",
                "Send the requested information via SMS to each of the mobile numbers selected",
              )}
              name="send-sms"
              control={<UICheckbox checked={sendSms} color="primary" onChange={onChange} />}
            />
          </UICard>
          <UIButton
            onClick={onApply}
            disabled={isLoading || isHandling}
            variant="contained"
            color="primary"
            data-cy="apply-btn"
            startIcon={isLoading && <UILoader size={20} />}
            className={classes.btn}
          >
            Apply
          </UIButton>
        </div>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingTop: theme.spacing(1.5),
  },
  card: {
    marginBottom: theme.spacing(3),
    height: "fit-content",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    "& > h6": {
      color: theme.palette.grey[400],
    },
  },
  btn: {
    width: 90,
  },
  handling: {
    display: "flex",
    height: "70vh",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "35px",
    marginTop: "30px",
  },
}));

export default SimSwitch;
