import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { useHasFeatureFlag } from "../../feature/hooks";
import Tabbed from "../../_app/layouts/Tabbed";
import { getInvoicesLabel } from "../utils";
import { featureFlagsMap } from "../../feature/utils";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageStatementTabBar({ selectedTab, children }: Props) {
  const hasStatementsFeature = useHasFeatureFlag(featureFlagsMap.STATEMENTS);
  const hasInvoicesFeature = useHasFeatureFlag(featureFlagsMap.INVOICES);
  const hasStatementsPermission = useHasPermission(permissionCodes.BILLING_STATEMENT);
  const hasInvoicesPermission = useHasPermission(permissionCodes.BILLING_STATEMENT);

  const showStatementsTab = Boolean(hasStatementsFeature && hasStatementsPermission);
  const showInvoicesTab = Boolean(hasInvoicesFeature && hasInvoicesPermission);

  return (
    <Tabbed
      selectedTab={selectedTab}
      tabs={[
        {
          value: "statements",
          label: "Statements",
          hidden: !showStatementsTab,
        },
        {
          value: "invoices",
          label: getInvoicesLabel(showInvoicesTab, showStatementsTab),
          hidden: !showInvoicesTab,
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default ManageStatementTabBar;
