import api from "../_app/api";
import { percentage, randomFromInterval } from "../_app/utils";
import {
  CreateTicket,
  GetTicketCommentsParams,
  GetTicketCommentsResponse,
  GetTicketsResponse,
  ServiceRequestCount,
  Ticket,
} from "./types";

export const getServiceRequestCount = async (max: number = 100) => {
  const fetch = () =>
    new Promise<ServiceRequestCount[]>((resolve) => {
      const total = randomFromInterval(10, max);
      const low = Math.ceil(percentage(max, total));
      const medium = Math.ceil(percentage(25, total));
      const high = Math.ceil(percentage(24, total));
      const critical = Math.ceil(percentage(1, total));
      const data: ServiceRequestCount[] = [
        { status: "Low", quantity: low, percentage: max },
        {
          status: "Medium",
          quantity: medium,
          percentage: Math.ceil(percentage(53, max)),
        },
        {
          status: "High",
          quantity: high,
          percentage: Math.ceil(percentage(32, max)),
        },
        {
          status: "Critical",
          quantity: critical,
          percentage: Math.ceil(percentage(15, max)),
        },
      ];
      return resolve(data);
    });
  const output = await fetch();
  return output;
};

export const getTickets = async (page: number, limit: number) => {
  let { data }: { data: GetTicketsResponse } = await api({
    method: "GET",
    url: "/support",
    context_injection: true,

    params: {
      page,
      size: limit,
    },
  });
  return data;
};

export const getTicketById = async (id: string) => {
  const { data }: { data: Ticket } = await api({
    method: "GET",
    context_injection: true,

    url: `/support/${id}`,
  });
  return data;
};

export const createTicket = async (ticket: CreateTicket) => {
  let { data }: { data: Ticket } = await api({
    method: "POST",
    url: "/support",
    context_injection: true,

    data: ticket,
  });
  return data;
};

export const getTicketComments = async (params: GetTicketCommentsParams) => {
  const { ticketId, ...parsed } = params;
  const { data }: { data: GetTicketCommentsResponse } = await api({
    method: "GET",
    context_injection: true,

    url: `/support/${ticketId}/comments`,
    params: parsed,
  });
  return data;
};

export const createTicketComment = async (ticketId: any, comment: string) => {
  const { data }: { data: any } = await api({
    method: "POST",
    context_injection: true,

    url: `/support/${ticketId}/comments`,
    data: { comment },
  });
  return data;
};
