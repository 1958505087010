import React, { forwardRef, memo, useEffect, useMemo, useState } from "react";
import UILoader from "../../_app/components/UILoader";
import { debounce } from "../../_app/utils";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UIFormControl,
  UIFormHelperText,
  UIInputLabel,
  UIMenuItem,
  UISelect,
  UITextField,
  UITypography,
} from "../../_app/components";

export interface Option {
  id?: number | string;
  value?: boolean | string | number | string[];
  label?: string | any;
  disabled?: boolean;
}

interface Props {
  data: Option[];
  ctrClass?: any;
  label?: string;
  name?: string;
  onChange?: any;
  value?: Option["value"];
  defaultValue?: Option["value"];
  multiple?: boolean;
  helperText?: string;
  error?: boolean;
  hasSearch?: boolean;
  defaultSearch?: string;
  searchList?: Option[];
  searchDebounce?: number;
  multiline?: boolean;
  hasNextPage?: boolean;
  infiniteScroll?: boolean;
  nextPage?: any;
  searchOn?: any;
  resetOnClose?: boolean;
  hasNoOptionsText: boolean;
  isFetching: boolean;

  [key: string]: any;
}

const SelectDropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      ctrClass,
      label,
      name,
      data,
      onChange = () => null,
      value,
      defaultValue,
      multiple,
      error,
      helperText,
      multiline = false,
      hasSearch = false,
      infiniteScroll = false,
      hasNextPage,
      nextPage = () => null,
      searchOn = () => null,
      defaultSearch = "",
      searchList,
      searchDebounce = 300,
      resetOnClose = true,
      isFetching,
      hasNoOptionsText = false,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const searchFunc = useMemo(() => debounce(searchOn, searchDebounce), []);
    const fetchNextPage = useMemo(() => debounce(nextPage, 50), [nextPage]);
    const [search, setSearch] = useState(defaultSearch);
    const [options, setOptions] = React.useState<any>(data || []);

    useEffect(() => {
      if (Boolean(search) && searchList && searchList?.length > 0) {
        setOptions(searchList);
      } else {
        setOptions(data);
      }
    }, [data, searchList, search]);

    useEffect(() => {
      searchFunc(search);
    }, [search, searchFunc]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setSearch(val);
    };

    const onScroll = (e: any) => {
      const buffer = 15;
      const scrolled = e.target.scrollHeight - e.target.scrollTop;
      const total = e.target.clientHeight + buffer;
      const hitBottom = scrolled <= total;
      if (hitBottom && hasNextPage) {
        fetchNextPage();
      }
    };

    const renderOption = (item: any) => {
      return (
        <UIMenuItem value={item?.value} key={item?.id || item?.value} className={classes.listValue} disabled={item?.disabled}>
          {item?.label || item.value}
        </UIMenuItem>
      );
    };

    return (
      <UIFormControl
        variant="outlined"
        fullWidth
        size="small"
        className={`${multiline && classes.multiline} ${ctrClass}`}
        error={error}
        data-cy="select-dropdown"
        ref={ref}
        {...props}
      >
        <UIInputLabel id={name}>{label}</UIInputLabel>
        <UISelect
          labelId={name}
          id={name}
          defaultValue={defaultValue || ""}
          value={value}
          onChange={onChange}
          onClose={() => {
            if (resetOnClose) setSearch("");
          }}
          label={label}
          name={name}
          multiple={multiple}
          MenuProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "center",
            },
            disablePortal: false,
            className: classes.body,
            autoFocus: false,
            MenuListProps: {
              onScroll: infiniteScroll ? onScroll : undefined,
              onFocus: (e: React.FocusEvent<HTMLUListElement, Element>) => {
                if (!hasNextPage) return;
                const el = e.currentTarget;
                if (el?.nodeName === "UL") {
                  el.style.height = el.clientHeight - 2 + "px";
                }
              },
            },
          }}
        >
          {hasSearch && (
            <div className={classes.nonClickable}>
              <UITextField
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                placeholder="Search..."
                name="search"
                data-cy="search_component"
                size="small"
                variant="outlined"
                autoFocus
                type="text"
                fullWidth
                defaultValue={search}
                onChange={handleSearch}
                className={classes.searchItem}
              />
            </div>
          )}
          {options?.length === 0 && hasNoOptionsText ? (
            <UITypography variant="body1" className={classes.noOptionsNotice}>
              No {label} found
            </UITypography>
          ) : (
            options?.map((item: any) => {
              return renderOption(item);
            })
          )}
          {isFetching && <UILoader linear />}
        </UISelect>
        {Boolean(helperText) && <UIFormHelperText>{helperText}</UIFormHelperText>}
      </UIFormControl>
    );
  },
);

const useStyles = createStylesheet((theme) => ({
  multiline: {
    "& .MuiSelect-selectMenu": {
      height: "1.1876em",
    },
  },
  listValue: {
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  body: {
    "& > .MuiPaper-root ul": {
      maxHeight: 500,
      overflowY: "auto",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  noOptionsNotice: {
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
  },
  searchItem: {
    pointerEvents: "none",
    position: "sticky",
    top: "0px",
    backgroundColor: "white!important",
    zIndex: 100,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "white!important",
    },
    "& > *": {
      pointerEvents: "auto", // Allow clicks on the search input itself
    },
  },
  nonClickable: {
    pointerEvents: "none",
  },
}));

export default memo(SelectDropdown);
