import { type JSX, useRef, useState } from "react";
import { MoreHoriz as MoreHorizIcon } from "../../_app/components/icons";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UIButton,
  UIButtonProps,
  UIClickAwayListener,
  UIGrow,
  UIList,
  UIListItem,
  UIPaper,
  UIPopper,
} from "../../_app/components";

interface Props {
  options: JSX.Element[];
  className?: string;
  variant?: "text" | "contained" | "outlined";
  renderButton?: (props: UIButtonProps) => JSX.Element;
}

export default function OptionsButton({
  options = [],
  className = "",
  variant = "text",
  renderButton = (props) => (
    <UIButton {...props} endIcon={<MoreHorizIcon />}>
      Options
    </UIButton>
  ),
}: Props) {
  const classes = useStyles();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOptionsOpen(false);
  };

  const buttonProps: UIButtonProps & { "data-cy": string } = {
    "data-cy": "options-button",
    innerRef: anchorRef,
    variant: variant,
    size: "small",
    color: "inherit",
    className: `${classes.button} ${className}`,
    onClick: handleToggle,
  };

  return (
    <>
      {renderButton(buttonProps)}
      <UIPopper
        open={optionsOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <UIGrow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <UIPaper>
              <UIClickAwayListener onClickAway={handleClose}>
                <UIList>
                  {options.map((item, i) => {
                    return <UIListItem key={item.props?.children ?? i}>{item}</UIListItem>;
                  })}
                </UIList>
              </UIClickAwayListener>
            </UIPaper>
          </UIGrow>
        )}
      </UIPopper>
    </>
  );
}

const useStyles = createStylesheet((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,
    fontSize: "0.95rem",
    minWidth: "fit-content",
  },
  popper: {
    zIndex: 101,
  },
}));
