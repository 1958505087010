import { MouseEvent as ReactMouseEvent, useRef, useState } from "react";
import { Filter } from "../types";
import { MIN_FILTERS_COUNT } from "../utils";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UIButton,
  UICheckbox,
  UIClickAwayListener,
  UIGrow,
  UIList,
  UIListItem,
  UIListItemText,
  UIPaper,
  UIPopper,
} from "../../_app/components";

interface Props {
  filters: Filter[];
  updateFilterVisibility: (filterId: string, value: boolean) => void;
}

const MoreFilters = ({ filters, updateFilterVisibility }: Props) => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);

  const onMoreFiltersClick = (event: ReactMouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };

  const onClickAway = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    setShowPopup(false);
  };

  if (filters?.length <= MIN_FILTERS_COUNT) {
    return <></>;
  }

  return (
    <div className={classes.ctr} ref={anchorRef}>
      <UIButton
        variant="contained"
        color="primary"
        onClick={onMoreFiltersClick}
        data-cy="more-filters-button"
        className={classes.button}
      >
        More filters
      </UIButton>
      <UIPopper
        open={showPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <UIGrow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <UIPaper>
              <UIClickAwayListener onClickAway={onClickAway}>
                <UIList id="filters-menu" data-cy="all-filters-items">
                  {filters?.map((filter: Filter, index: any) => (
                    <UIListItem className={classes.filterItem} key={`filter-${filter.id}`} data-cy={"filter-item-" + index}>
                      <div className={classes.filterBlock}>
                        <UIListItemText className={classes.filterName} primary={filter.name} />
                        <UICheckbox
                          color="primary"
                          className={classes.checkbox}
                          data-cy={"filter-item-checkbox-" + index}
                          checked={filter.showToggle}
                          onChange={(e) => updateFilterVisibility(filter.id, e.target.checked)}
                        />
                      </div>
                    </UIListItem>
                  ))}
                </UIList>
              </UIClickAwayListener>
            </UIPaper>
          </UIGrow>
        )}
      </UIPopper>
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    marginLeft: "auto",
  },
  button: {
    padding: "5px 15px",
  },
  popper: {
    zIndex: 1100,
  },
  filterItem: {
    minWidth: "200px",
    height: "36px",
  },
  filterName: {
    float: "left",
  },
  filterBlock: {
    flex: "auto",
  },
  checkbox: {
    float: "right",
  },
}));

export default MoreFilters;
