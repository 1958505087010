/**
 * @deprecated Use {@link Feature} instead
 */
export const featureFlagsMap = {
  BALANCE_WIDGET: "BALANCE_WIDGET",
  OVERDUE_WIDGET: "OVERDUE_WIDGET",
  CARD_PAYMENT: "CARD_PAYMENT",
  DIRECT_DEBIT: "DIRECT_DEBIT",
  DIRECT_DEBIT_UPDATE: "DIRECT_DEBIT_UPDATE",
  STATEMENTS: "STATEMENTS",
  INVOICES: "INVOICES_DEV_EXPERIMENTAL",
  CONTACTS: "CONTACTS",
  CONTACTS_UPDATE: "CONTACTS_UPDATE",
  BILLING_ADDRESS: "BILLING_ADDRESS",
  BILLING_ADDRESS_UPDATE: "BILLING_ADDRESS_UPDATE",
  BILLING_COST_CENTRE_SUMMARY: "BILLING_COST_CENTRE_SUMMARY",
  BILL_TYPE: "BILL_TYPE",
  BILL_TYPE_UPDATE: "BILL_TYPE_UPDATE",
  BILL_NOTIFICATION_EMAIL: "BILL_NOTIFICATION_EMAIL",
  BILL_NOTIFICATION_EMAIL_UPDATE: "BILL_NOTIFICATION_EMAIL_UPDATE",
  CUSTOMER_SUPPORT: "CUSTOMER_SUPPORT",
  PROMOTION: "PROMOTION",
  ADVERT: "ADVERT",
  ASSETS: "ASSETS",
  ASSETS_DETAILED: "ASSETS_DETAILED",
  REPORT_GRAPHS: "REPORT_GRAPHS",
  SUPPORT: "SUPPORT",
  SUPPORT_DC: "SUPPORT_DC",
  HELP: "HELP",
  TICKETS: "TICKETS",
  LINE_TEST: "LINE_TEST",
  LOGIC_MONITOR: "LOGIC_MONITOR",
  ALERTS: "ALERTS",
  BUNDLE_ALERTS: "BUNDLE_ALERTS",
  GLOBAL_BUNDLE_ALERTS: "GLOBAL_BUNDLE_ALERTS",
  BILL_LIMIT_ALERTS: "BILL_LIMIT_ALERTS",
  USAGE_ALERTS: "USAGE_ALERTS",
  BULK_EDIT_USAGE_ALERTS: "BULK_EDIT_USAGE_ALERTS",
  MOBILE_BARS: "MOBILE_BARS",
  BILL_LIMIT: "BILL_LIMIT",
  AUTO_SWITCH: "AUTO_SWITCH",
  DATA_ROAMING: "DATA_ROAMING",
  SIM_SWAP: "SIM_SWAP",
  SIM_ACTIVATE: "SIM_ACTIVATE",
  SIM_LOCK: "SIM_LOCK",
  SIM_UNLOCK: "SIM_UNLOCK",
  SIM_ORDER: "SIM_ORDER",
  PENDING_DISCONNECTIONS: "PENDING_DISCONNECTIONS",
  COST_CENTRE_MANAGEMENT: "COST_CENTRE_MANAGEMENT",
  BILL_SUMMARY_GRAPH: "BILL_SUMMARY_GRAPH",
  USAGE_COSTS_GRAPH: "USAGE_COSTS_GRAPH",
  SERVICE_CHARGE_GRAPH: "SERVICE_CHARGE_GRAPH",
  ASSETS_BY_COST_GRAPH: "ASSETS_BY_COST_GRAPH",
  DATA_USERS_GRAPH: "DATA_USERS_GRAPH",
  CALL_COUNT_GRAPH: "CALL_COUNT_GRAPH",
  CALL_COST_GRAPH: "CALL_COST_GRAPH",
  CALL_DURATION_GRAPH: "CALL_DURATION_GRAPH",
  USAGE_BY_PRODUCT_GRAPH: "USAGE_BY_PRODUCT_GRAPH",
  USAGE_BY_TYPE_GRAPH: "USAGE_BY_TYPE_GRAPH",
  SERVICE_BY_PRODUCT_GRAPH: "SERVICE_BY_PRODUCT_GRAPH",
  SERVICE_BY_CATEGORY_GRAPH: "SERVICE_BY_CATEGORY_GRAPH",
  LIVE_CHAT: "LIVE_CHAT",
  COMMUNICATIONS_CENTRE: "COMMUNICATIONS_CENTRE",
};
