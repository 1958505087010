import { Fragment, useEffect, useState } from "react";
import { groupBy } from "../../_app/utils";
import { CheckCircleRounded as CheckCircleRoundedIcon, RemoveCircle as RemoveCircleIcon } from "../../_app/components/icons";
import { BarUpdate } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIAvatar, UICard, UICardContent, UICardHeader, UIDivider, UIGrid, UITypography } from "../../_app/components";

interface Props {
  updates: BarUpdate[];
}

function BarsUpdateConfirm({ updates, ...props }: Props) {
  const classes = useStyles();
  const [parsed, setParsed] = useState<BarUpdate[][]>([]);

  useEffect(() => {
    const parsed = groupBy(updates || [], "network");
    setParsed(Object.values(parsed));
  }, [updates]);

  return (
    <div className={classes.ctr}>
      <UIAlert severity="info">Please confirm you wish to make the below changes.</UIAlert>
      {parsed.map((group, i) => {
        const network = group?.[0]?.network;
        return (
          <UICard className={classes.card} key={network || i}>
            {Boolean(network) && (
              <UICardHeader
                avatar={<UIAvatar src={require(`../../_app/images/logo-${network?.toLowerCase()}.png`)} variant="square" />}
              />
            )}
            <UICardContent>
              <UIGrid className={classes.cardContent} container>
                <UIGrid container direction="row" alignItems="center" spacing={1}>
                  <UIGrid size={{ sm: 3 }}>
                    <UITypography variant="h5">Bars</UITypography>
                  </UIGrid>
                  <UIGrid size={{ sm: 9 }}>
                    <UITypography variant="h5">Action</UITypography>
                  </UIGrid>
                </UIGrid>
                {group.map((bar: BarUpdate) => (
                  <Fragment key={bar.name}>
                    <UIDivider className={classes.divider} />
                    <UIGrid container direction="row" alignItems="center" spacing={1} key={bar.name}>
                      <UIGrid size={{ sm: 3 }}>{bar.label}</UIGrid>
                      <UIGrid size={{ sm: 9 }}>
                        <UIGrid container direction="row" alignItems="center">
                          {bar.value ? (
                            <>
                              <CheckCircleRoundedIcon className={classes.checkIcon} fontSize="small" />
                              Add Bar
                            </>
                          ) : (
                            <>
                              <RemoveCircleIcon className={classes.removeIcon} fontSize="small" />
                              Remove Bar
                            </>
                          )}
                        </UIGrid>
                      </UIGrid>
                    </UIGrid>
                  </Fragment>
                ))}
              </UIGrid>
            </UICardContent>
          </UICard>
        );
      })}
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  ctr: {},
  card: {
    borderLeft: "3px solid",
    borderLeftColor: theme.palette.primary.main,
    marginTop: "1rem",
    paddingLeft: theme.spacing(1),
    width: "100%",
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
  },
  cardContent: {
    gap: theme.spacing(1.5),
  },
  divider: {
    width: "100%",
  },
  checkIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  removeIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

export default BarsUpdateConfirm;
