import { useState } from "react";
import { createStylesheet, lighten } from "../../_app/utils/styles";
import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from "../../_app/components/icons";
import { ArticleTopic } from "../types";
import { UICollapse, UIList, UIListItemButton, UIListItemText } from "../../_app/components";

interface MainProps {
  topic: ArticleTopic;
  handleClick: (id: ArticleTopic["articleId"]) => void;
  selectedArticleId?: string;
}

const HelpNavMenu = ({ topic, selectedArticleId, handleClick }: MainProps) => {
  const classes = useStyles();
  const subTopicLength = topic?.subTopics?.length;

  const [openTopic, setOpenTopic] = useState(false);

  const onClick = () => {
    handleClick(topic.articleId);
    setOpenTopic(!openTopic);
  };

  const selected = selectedArticleId === topic.articleId;
  const hasSubTopics = subTopicLength !== 0;
  const expand = openTopic && hasSubTopics;

  if (topic) {
    return (
      <>
        <UIListItemButton onClick={onClick} data-cy={`nav-help-article-${topic.articleId}`} dense>
          <UIListItemText primary={topic.name} className={selected && !hasSubTopics ? classes.selected : ""} />
          {expand ? (
            <ExpandMoreIcon className={classes.moreIcon} />
          ) : !hasSubTopics ? (
            <></>
          ) : (
            <ChevronRightIcon className={classes.moreIcon} />
          )}
        </UIListItemButton>
        {subTopicLength !== 0 && (
          <UICollapse in={openTopic} timeout="auto" unmountOnExit>
            <UIList component="div" disablePadding className={classes.subList}>
              {topic?.subTopics?.map((subTopic: ArticleTopic, i: any) => (
                <HelpNavMenu
                  key={subTopic.articleId}
                  topic={subTopic}
                  handleClick={handleClick}
                  selectedArticleId={selectedArticleId}
                />
              ))}
            </UIList>
          </UICollapse>
        )}
      </>
    );
  }

  return <></>;
};

const useStyles = createStylesheet((theme) => ({
  selected: {
    "& > span": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  icon: {
    marginRight: "20px",
    width: "20px",
    height: "20px",
  },
  moreIcon: {
    width: "18px",
    height: "18px",
  },
  subList: {
    backgroundColor: lighten(theme.palette.grey[100], 0.5),
  },
}));

export default HelpNavMenu;
