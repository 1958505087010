import { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from "react";
import { debounce } from "../../_app/utils";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UIFormControl,
  UIFormHelperText,
  UIInputLabel,
  UIMenuItem,
  UISelect,
  UITextField,
  UITooltip,
  UITypography,
} from "../../_app/components";

const SelectDropdownLegacy = ({
  ctrClass,
  label,
  name,
  data,
  selectedLevel,
  onChange = () => null,
  value,
  multiple,
  error,
  helperText,
  multiline = false,
  hasSearch = false,
  infiniteScroll = false,
  hasNextPage,
  nextPage = () => null,
  searchOn = () => null,
  searchList,
  searchDebounce = 300,
  hasNoOptionsText = false,
  ...props
}: any) => {
  const classes = useStyles();
  // eslint-disable-next-line
  const searchFunc = useMemo(() => debounce(searchOn, searchDebounce), []);
  const fetchNextPage = useMemo(() => debounce(nextPage, 50), [nextPage]);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [showNotFound, setSearchNotFound] = useState(false);

  useEffect(() => {
    if (Boolean(search) && !Boolean(searchList?.length)) {
      setSearchNotFound(true);
    } else if (Boolean(search) && searchList?.length > 0) {
      setOptions(searchList);
    } else {
      setOptions(data);
      setSearchNotFound(false);
    }
  }, [selectedLevel, data, searchList, search]);

  useEffect(() => {
    searchFunc(search);
  }, [search, searchFunc]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setSearch(val);
  };

  const onScroll = (e: any) => {
    const buffer = 15;
    const scrolled = e.target.scrollHeight - e.target.scrollTop;
    const total = e.target.clientHeight + buffer;
    const hitBottom = scrolled <= total;
    if (hitBottom && hasNextPage) {
      fetchNextPage();
    }
  };

  const searchTitle = (userLevel: any, label: any) => {
    switch (userLevel) {
      case 10:
        return `Search ${label}s...`;
      case 20:
        return "Search Companies...";
      case 30:
        return `Search ${label}s...`;
      case 35:
        return `Search ${label}s...`;
      case 40:
        return "Search Cost Centres...";
      case 50:
        return "Search CLI/Identifiers...";
      default:
        return "Search...";
    }
  };

  const renderOption = (item: any, props: any) => {
    if (item.title) {
      return (
        <UITooltip title={item.title}>
          <div>
            <UIMenuItem
              value={item.value}
              key={item.id}
              className={`${classes.filterListValue} ${props.periods ? classes.period : ""}`}
              disabled={item.disabled}
            >
              {`${item.label}`}
            </UIMenuItem>
          </div>
        </UITooltip>
      );
    } else {
      return (
        <UIMenuItem
          value={item.value}
          key={item.id}
          className={`${classes.filterListValue} ${props.periods ? classes.period : ""}`}
          disabled={item.disabled}
        >
          {`${item.label}`}
        </UIMenuItem>
      );
    }
  };

  if (!options.length && !hasNoOptionsText) return null;
  if (!options.length && hasNoOptionsText) {
    return (
      <UITypography variant="body1" className={classes.noOptionsNotice}>
        No {label} to display
      </UITypography>
    );
  }

  return (
    <UIFormControl
      variant="outlined"
      fullWidth
      size="small"
      className={`${multiline ? classes.multiline : ""} ${ctrClass ? ctrClass : ""} ${props.periods ? classes.period : ""}`}
      error={error}
      {...props}
    >
      <UIInputLabel id={name}>{label}</UIInputLabel>
      <UISelect
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        onClose={() => setSearch("")}
        label={label}
        name={name}
        multiple={multiple && data?.length >= 1}
        MenuProps={{
          anchorOrigin: {
            vertical: "center",
            horizontal: "center",
          },
          className: classes.filterBody,
          autoFocus: false,
          MenuListProps: {
            onScroll: infiniteScroll ? onScroll : undefined,
            onFocus: (e: FocusEvent<HTMLUListElement, Element>) => {
              if (!hasNextPage) return;
              const el = e.currentTarget;
              if (el.nodeName === "UL") {
                el.style.height = el.clientHeight - 2 + "px";
              }
            },
          },
        }}
      >
        {hasSearch && (
          <UITextField
            onKeyDown={(e) => e.stopPropagation()}
            placeholder={searchTitle(selectedLevel, label)}
            data-cy="search_component"
            size="small"
            variant="outlined"
            autoFocus
            fullWidth
            value="search"
            onChange={handleSearch}
            className={classes.searchItem}
          />
        )}
        {showNotFound === true ? (
          <div className={classes.loader}>Nothing was found</div>
        ) : (
          options.map((item: any) => {
            return renderOption(item, props);
          })
        )}
      </UISelect>
      <UIFormHelperText>{helperText}</UIFormHelperText>
    </UIFormControl>
  );
};

const useStyles = createStylesheet((theme) => ({
  multiline: {
    "& .MuiSelect-selectMenu": {
      height: "1.1876em",
    },
  },
  filterListValue: {
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  period: {
    "&.MuiListItem-root": {
      justifyContent: "center",
      textTransform: "capitalize",
    },
    "& .MuiSelect-select": {
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
  filterBody: {
    "& > .MuiPaper-root ul": {
      maxHeight: 500,
      overflowY: "auto",
      paddingTop: "0px",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    flex: 1,
  },
  filterSearch: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  searchItem: {
    position: "sticky",
    top: "0px",
    backgroundColor: "white!important",
    zIndex: 100,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "white!important",
    },
  },
  searchField: {
    backgroundColor: "white",
  },
  noOptionsNotice: {
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
}));

export default SelectDropdownLegacy;
