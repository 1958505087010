import { type JSX, ReactElement, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "../sso-api";
import { useIdentityUser } from "../hooks";
import { useHubUserExist } from "../../user/hooks";
import { useMyAccounts } from "../../account/hooks";
import LoadingOverlay from "../../_app/components/LoadingOverlay";
import { getCachedContextHierarchy } from "../../context/utils";
import { isCognitoUser } from "../utils";
import { useAuthenticator } from "@aws-amplify/ui-react";

interface PrivateRouteProps {
  children: ReactElement<any, any> | null;
}

export const PrivateRoute = ({ children }: PrivateRouteProps): JSX.Element => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context: any) => [context.authStatus]);

  const { data: identityUser, isFetching: identityUserFetching } = useIdentityUser();
  const hasValidIdentityUser = identityUser && !identityUser?.expired;

  const { data: doesHubUserExist, isFetching: hubUserExistenceFetching } = useHubUserExist({
    enabled: hasValidIdentityUser,
  });

  const { data: groups } = useMyAccounts();
  const hasGroupContext = Boolean(getCachedContextHierarchy()?.lastParentId);
  const hasMultipleGroups = groups && groups.length > 1;

  // Log in if we don't have a valid IdP user
  useEffect(() => {
    if (!identityUserFetching && !hasValidIdentityUser) {
      if (isCognitoUser()) {
        navigate("/login");
      } else {
        login();
      }
    }
  }, [hasValidIdentityUser, identityUserFetching, navigate]);

  // Redirect to the `no-user` page if we don't have a user in Hub
  useEffect(() => {
    if (hasValidIdentityUser && !hubUserExistenceFetching && !doesHubUserExist) {
      setTimeout(() => navigate("/no-user", { replace: true }));
    }
  }, [hasValidIdentityUser, hubUserExistenceFetching, doesHubUserExist, navigate]);

  // Redirect to the Group selection if a group was not selected yet
  useEffect(() => {
    if (!hasGroupContext && hasMultipleGroups) {
      setTimeout(() => navigate("/groups", { replace: true }));
    }
  }, [hasMultipleGroups, hasGroupContext, navigate]);

  if (isCognitoUser() && authStatus !== "authenticated" && hasValidIdentityUser) {
    return <Navigate to="/login" />;
  }

  if (doesHubUserExist) {
    return children || <div />;
  }

  return <LoadingOverlay />;
};
