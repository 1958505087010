import api from "../_app/api";
import { SortParam } from "../_app/components/Table/helpers";
import { Query } from "../filter/types";
import { filtersIdMap } from "../filter/utils";
import { UsageAnalysisColumn, UsageAnalysisResponse, UsageAnalysisSummaryTable, UsageAnalysisSummaryTotal } from "./types";
import { formatTableTypeParam } from "./utils";

export const getUsageAnalysisColumns = async (tableType: string) => {
  const type = formatTableTypeParam(tableType);
  let { data }: { data: UsageAnalysisColumn[] } = await api({
    method: "GET",
    url: `/usage${type ? "/summary/" + type : ""}/columns`,
    context_injection: true,
  });
  return data;
};

export const getUsageAnalysis = async (startingToken: string, limit: number, sort: SortParam[], queries: Query[]) => {
  const params = new URLSearchParams();
  if (startingToken) params.append("starting-token", startingToken);
  params.append("size", limit.toString());
  appendQueryParams(params, queries);
  if (sort.length) appendSortParams(params, sort);
  const { data }: { data: UsageAnalysisResponse } = await api({
    method: "GET",
    url: "/usage",
    context_injection: true,

    params,
  });
  return data;
};

export const getUsageAnalysisSummary = async (
  startingToken: string,
  limit: number,
  sort: SortParam[],
  tableType: string,
  queries: Query[],
) => {
  const params = new URLSearchParams();
  if (startingToken) params.append("starting-token", startingToken);
  params.append("size", limit.toString());
  appendQueryParams(params, queries);
  if (sort.length) appendSortParams(params, sort);
  const { data }: { data: UsageAnalysisSummaryTable } = await api({
    method: "GET",
    url: `/usage/summary/${formatTableTypeParam(tableType)}`,
    context_injection: true,

    params,
  });
  return data;
};

export const getUsageAnalysisSummaryTotal = async (queries: Query[]) => {
  const params = new URLSearchParams();
  appendQueryParams(params, queries);
  const { data }: { data: UsageAnalysisSummaryTotal } = await api({
    method: "GET",
    url: "/usage/summary/totals",
    context_injection: true,

    params,
  });
  return data;
};

export const getUsageAnalysisFile = async (fileType: string, queries: Query[], sort: SortParam[]) => {
  const params = new URLSearchParams();
  appendQueryParams(params, queries);
  if (sort.length) appendSortParams(params, sort);
  return api({
    method: "GET",
    url: `/usage/files/${fileType}`,
    context_injection: true,

    params,
  });
};

export const getUsageAnalysisSummaryFile = async (tableType: string, queries: Query[], sort: SortParam[]) => {
  const params = new URLSearchParams();
  appendQueryParams(params, queries);
  if (sort.length) appendSortParams(params, sort);
  const type = formatTableTypeParam(tableType);
  return api({
    method: "GET",
    url: `/usage${type ? "/summary/" + type : ""}/export`,
    context_injection: true,

    params,
  });
};

const appendQueryParams = (params: URLSearchParams, queries: Query[]) => {
  queries.forEach((q) => {
    if (q.id === filtersIdMap.BILL_PERIOD) {
      params.append(q.id, q.value.replace("UNBILLED-", ""));
    } else {
      params.append(q.id, q.value);
    }
  });
};

const appendSortParams = (params: URLSearchParams, sort: SortParam[]) => {
  sort.forEach((s) => {
    params.append("sort", [s.col, s.dir].join(","));
  });
};
