import { UITypography } from "../../_app/components";
import { createStylesheet } from "../../_app/utils/styles";

interface PaymentDialogContentFieldProps {
  name: string;
  value: string | number;
}

export const PaymentDialogContentField = ({ name, value }: PaymentDialogContentFieldProps) => {
  const classes = useStyles();

  return (
    <div className={classes.detailFieldWrap}>
      <UITypography variant="subtitle1" className={classes.fieldName}>
        {name}
      </UITypography>
      <UITypography variant="h4" data-cy={name}>
        {value}
      </UITypography>
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  detailFieldWrap: {
    display: "flex",
    alignItems: "baseline",
  },
  fieldName: {
    width: "140px",
    margin: `0 ${theme.spacing(3)} 8px 0`,
    textAlign: "right",
  },
}));
