import { UIBackdrop } from ".";
import UILoader from "./UILoader";

function LoadingOverlay() {
  return (
    <UIBackdrop open invisible>
      <UILoader color="inherit" />
    </UIBackdrop>
  );
}

export default LoadingOverlay;
