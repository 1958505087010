import { ChangeEvent, useEffect, useState } from "react";
import { Filter } from "../../types";
import FilterBox from "./FilterBox";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIButton, UIFormControl, UIGrid, UISelect, UISelectChangeEvent, UITextField } from "../../../_app/components";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

export default function AmountFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();
  const val1 = selection?.[0]?.split(" ");
  const val2 = selection?.[1]?.split(" ");
  const [value, setValue] = useState(val1 ? val1?.[0] : "none");
  const [inputValue, setInputValue] = useState(val1 ? val1?.[1] : "");
  const [criteriaValue, setCriteriaValue] = useState(val2 ? val2?.[0] : "none");
  const [inputCriteriaValue, setInputCriteriaValue] = useState(val2 ? val2?.[1] : "");

  const [criteriaShow, setCriteria] = useState(!!val2);

  useEffect(() => {
    const firstConditionValues = [value, inputValue];
    const secondConditionValues = [criteriaValue, inputCriteriaValue];

    const firstCondition = firstConditionValues?.join(" ");
    const secondCondition = secondConditionValues?.join(" ");

    const result = [
      ...(firstCondition !== "none " ? [firstCondition] : []),
      ...(secondCondition !== "none " ? [secondCondition] : []),
    ];
    setSelection(result);
  }, [setSelection, value, inputValue, criteriaValue, inputCriteriaValue]);

  const addCriteria = () => {
    if (criteriaShow) {
      setCriteriaValue("none");
      setInputCriteriaValue("");
      setCriteria((oldCriteria) => !oldCriteria);
    } else {
      setCriteria((oldCriteria) => !oldCriteria);
    }
  };

  const handleChange = (event: UISelectChangeEvent<any>) => {
    setValue(event.target.value as string);
  };

  const handleCriteriaChange = (event: UISelectChangeEvent<any>) => {
    setCriteriaValue(event.target.value as string);
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const validatedValue = value.replace(/[^0-9.]/g, "");
    setInputValue(validatedValue);
  };

  const handleCriteriaInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const validatedValue = value.replace(/[^0-9.]/g, "");
    setInputCriteriaValue(validatedValue);
  };

  useEffect(() => {
    if (value === "=") {
      setCriteria(false);
      setCriteriaValue("none");
      setInputCriteriaValue("");
    }
  }, [setCriteria, value]);

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        disableApply={
          selection.length < 1 ||
          value === "none" ||
          (value !== "none" && inputValue === "") ||
          (criteriaValue === "none" && inputCriteriaValue !== "") ||
          (criteriaValue !== "none" && inputCriteriaValue === "")
        }
      >
        <div>
          <UIFormControl variant="outlined" size="small" className={classes.formSelect}>
            <UISelect
              native
              value={value}
              onChange={handleChange}
              inputProps={{
                name: "value",
                id: "value",
              }}
            >
              <option value="none" hidden />
              <option value="equal">=</option>
              <option value="greater_than">&gt;</option>
              <option value="less_than">&lt;</option>
            </UISelect>
          </UIFormControl>
          <UITextField
            variant="outlined"
            size="small"
            name="inputValue"
            value={inputValue}
            onChange={handleInput}
            className={classes.formInput}
            placeholder="Enter cost"
          />
        </div>

        <UIGrid container direction="row" justifyContent="center" alignItems="center">
          <UIButton
            size="small"
            disabled={value === "=" || value === "none"}
            onClick={() => addCriteria()}
            className={classes.criteriaButton}
          >
            {!criteriaShow ? "add another criteria" : "remove criteria"}
          </UIButton>
        </UIGrid>

        {criteriaShow && (
          <div>
            <UIFormControl variant="outlined" size="small" className={classes.formSelect}>
              <UISelect
                native
                value={criteriaValue}
                onChange={handleCriteriaChange}
                inputProps={{
                  name: "criteriaValue",
                  id: "criteriaValue",
                }}
              >
                {value === "greater_than" ? (
                  <>
                    <option value="none" hidden />
                    <option value="less_than">&lt;</option>
                  </>
                ) : (
                  <>
                    <option value="none" hidden />
                    <option value="greater_than">&gt;</option>
                  </>
                )}
              </UISelect>
            </UIFormControl>
            <UITextField
              variant="outlined"
              size="small"
              name="inputCriteriaValue"
              value={inputCriteriaValue}
              onChange={handleCriteriaInput}
              placeholder="Enter cost"
              className={classes.formInput}
            />
          </div>
        )}
      </FilterBox>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  formSelect: {
    marginLeft: theme.spacing(2),
  },
  formInput: {
    marginInline: theme.spacing(2),
  },
  criteriaButton: {
    margin: theme.spacing(1),
  },
}));
