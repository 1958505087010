import { createContact, deleteContact, getContactById, getContacts, updateContact } from "./apis";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { Contact, GetContactsResponse } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";

export function useSingleContact(id: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<Contact, AjaxResponse>(["contact", id], () => getContactById(id), {
    ...options,
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useContacts(page: number, limit: number, options = {}) {
  return useQuery<GetContactsResponse, AjaxResponse>(["contacts", { page, limit }], () => getContacts(page, limit), {
    keepPreviousData: true,
    ...options,
  });
}

export function useContactsInfinite(pageSize: number = 25, options = {}) {
  return useInfiniteQuery<GetContactsResponse>("contacts-inf", ({ pageParam = 0 }) => getContacts(pageParam, pageSize), {
    getNextPageParam: ({ page, total }: any) => {
      if ((page + 1) * pageSize < total) {
        return page + 1;
      } else {
        return undefined;
      }
    },
    ...options,
  });
}

export function useContactCreate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Contact, AjaxResponse, Contact>((contact: Contact) => createContact(contact), {
    ...options,
    onSuccess: (contact) => {
      queryClient.setQueryData(["contact", contact.id], () => contact);
      queryClient.invalidateQueries(["contacts"]);
      setFeedbackAlertSuccess("Your request has been submitted");
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useContactUpdate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Contact, AjaxResponse, Contact>((contact) => updateContact(contact), {
    ...options,
    onSuccess: (contact) => {
      queryClient.invalidateQueries(["contact", contact.id]);
      queryClient.invalidateQueries(["contacts"]);
      setFeedbackAlertSuccess("Your request has been submitted");
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useContactDelete(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Contact, AjaxResponse, Contact>((contact) => deleteContact(contact), {
    onSuccess: (contact) => {
      queryClient.invalidateQueries(["contact", contact.id]);
      queryClient.invalidateQueries(["contacts"]);
      setFeedbackAlertSuccess("Contact deleted");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}
