import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from "../../_app/components/icons";
import { Filter } from "../types";
import IdentifierFilter from "./FilterTypes/IdentifierFilter";
import AmountFilter from "./FilterTypes/AmountFilter";
import DateRangeFilter from "./FilterTypes/DateRangeFilter";
import TextFilter from "./FilterTypes/TextFilter";
import EnumFilter from "./FilterTypes/EnumFilter";
import TimeFilter from "./FilterTypes/TimeFilter";
import RangeFilter from "./FilterTypes/RangeFilter";
import BooleanFilter from "./FilterTypes/BooleanFilter";
import { filtersIdMap } from "../utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIClickAwayListener, UIGrow, UIPaper, UIPopper } from "../../_app/components";

interface Props {
  item: Filter;
}

const FilterItem = ({ item }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selection, setSelection] = useState<string[]>(item?.value ? item.value.split(",") : []);

  const openDropdown = () => {
    let selectedValues: string[] = [];
    queryParams.forEach((value, key) => {
      if (key === item.id) {
        selectedValues = value.split(",");
      }
    });
    setSelection(selectedValues);
    setShowPopup(!showPopup);
  };

  const resetParamsQuery = useCallback(() => {
    const params: string[] = [];

    queryParams.forEach((value, key) => {
      if (key === item?.id) {
        params.push(key);
      }
    });
    params.forEach((key) => {
      queryParams.delete(key);
    });
    navigate({ search: queryParams.toString() }, { replace: true });
  }, [navigate, item?.id, queryParams]);

  const addFilterParams = useCallback(
    (updated: Filter) => {
      updated?.values?.forEach((value) => {
        queryParams.append(updated?.id, value);
      });
      navigate({ search: queryParams.toString() }, { replace: true });
      setShowPopup(false);
    },
    [navigate, queryParams],
  );

  const addFilter = useCallback(
    (value?: string) => {
      let newSelection = selection.slice();
      if (value && typeof value === "string") {
        newSelection = [value];
        setSelection([value]);
      }
      if (item.id === filtersIdMap.ACCOUNT || item.id === filtersIdMap.SERVICES_ACCOUNT) {
        newSelection = selection.map((item) => item.split("-")[0].trim());
      }

      const updated = {
        ...item,
        values: [newSelection?.join(",")],
      };

      resetParamsQuery();
      addFilterParams(updated);
    },
    [addFilterParams, item, resetParamsQuery, selection],
  );

  useEffect(() => {
    if (!queryParams.toString() && item.defaultValue) addFilter(item.defaultValue);
  }, [queryParams, addFilter, item.defaultValue]);

  const resetFilter = () => {
    resetParamsQuery();
    setSelection([]);
  };

  const closeFilter = () => {
    setSelection([]);
    setShowPopup(false);
  };

  const renderFilterByType = (item: Filter) => {
    let FilterComponent = null;
    if (item.name === "Call Duration") FilterComponent = TimeFilter;
    else {
      switch (item.type) {
        case "IDENTIFIER": {
          FilterComponent = IdentifierFilter;
          break;
        }
        case "PERIOD_RANGE": {
          FilterComponent = RangeFilter;
          break;
        }
        case "ENUM": {
          FilterComponent = EnumFilter;
          break;
        }
        case "AMOUNT": {
          FilterComponent = AmountFilter;
          break;
        }
        case "TEXT": {
          FilterComponent = TextFilter;
          break;
        }
        case "DATE_RANGE": {
          FilterComponent = DateRangeFilter;
          break;
        }
      }
    }
    if (!FilterComponent) return;
    return (
      <FilterComponent
        key={item?.id}
        item={item}
        options={item.options}
        setSelection={setSelection}
        selection={selection}
        addSelection={addFilter}
        resetSelection={resetFilter}
        close={closeFilter}
      />
    );
  };

  const onFilterAwayClick = (e: any) => {
    const filterBtn = e?.path?.find?.((e: any) => {
      return e.attributes?.[3]?.nodeValue === `filter-button-${item.id}`;
    });
    if (filterBtn || item.type === "PERIOD_RANGE") return; //@hack - select portal fires clickaway
    setShowPopup(false);
  };

  if (item.type === "BOOLEAN") {
    return (
      <BooleanFilter
        key={item?.id}
        addSelection={addFilter}
        defaultValue={item?.defaultValue ?? "false"}
        value={queryParams.get(item?.id)}
        label={item.label ?? item.name ?? ""}
      />
    );
  }

  return (
    <div ref={anchorRef} data-cy={`filter-id-${item.id}`}>
      <UIButton
        variant="text"
        color="inherit"
        className={classes.itemButton}
        onClick={openDropdown}
        data-id={`filter-button-${item.id}`}
      >
        {item.label ?? item.name}
        {showPopup ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </UIButton>
      <UIPopper
        open={showPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        className={classes.popper}
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <UIGrow {...TransitionProps}>
            <UIPaper elevation={5}>
              <UIClickAwayListener onClickAway={onFilterAwayClick} disableReactTree>
                <div className={classes.filterItem}>{renderFilterByType(item)}</div>
              </UIClickAwayListener>
            </UIPaper>
          </UIGrow>
        )}
      </UIPopper>
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  popper: {
    zIndex: 1100,
  },
  filterItem: {
    minWidth: 325,
    padding: "0px",
  },
  itemButton: {
    fontWeight: 600,
    fontSize: "0.95rem",
  },
}));

export default FilterItem;
