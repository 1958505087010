import Public from "../../_app/layouts/Public";
import { useJWTAuth } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import { createStylesheet } from "../../_app/utils/styles";
import { UITypography } from "../../_app/components";

export const JWTLogin = () => {
  const classes = useStyles();
  const { isLoading } = useJWTAuth();

  return (
    <Public className={classes.ctr}>
      {isLoading && <UILoader className={classes.loader} />}
      <UITypography>Logging you in</UITypography>
    </Public>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "calc(100vh)",
  },
  loader: {
    marginBottom: theme.spacing(3),
  },
}));

export default JWTLogin;
