import { useNavigate } from "react-router-dom";
import { ArrowForward as ArrowForwardIcon } from "../../_app/components/icons";
import { withWidget } from "./components/Widget";
import { useGetBalance, useGetOverdue } from "../../billing/hooks";
import { formatNumber } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UICard, UITypography } from "../../_app/components";

interface Props {
  hasManage?: boolean;
}

const BalanceOverdue = ({ hasManage = true }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: balance, isFetching } = useGetBalance();
  const { data: overdue, isFetching: isFetchingOver } = useGetOverdue();
  return (
    <UICard isFetching={isFetching || isFetchingOver} className={classes.ctr}>
      <div className={classes.contentWrap}>
        <UITypography variant="h6">Balance / Overdue</UITypography>
        <div className={classes.value}>
          <UITypography variant="h2">{formatNumber(balance || 0, 2, "£")}</UITypography>
          <UITypography variant="h2">
            &nbsp;&nbsp;/&nbsp;&nbsp;
            {formatNumber(overdue || 0, 2, "£")}
          </UITypography>
        </div>
        {hasManage && (
          <UIButton
            className="widget-button"
            color="primary"
            variant="contained"
            onClick={() => navigate("/statements")}
            endIcon={<ArrowForwardIcon />}
          >
            View
          </UIButton>
        )}
      </div>
    </UICard>
  );
};

const useStyles = createStylesheet(() => ({
  ctr: {
    height: "100%",
  },
  contentWrap: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  value: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    justifyContent: "center",
  },
}));

export default withWidget(BalanceOverdue, "balance-overdue", featureFlagsMap.BALANCE_WIDGET);
