import { Fragment } from "react";
import { Permission } from "../api";
import { formatDisabledReason } from "../utils";
import { createStylesheet, lighten } from "../../_app/utils/styles";
import {
  UICheckbox,
  UIFormControl,
  UIFormControlLabel,
  UIFormGroup,
  UIGrid,
  UITooltip,
  UITypography,
  UIZoom,
} from "../../_app/components";

interface Props {
  permissions: Permission[];
  onChange?: (perm: any) => void;
  allowDisabling?: boolean;
}

const PermissionsViewer = ({ permissions = [], onChange = () => null, allowDisabling = true }: Props) => {
  const classes = useStyles();

  const onParentPermissionClick = (parent: any, value: any) => {
    onChange({
      ...parent,
      value,
      permissions: parent?.permissions.map((p: any) => ({
        ...p,
        value: false,
      })),
    });
  };

  const onChildPermissionClick = (child: any, value: boolean, parent: any) => {
    onChange({
      ...parent,
      value: value && !parent?.value ? true : parent?.value,
      permissions: parent?.permissions.map((p: any) => ({
        ...p,
        value: p?.id === child?.id ? value : p?.value,
      })),
    });
  };

  const isDisabled = (p: Permission) => allowDisabling && p?.disabled;

  const renderWithTooltip = (msg: string, children: any, key?: string) => {
    return (
      <UITooltip key={key} title={msg} TransitionComponent={UIZoom} placement="top" arrow>
        {children}
      </UITooltip>
    );
  };

  return (
    <>
      {permissions?.map((parent: Permission) => {
        return (
          <Fragment key={parent?.id}>
            <UIGrid size={{ xs: 12, md: 4 }} className={classes.groupTitleCtr} data-cy="permission-group">
              <UITypography variant="h3" gutterBottom>
                {parent?.groupName}
              </UITypography>
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 8 }}>
              <UIFormControl component="fieldset" className={classes.permRow}>
                <UIFormGroup className={classes.parentPerm} row>
                  {renderWithTooltip(
                    isDisabled(parent) ? formatDisabledReason(parent.disabledReason) : "",
                    <UIFormControlLabel
                      data-cy="permission-item"
                      value={parent?.value}
                      className={classes.label}
                      label={<UITypography variant="subtitle1">{parent?.name}</UITypography>}
                      labelPlacement="start"
                      control={
                        <UICheckbox
                          color="primary"
                          disabled={isDisabled(parent)}
                          className={classes.checkbox}
                          checked={Boolean(parent?.value)}
                          data-cy={parent?.code}
                          onChange={(ev: any, value: boolean) => onParentPermissionClick(parent, value)}
                        />
                      }
                    />,
                  )}
                </UIFormGroup>
                <UIFormGroup className={classes.permRows} row>
                  {parent?.permissions?.map((child: Permission) =>
                    renderWithTooltip(
                      isDisabled(child) ? formatDisabledReason(child.disabledReason) : "",
                      <UIFormControlLabel
                        key={child?.id}
                        data-cy="permission-item"
                        className={classes.label}
                        value={child?.value}
                        label={<UITypography variant="subtitle1">{child?.name}</UITypography>}
                        labelPlacement="start"
                        control={
                          <UICheckbox
                            color="primary"
                            disabled={isDisabled(child)}
                            className={classes.checkbox}
                            checked={Boolean(child?.value)}
                            data-cy={child?.code}
                            onChange={(ev: any, value: boolean) => onChildPermissionClick(child, value, parent)}
                          />
                        }
                      />,
                      child?.id,
                    ),
                  )}
                </UIFormGroup>
              </UIFormControl>
            </UIGrid>
          </Fragment>
        );
      })}
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  groupTitleCtr: {
    paddingTop: "7px",
  },
  permRow: {
    width: "100%",
    marginBottom: "4em",
    "& label": {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  permRows: {
    "& :hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
  },
  parentPerm: {
    borderBottom: "2px solid " + theme.palette.grey[300],
    "& :hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
  },
  label: {
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    padding: "0px 9px 0px 15px",
  },
  checkbox: {
    paddingRight: 0,
  },
}));

export default PermissionsViewer;
