import { Face as FaceIcon } from "../../components/icons";
import { useIdentityUser } from "../../../auth/hooks";
import { format } from "date-fns";
import { isImpersonatedUser } from "../../../auth/utils";
import { createStylesheet } from "../../utils/styles";
import { UIChip, UITooltip, UITooltipProps } from "../../components";

interface Props {
  className?: string;
  lite?: boolean;
  placement?: UITooltipProps["placement"];
}

const MasqueradingChip = ({ lite, placement = "top", className }: Props) => {
  const classes = useStyles();
  const { data: identityUser, isFetching } = useIdentityUser();
  const isMasquerading = isImpersonatedUser(identityUser);

  if (isFetching || !isMasquerading) return null;

  const sessionEnding = identityUser?.expires_at && format(identityUser?.expires_at * 1000, "HH:mm aaaaa'm'");
  return (
    <UITooltip
      title={`${lite ? "Masquerading session" : "Session"} ${sessionEnding ? `ends at ${sessionEnding}` : "ended."}`}
      placement={placement}
      classes={{ tooltip: classes.tooltip }}
    >
      <UIChip
        icon={<FaceIcon />}
        color="primary"
        label={lite ? "" : "Masquerading"}
        className={`${classes.chip} ${lite && classes.lite} ${className}`}
        data-cy="masquerading-user-chip"
        size="small"
      />
    </UITooltip>
  );
};

const useStyles = createStylesheet((theme) => ({
  chip: {
    display: "flex",
    margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
  },
  lite: {
    paddingRight: 7,
    "& > span": {
      display: "none",
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "12px",
    fontWeight: "bold",
  },
}));

export default MasqueradingChip;
