import { useEffect, useState } from "react";
import { getAuthConfig } from "../auth/api";
import { setupIdentity } from "../auth/sso-api";
import { isCognitoUser } from "../auth/utils";
import LoadingOverlay from "./components/LoadingOverlay";
import { initAmplify } from "./lib/amplify";
import { loadCspTag } from "./lib/csp";
import Providers from "./providers";
import { getUrlHost } from "./utils";
import AppRoutes from "./Routes";

function App() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const authConfig = await getAuthConfig();
        window.authConfig = authConfig;

        loadCspTag("default-src", [authConfig.hostedLoginPageDomain, getUrlHost(authConfig.tokenIssuerUrl)]);
        if (isCognitoUser()) {
          initAmplify(authConfig);
        } else {
          setupIdentity(authConfig.customerPortalClientId, authConfig.tokenIssuerUrl);
        }

        setIsReady(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  if (!isReady) return <LoadingOverlay />;
  return (
    <Providers>
      <AppRoutes />
    </Providers>
  );
}

export default App;
