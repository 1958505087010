import { CSSObject } from "tss-react";
import { tss } from "tss-react/mui";
import type { Theme } from "@mui/material";

export { useMediaQuery, CssBaseline, ThemeProvider as MUIProvider } from "@mui/material";
export type { Theme, Palette, SimplePaletteColorOptions } from "@mui/material";
export { useTheme, lighten, darken, createTheme } from "@mui/material/styles";
export { withStyles } from "tss-react/mui";
export type { CSSObject } from "tss-react";

export const createStylesheet = (styleFunction: (theme: Theme, params: any) => Record<string, CSSObject>) => {
  const styles = tss.withParams().create(({ theme, ...params }) => styleFunction(theme, params));

  return (props?: any) => styles(props).classes;
};
