import { useQuery } from "react-query";
import { getCommunications, getDocumentPresignedUrl, getExportCsv } from "./api";
import { useFeedbackAlerts } from "../_app/hooks";
import { SortRequestParam } from "../_app/components/Table/helpers";
import { communicationFilters } from "./filterConfig";
import { Query } from "../filter/types";

export function useCommunications(page: number, limit: number, sort: SortRequestParam, queries: Query[] = [], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery(["communications", { page, limit, sort, queries }], () => getCommunications(page, limit, sort, queries), {
    ...options,
    onError: () => {
      setFeedbackAlertError("Could not fetch communications.");
    },
  });
}

export function useDocumentDownload(communicationId: string, options = {}) {
  return useQuery(["document-download"], () => getDocumentPresignedUrl(communicationId), { ...options });
}

export function useCommunicationFilters() {
  return { data: communicationFilters };
}

export function useExportAllCommunications(options: any = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery(["all-communications-export"], () => getExportCsv(), {
    enabled: false,
    cacheTime: 0,
    onError: () => {
      setFeedbackAlertError("Cannot download communication export due to an error.");
    },
    ...options,
  });
}
