import { UIButton, UIButtonGroup } from "../../_app/components";
import { createStylesheet } from "../../_app/utils/styles";

interface Props {
  selected: string;
  setRange: (range: string) => void;
}

function RangeSelector({ selected, setRange }: Props) {
  const classes = useStyles();
  const dashboardDateRanges = [
    {
      id: "24h",
      label: "Last 24 hours",
    },
    {
      id: "1wk",
      label: "This Week",
    },
    {
      id: "1mo",
      label: "This Month",
    },
    {
      id: "1yr",
      label: "This Year",
    },
  ];
  return (
    <UIButtonGroup className={classes.group}>
      {dashboardDateRanges.map((range) => {
        return (
          <UIButton
            className={selected === range.id ? classes.button : ""}
            key={range.id}
            onClick={() => setRange(range.id)}
            variant={selected === range.id ? "contained" : "outlined"}
            color="inherit"
          >
            {range.label}
          </UIButton>
        );
      })}
    </UIButtonGroup>
  );
}

const useStyles = createStylesheet((theme) => ({
  group: {
    backgroundColor: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}));

export default RangeSelector;
