import { useNavigate } from "react-router-dom";
import { ArrowForward as ArrowForwardIcon } from "../../_app/components/icons";
import { withWidget } from "./components/Widget";
import { formatNumber } from "../../_app/utils/format";
import { useUnbilledTotals } from "../../billing/hooks";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { filtersIdMap } from "../../filter/utils";
import { UNBILLED } from "../../billing/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UICard, UITypography } from "../../_app/components";

interface Props {
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

const Charges = ({ align = "center" }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: total, isFetching } = useUnbilledTotals();
  const hasBillingManagePermission = useHasPermission(permissionCodes.BILLING_MANAGE);
  const canViewMore = hasBillingManagePermission;

  return (
    <UICard isFetching={isFetching} className={classes.ctr}>
      <div className={classes.contentWrap}>
        <UITypography variant="h6">Current Unbilled Usage Charges</UITypography>
        <UITypography variant="h1" align={align}>
          {formatNumber(total, 2, "£")}
        </UITypography>
        {canViewMore && (
          <UIButton
            className="widget-button"
            color="primary"
            variant="contained"
            onClick={() => navigate(`/bills?${filtersIdMap?.BILL_PERIOD}=${UNBILLED}`)}
            endIcon={<ArrowForwardIcon />}
          >
            View
          </UIButton>
        )}
      </div>
    </UICard>
  );
};

const useStyles = createStylesheet(() => ({
  ctr: {
    height: "100%",
  },
  contentWrap: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export default withWidget(Charges, "usage-charges");
