import api from "../_app/api";
import { Query } from "../filter/types";
import { BillDetail, CostBreakdown, GetStatementsResponse, Price, UnbilledCost } from "./types";

export const getUnbilledTotals = async () => {
  const { data }: { data: number } = await api({
    method: "GET",
    url: "/costs/unbilled/net",
    context_injection: true,

    params: {},
  });
  return data;
};

export const getLastBill = async () => {
  const { data }: { data: BillDetail } = await api({
    method: "GET",
    url: "/bills/last",
    context_injection: true,

    params: {},
  });
  return data;
};

export const getCostsBreakdown = async (period: string, systemInvoiceRef: string) => {
  const { data }: { data: CostBreakdown } = await api({
    method: "GET",
    url: `/costs/${period}/breakdown`,
    context_injection: true,

    params: {
      "system-invoice-ref": systemInvoiceRef,
    },
  });
  return data;
};

export const getHeadAccountBillDetails = async (period: string) => {
  const { data }: { data: BillDetail[] } = await api({
    method: "GET",
    url: `/bills/${period}`,
    context_injection: true,

    params: {},
  });
  return data;
};

export const getSubAccountBillDetails = async (period: string) => {
  const { data }: { data: BillDetail[] } = await api({
    method: "GET",
    url: `/costs/${period}`,
    context_injection: true,

    params: {},
  });
  return data;
};

export const getBillFile = async (systemInvoiceRef: string, type: string) => {
  const resp = await api({
    method: "GET",
    url: `/bills/${systemInvoiceRef}/outputs/${type}`,
    context_injection: true,

    responseType: "arraybuffer",
    params: {
      "system-invoice-ref": systemInvoiceRef,
    },
  });
  return resp;
};

export const getBilledPeriods = async () => {
  const { data }: { data: string[] } = await api({
    method: "GET",
    url: "/billed-periods",
    context_injection: true,

    params: {},
  });
  return data;
};

export const getUnbilledCosts = async () => {
  const { data }: { data: UnbilledCost[] } = await api({
    method: "GET",
    url: "/costs/unbilled",
    context_injection: true,

    params: {},
  });
  return data;
};

export const getUsageFile = async (params: { [x: string]: string }) => {
  const resp = await api({
    method: "GET",
    url: "/usage/files/CSV",
    context_injection: true,
    responseType: "arraybuffer",
    params,
  });
  return resp;
};

export const getInvoiceNumbers = async () => {
  const { data }: { data: any } = await api({
    method: "GET",
    url: "/billing/invoice-numbers",
    context_injection: true,

    params: {},
  });
  return data;
};

export const getLastBillPdf = async (systemInvoiceRef: string) => {
  const response = await api({
    method: "GET",
    url: `/bills/${systemInvoiceRef}/outputs/INVOICE`,
    context_injection: true,
    responseType: "arraybuffer",
    params: {},
  });
  return response;
};

export const getBalance = async () => {
  const { data }: { data: Price } = await api({
    method: "GET",
    url: "/billing/balance",
    context_injection: true,

    params: {},
  });
  return data;
};

export const getOverdue = async () => {
  const { data }: { data: Price } = await api({
    method: "GET",
    url: "/billing/overdue",
    context_injection: true,

    params: {},
  });
  return data;
};

export const getStatements = async (startingToken: string, limit: number, queries: Query[]) => {
  let { data }: { data: GetStatementsResponse } = await api({
    method: "GET",
    url: "/statements",
    serialize: true,
    context_injection: true,

    params: {
      "starting-token": startingToken,
      size: limit,
      queries,
    },
  });
  return data;
};

export const getStatementsFile = async (ref: string, params: any = {}) => {
  const { data } = await api({
    method: "GET",
    url: `/statements/${ref}/download`,
    context_injection: true,

    params: { ...params },
    responseType: "arraybuffer",
  });
  return data;
};

export const getBulkStatementsFile = async (queries: Query[]) => {
  const params = new URLSearchParams();
  queries.forEach((q) => params.append(q.id, q.value));
  const { data } = await api({
    method: "GET",
    url: "/statements/download",
    context_injection: true,

    params,
    responseType: "arraybuffer",
  });
  return data;
};

export const getInvoiceFile = async (params: any = {}) => {
  const { data } = await api({
    method: "GET",
    url: "/statements/invoice/download",
    context_injection: true,

    params: { ...params },
    responseType: "arraybuffer",
  });
  return data;
};
