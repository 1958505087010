import { ChangeEvent, useEffect, useState } from "react";
import Main from "../../_app/layouts/Main";
import ManagePaymentsTabBar from "../components/ManagePaymentsTabBar";
import { permissionCodes } from "../../permission/hooks";
import { usePaymentDetails } from "../hooks";
import { useGetBalance, useGetOverdue } from "../../billing/hooks";
import { formatNumber } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UIButton,
  UICard,
  UIFormControl,
  UIFormControlLabel,
  UIGrid,
  UIRadio,
  UIRadioGroup,
  UITextField,
  UITypography,
} from "../../_app/components";

export const CardPayment = () => {
  const classes = useStyles();
  const selectedTab = "card-payment";
  const { data: balance } = useGetBalance();
  const { data: overdue } = useGetOverdue();

  const [accountNumber, setAccountNumber] = useState("");
  const [payment, setPayment] = useState("FULL_BALANCE");
  const [savedCard, setSavedCard] = useState("CARD1");

  const { data } = usePaymentDetails();

  useEffect(() => {
    if (data?.accountHoldersName) {
      setAccountNumber(data?.accountNumber);
    }
  }, [data]);

  const handlePaymentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPayment((event.target as HTMLInputElement).value);
  };

  const handleCardChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSavedCard((event.target as HTMLInputElement).value);
  };

  const handleUpdate = () => {
    return;
  };

  return (
    <Main
      title="Payment details"
      data-cy="card-payment-page"
      accessPermission={permissionCodes.ACCOUNT_PAYMENTS}
      needSelectedAccount={true}
      featureFlag={featureFlagsMap.CARD_PAYMENT}
    >
      <ManagePaymentsTabBar selectedTab={selectedTab}>
        <UIGrid container spacing={2}>
          <UIGrid size={{ xs: 12, md: 5 }}>
            <UICard className={classes.directDebitCardDetails}>
              <div className={classes.filledContentWrap} data-cy="payment-details-type">
                <UIGrid container direction="row" justifyContent="space-between" alignItems="center">
                  <UITypography variant="h2" gutterBottom>
                    Your Balance
                  </UITypography>
                  <UITypography variant="h2" gutterBottom>
                    {formatNumber(balance?.gross, 2, balance?.unit)}
                  </UITypography>
                </UIGrid>
                <UIGrid container direction="row" justifyContent="space-between" alignItems="center">
                  <UITypography variant="h6" gutterBottom>
                    Overdue amount
                  </UITypography>
                  <UITypography variant="h6" color="error" gutterBottom>
                    {formatNumber(overdue?.gross, 2, overdue?.unit)}
                  </UITypography>
                </UIGrid>
                <UITypography gutterBottom component="div">
                  <hr />
                </UITypography>
                <UITypography variant="h6" gutterBottom>
                  I would like to pay
                </UITypography>
                <UIFormControl component="fieldset" fullWidth>
                  <UIRadioGroup value={payment} onChange={handlePaymentChange}>
                    <UIFormControlLabel
                      value="FULL_BALANCE"
                      control={<UIRadio color="primary" />}
                      label={
                        <UIGrid container direction="row" justifyContent="space-between" alignItems="center">
                          <UITypography variant="h6">Full balance</UITypography>
                          <UITypography variant="h6">{formatNumber(balance?.gross, 2, balance?.unit)}</UITypography>
                        </UIGrid>
                      }
                      className={payment === "FULL_BALANCE" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                    <UIFormControlLabel
                      value="OVERDUE_AMOUNT"
                      control={<UIRadio color="primary" />}
                      label={
                        <UIGrid container direction="row" justifyContent="space-between" alignItems="center">
                          <UITypography variant="h6">Overdue amount</UITypography>
                          <UITypography variant="h6">{formatNumber(overdue?.gross, 2, overdue?.unit)}</UITypography>
                        </UIGrid>
                      }
                      className={payment === "OVERDUE_AMOUNT" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                    <UIFormControlLabel
                      value="PAYMENT_AMOUNT"
                      control={<UIRadio color="primary" />}
                      label={
                        <UIGrid container direction="row" justifyContent="space-between" alignItems="center">
                          <UITypography variant="h6">Enter a payment amount</UITypography>
                          <UITextField name="paymentAmount" label="£" type="code" variant="outlined" size="small" />{" "}
                        </UIGrid>
                      }
                      className={payment === "PAYMENT_AMOUNT" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                  </UIRadioGroup>
                </UIFormControl>

                <UITypography gutterBottom component="div">
                  <hr />
                </UITypography>
                <UITypography variant="h6" gutterBottom>
                  Saved cards
                </UITypography>

                <UIFormControl component="fieldset" fullWidth>
                  <UIRadioGroup value={savedCard} onChange={handleCardChange}>
                    <UIFormControlLabel
                      value="CARD1"
                      control={<UIRadio color="primary" />}
                      label={<UITypography variant="h6">Card ending {accountNumber}</UITypography>}
                      className={savedCard === "CARD1" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                    <UIFormControlLabel
                      value="CARD2"
                      control={<UIRadio color="primary" />}
                      label={<UITypography variant="h6">Card ending {accountNumber}</UITypography>}
                      className={savedCard === "CARD2" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                  </UIRadioGroup>
                </UIFormControl>
              </div>
              <UIButton variant="contained" color="primary" onClick={handleUpdate} data-cy="payment-button" fullWidth>
                Make a payment
              </UIButton>
            </UICard>
          </UIGrid>
        </UIGrid>
      </ManagePaymentsTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  directDebitCardDetails: {
    minHeight: "550px",
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "center",
    "& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      width: "100%",
    },
  },
  filledContentWrap: {
    minHeight: "410px",
    textAlign: "left",
  },
  radioWrap: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  radioCheckedWrap: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

export default CardPayment;
