import { useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { createRole, deleteRole, getAllRoles, getRoleById, updateRole } from "./api";
import { GetRolesResponse, Role } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";

export function useAllRoles(page?: number, limit?: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetRolesResponse, AjaxResponse>(["roles", { page, limit }], () => getAllRoles(page, limit), {
    keepPreviousData: true,
    ...options,
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || "Failed to fetch roles");
    },
  });
}

export function useSingleRole(id: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<Role, AjaxResponse>(["role", id], () => getRoleById(id), {
    ...options,
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || "Failed to fetch role");
    },
  });
}

export function useRoleUpdate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Role, AjaxResponse, Role>(updateRole, {
    ...options,
    onSuccess: (role) => {
      queryClient.setQueryData(["role", role.id], () => role);
      queryClient.invalidateQueries(["roles"]);
      queryClient.invalidateQueries(["permissions"]);
      setFeedbackAlertSuccess("Role updated");
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || "Failed to update role");
    },
  });
}

export function useRoleCreate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Role, AjaxResponse, Role>(createRole, {
    ...options,
    onSuccess: (role) => {
      queryClient.setQueryData(["role", role.id], () => role);
      queryClient.invalidateQueries(["roles"]);
      setFeedbackAlertSuccess("Role created");
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || "Failed to create role");
    },
  });
}

export function useRoleDelete(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Role, AjaxResponse, Role>((user: Role) => deleteRole(user), {
    ...options,
    onSuccess: (role) => {
      queryClient.invalidateQueries(["role", role.id]);
      queryClient.invalidateQueries(["roles"]);
      setFeedbackAlertSuccess("Role deleted");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || "Failed to delete role");
    },
  });
}
