import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import ManageBillDashboardTabs from "../components/ManageBillDashboardTabs";
import FiltersCard from "../../filter/components/FiltersCard";
import { useAllFilters } from "../../filter/hooks";
import { useQueryParam, useQueryParams } from "../../_app/hooks";
import Tabbed from "../../_app/layouts/Tabbed";
import CostByUsageType from "../components/graphs/CostByUsageType";
import { Query } from "../../filter/types";
import CostByProduct from "../components/graphs/CostByProduct";
import { useBilledPeriods } from "../../billing/hooks";
import ServiceCostByType from "../components/graphs/ServiceCostByType";
import ServiceCostByProduct from "../components/graphs/ServiceCostByProduct";
import { createStylesheet } from "../../_app/utils/styles";
import { UIGrid } from "../../_app/components";
import { Feature } from "../../feature/types";

export const PERIOD_FILTER = "80";
export const BillDashTrends = () => {
  const selectedTab = "bill-dashboard-trends";
  const classes = useStyles();
  const navigate = useNavigate();
  const [type, setType] = useState("usage");
  const queryParams = useQueryParams();
  const period = useQueryParam(PERIOD_FILTER);

  const { data: periodList } = useBilledPeriods();

  const getParams = useCallback(() => {
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      values.forEach((query) => {
        if (key === PERIOD_FILTER) {
          const periods = query.split(" - ");
          filters.push({
            id: key,
            value: periods[0],
          });
          filters.push({
            id: key,
            value: periods[periods.length - 1],
          });
        } else {
          filters.push({
            id: key,
            value: query,
          });
        }
      });
    });
    return filters;
  }, [queryParams]);

  useEffect(() => {
    if (!period) {
      const endPeriod = periodList?.[0];
      const startPeriod = periodList?.[Math.min(6, periodList?.length - 1)];
      if (startPeriod && endPeriod) {
        queryParams.append(PERIOD_FILTER, startPeriod + " - " + endPeriod);
        navigate({ search: queryParams.toString() }, { replace: true });
      }
    }
  }, [periodList, period, queryParams, navigate]);

  return (
    <Main title="Billing Summary" data-cy="bill-dashboard-trends-page" feature={Feature.BillingDashboard} needSelectedAccount>
      <ManageBillDashboardTabs selectedTab={selectedTab}>
        <UIGrid container direction="row" className={classes.ctr} spacing={0.5}>
          <UIGrid size={{ xs: 12 }}>
            <FiltersCard className={classes.filtersCard} hasReset={false} fetchHook={useAllFilters} usage="GRAPHS_TRENDS" />
          </UIGrid>
          <UIGrid size={{ xs: 12 }}>
            <Tabbed
              selectedTab={type}
              handleTabChange={(_, val) => setType(val)}
              tabs={[
                { value: "usage", label: "Usage" },
                { value: "services", label: "Services" },
              ]}
            />
          </UIGrid>
          <div className={classes.widgetWrap}>
            <UIGrid container direction="row" spacing={3}>
              {(() => {
                switch (type) {
                  case "usage":
                    return (
                      <>
                        <CostByProduct queries={getParams()} />
                        <CostByUsageType queries={getParams()} />
                      </>
                    );
                  case "services":
                    return (
                      <>
                        <ServiceCostByProduct queries={getParams()} />
                        <ServiceCostByType queries={getParams()} />
                      </>
                    );
                  default:
                    break;
                }
              })()}
            </UIGrid>
          </div>
        </UIGrid>
      </ManageBillDashboardTabs>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {},
  filtersCard: {},
  widgetWrap: {
    borderRadius: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    width: "100%",
    padding: "22px 5px",
    minHeight: "50vh",
  },
}));

export default BillDashTrends;
