import { Dispatch, MouseEvent, SetStateAction, useMemo } from "react";
import { UIAlert, UIButton } from "../../_app/components";
import UILoader from "../../_app/components/UILoader";
import BarsUpdateForm from "./BarsUpdateForm";
import { useBars, useBarsByAssetId } from "../hooks";
import { Bar, BarUpdate } from "../types";
import { createStylesheet } from "../../_app/utils/styles";

interface Props {
  assetId?: string;
  network?: string;
  onApply?: (event: MouseEvent<HTMLButtonElement>) => void;
  disableApply?: boolean;
  updates: BarUpdate[];
  setUpdates: Dispatch<SetStateAction<BarUpdate[]>>;
  resetKey?: string;
  onReset?: () => void;
}

export const BarGroups = ({ assetId, network, onApply, disableApply = false, updates, setUpdates, resetKey, onReset }: Props) => {
  const classes = useStyles();

  const {
    data: barsSingle,
    isFetching: singleFetching,
    isError: isSingleError,
  } = useBarsByAssetId(assetId || "", { enabled: Boolean(assetId), retry: 4 });
  const {
    data: barsAll,
    isFetching: allFetching,
    isError: isAllError,
  } = useBars({ network }, { enabled: !Boolean(assetId), retry: 4 });

  const bars = useMemo(() => barsSingle?.bars || barsAll, [barsSingle, barsAll]);

  const isFetching = singleFetching || allFetching;
  const isError = isSingleError || isAllError;

  const onChange = (bar: Bar, newVal: boolean | string, disabledBars: Bar[]) => {
    const updated = {
      name: bar.name,
      value: bar.value === newVal ? undefined : newVal,
      label: bar.label,
      network: bar.network,
    };

    const updatedByCompatibility = bar.disableIncompatible
      ? disabledBars.map((bar2) => ({
          name: bar2.name,
          value: false,
          label: bar2.label,
          network: bar.network,
        })) || []
      : [];

    setUpdates((old: any[]) => {
      const filtered = old.filter((o: Bar) => {
        const existing =
          o.name === bar.name ||
          (!updatedByCompatibility.find((b) => b.name === o.name) && bar.value === false && bar.disableIncompatible);
        return !existing;
      });
      return [...filtered, updated, ...(updated.value ? updatedByCompatibility : [])]
        ?.filter((bar) => typeof bar.value === "boolean")
        ?.filter(
          //remove duplicates
          (thing, i, arr) => arr.findIndex((t) => t.name === thing.name) === i,
        );
    });
  };

  if (isError) {
    return <UIAlert severity="error">This feature is currently unavailable, please try again later.</UIAlert>;
  }

  if (isFetching) {
    return (
      <div className={classes.loader}>
        <UILoader />
      </div>
    );
  }

  return (
    <>
      <BarsUpdateForm bars={bars} resetKey={resetKey} network={network} onChange={onChange} updatedBars={updates} />
      <div className={classes.buttonWrap}>
        {Boolean(typeof onReset === "function" && updates.length) && (
          <UIButton variant="outlined" color="primary" onClick={onReset} data-cy="reset-btn" className={classes.resetButton}>
            Reset
          </UIButton>
        )}
        {typeof onApply === "function" && (
          <UIButton variant="contained" color="primary" onClick={onApply} disabled={disableApply} data-cy="apply-btn">
            Apply
          </UIButton>
        )}
      </div>
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  resetButton: {
    marginRight: theme.spacing(1.5),
  },
  loader: {
    display: "flex",
    marginTop: "20vh",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(3),
  },
}));

export default BarGroups;
