import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from "../../_app/components/icons";
import { useUserDisable, useUserEnable, useUserResendEmail } from "../hooks";
import UIConfirm from "../../_app/components/UIConfirm";
import { UserListItem } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIClickAwayListener, UIGrid, UIGrow, UIMenuItem, UIMenuList, UIPaper, UIPopper } from "../../_app/components";

interface Props {
  user: UserListItem;
  deleteUser(user: UserListItem): void;
}

enum ButtonActions {
  Edit = "Edit",
  Disable = "Disable",
  Enable = "Enable",
  Delete = "Delete",
  ResendEmail = "Resend Welcome Email",
}

const ManageUsersActionButton = ({ user, deleteUser }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const canResendEmail = !user?.confirmed;
  const [warning, setWarning] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [shouldHandleClickAway, setShouldHandleClickAway] = useState(true);
  const [currentUserActions, setCurrentUserActions] = useState<ButtonActions[]>([]);

  const { mutate: executeUserDisable } = useUserDisable();
  const { mutate: executeUserEnable } = useUserEnable();
  const { mutate: executeUserResendEmail } = useUserResendEmail();

  const constructActions = useCallback(
    (isDisabling: boolean = false) => {
      const actions = [
        ButtonActions.Edit,
        !user.active || isDisabling ? ButtonActions.Enable : ButtonActions.Disable,
        ButtonActions.Delete,
      ];
      if (canResendEmail) {
        actions.push(ButtonActions.ResendEmail);
      }
      return actions;
    },
    [canResendEmail, user],
  );

  useEffect(() => {
    setCurrentUserActions(constructActions());
  }, [constructActions]);

  const switchDropdownVisibility = () => {
    setShouldHandleClickAway(false);
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleUserEdit = (user: UserListItem) => navigate(`/users/${user?.id}`);

  const handleUserDisable = (user: UserListItem) => {
    setCurrentUserActions(constructActions(true));
    switchDropdownVisibility();
    return executeUserDisable(user);
  };

  const handleUserEnable = (user: UserListItem) => {
    setCurrentUserActions(constructActions(false));
    switchDropdownVisibility();
    return executeUserEnable(user);
  };

  const handleUserDelete = (user: UserListItem) => {
    switchDropdownVisibility();
    return deleteUser(user);
  };

  const handleResendEmail = () => {
    if (!user) return;
    executeUserResendEmail(user);
    switchDropdownVisibility();
  };

  const handleClick = (action: string) => {
    switch (action) {
      case ButtonActions.Edit:
        return handleUserEdit(user);
      case ButtonActions.Disable:
        return handleUserDisable(user);
      case ButtonActions.Enable:
        return handleUserEnable(user);
      case ButtonActions.ResendEmail:
        return handleResendEmail();
      case ButtonActions.Delete:
        return setWarning(true);
      default:
        return;
    }
  };

  return (
    <UIGrid ref={anchorRef} className={classes.buttonWrap}>
      <UIButton
        variant="outlined"
        color="primary"
        onClick={switchDropdownVisibility}
        className={classes.button}
        data-cy={`actions-button-id-${user.id}`}
      >
        Actions
        {isDropdownVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </UIButton>
      <UIPopper open={isDropdownVisible} anchorEl={anchorRef.current} transition className={classes.popper} placement="bottom">
        {({ TransitionProps }) => (
          <UIGrow {...TransitionProps}>
            <UIPaper>
              <UIClickAwayListener
                onClickAway={() => (shouldHandleClickAway ? setIsDropdownVisible(false) : setShouldHandleClickAway(true))}
              >
                <UIMenuList id="actions-button-menu" data-cy="actions-button-menu-items">
                  {currentUserActions.map((action: string) => (
                    <UIMenuItem
                      key={action}
                      onClick={() => handleClick(action)}
                      className={classes.item}
                      data-cy={`${action}-action-item`}
                    >
                      {action?.toLowerCase()}
                    </UIMenuItem>
                  ))}
                </UIMenuList>
              </UIClickAwayListener>
            </UIPaper>
          </UIGrow>
        )}
      </UIPopper>
      <UIConfirm
        open={warning}
        setOpen={setWarning}
        onConfirm={() => {
          handleUserDelete(user);
        }}
      >
        Are you sure you want to delete{" "}
        <b>
          {user?.firstName} {user?.lastName}
        </b>{" "}
        ?
      </UIConfirm>
    </UIGrid>
  );
};

const useStyles = createStylesheet(() => ({
  buttonWrap: {
    textAlign: "center",
  },
  button: {
    width: 110,
  },
  popper: {
    zIndex: 100,
    minWidth: 120,
  },
  item: {
    textTransform: "capitalize",
  },
}));

export default ManageUsersActionButton;
