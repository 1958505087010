import { useState } from "react";
import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import {
  getBalance,
  getBilledPeriods,
  getHeadAccountBillDetails,
  getCostsBreakdown,
  getLastBill,
  getOverdue,
  getStatements,
  getSubAccountBillDetails,
  getUnbilledCosts,
  getUnbilledTotals,
} from "./api";
import { AjaxResponse } from "../_app/api";
import { serviceAnalysisFilters, usageAnalysisFilters } from "./filterConfig";
import { Filter, Query } from "../filter/types";
import { BillDetail, GetStatementsResponse, Price } from "./types";
import { addFormatFunctionToFilters } from "../filter/utils";

export function useUnbilledTotals(options = {}) {
  return useQuery<number, AjaxResponse>(["unbilled-totals"], getUnbilledTotals, { ...options });
}

export function useLastBill(options = {}) {
  return useQuery<BillDetail, AjaxResponse>(["billing-last"], getLastBill, {
    ...options,
  });
}

export function useCostsBreakdown(period: string, systemInvoiceRef: string, options = {}) {
  return useQuery(["costs-breakdown", period, systemInvoiceRef], () => getCostsBreakdown(period, systemInvoiceRef), {
    ...options,
  });
}

export function useBillDetails(periods: string[], isHeadAccount: boolean = false, options = {}) {
  const queryFn = isHeadAccount ? getHeadAccountBillDetails : getSubAccountBillDetails;
  const queries = periods
    .map((p, i) => {
      return {
        queryKey: ["billDetails", p, isHeadAccount],
        queryFn: () => queryFn(p),
        options,
      };
    })
    .filter((q) => q.queryKey);
  return useQueries(queries);
}

export function useBilledPeriods(options = {}) {
  return useQuery(["periods"], getBilledPeriods, {
    ...options,
  });
}

export function useUnbilledCosts(options = {}) {
  return useQuery(["unbilled-costs"], getUnbilledCosts, {
    ...options,
  });
}

export function useGetBalance(options = {}) {
  return useQuery<Price, AjaxResponse>(["balance"], () => getBalance(), {
    keepPreviousData: true,
    ...options,
  });
}

export function useGetOverdue(options = {}) {
  return useQuery<Price, AjaxResponse>(["overdue"], () => getOverdue(), {
    keepPreviousData: true,
    ...options,
  });
}

export function useStatements(limit: number, queries: Query[], options = {}) {
  return useInfiniteQuery<GetStatementsResponse, AjaxResponse>(
    ["statements", { limit, queries }],
    ({ pageParam }) => getStatements(pageParam, limit, queries),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      retry: 2,
      ...options,
    },
  );
}

export function useUsageAnalysisFilters() {
  const [data, setData] = useState<Filter[]>([]);
  const filters = usageAnalysisFilters;

  const formatData = async () => {
    try {
      const response = await addFormatFunctionToFilters(filters);
      setData(response);
    } catch (error) {
      console.error("formatting failed");
    }
  };
  if (!data.length) formatData();
  return { data };
}

export function useServiceAnalysisFilters() {
  const [data, setData] = useState<Filter[]>([]);
  const filters = serviceAnalysisFilters;

  const formatData = async () => {
    try {
      const response = await addFormatFunctionToFilters(filters);
      setData(response);
    } catch (error) {
      console.error("formatting failed");
    }
  };
  if (!data.length) formatData();
  return { data };
}
