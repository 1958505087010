import { Dispatch, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import actions from "../store/Actions";
import { Action, StoreContext, StoreState } from "../store/Store";
import { debounce } from "../utils/index";
import { useMediaQuery, useTheme } from "../utils/styles";

// Allows parent component to trigger actions within child component - use with caution.
export function useTriggerKey() {
  const [key, setKey] = useState(0);
  const trigger = useCallback(() => setKey((prev) => prev + 1), []);
  return [key, trigger] as const;
}

export function useReset(prefix = "") {
  const [key, setKey] = useState(0);
  const reset = () => setKey((prev) => prev + 1);
  return [prefix + "reset" + key, reset] as const;
}

export function useStore() {
  const [state, dispatch]: [StoreState, Dispatch<Action>] = useContext(StoreContext);
  return { state, dispatch };
}

/**
 * Used to avoid triggering re-renders when passing functions as props that rely on some state.
 *
 * @example
 *
 * function Parent() {
 *  const onChange = useMemoizedCallback((value: T) => {
 *    if (someState) setSomething(true);
 * }, [someState])
 *
 *  return <Child onChange={onChange} />
 * }
 *
 */
export function useMemoizedCallback(callback: any, inputs: any = []) {
  const callbackRef = useRef(callback);
  const memoizedCallback = useCallback((...args: any[]) => {
    return callbackRef.current(...args);
  }, []);
  // eslint-disable-next-line
  const updatedCallback = useCallback(callback, inputs);
  useEffect(() => {
    callbackRef.current = updatedCallback;
  }, [updatedCallback]);
  return memoizedCallback;
}

/**
 * Used to provide old state values to compare with new.
 *
 * @example
 *
 * useEffectWithPrev(([prevValue1]) => {
 *    if (prevValue1 !== value1) setSomething(true);
 * }, [value1])
 *
 */
export const useEffectWithPrev = (fn: any, inputs: any[] = []) => {
  const prevInputsRef = useRef([...inputs]);
  useEffect(() => {
    fn(prevInputsRef.current);
    prevInputsRef.current = [...inputs];
  }, [fn, inputs]);
};

export const useQueryParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const useQueryParam = (key: string, asArray: boolean = false) => {
  const params = useQueryParams();
  const param = params?.get(key);
  if (asArray) {
    return param?.split(",")?.filter((val) => val);
  }
  return param;
};

export const useAllParamValues = (param: string) => {
  const { search } = useLocation();
  const allParams = new URLSearchParams(search);
  let params = allParams.getAll(param);
  return params;
};

export const useMedia = () => {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelowXs = useMediaQuery(theme.breakpoints.down("xs"));
  return { isBelowXs, isBelowSm, isBelowMd };
};

export const useFeedbackAlerts = () => {
  const { dispatch } = useStore();

  const setFeedbackAlert = useCallback(
    (message: any) => {
      dispatch({
        type: actions.SET_ALERT,
        payload: { message },
      });
    },
    [dispatch],
  );

  const setFeedbackAlertError = useCallback(
    (message: any) => {
      dispatch({
        type: actions.SET_ERROR,
        payload: { message },
      });
    },
    [dispatch],
  );

  const setFeedbackAlertSuccess = useCallback(
    (message: any) => {
      dispatch({
        type: actions.SET_SUCCESS,
        payload: { message },
      });
    },
    [dispatch],
  );

  return { setFeedbackAlert, setFeedbackAlertError, setFeedbackAlertSuccess };
};

export const useDebouncedValue = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = debounce((val: string) => setDebouncedValue(val), delay);
    handler(value);
    return () => {
      handler.clear();
    };
  }, [value, delay]);

  return debouncedValue;
};
