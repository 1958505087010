import { AuthUser } from "aws-amplify/auth";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useMyAccounts } from "../../account/hooks";
import { useNavigate } from "react-router-dom";

const SignInRedirect = ({ user }: { user?: AuthUser }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useMyAccounts();

  useEffect(() => {
    if (user) {
      queryClient.invalidateQueries(["hubUserExist"]);
      navigate("/");
    }
  }, [user, navigate, queryClient]);

  return null;
};

export default SignInRedirect;
