import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { useMobileAssets } from "../../asset/hooks";
import { MobileLimits } from "../../asset/utils";
import { getSimDetails } from "../api";
import { permissionCodes } from "../../permission/hooks";
import { AxiosResponse } from "axios";
import TableHeader from "../../_app/components/Table/TableHeader";
import UILoader from "../../_app/components/UILoader";
import { SimInfo, SimSwapOperation, SimType } from "../types";
import { getSimParams, getSimTypes } from "../utils";
import { capitalize } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";
import { useSimSwapUpdate } from "../hooks";
import SimNumberInput from "../components/SimNumberInput";
import { createStylesheet, useTheme } from "../../_app/utils/styles";
import { UIAlert, UIButton, UIFormControl, UIMenuItem, UISelect } from "../../_app/components";
import UIDataTable from "../../_app/components/Table/UIDataTable";

interface DataMap {
  [cli: string]: SimInfo;
}

const simTypes = getSimTypes();

export const SimSwap = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const [canView, setCanView] = useState(false);
  const [data, setData] = useState<DataMap>({});
  const [tableData, setTableData] = useState<any[]>([]);

  const { data: assetsResp, isLoading } = useMobileAssets(
    0,
    MobileLimits.SimSwap,
    filters,
    assets?.map((a: any) => a.cli),
    { enabled: !assets?.length && canView },
  );

  const { mutate: executeSimSwapUpdate, isLoading: isProcessing } = useSimSwapUpdate({
    onSuccess: () => {
      navigate("/mobile-management");
    },
  });

  useEffect(() => {
    const assetsList = assets?.length ? assets : assetsResp?.list;
    const parsed =
      assetsList?.reduce((map: any, asset: any) => {
        const simParams = getSimParams(asset?.network);

        return {
          ...map,
          [asset.cli]: {
            id: asset.id,
            cli: asset.cli,
            tag: asset.tag,
            network: asset.network,
            simNumber: asset.simNumber,
            simType: "-",
            simNumberNew: simParams?.simPrefix,
            simTypeNew: SimType.Triple,
            simLength: simParams?.simLength,
            simPrefix: simParams?.simPrefix,
          },
        };
      }, {}) || {};
    setData(parsed);
  }, [assets, assetsResp]);

  useEffect(() => {
    const onSimTypeChange = (simTypeNewValue: SimType, cli: string) => {
      setData({
        ...data,
        [cli]: { ...data[cli], simTypeNew: simTypeNewValue },
      });
    };

    const onSimNumberChange = (event: ChangeEvent<HTMLInputElement>, cli: string) => {
      setData({
        ...data,
        [cli]: { ...data[cli], simNumberNew: event.target.value },
      });
    };

    const onSimNumberBlur = async (ev: FocusEvent<HTMLInputElement>, item: SimInfo) => {
      const val = ev.target.value;
      if (!val) return;
      try {
        setData((prev: DataMap) => ({
          ...prev,
          [item?.cli]: {
            ...(prev?.[item?.cli] || {}),
            simNumberNewValidation: {
              isValid: false,
              isValidating: true,
            },
          },
        }));
        const resp = await getSimDetails(val, item?.network);
        setData((prev) => ({
          ...prev,
          [item?.cli]: {
            ...(prev?.[item?.cli] || {}),
            simNumberNewValidation: {
              isValid: resp?.simStatus === "INACTIVE",
              error: resp?.simStatus !== "INACTIVE" ? "SIM already active" : undefined,
              isValidating: false,
            },
          },
        }));
      } catch (error) {
        setData((prev: DataMap) => ({
          ...prev,
          [item?.cli]: {
            ...(prev?.[item?.cli] || {}),
            simNumberNewValidation: {
              isValid: false,
              isValidating: false,
              error: (error as AxiosResponse).data?.message || "Invalid SIM number",
            },
          },
        }));
      }
    };

    const table =
      Object.values(data).map((item, index) => [
        item.cli,
        item.tag,
        item.network,
        item.simNumber,
        item.simType,
        <SimNumberInput
          simInfo={item}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onSimNumberChange(event, item.cli)}
          onBlur={(e: FocusEvent<HTMLInputElement>) => onSimNumberBlur(e, item)}
        />,
        <UIFormControl variant="filled" size="small" style={{ width: "150px" }}>
          <UISelect
            value={item?.simTypeNew}
            variant="outlined"
            displayEmpty={true}
            data-cy={"select-sim-type-" + index}
            onChange={(event: any) => onSimTypeChange(event.target.value, item?.cli)}
          >
            {simTypes.map((option) => (
              <UIMenuItem value={option.value} key={option.value} data-cy={option.value}>
                {option.label}
              </UIMenuItem>
            ))}
          </UISelect>
        </UIFormControl>,
      ]) || [];
    setTableData(table);
  }, [classes, data, theme]);

  const canSubmit = Object.values(data)?.reduce((prev, curr) => {
    return Boolean(prev && curr?.simNumberNewValidation?.isValid && curr?.simTypeNew);
  }, true);

  const onSubmit = () => {
    if (canSubmit) {
      const operations: SimSwapOperation[] = Object.entries(data).map(([cli]) => {
        return {
          assetId: data?.[cli]?.id,
          simNumber: data?.[cli]?.simNumberNew,
          simType: data?.[cli]?.simTypeNew,
        };
      });
      return executeSimSwapUpdate(operations);
    }
  };

  const renderError = () => {
    if (assets?.length) return null;
    if (filters) return null;
    return (
      <UIAlert severity="error" className={classes.error}>
        No mobile numbers selected
      </UIAlert>
    );
  };

  return (
    <Main
      title="SIM Swap"
      data-cy="sim-swap-page"
      isLoading={false}
      featureFlag={featureFlagsMap.SIM_SWAP}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      accessGrantedCb={() => setCanView(true)}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      {renderError() || isLoading ? (
        <div className={classes.handling}>
          <UILoader size={50} />
        </div>
      ) : (
        <>
          <div className={classes.message}>
            <UIAlert severity="warning">
              Once you click <b>Activate all</b> please reboot the handset and allow up to 24 hours for the SIM card to be
              activated. If, after 24 hours, the SIM card has still not been activated, please contact your dedicated Customer
              Services team.
            </UIAlert>
          </div>
          <TableHeader>
            <UIButton
              variant="outlined"
              color="primary"
              onClick={onSubmit}
              disabled={!canSubmit || isProcessing}
              className={classes.activate}
              data-cy="activate-btn"
            >
              {isProcessing ? <UILoader size={24} /> : "Activate All"}
            </UIButton>
          </TableHeader>
          <div className={classes.tableCtr} data-cy="sim-swap-table">
            <UIDataTable
              title=""
              data={tableData}
              columns={[
                "Identifier",
                "Tag",
                {
                  name: "Network",
                  options: { customBodyRender: (val) => capitalize(val) },
                },
                "SIM Number",
                "SIM Type",
                "New SIM Number",
                "New SIM Type",
              ]}
              options={{
                pagination: false,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                sort: false,
                search: false,
                serverSide: true,
                selectableRows: "none",
                rowHover: true,
                setTableProps: () => ({ size: "medium" }),
                setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                viewColumns: false,
              }}
            />
          </div>
        </>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  message: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
    maxWidth: "80%",
    "& .MuiAlert-icon": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  tableCtr: {
    width: "100%",
    marginTop: 0.5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    "& div:first-of-type": {
      marginTop: 0,
    },
  },
  activate: {
    marginLeft: "auto",
  },
  error: {
    paddingLeft: "10px",
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  newSimNumber: {
    minWidth: "225px",
    textUnderlineOffset: "10px",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: theme.spacing(1),
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "5px",
    },
  },
  handling: {
    display: "flex",
    height: "70vh",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "35px",
    marginTop: "30px",
  },
}));

export default SimSwap;
