import { ChangeEvent } from "react";
import { Filter } from "../../types";
import FilterBox from "./FilterBox";
import { createStylesheet } from "../../../_app/utils/styles";
import { UITextField } from "../../../_app/components";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

export default function TextFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueSelection = value.split(",");
    setSelection(valueSelection);
  };

  return (
    <div {...props}>
      <FilterBox item={item} resetSelection={resetSelection} addSelection={addSelection} selection={selection} close={close}>
        <div className={classes.filterBody}>
          <UITextField
            variant="outlined"
            size="small"
            name="phoneNumber"
            value={selection}
            onChange={handleInput}
            placeholder="Comma separated values"
            fullWidth
          />
        </div>
      </FilterBox>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  filterBody: {
    paddingInline: theme.spacing(2),
  },
}));
