import { useLocation, useParams } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import BillPackages from "../components/BillPackages";
import { useBillDetails, useCostsBreakdown, useUnbilledCosts } from "../hooks";
import { formatPeriodToMonthYear, UNBILLED } from "../utils";
import { formatDate, formatNumber } from "../../_app/utils/format";
import { useUserLevelPrefix } from "../../user-level/hooks";
import { BillDetail } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIBox, UIDivider, UITypography } from "../../_app/components";

interface BillRow {
  name: string;
  value: string | undefined;
  isTotal?: boolean;
  cy?: string;
}

export const PeriodDetails = () => {
  const classes = useStyles();
  const userLevelPrefix = useUserLevelPrefix();
  const { periodId, invoiceId } = useParams<{
    periodId: string;
    invoiceId: string;
  }>();
  const { search } = useLocation();
  const isUnbilled = search.includes(UNBILLED);
  const { data: unbilledCosts, isFetching: isFetchingUnbilled } = useUnbilledCosts({ enabled: isUnbilled });

  const billDetails = useBillDetails([periodId ?? ""], true, {
    enabled: !isUnbilled,
  });
  const billDetail: BillDetail = (billDetails?.[0]?.data as BillDetail[])?.find(
    (bd) => bd.systemInvoiceRef === invoiceId,
  ) as BillDetail;

  const { data: costsBreakdown, isLoading: isLoadingBreakdown } = useCostsBreakdown(periodId ?? "", invoiceId ?? "", {
    enabled: Boolean(periodId && invoiceId),
  });

  const isLoading = billDetails.some((query) => query.isLoading) || isLoadingBreakdown || isFetchingUnbilled;

  const BillRow = ({ name, value, isTotal, cy }: BillRow) => {
    return (
      <div className={classes.row} data-cy={cy}>
        <div className={isTotal ? classes.isTotal : ""}>{name}</div>
        <div className={isTotal ? classes.isTotal : ""}>{value}</div>
      </div>
    );
  };

  return (
    <Main
      title={`${formatPeriodToMonthYear(periodId)}${isUnbilled ? " (Unbilled)" : ""}`}
      data-cy={`details-page-${periodId}-${invoiceId}`}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      needSelectedAccount={true}
      isLoading={isLoading}
    >
      <>
        <div className={classes.grid}>
          <UIBox pb={4}>
            {Boolean(!isUnbilled && billDetail) && (
              <>
                <UITypography variant="h4">Invoice No.: {billDetail.invoiceNumber}</UITypography>
                <UITypography variant="h4">Invoice Date: {formatDate(billDetail.billDate, "dd MMM yyyy")}</UITypography>
                <BillRow name="Net" value={formatNumber(billDetail.net, 2, "£")} cy="bill-price" />
                <BillRow name="VAT" value={formatNumber(billDetail.vat, 2, "£")} cy="bill-vat" />
                <UIDivider />
                <BillRow
                  name={`${userLevelPrefix ? userLevelPrefix + " " : ""}Total`}
                  value={formatNumber(billDetail.gross, 2, "£")}
                  isTotal
                  cy="bill-total"
                />
              </>
            )}
            {isUnbilled && (
              <BillRow
                name="Net"
                value={formatNumber(unbilledCosts?.find((cost) => cost.billingPeriod === periodId)?.net, 2, "£")}
                cy="bill-price"
              />
            )}
          </UIBox>
          <>
            {costsBreakdown
              ? Object.keys(costsBreakdown).map((packageName) => {
                  const cost = costsBreakdown[packageName];
                  if (!cost) return null;
                  return <BillPackages key={packageName} cost={cost} name={packageName} isUnbilled={isUnbilled} />;
                })
              : null}
          </>
        </div>
      </>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0px",
    fontSize: "18px",
    fontWeight: 500,
    alignItems: "center",
  },
  isTotal: {
    fontWeight: 700,
    padding: "6px 0px",
  },
  grid: {
    width: "100%",
    padding: 15,
    paddingTop: 30,
    justifyContent: "space-around",
    [theme?.breakpoints?.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
}));

export default PeriodDetails;
