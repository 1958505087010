import { Check as CheckIcon, Delete as DeleteIcon } from "../../_app/components/icons";
import { UIBox, UIButton, UITypography } from "../../_app/components";

interface Props {
  file: File | null;
  index: number;
  handleRemoveFile: Function;
  uploading: boolean;
}

function FileItem({ file, index, handleRemoveFile, uploading }: Props) {
  return (
    <UIBox key={index} display="flex" alignItems="center" paddingBottom="8px">
      <CheckIcon fontSize="small" />
      <UIBox paddingLeft="8px" paddingRight="8px" width="200px">
        <UITypography noWrap variant="subtitle2">
          {file?.name}
        </UITypography>
      </UIBox>
      <UIButton
        component="label"
        size="small"
        color="inherit"
        startIcon={<DeleteIcon />}
        onClick={() => handleRemoveFile(index)}
        disabled={uploading}
      />
    </UIBox>
  );
}

export default FileItem;
