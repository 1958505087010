import { formatPrice } from "../../billing/utils";
import { ServiceAnalysisColumn } from "../../service-analysis/types";
import { bytesToSize, formatDate, formatQuantity, secondsToHms, upperCasetoCamelCase } from "../../_app/utils/format";
import { UsageAnalysisColumn } from "../types";
import { TotalToButton } from "./TotalToButton";

import type { JSX } from "react";

const columnStyling: any = {
  setCellHeaderProps: () => ({
    style: { textAlign: "center", whiteSpace: "nowrap" },
  }),
  setCellProps: () => ({ style: { textAlign: "center" } }),
};

const priceColumnStyling: any = {
  setCellHeaderProps: () => ({
    style: { textAlign: "right", whiteSpace: "nowrap" },
  }),
  setCellProps: () => ({ style: { textAlign: "right" } }),
};

export const parseColumns = (
  columns: UsageAnalysisColumn[] | ServiceAnalysisColumn[] | undefined,
  persistenceKey: string = "",
  customHeader?: (label: string, name: string, sortable: boolean) => JSX.Element,
): any[] => {
  const columnsConfiguration = [] as any;
  if (!columns) return [];

  const persistedConfig = JSON.parse(localStorage.getItem(persistenceKey) || "{}");

  columns?.forEach((col: UsageAnalysisColumn | ServiceAnalysisColumn) => {
    const alignRight = col.type !== "TEXT" && col.type !== "DATE" && col.type !== "TIME";
    columnsConfiguration.push({
      name: col.code,
      label: col.name,
      type: col.type,
      code: col.code,
      options: {
        ...(col.type === "PRICE" ? priceColumnStyling : columnStyling),
        viewColumns: !col.required,
        display:
          typeof persistedConfig[col.code]?.visible === "boolean"
            ? persistedConfig[col.code]?.visible
            : typeof col.visible === "boolean"
              ? col.visible
              : true,
        sort: col.code !== "ACCOUNT_CODE" && col.code !== "ACCOUNT_NAME",
        sortDescFirst: true,
        customHeadLabelRender: customHeader ? (p: any) => customHeader(p.label, p.name, p.sort) : undefined,
        setCellHeaderProps: () => alignRight && { align: "right" },
        setCellProps: () => alignRight && { align: "right" },
      },
    });
  });
  return columnsConfiguration;
};

export const pushValueToRow = (
  row: any[],
  data: any,
  columnConfiguration: UsageAnalysisColumn | ServiceAnalysisColumn,
  setApplied: Function,
  tableType?: string,
  setTableType?: Function,
) => {
  const value = data ? data[upperCasetoCamelCase(columnConfiguration.code)] : "";

  if (columnConfiguration.type === "DATE") {
    row.push(formatDate(value, "dd/MM/yyyy"));
  } else if (columnConfiguration.type === "TIME") {
    row.push(value.slice(0, -3));
  } else if (columnConfiguration.type === "DURATION") {
    row.push(secondsToHms(value));
  } else if (columnConfiguration.type === "QUANTITY") {
    row.push(formatQuantity(value, data?.unit));
  } else if (columnConfiguration.type === "DATA") {
    row.push(bytesToSize(value, "KB"));
  } else if (columnConfiguration.code === "TOTAL_PRICE") {
    row.push(
      <TotalToButton
        price={formatPrice(value, data.currency)}
        link={row}
        tableType={tableType}
        setTableType={setTableType}
        setApplied={setApplied}
      />,
    );
  } else if (columnConfiguration.type === "PRICE") {
    row.push(formatPrice(value, data.currency));
  } else {
    row.push(value);
  }
};
