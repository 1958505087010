import { useState } from "react";
import { Role } from "../types";
import UIConfirm from "../../_app/components/UIConfirm";
import { DeleteOutline as DeleteIcon } from "../../_app/components/icons";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIGrid } from "../../_app/components";

interface Props {
  role: Role;
  deleteRole(user: Role): void;
}

const ManageRolesDeleteButton = ({ role, deleteRole }: Props) => {
  const classes = useStyles();
  const [warning, setWarning] = useState(false);

  return (
    <UIGrid className={classes.buttonWrap}>
      <UIButton variant="text" onClick={() => setWarning(true)} startIcon={<DeleteIcon />} color="error">
        Delete
      </UIButton>
      <UIConfirm
        open={warning}
        setOpen={setWarning}
        onConfirm={() => {
          deleteRole(role);
        }}
      >
        Are you sure you want to delete <b>{role?.name}</b> ?
      </UIConfirm>
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  buttonWrap: {
    textAlign: "left",
  },
}));

export default ManageRolesDeleteButton;
