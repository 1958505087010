import { getCachedContextHierarchy } from "../context/utils";
import { convertFiltersToQueries } from "../filter/api";
import api from "../_app/api";
import {
  AccountHierarchy,
  BillingAddress,
  BillingAddressUpdateParams,
  BillSettings,
  CostCentre,
  CostCentreAssignParams,
  FilterCostCentresParams,
  FilterCostCentresResponse,
  GetAccountsParams,
  GetAccountsResponse,
  Group,
} from "./types";

/* ACCOUNTS START */

export const getMyAccounts = async () => {
  const { data }: { data: Group[] } = await api({
    method: "GET",
    url: "/accounts/my",
  });
  return data.sort((a, b) => a.name.localeCompare(b.name));
};

export const getAccounts = async (params: GetAccountsParams) => {
  const { data }: { data: GetAccountsResponse } = await api({
    method: "GET",
    url: "/accounts",
    context_injection: false,
    params,
  });
  return data;
};

export const getAccountHierarchy = async (accountId: string) => {
  const { data }: { data: AccountHierarchy } = await api({
    method: "GET",
    url: `/accounts/${accountId}/hierarchy`,
  });

  return data;
};

/* ACCOUNTS END */

/* BILLING CONFIG START */

export const getBillingAddress = async () => {
  const accountId = getCachedContextHierarchy()?.id;
  const { data }: { data: BillingAddress } = await api({
    method: "GET",
    url: "/accounts/" + accountId + "/address",
  });
  return data;
};

export const updateBillingAddress = async (address: BillingAddressUpdateParams) => {
  const accountId = getCachedContextHierarchy()?.id;
  const { data }: { data: BillingAddress } = await api({
    method: "PUT",
    url: "/accounts/" + accountId + "/address",
    data: address,
  });
  return data;
};

export const getBillSettings = async () => {
  const accountId = getCachedContextHierarchy()?.id;

  const { data }: { data: BillSettings } = await api({
    method: "GET",
    url: "/accounts/" + accountId + "/bill-setting",
  });
  return data;
};

export const updateBillSettings = async (billSettings: BillSettings) => {
  const accountId = getCachedContextHierarchy()?.id;

  const { data }: { data: BillSettings } = await api({
    method: "PUT",
    url: "/accounts/" + accountId + "/bill-setting",
    data: {
      ...billSettings,
    },
  });
  return data;
};

/* BILLING CONFIG END */

/* COST CENTRES START */

export const UNASSIGNED = "Unassigned";
export const UNASSIGNED_CODE = "false";

export const unassignedCostCentreModel: CostCentre = {
  id: UNASSIGNED_CODE,
  code: UNASSIGNED_CODE,
  name: UNASSIGNED,
};

export const getCostCentres = async () => {
  let { data }: { data: CostCentre[] } = await api({
    method: "GET",
    url: "/cost-centres",
    context_injection: true,
  });
  return data;
};

export const getFilteredCostCentres = async (params: FilterCostCentresParams) => {
  let { data }: { data: FilterCostCentresResponse } = await api({
    method: "GET",
    url: "/cost-centres/assets",
    context_injection: true,

    params,
  });
  return data;
};

export const updateCostCentre = async (cc: CostCentre) => {
  const { data }: { data: CostCentre } = await api({
    method: "PUT",

    url: `/cost-centres/${cc?.id}`,
    data: cc,
  });
  return data;
};

export const createCostCentre = async (cc: any) => {
  const { data }: { data: CostCentre } = await api({
    method: "POST",
    context_injection: true,

    url: "/cost-centres",
    data: cc,
  });
  return data;
};
export const deleteCostCentre = async (id: string) => {
  const { data }: { data: string } = await api({
    method: "DELETE",

    url: `/cost-centres/${id}`,
  });
  return data;
};

export const assignCostCentres = async (params: CostCentreAssignParams) => {
  const parsed = convertFiltersToQueries(params);
  parsed.costCentreIds = parsed.costCentreIds?.filter((cc: string) => cc !== UNASSIGNED_CODE);
  const { data }: { data: any } = await api({
    method: "PUT",
    context_injection: true,

    url: "/cost-centres/assign",
    data: parsed,
  });
  return data;
};

/* COST CENTRES END */
