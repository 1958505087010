import { useNavigate, useParams } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import EditRoleForm from "../components/EditRoleForm";
import { useRoleUpdate, useSingleRole } from "../hooks";
import { permissionCodes, usePermissions } from "../../permission/hooks";
import { Role } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UIGrid } from "../../_app/components";

export const EditRole = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const classes = useStyles();

  const { data: role, isFetching: roleFetching, isSuccess: roleSuccess } = useSingleRole(id ?? "");
  const { data: permissions, isLoading: permsFetching } = usePermissions(undefined, id);
  const { mutate: executeRoleUpdate, isLoading: isUpdating } = useRoleUpdate({
    onSuccess: () => {
      navigate("/roles");
    },
  });

  const isLoading = roleFetching || permsFetching;
  const onSubmit = (data: Role) => executeRoleUpdate(data);

  return (
    <Main title="Edit Role" data-cy="edit-role-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={isLoading}>
      {roleSuccess && (
        <UICard elevation={1} className={classes.card}>
          <UIGrid container>
            <EditRoleForm
              role={{ ...role, permissions }}
              handleSubmit={onSubmit}
              submitLabel="Save Changes"
              handleCancel={() => navigate(-1)}
              isSubmitting={isUpdating}
            />
          </UIGrid>
        </UICard>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    padding: theme.spacing(5),
    width: "100%",
  },
}));

export default EditRole;
