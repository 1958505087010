import { SortRequestParam } from "./helpers";
import { createStylesheet } from "../../utils/styles";
import { UIBox, UITableSortLabel } from "..";

interface SortableHeaderLabelProps {
  label: string;
  name: string;
  sort: SortRequestParam;
}
export const SortableHeaderLabel = ({ label, name, sort }: SortableHeaderLabelProps) => {
  const classes = useStyles();

  return sort.column !== name ? (
    <UIBox display="flex">
      {label}
      <span className={classes.labelWrap}>
        <UITableSortLabel active className={classes.inactiveSortIcon} />
      </span>
    </UIBox>
  ) : (
    <span className={classes.activeSortIcon}>{label}</span>
  );
};

const useStyles = createStylesheet((theme) => ({
  labelWrap: {
    marginBottom: "2px",
    "& .Mui-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
  },
  inactiveSortIcon: {
    "& .MuiTableSortLabel-icon": {
      color: `${theme.palette.grey[200]} !important`,
    },
  },
  activeSortIcon: {
    "& .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
  },
}));

export default SortableHeaderLabel;
