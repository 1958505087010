import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AuthCallback, SilentAuthCallback } from "../auth/components/AuthCallback";
import { Home } from "../dashboard/screens/Home";
import ManageUsers from "../user/screens/ManageUsers";
import ManageRoles from "../role/screens/ManageRoles";
import AddUser from "../user/screens/AddUser";
import EditUser from "../user/screens/EditUser";
import MyDetails from "../user/screens/MyDetails";
import Page404 from "./screens/Page404";
import EditRole from "../role/screens/EditRole";
import AddRole from "../role/screens/AddRole";
import BillExplorer from "../billing/screens/BillExplorer";
import PeriodDetails from "../billing/screens/PeriodDetails";
import UsageAnalysis from "../usage-analysis/screens/UsageAnalysis";
import ServiceAnalysis from "../service-analysis/screens/ServiceAnalysis";
import AccountDetails from "../account/screens/AccountDetails";
import Statements from "../billing/screens/Statements";
import Invoices from "../billing/screens/Invoices";
import Groups from "../context/screens/Groups";
import PaymentType from "../payment/screens/PaymentType";
import Logout from "../auth/screens/Logout";
import NoUser from "../auth/screens/NoUser";
import CardPayment from "../payment/screens/CardPayment";
import ManageContacts from "../contacts/screens/ManageContacts";
import EditContact from "../contacts/screens/EditContact";
import AddContact from "../contacts/screens/AddContact";
import SearchAssets from "../asset/screens/SearchAssets";
import AssetDetails from "../asset/screens/AssetDetails";
import BillDashMonthly from "../bill-dash/screens/BillDashMonthly";
import BillDashTrends from "../bill-dash/screens/BillDashTrends";
import Support from "../support/screens/Support";
import EditUsageAlert from "../alerts/screens/EditUsageAlert";
import ContactSupport from "../support/screens/ContactSupport";
import MobileManagement from "../asset/screens/MobileManagement";
import SimSwap from "../sim/screens/SimSwap";
import BarsUpdate from "../bars/screens/BarsUpdate";
import BillLimit from "../asset/screens/BillLimit";
import SimSwitchResults from "../sim/screens/SimSwitchResults";
import SimUnlock from "../sim/screens/SimUnlock";
import SimActivate from "../sim/screens/SimActivate";
import SimDisconnect from "../sim/screens/SimDisconnect";
import SimOrder from "../sim/screens/SimOrder";
import DataRoaming from "../data-roaming/screens/DataRoaming";
import SimLock from "../sim/screens/SimLock";
import UpdateCostCentres from "../account/screens/UpdateCostCentres";
import ManageCostCentres from "../account/screens/ManageCostCentres";
import CliDetails from "../account/components/CliDetails";
import BillLimitAlertsAccount from "../alerts/screens/BillLimitAlertsAccount";
import BillLimitAlertsCli from "../alerts/screens/BillLimitAlertsCli";
import BillLimitAlertsCliDetail from "../alerts/screens/BillLimitAlertsCliDetail";
import Drawered from "./layouts/Drawered";
import Modaled from "./layouts/Modaled";
import SimSwitch from "../sim/screens/SimSwitch";
import LineTest from "../asset/screens/LineTest";
import TicketDetails from "../support/screens/TicketDetails";
import AddNewTicket from "../support/screens/AddNewTicket";
import Tickets from "../support/screens/Tickets";
import AddTicketAdvanced from "../support/screens/AddTicketAdvanced";
import UsageAlerts from "../alerts/screens/UsageAlerts";
import UsageAlertsHistory from "../alerts/screens/UsageAlertsHistory";
import BundleAlertsIndividual from "../alerts/screens/BundleAlertsIndividual";
import BundleAlertsAccount from "../alerts/screens/BundleAlertsAccount";
import BundleAlertsGlobal from "../alerts/screens/BundleAlertsGlobal";
import LogicMonitor from "../logic-monitor/screens/LogicMonitor";
import MobileManagementHistory from "../asset/screens/MobileManagementHistory";
import Help from "../help/screens/Help";
import Login from "../auth/screens/Login";
import JWTLogin from "../auth/screens/JWTLogin";
import Register from "../user/screens/Register";
import Communications from "../communications/screens/Communications";
import MyDetailsCognito from "../user/screens/MyDetailsCognito";
import { isCognitoUser } from "../auth/utils";
import BulkEditUsageAlerts from "../alerts/screens/BulkEditUsageAlerts";
import { PrivateRoute } from "../auth/components/PrivateRoute";
import { filtersIdMap } from "../filter/utils";

function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/no-user" element={<NoUser />} />
      <Route path="/login" element={<Login />} />
      <Route path="/oauth_callback" element={<AuthCallback />} />
      <Route path="/silent_oauth_callback" element={<SilentAuthCallback />} />
      <Route path="/profile" element={<PrivateRoute>{isCognitoUser() ? <MyDetailsCognito /> : <MyDetails />}</PrivateRoute>} />
      <Route
        path="/groups"
        element={
          <PrivateRoute>
            <Groups />
          </PrivateRoute>
        }
      />
      <Route
        path="/account"
        element={
          <PrivateRoute>
            <AccountDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <ManageUsers />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/:id"
        element={
          <PrivateRoute>
            <EditUser />
          </PrivateRoute>
        }
      />
      <Route
        path="/user/new"
        element={
          <PrivateRoute>
            <AddUser />
          </PrivateRoute>
        }
      />
      <Route
        path="/roles"
        element={
          <PrivateRoute>
            <ManageRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/roles/:id"
        element={
          <PrivateRoute>
            <EditRole />
          </PrivateRoute>
        }
      />
      <Route
        path="/role/new"
        element={
          <PrivateRoute>
            <AddRole />
          </PrivateRoute>
        }
      />
      <Route
        path="/bills"
        element={
          <PrivateRoute>
            <BillExplorer />
          </PrivateRoute>
        }
      >
        <Route
          path="period-details/:periodId/:invoiceId"
          element={
            <PrivateRoute>
              <Drawered
                width={40}
                onClose={() => {
                  const { fromHome, periodId } = location.state || {};
                  if (fromHome) return navigate(`/bills?${filtersIdMap?.BILL_PERIOD}=${periodId}`);
                  else return navigate(-1);
                }}
              >
                <PeriodDetails />
              </Drawered>
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/usage-analysis"
        element={
          <PrivateRoute>
            <UsageAnalysis />
          </PrivateRoute>
        }
      />
      <Route
        path="/service-analysis"
        element={
          <PrivateRoute>
            <ServiceAnalysis />
          </PrivateRoute>
        }
      />
      <Route
        path="/statements"
        element={
          <PrivateRoute>
            <Statements />
          </PrivateRoute>
        }
      />
      <Route
        path="/invoices"
        element={
          <PrivateRoute>
            <Invoices />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment-type"
        element={
          <PrivateRoute>
            <PaymentType />
          </PrivateRoute>
        }
      />
      <Route
        path="/card-payment"
        element={
          <PrivateRoute>
            <CardPayment />
          </PrivateRoute>
        }
      />
      <Route
        path="/contacts"
        element={
          <PrivateRoute>
            <ManageContacts />
          </PrivateRoute>
        }
      />
      <Route
        path="/contacts/:id"
        element={
          <PrivateRoute>
            <EditContact />
          </PrivateRoute>
        }
      />
      <Route
        path="/contact/new"
        element={
          <PrivateRoute>
            <AddContact />
          </PrivateRoute>
        }
      />
      <Route
        path="/assets"
        element={
          <PrivateRoute>
            <SearchAssets />
          </PrivateRoute>
        }
      />
      <Route
        path="/assets/:id"
        element={
          <PrivateRoute>
            <AssetDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/bill-dashboard-monthly"
        element={
          <PrivateRoute>
            <BillDashMonthly />
          </PrivateRoute>
        }
      />
      <Route
        path="/bill-dashboard-trends"
        element={
          <PrivateRoute>
            <BillDashTrends />
          </PrivateRoute>
        }
      />
      <Route
        path="/support"
        element={
          <PrivateRoute>
            <Support />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets/new-advanced"
        element={
          <PrivateRoute>
            <AddTicketAdvanced />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets"
        element={
          <PrivateRoute>
            <Tickets />
          </PrivateRoute>
        }
      >
        <Route
          path="new"
          element={
            <PrivateRoute>
              <Modaled onClose={() => navigate("/tickets")}>
                <AddNewTicket />
              </Modaled>
            </PrivateRoute>
          }
        />
        <Route
          path="details/:id"
          element={
            <PrivateRoute>
              <Drawered width={68} onClose={() => navigate("/tickets")}>
                <TicketDetails />
              </Drawered>
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/line-test"
        element={
          <PrivateRoute>
            <LineTest />
          </PrivateRoute>
        }
      />
      <Route
        path="/usage-alerts"
        element={
          <PrivateRoute>
            <UsageAlerts />
          </PrivateRoute>
        }
      />
      <Route
        path="/usage-alerts-history"
        element={
          <PrivateRoute>
            <UsageAlertsHistory />
          </PrivateRoute>
        }
      />
      <Route
        path="/usage-alerts/new"
        element={
          <PrivateRoute>
            <EditUsageAlert />
          </PrivateRoute>
        }
      />
      <Route
        path="/usage-alerts/:id"
        element={
          <PrivateRoute>
            <EditUsageAlert />
          </PrivateRoute>
        }
      />
      <Route
        path="/usage-alerts/bulk-edit"
        element={
          <PrivateRoute>
            <BulkEditUsageAlerts />
          </PrivateRoute>
        }
      />
      <Route
        path="/bundle-alerts"
        element={
          <PrivateRoute>
            <BundleAlertsIndividual />
          </PrivateRoute>
        }
      />
      <Route
        path="/bundle-alerts-account"
        element={
          <PrivateRoute>
            <BundleAlertsAccount />
          </PrivateRoute>
        }
      />
      <Route
        path="/bundle-alerts-global"
        element={
          <PrivateRoute>
            <BundleAlertsGlobal />
          </PrivateRoute>
        }
      />
      <Route
        path="/mobile-management-history"
        element={
          <PrivateRoute>
            <MobileManagementHistory />
          </PrivateRoute>
        }
      />
      <Route
        path="/mobile-management"
        element={
          <PrivateRoute>
            <MobileManagement />
          </PrivateRoute>
        }
      >
        <Route
          path="bars/update"
          element={
            <PrivateRoute>
              <Drawered width={70}>
                <BarsUpdate />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="sim-switch"
          element={
            <PrivateRoute>
              <Drawered width={50}>
                <SimSwitch />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="sim-switch/results"
          element={
            <PrivateRoute>
              <Drawered
                onClose={() => {
                  navigate("/mobile-management", { state: { search: location.search } });
                }}
              >
                <SimSwitchResults />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="data-roaming"
          element={
            <PrivateRoute>
              <Drawered>
                <DataRoaming />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="sim-swap"
          element={
            <PrivateRoute>
              <Drawered width={78}>
                <SimSwap />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="bill-limit"
          element={
            <PrivateRoute>
              <Drawered width={78}>
                <BillLimit />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="sim-lock"
          element={
            <PrivateRoute>
              <Drawered width={78}>
                <SimLock />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="sim-unlock"
          element={
            <PrivateRoute>
              <Drawered width={78}>
                <SimUnlock />
              </Drawered>
            </PrivateRoute>
          }
        />
        <Route
          path="sim-order"
          element={
            <PrivateRoute>
              <Drawered width={78}>
                <SimOrder />
              </Drawered>
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/sim-activate"
        element={
          <PrivateRoute>
            <SimActivate />
          </PrivateRoute>
        }
      />
      <Route
        path="/sim-disconnect"
        element={
          <PrivateRoute>
            <SimDisconnect />
          </PrivateRoute>
        }
      />
      <Route
        path="/contact-support"
        element={
          <PrivateRoute>
            <ContactSupport />
          </PrivateRoute>
        }
      />
      <Route
        path="/manage-cost-centres"
        element={
          <PrivateRoute>
            <ManageCostCentres />
          </PrivateRoute>
        }
      >
        <Route
          path=":cli/:id"
          element={
            <PrivateRoute>
              <Drawered width={70} onClose={() => navigate("/manage-cost-centres")}>
                <CliDetails />
              </Drawered>
            </PrivateRoute>
          }
        />
      </Route>

      <Route
        path="/update-cost-centres"
        element={
          <PrivateRoute>
            <UpdateCostCentres />
          </PrivateRoute>
        }
      />
      <Route
        path="/bill-limit-alerts-account"
        element={
          <PrivateRoute>
            <BillLimitAlertsAccount />
          </PrivateRoute>
        }
      />
      <Route
        path="/bill-limit-alerts-cli"
        element={
          <PrivateRoute>
            <BillLimitAlertsCli />
          </PrivateRoute>
        }
      />
      <Route
        path="/bill-limit-alerts-cli/:id"
        element={
          <PrivateRoute>
            <BillLimitAlertsCliDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/help/:category?"
        element={
          <PrivateRoute>
            <Help />
          </PrivateRoute>
        }
      >
        <Route
          path="new-ticket"
          element={
            <PrivateRoute>
              <Modaled onClose={() => navigate(-1)}>
                <AddNewTicket />
              </Modaled>
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/logic-monitor"
        element={
          <PrivateRoute>
            <LogicMonitor />
          </PrivateRoute>
        }
      />
      <Route
        path="/communications"
        element={
          <PrivateRoute>
            <Communications />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route path="/jwt-login" element={<JWTLogin />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default AppRoutes;
