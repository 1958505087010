import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Public from "../../_app/layouts/Public";
import { useHubUserExist } from "../../user/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIButton, UIGrid, UITypography } from "../../_app/components";

const NoUser = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [timer, setTimer] = useState<number>(20);
  const { data: doesHubUserExist, isFetchedAfterMount } = useHubUserExist();

  const handleLogout = useCallback(() => {
    navigate("/logout");
  }, [navigate]);

  useEffect(() => {
    if (isFetchedAfterMount && doesHubUserExist) {
      navigate("/");
    }
  }, [navigate, isFetchedAfterMount, doesHubUserExist]);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((old: number) => Math.max(old - 1, 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!timer && !doesHubUserExist) {
      handleLogout();
    }
  }, [handleLogout, timer, doesHubUserExist]);

  return (
    <Public data-cy="disabled-page" title="No User Access" className={classes.ctr}>
      <UIGrid className={classes.alert}>
        <UIAlert severity="info">You do not have access, please contact your administrator</UIAlert>
      </UIGrid>
      <UIButton variant="outlined" onClick={handleLogout} data-cy="disabled-page-logout-button" className={classes.logoutButton}>
        Logout
      </UIButton>
      <UITypography className={classes.countdown}>Logging out in {timer} seconds</UITypography>
    </Public>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    gap: theme.spacing(3),
  },
  alert: {
    maxWidth: 450,
  },
  logoutButton: {
    textAlign: "center",
  },
  countdown: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default NoUser;
