import { MouseEvent } from "react";
import { onLinkClickHandler } from "../utils";
import { createStylesheet } from "../utils/styles";

export interface Props {
  html?: string;
}

export const UIHtml = ({ html = "" }: Props) => {
  const classes = useStyles();
  const parsedHtml = html.replace(/\n/g, "<br />");
  return (
    <div
      className={classes.ctr}
      onClick={(ev: MouseEvent<HTMLElement>) => onLinkClickHandler(ev, history)}
      dangerouslySetInnerHTML={{
        __html: parsedHtml,
      }}
    />
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
    "& a:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
}));

export default UIHtml;
