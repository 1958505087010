import { useState } from "react";
import Main from "../../_app/layouts/Main";
import { useNavigate } from "react-router-dom";
import ServiceRequests from "../../dashboard/widgets/ServiceRequests";
import { Add as AddIcon } from "../../_app/components/icons";
import RangeSelector from "../components/RangeSelector";
import { permissionCodes } from "../../permission/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIGrid } from "../../_app/components";

export const Support = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [range, setRange] = useState("1mo");

  return (
    <Main
      title="Support"
      titleProps={{ style: { marginBottom: "-25px" } }}
      data-cy="support-page"
      featureFlag={featureFlagsMap.SUPPORT + "_HIDDEN"}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
    >
      <UIGrid container direction="row" spacing={2} className={classes.ctr}>
        <UIGrid size={{ sm: 12 }} className={classes.actionBar}>
          <RangeSelector selected={range} setRange={setRange} />
          <UIButton
            variant="contained"
            color="primary"
            data-cy="add-button"
            startIcon={<AddIcon />}
            onClick={() => navigate("/tickets/new")}
          >
            New Ticket
          </UIButton>
        </UIGrid>
        <UIGrid size={{ sm: 12, md: 6 }} className={classes.fullWidth}>
          <ServiceRequests />
        </UIGrid>
        <UIGrid size={{ sm: 12, md: 6 }} className={classes.fullWidth}>
          <ServiceRequests title="Incidents" />
        </UIGrid>
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {},
  actionBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: `0px ${theme.spacing(2)} !important`,
    gap: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
}));

export default Support;
