const config = {
  configEndpoints: {
    baseEndpoint: "https://api.dev.hub.affinity.io",
  },
  configGoogleTagManager: {
    gtmId: "GTM-NL6LH2C",
  },
  configRecaptcha: {
    siteKey: "6LdTd8IhAAAAANZXLQF2hWnq75vSskCRs5ufFLiC",
  },
  configIntercom: {
    enabled: false,
  },
  configLiveChat: {
    script: "//js.hs-scripts.com/8487018.js?businessUnitId=108691",
  },
  configLogicMonitor: {
    baseUrl: "https://dc.api.masro.io/uat",
    apiToken: "bXlhY2NvdW50X3BvcnRhbDp4aXdSeG41YVoweTVeaUI4",
    jsBundleUrl: "https://s3.eu-west-2.amazonaws.com/dc.masro.io/js/logicone.js",
  },
  configSentry: {
    enabled: false,
  },
};

export default config;
