import Tabbed from "../../_app/layouts/Tabbed";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageCostCentresTabBar({ selectedTab, children }: Props) {
  return (
    <Tabbed
      selectedTab={selectedTab}
      tabs={[
        {
          value: "manage-cost-centres",
          label: "Create and Manage",
          hidden: false,
        },
        {
          value: "update-cost-centres",
          label: "Bulk update",
          hidden: false,
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default ManageCostCentresTabBar;
