import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import EditRoleForm from "../components/EditRoleForm";
import { permissionCodes, usePermissions } from "../../permission/hooks";
import { useRoleCreate } from "../hooks";
import { Role } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UIGrid } from "../../_app/components";

export const AddRole = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { data: permissions, isFetching } = usePermissions();
  const { mutate: executeRoleCreate, isLoading: isCreating } = useRoleCreate({
    onSuccess: () => {
      navigate("/roles");
    },
  });

  const onSubmit = (role: Role) => {
    executeRoleCreate(role);
  };

  return (
    <Main title="Add Role" data-cy="add-role-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={isFetching}>
      <UICard elevation={1} className={classes.card}>
        <UIGrid container>
          <EditRoleForm
            role={{ permissions }}
            submitLabel={"Submit"}
            handleSubmit={onSubmit}
            isSubmitting={isCreating}
            handleCancel={() => navigate(-1)}
          />
        </UIGrid>
      </UICard>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    padding: theme.spacing(5),
    width: "100%",
  },
}));

export default AddRole;
