import NavWidget from "./NavWidget";
import { createStylesheet } from "../../../_app/utils/styles";
import { UITypography } from "../../../_app/components";

interface Props {
  icon: any;
  title: string;
  description?: string;
  link: string;
}

const SimpleNavWidget = ({ icon, title, description, link, ...props }: Props) => {
  const classes = useStyles();

  return (
    <NavWidget icon={icon} title={title} link={link} {...props}>
      <UITypography className={classes.desc}>{description}</UITypography>
    </NavWidget>
  );
};

const useStyles = createStylesheet((theme) => ({
  desc: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

export default SimpleNavWidget;
