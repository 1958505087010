import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import { useUsageBreakdown } from "../../hooks";
import GraphWidget from "./GraphWidget";
import { formatNumber, humanize } from "../../../_app/utils/format";
import { Query } from "../../../filter/types";
import { GRAPH_COLORS } from "../../utils";
import { featureFlagsMap } from "../../../feature/utils";
import { createStylesheet } from "../../../_app/utils/styles";
import { UITypography } from "../../../_app/components";

interface Props {
  queries: Query[];
}

const UsageCost = ({ queries }: Props) => {
  const classes = useStyles();
  const { data = [], isFetching } = useUsageBreakdown(queries);

  const unit = data?.unit;

  const parsedTotals = useMemo(() => {
    return data?.items?.map((item: any) => parseFloat(item?.number || 0).toFixed(2)) ?? [];
  }, [data]);

  const parsedLabels = useMemo(() => {
    return data?.items?.map((item: any) => item?.name || "") ?? [];
  }, [data]);

  const list = data?.items?.map((values: any, i: any) => {
    return (
      <div className={classes.legendGroup} key={values.name}>
        {Object.entries(values?.values)?.map(([label, value]) => {
          return (
            <div className={classes.legendItem} style={{ borderColor: GRAPH_COLORS[i] }} key={label}>
              <UITypography variant="body2">{humanize(label)}</UITypography>
              <UITypography variant="body2">{`${formatNumber(value, 2, unit)}`}</UITypography>
            </div>
          );
        })}
      </div>
    );
  });

  const chartData: ChartData<"doughnut", number[], string> = {
    datasets: [
      {
        data: parsedTotals,
        backgroundColor: GRAPH_COLORS,
        hoverOffset: 5,
      },
    ],
    labels: parsedLabels,
  };

  const doughnutAttr: ChartOptions<"doughnut"> = {
    responsive: true,
    cutout: 95,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 35,
        },
      },
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "#000000",
        bodyColor: "#000000",
        displayColors: false,
        padding: { left: 25, right: 25, top: 15, bottom: 15 },
        bodyFont: { weight: "bold" },
        borderColor: "#000000",
        caretSize: 0,
        borderWidth: 1,
      },
    },
  };

  return (
    <GraphWidget title="Usage Cost" isLoading={isFetching} featureFlag={featureFlagsMap.USAGE_COSTS_GRAPH}>
      <div className={classes.ctr}>
        <div className={classes.graph}>
          <Doughnut data={chartData} options={doughnutAttr} />
        </div>
        <div className={classes.detailedLegend}>{list}</div>
      </div>
    </GraphWidget>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    padding: "0px",
    paddingTop: 8,
    display: "flex",
    flex: 1,
    gap: "8%",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    //minHeight: "200px"
  },
  graph: {},
  detailedLegend: {
    flex: 1,
    marginTop: "10px",
  },
  legendGroup: {
    marginTop: theme.spacing(1),
  },
  legendItem: {
    display: "flex",
    gap: "5px",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "5px 10px",
    borderLeft: "solid 5px",
    borderColor: theme.palette.grey[300],
  },
}));

export default UsageCost;
