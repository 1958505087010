import { useState } from "react";
import StatusChip from "../../../../_app/components/StatusChip";
import { useSetWdrStatusForAsset } from "../../../../data-roaming/hooks";
import { UILoader, UISwitch } from "../../../../_app/components";
import UISimpleDialog from "../../../../_app/components/UISimpleDialog";

interface Props {
  assetId: string;
  capped: boolean;
  pendingChange: boolean;
}

const WorldwideDataCapToggle = ({ assetId, capped, pendingChange }: Props) => {
  const [open, setOpen] = useState(false);
  const { mutate: updateWdrStatus, isLoading } = useSetWdrStatusForAsset(assetId);

  const handleToggle = () => {
    updateWdrStatus({ assetId: assetId, capped: !capped });
    setOpen(false);
  };

  return isLoading || pendingChange ? (
    <StatusChip status="Pending update" avatar={<UILoader size={15} />} />
  ) : (
    <>
      <UISimpleDialog
        title={"Confirm Data Cap Change"}
        open={open}
        actions={[
          {
            label: "Confirm change",
            props: {
              onClick: () => handleToggle(),
              color: "primary",
            },
          },
          {
            label: "Close",
            props: {
              onClick: () => setOpen(false),
              variant: "outlined",
            },
          },
        ]}
      >
        Are you sure you want to change the Worldwide Data Cap setting for this mobile?
      </UISimpleDialog>
      <UISwitch data-cy={"wdr-cap-toggle"} onChange={() => setOpen(true)} checked={capped} />
    </>
  );
};

export default WorldwideDataCapToggle;
