import MUIDataTable from "mui-datatables";

export type {
  MUIDataTableState as UIDataTableState,
  MUIDataTableColumnDef as UIDataTableColumnDef,
  MUIDataTableOptions as UIDataTableOptions,
  MUIDataTableColumnOptions as UIDataTableColumnOptions,
  CustomHeadLabelRenderOptions as UICustomHeadLabelRenderOptions,
} from "mui-datatables";
export default MUIDataTable;
