import { ChangeEvent, useState } from "react";
import { Add as AddIcon, Create as EditIcon, Delete as DeleteIcon } from "../../_app/components/icons";
import { UIButton, UIConfirm, UITextField, UITypography } from "../../_app/components";
import { CostCentre } from "../types";
import { createStylesheet } from "../../_app/utils/styles";

interface Props {
  hasAdd?: boolean;
  costCentre: CostCentre;
  handleSubmit?: Function;
  handleDelete?: Function;
  handleUpdate?: Function;
  [key: string]: any;
}

function CostCentreActionButtons({
  hasAdd = true,
  costCentre,
  handleSubmit = () => {},
  handleDelete = () => {},
  handleUpdate = () => {},
}: Props) {
  const classes = useStyles();
  const [warning, setWarning] = useState(false);
  const [addWarning, setAddWarning] = useState(false);
  const [editWarning, setEditWarning] = useState(false);
  const [data, setData] = useState({ ...costCentre, newName: null });

  const handleCostCentreSubmit = () => {
    return handleSubmit({
      ...costCentre,
      id: "",
      parent: data?.id,
      name: data?.newName,
      subCostCentres: [],
    });
  };

  const handleCostCentreCancel = () => {
    setData({ ...costCentre, newName: null });
  };

  const handleCostCentreUpdate = () => {
    const { newName, ...edited } = data;
    return handleUpdate(edited);
  };

  const handleCostCentreDelete = () => {
    return handleDelete(data?.id);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const val = e.target.value;
    setData({ ...data, [key]: val });
  };

  return (
    <div className={classes.right} onClick={(ev) => ev?.stopPropagation()}>
      {Boolean(hasAdd) && (
        <>
          <UIButton
            component={"span"}
            data-cy="cost-centre-add"
            startIcon={<AddIcon />}
            size="small"
            color="primary"
            onClick={() => setAddWarning(true)}
          >
            <span className={classes.buttonTxt}>Add</span>
          </UIButton>
          <UIConfirm
            title="Add Cost Centre"
            onSubmitTitle="Add"
            open={addWarning}
            setOpen={setAddWarning}
            onConfirm={handleCostCentreSubmit}
            onCancel={handleCostCentreCancel}
            disableOnSubmit={!data?.newName}
          >
            <UITextField
              name="newName"
              placeholder="Cost Centre Name"
              type="text"
              value={data?.newName || ""}
              onChange={onInputChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </UIConfirm>
        </>
      )}
      <UIButton
        data-cy="cost-centre-edit"
        variant="outlined"
        startIcon={<EditIcon />}
        size="small"
        color="primary"
        onClick={() => setEditWarning(true)}
        component={"span"}
      >
        <span className={classes.buttonTxt}>Edit</span>
      </UIButton>
      <UIConfirm
        title="Edit Cost Centre"
        onSubmitTitle="Save"
        open={editWarning}
        setOpen={setEditWarning}
        onConfirm={handleCostCentreUpdate}
        disableOnSubmit={!data?.name}
      >
        <UITextField
          name="name"
          type="text"
          defaultValue={costCentre?.name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          fullWidth
        />
      </UIConfirm>
      <UIButton
        data-cy="cost-centre-delete"
        variant="outlined"
        startIcon={<DeleteIcon />}
        size="small"
        color="error"
        onClick={() => setWarning(true)}
        component={"span"}
      >
        <span className={classes.buttonTxt}>Delete</span>
      </UIButton>
      <UIConfirm title="Delete" open={warning} setOpen={setWarning} onConfirm={handleCostCentreDelete} isDelete>
        <UITypography>Are you sure you want to delete {costCentre?.name} ?</UITypography>
      </UIConfirm>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  right: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    "& button": {
      [theme.breakpoints.down("md")]: {
        minWidth: 32,
        paddingLeft: 8,
        paddingRight: 8,
        "& .MuiButton-startIcon": {
          margin: 0,
        },
      },
    },
  },
  buttonTxt: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default CostCentreActionButtons;
