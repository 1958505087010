import SelectDropdown from "../../../form/components/SelectDropdown";
import { Controller, useFormContext } from "react-hook-form";
import { useAllRoles } from "../../../role/hooks";
import { UIGrid } from "../../../_app/components";

interface Props {
  classes?: any;
}

const RoleSelector = ({ classes }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data } = useAllRoles();

  const roles =
    data?.list?.map((role) => ({
      id: role.id,
      label: role.name,
      value: role.id,
    })) || [];

  if (!roles.length) return null;

  const fullList = [{ id: "", label: "No Role", value: "" }, ...roles];

  return (
    <>
      <UIGrid size={{ xs: 12, md: 4 }}></UIGrid>
      <UIGrid size={{ xs: 12, md: 8 }}>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <SelectDropdown
              {...field}
              label="Role"
              data={fullList}
              ctrClass={classes.textField}
              error={!!errors?.role}
              helperText={errors?.role?.message as string | undefined}
            />
          )}
        />
      </UIGrid>
    </>
  );
};

export default RoleSelector;
