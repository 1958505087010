import { UIEvent, useEffect, useRef } from "react";
import { Info as InfoIcon } from "../../_app/components/icons";
import { formatDate } from "../../_app/utils/format";
import { useTicketComments } from "../hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UIChip, UIGrid, UIHtml, UILoader, UISkeleton, UITimelineDot, UITypography } from "../../_app/components";

interface Props {
  ticketId: string;
  scrollToTop?: number;
}

export const CommentsList = ({ ticketId, scrollToTop }: Props) => {
  const classes = useStyles();
  const ref = useRef<null | HTMLDivElement>(null);
  const { data: commentsResp, fetchNextPage, hasNextPage, isFetching } = useTicketComments(ticketId);
  const comments = commentsResp?.pages.reduce((acc: any, page: any) => [...acc, ...page.list], []);
  useEffect(() => {
    if (scrollToTop) performScrollToTop();
  }, [scrollToTop]);

  const performScrollToTop = () => ref?.current?.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <UICard
      innerRef={ref}
      elevation={1}
      className={classes.ctr}
      data-cy="ticket-notes-card"
      onScroll={(e: UIEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        const buffer = 15;
        const scrolled = target.scrollHeight - target.scrollTop;
        const total = target.clientHeight + buffer;
        const hitBottom = scrolled <= total;
        if (hitBottom && hasNextPage) {
          fetchNextPage();
        }
      }}
    >
      {!comments?.length && !isFetching && (
        <div className={classes.emptyCtr}>
          <UITypography variant="body1">No comments</UITypography>
        </div>
      )}
      {comments?.map((_comment) => (
        <UICard key={_comment.id} elevation={1} className={classes.commentItem}>
          <UIGrid container alignItems="center" className={classes.grid}>
            <UITimelineDot color="primary" />
            <UITypography component="span" variant="subtitle2" className={classes.marginLeft}>
              {formatDate(_comment.created, "dd/MM/yyyy HH:mm:ss") || <UISkeleton width={100} />}
            </UITypography>
          </UIGrid>
          <UIGrid container className={classes.grid}>
            <UITypography component="span" variant="h3" className={classes.createdBy}>
              {_comment.createdBy || <UISkeleton width={200} />}
            </UITypography>
            {Boolean(_comment.internal) && (
              <UIChip
                variant="outlined"
                color="primary"
                label="Daisy Customer Services"
                size="small"
                icon={<InfoIcon />}
                className={classes.marginLeft}
              />
            )}
          </UIGrid>
          <UIGrid className={classes.grid}>
            <UIHtml html={_comment.comment} />
          </UIGrid>
        </UICard>
      ))}
      {isFetching && (
        <div className={classes.emptyCtr}>
          <UILoader />
        </div>
      )}
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  emptyCtr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ctr: {
    maxHeight: "calc(100vh - 260px)",
    padding: theme.spacing(2),
    overflow: "auto",
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  grid: {
    paddingBottom: theme.spacing(2),
  },
  createdBy: {
    fontWeight: 700,
  },
  commentItem: {
    borderRadius: "5px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderLeft: "3px solid " + theme.palette.primary.main,
  },
}));

export default CommentsList;
