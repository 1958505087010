import { useLocation } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { BarControllerChartOptions } from "chart.js";
import GraphWidget from "./GraphWidget";
import { useCostUser } from "../../hooks";
import { Query } from "../../../filter/types";
import { useHasFeatureFlag } from "../../../feature/hooks";
import { humanize } from "../../../_app/utils/format";
import FilterItem from "../../../filter/components/FilterItem";
import AddedFilters from "../../../filter/components/AddedFilters";
import { useAllFilters } from "../../../filter/hooks";
import { Price } from "../../../billing/types";
import { formatPrice } from "../../../billing/utils";
import { featureFlagsMap } from "../../../feature/utils";
import { createStylesheet } from "../../../_app/utils/styles";

const featureFlag = featureFlagsMap.ASSETS_BY_COST_GRAPH;
const PRODUCT_TYPE_FILTER_ID = "71";

let unit = "";
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        usePointStyle: true,
        padding: 35,
      },
    },
    title: {
      display: false,
    },
    tooltip: {
      position: "nearest",
      backgroundColor: "#ffffff",
      titleColor: "#000000",
      bodyColor: "#000000",
      padding: 15,
      displayColors: false,
      borderColor: "#000000",
      caretSize: 0,
      borderWidth: 1,
      callbacks: {
        label: function (context: any) {
          if (context.parsed.y !== null) {
            const p: Price = {
              gross: context.parsed.y,
              net: context.parsed.y,
              vat: 0,
              unit: unit,
            };
            return context?.dataset?.label + " " + formatPrice(p);
          } else {
            return null;
          }
        },
        title: function (context: any) {
          var title = context?.[0]?.raw?.name;
          var number = context?.[0]?.raw?.number;

          return ["Name: " + title, "Number: " + number];
        },
      },
    },
  },
  parsing: {
    xAxisKey: "label",
  },
  skipNull: true,
  interaction: {
    mode: "point",
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
    },
    y: {
      ticks: {
        callback: (value: any) => unit + value,
      },
    },
  },
} as BarControllerChartOptions;

interface Props {
  queries: Query[];
}

const TopCostAsset = ({ queries }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasProductParam = queryParams.has(PRODUCT_TYPE_FILTER_ID);
  const {
    data = [],
    isFetching,
    isError,
  } = useCostUser(queries, {
    enabled: useHasFeatureFlag(featureFlag),
  });
  const { data: filtersResponse } = useAllFilters("GRAPHS_MONTHLY", {
    enabled: useHasFeatureFlag(featureFlag),
  });
  const productFilter = {
    ...filtersResponse?.find((filter) => filter.id === PRODUCT_TYPE_FILTER_ID),
    options: {
      HIDDEN: false,
    },
    id: PRODUCT_TYPE_FILTER_ID,
    name: "Product",
    valueDisplayFunc: humanize,
  };
  const hasError = isError || data?.length <= 0;
  unit = data?.unit ?? "";

  return (
    <GraphWidget
      title="Top 10 Assets by Cost"
      filter={
        <>
          {!hasProductParam && <FilterItem item={productFilter} />}
          {hasProductParam && <AddedFilters filters={[productFilter]} />}
        </>
      }
      isLoading={isFetching}
      hasError={hasError}
      featureFlag={featureFlag}
    >
      <div className={classes.horizontalBarWrap}>
        <Bar data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = createStylesheet(() => ({
  horizontalBarWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default TopCostAsset;
