import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { UIBox, UIButton, UIFormControlLabel, UILoader, UIRadio, UIRadioGroup, UITypography } from "../../_app/components";
import Main from "../../_app/layouts/Main";
import { useSingleAsset, useUpdateTag } from "../../asset/hooks";
import UITextInput from "../../form/components/UITextInput";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { UNASSIGNED, UNASSIGNED_CODE } from "../api";
import { useCostCentres, useCostCentresAssign } from "../hooks";
import { formatCostCentreCode, formatCostCentreOptions } from "../utils";
import { createStylesheet } from "../../_app/utils/styles";

export const CliDetails = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { cli, id } = useParams<{
    cli: string;
    id: string;
  }>();
  const { data: asset, isLoading, refetch: refetchAsset } = useSingleAsset(id ?? "");
  const [newTagValue, setNewTagValue] = useState("");
  const [newCostCentreValue, setNewCostCentreValue] = useState<string | null>(null);

  const hasCostCenterPermission = useHasPermission(permissionCodes.COST_CENTRE_MANAGE);
  const { data: costCentres } = useCostCentres({ enabled: hasCostCenterPermission });
  const costCentreOptions = useMemo(() => formatCostCentreOptions(costCentres ?? []), [costCentres]);
  const isChanged = () =>
    asset &&
    (newTagValue !== (asset.tag || "") || newCostCentreValue !== (formatCostCentreCode(asset.costCentre) || UNASSIGNED_CODE));

  const refetchCCTable = (costCentre: string) => {
    costCentreOptions?.forEach((cc) => {
      if (costCentre.replace(/;/g, " | ") === cc.label) {
        const queryKey = cc.id === "false" ? ["assets-by-cost-centre", "unassigned"] : ["assets-by-cost-centre", cc.id];
        queryClient.invalidateQueries(queryKey);
      }
    });
  };

  const { mutateAsync: executeUpdateTag, isLoading: isUpdatingTag } = useUpdateTag();
  const updateTag = async () => await executeUpdateTag({ assetId: id, tag: newTagValue });

  const { mutateAsync: executeUpdateCostCentre, isLoading: isUpdatingCostCentre } = useCostCentresAssign(
    undefined,
    { onSuccess: () => queryClient.invalidateQueries(["assets"]) },
    false,
  );
  const updateCostCentre = async () => {
    if (!hasCostCenterPermission) return;
    const cc = costCentreOptions?.find((cc) => cc.label === newCostCentreValue);
    if (cc) await executeUpdateCostCentre({ assetIds: [id], costCentreIds: [cc.id] });
  };

  const isUpdating = isUpdatingTag || isUpdatingCostCentre;

  useEffect(() => {
    if (asset) {
      setNewTagValue(asset.tag ?? "");
      if (!newCostCentreValue) setNewCostCentreValue(formatCostCentreCode(asset.costCentre) || UNASSIGNED_CODE);
    }
    // eslint-disable-next-line
  }, [asset]);

  const handleCostCentreChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewCostCentreValue(formatCostCentreCode((event.target as HTMLInputElement).value));
  };

  const onUpdate = async () => {
    if (newCostCentreValue !== formatCostCentreCode(asset?.costCentre)) await updateCostCentre();
    if (newTagValue !== asset?.tag) await updateTag();
    await refetchAsset();
    refetchCCTable(asset?.costCentre?.replace(/;/g, " | ") ?? UNASSIGNED);
    if (newCostCentreValue !== formatCostCentreCode(asset?.costCentre)) refetchCCTable(newCostCentreValue ?? UNASSIGNED);
  };

  const onReset = () => {
    setNewTagValue(asset?.tag ?? "");
    setNewCostCentreValue(formatCostCentreCode(asset?.costCentre) || UNASSIGNED_CODE);
  };

  return (
    <Main
      title={`Details for: ${cli}`}
      data-cy={`cli-details-page-${cli}`}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      needSelectedAccount={true}
      isLoading={isLoading}
    >
      <div className={classes.grid}>
        <UIBox>
          <UIBox pb={6}>
            <UITypography variant="h4" paragraph>
              Change Tag
            </UITypography>
            <UITextInput title="Tag" value={newTagValue} onChange={setNewTagValue} disabled={isUpdating} autoFocus={false} />
          </UIBox>
          <UIBox pb={3}>
            <UITypography variant="h4" paragraph>
              Change Cost Centre
            </UITypography>
            <UIRadioGroup name="auto-switch-type" data-cy="auto-switch-type" onChange={handleCostCentreChange} color="primary">
              {costCentreOptions.map((cc) => (
                <UIFormControlLabel
                  key={cc.id}
                  label={cc.label}
                  value={cc.label}
                  control={<UIRadio color="primary" />}
                  checked={formatCostCentreCode(cc.label) === formatCostCentreCode(newCostCentreValue ?? "")}
                  disabled={isUpdating}
                />
              ))}
            </UIRadioGroup>
          </UIBox>
        </UIBox>
      </div>
      <UIBox className={classes.buttons}>
        <UIButton
          variant="outlined"
          color="primary"
          onClick={onReset}
          data-cy="reset-changes-button"
          disabled={!isChanged() || isUpdating}
        >
          Reset
        </UIButton>
        {hasCostCenterPermission && (
          <UIButton
            color="primary"
            onClick={onUpdate}
            data-cy="update-cli-button"
            disabled={!isChanged() || isUpdating}
            startIcon={isUpdating && <UILoader color="inherit" size={18} />}
          >
            Update
          </UIButton>
        )}
      </UIBox>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  grid: {
    minHeight: "calc(100vh - 138px)",
    width: "100%",
    padding: 15,
    paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default CliDetails;
