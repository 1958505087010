import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { SingleActionCard } from "../../_app/components/SingleActionCard";
import { useServiceRequestCount } from "../../support/hooks";
import { randomFromInterval } from "../../_app/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UITypography } from "../../_app/components";

interface Props {
  title?: string;
  sla?: boolean;
}

type ServiceRequestColors = "Low" | "Medium" | "High" | "Critical";

const colors = {
  Low: "#80caf5",
  Medium: "#50b063",
  High: "#f8b232",
  Critical: "#cc3223",
};

const ServiceRequests = ({ title = "Service Requests", sla = true }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data = [], isFetching } = useServiceRequestCount(randomFromInterval(10, 100));

  const parsedTotalQuantities = useMemo(() => {
    return data?.map((item) => {
      return item?.quantity;
    });
  }, [data]);

  const parsedColors = useMemo(() => {
    return data?.map((item) => colors[item.status as ServiceRequestColors]);
  }, [data]);

  const parsedLabels = useMemo(() => {
    return data?.map((item: any) => {
      return item?.status;
    });
  }, [data]);

  const totalQuantity = data?.reduce((agg, item) => item.quantity + agg, 0);

  return (
    <div className={classes.ctr}>
      <SingleActionCard
        title={title}
        actionTitle="View All"
        isFetching={isFetching}
        actionFunction={() => {
          navigate("/tickets");
        }}
      >
        <div className={classes.card}>
          <div className={classes.graph}>
            <div className={classes.doughnutWrap}>
              <div className={classes.doughnutCenter}>
                <UITypography variant="h1">{totalQuantity}</UITypography>
              </div>
              <Doughnut
                data={{
                  datasets: [
                    {
                      label: "dataset1",
                      data: parsedTotalQuantities,
                      backgroundColor: parsedColors,
                      hoverOffset: 5,
                    },
                  ],
                  labels: parsedLabels,
                }}
                options={{
                  responsive: true,
                  cutout: 95,
                  plugins: {
                    tooltip: {
                      backgroundColor: "#ffffff",
                      titleColor: "#000000",
                      bodyColor: "#000000",
                      displayColors: false,
                      padding: { left: 25, right: 25, top: 15, bottom: 15 },
                      bodyFont: { weight: "bold" },
                      borderColor: "#000000",
                      caretSize: 0,
                      borderWidth: 1,
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className={classes.detailedLegend}>
            {data?.map((item) => {
              return (
                <div className={classes.legendGroup} key={item?.status}>
                  <div className={classes.legendItem}>
                    <div className={classes.legendLeft}>
                      <div
                        className={classes.dot}
                        style={{
                          backgroundColor: colors[item?.status as ServiceRequestColors],
                        }}
                      />
                      <UITypography variant="body2">{item?.status}</UITypography>
                    </div>
                    <div className={classes.legendRight}>
                      <UITypography variant="body2">({item?.percentage}%)</UITypography>
                      <UITypography variant="body2" style={{ fontWeight: 600 }}>
                        {item?.quantity}
                      </UITypography>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </SingleActionCard>
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    position: "relative",
  },
  card: {
    padding: "0px",
    display: "flex",
    flex: 1,
    gap: "15%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    minHeight: "313px",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  graph: {},
  doughnutWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  doughnutCenter: {
    position: "absolute",
  },
  detailedLegend: {
    flex: 1,
    maxWidth: "250px",
    marginTop: "10px",
  },
  legendGroup: {
    marginTop: theme.spacing(1),
  },
  legendItem: {
    display: "flex",
    gap: "5px",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "5px 10px",
    color: theme.palette.grey[400],
  },
  legendLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dot: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  legendRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
  },
  chip: {
    position: "absolute",
    top: "7px",
    right: "7px",
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
  },
}));

export default ServiceRequests;
