import { ReactNode } from "react";
import { useHasFeatureFlag } from "../../../feature/hooks";
import { useHasPermission } from "../../../permission/hooks";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIAlert, UICard, UICardHeader, UIGrid, UILoader, UITypography } from "../../../_app/components";

interface Props {
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  title?: string | ReactNode;
  filter?: ReactNode;
  bodyPadding?: number;
  isLoading?: boolean;
  hasError?: boolean;
  featureFlag?: string | undefined;
  accessPermission?: string | undefined;
  children?: any;
}

const GraphWidget = ({
  width = 6,
  title = "",
  filter,
  bodyPadding = 12,
  isLoading = false,
  hasError = false,
  featureFlag,
  accessPermission = "",
  children,
}: Props) => {
  const classes = useStyles();
  const hasFeatureFlag = useHasFeatureFlag(featureFlag);
  const hasFeature = featureFlag ? hasFeatureFlag : true;
  const hasPermission = useHasPermission(accessPermission);

  if (!hasFeature || !hasPermission) return null;
  return (
    <UIGrid size={{ xs: 12, md: width }} data-testid={title}>
      <UICard raised className={classes.card} elevation={1}>
        {Boolean(title) && (
          <UICardHeader
            className={classes.header}
            title={
              <UITypography variant="h4" component="span">
                {title}
              </UITypography>
            }
            action={filter}
          />
        )}
        <div className={classes.body} style={{ padding: bodyPadding }}>
          {isLoading ? (
            <UILoader className={classes.loader} />
          ) : hasError ? (
            <div className={classes.error}>
              <UIAlert severity="info">No data found</UIAlert>
            </div>
          ) : (
            children
          )}
        </div>
      </UICard>
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {},
  header: {
    maxHeight: 52,
    "& ul": {
      margin: 0,
    },
  },
  loader: {
    margin: theme.spacing(2),
  },
  error: {
    width: "100%",
    margin: theme.spacing(2),
  },
  body: {
    borderTop: "1px solid #d3d2d7",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default GraphWidget;
