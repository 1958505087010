import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { featureFlagsMap } from "../../feature/utils";
import StatusChip from "../../_app/components/StatusChip";
import { useFeedbackAlerts } from "../../_app/hooks";
import { useSetWdrStatus, useWdrStatus } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import { WdrStatus } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIButton, UIFormControlLabel, UISwitch } from "../../_app/components";
import UIDataTable, { UIDataTableState } from "../../_app/components/Table/UIDataTable";

export const DataRoaming = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const location = useLocation();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const [canSubmit, setCanSubmit] = useState<Boolean>(false);
  const [changes, setChanges] = useState(new Map());
  const [parsed, setParsed] = useState([] as any);

  useEffect(() => {
    if ((!assets || !assets?.length) && !filters) {
      setFeedbackAlertError("No mobile numbers selected");
      navigate("/mobile-management");
    }
  }, [assets, filters, navigate, setFeedbackAlertError]);

  const { data: wdrStatus, isFetching } = useWdrStatus(
    page,
    rowCount,
    filters,
    assets?.map((asset: any) => asset.id),
  );

  const { mutate: executeChangeWdr } = useSetWdrStatus(changes, {
    onSuccess: () => {
      navigate("/mobile-management");
    },
  });

  const handleToggle = useCallback(
    (asset: WdrStatus) => {
      const statuses = new Map(changes);
      const mobile = statuses.get(asset.assetId);
      if (mobile !== undefined) {
        statuses.delete(asset.assetId);
      } else {
        statuses.set(asset.assetId, !asset.wdrValue);
      }
      setChanges(statuses);
      setCanSubmit(statuses.size > 0);
    },
    [changes],
  );

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
    }
  };

  const onSubmit = () => {
    executeChangeWdr(changes);
  };

  useEffect(() => {
    const list = wdrStatus?.list?.map((item: WdrStatus) => {
      let checked = changes.get(item.assetId) !== undefined ? changes.get(item.assetId) : item.wdrValue;

      return [
        item?.cli,
        item?.tag,
        item?.costCentre,
        item?.account,
        item?.pendingChange ? (
          <StatusChip status="Updates Pending" />
        ) : (
          <UIFormControlLabel
            control={<UISwitch onChange={() => handleToggle(item)} checked={checked} />}
            label={checked ? "Capped" : "Uncapped"}
          />
        ),
      ];
    });
    setParsed(list);
  }, [wdrStatus, changes, handleToggle]);

  return (
    <Main
      title="Worldwide Data Roaming"
      data-cy="data-roaming-page"
      isLoading={false}
      featureFlag={featureFlagsMap.DATA_ROAMING}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      <>
        <div className={classes.grid} data-cy="data-roaming-table">
          <UIAlert severity="info" className={classes.info}>
            Worldwide Legislation from OFCOM means that we will be capping your Worldwide data roaming at £45 ex. VAT per billing
            month and barring you from using any more data abroad for the rest of your billing period.
            <br />
            <br />
            To ensure your business can continue to enjoy the same level of flexibility at present and prevent us from restricting
            your data usage abroad please opt out now by uncapping the relevant subscriptions.
            <br />
            <br />
            Please note that by removing the cap, you will be liable for any data charges incurred including, but not limited to,
            access charges for roaming services as well as out of bundle data roaming charges.
          </UIAlert>
          {isFetching ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <UIDataTable
              title=""
              data={parsed}
              columns={["CLI/Identifier", "Tag", "Cost Centre", "Account", "Uncap/Cap"]}
              options={{
                page,
                rowsPerPage: rowCount,
                rowsPerPageOptions: rowCountOptions,
                onTableChange: handleTableChange,
                count: wdrStatus?.total,
                pagination: true,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                serverSide: true,
                viewColumns: false,
                selectableRows: "none",
                selectableRowsHeader: false,
                sort: false,
                search: false,
                rowHover: true,
                setTableProps: () => ({ size: "small" }),
                setRowProps: (row) => ({ "data-cy": `row-id-${row[0]}` }),
              }}
            />
          )}
        </div>
        <div className={classes.actions}>
          {!isFetching && (
            <UIButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={onSubmit}
              disabled={!canSubmit}
              style={{ marginLeft: "auto" }}
              data-cy="save-btn"
            >
              Save Changes
            </UIButton>
          )}
        </div>
      </>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    padding: theme.spacing(5),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  grid: {
    width: "100%",
    marginTop: theme.spacing(1.5),
  },
  info: {
    marginBottom: theme.spacing(3),
    alignItems: "center",
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
}));

export default DataRoaming;
