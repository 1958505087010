import { ChangeEvent, useState } from "react";
import { AttachFile as AttachFileIcon } from "../../_app/components/icons";
import FileItem from "./FileItem";
import { UIAlert, UIBadge, UIBox, UIButton, UITooltip, UITypography } from "../../_app/components";

const FILE_SIZE_LIMIT_B = 200000000;
const FILE_SIZE_LIMIT_MB = FILE_SIZE_LIMIT_B / 1000000;

interface Props {
  label?: string;
  multiple?: boolean;
  name?: string;
  value: Array<File>;
  variant?: "text" | "outlined" | "contained";
  onChange: (event: ChangeEvent<HTMLInputElement>, files: Array<File>) => void;
  setFields: Function;
  uploading?: boolean;
}

function FileUpload({ label, onChange, multiple, name, value, variant, setFields, uploading = false }: Props) {
  const [filesTooBig, setFilesTooBig] = useState<Array<string>>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let addedFiles = value ? [...value] : [];
    let filesTooBig: string[] = [];

    if (event.target?.files) {
      for (let i = 0; i < event.target.files?.length; ++i) {
        const file = event.target.files.item(i);
        if (file) {
          if (file.size <= FILE_SIZE_LIMIT_B) {
            addedFiles.push(file);
          } else {
            filesTooBig.push(file?.name);
          }
        }
      }
    }

    onChange(event, addedFiles);
    setFilesTooBig(filesTooBig);
  };

  const Preview = () => {
    if (!value?.length) {
      return <AttachFileIcon />;
    }

    const title = <UITypography variant="body2">{`${value?.length} file${value?.length > 1 ? "s" : ""} attached`}</UITypography>;

    return (
      <UITooltip title={title}>
        <UIBadge badgeContent={value?.length} color="primary">
          <AttachFileIcon />
        </UIBadge>
      </UITooltip>
    );
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...value];
    newFiles.splice(index, 1);
    setFields({ files: newFiles });
  };

  return (
    <>
      <UIBox marginRight="12px" marginBottom="12px" display="inline-block">
        <UIButton
          color="primary"
          component="label"
          size="medium"
          startIcon={<Preview />}
          variant={variant || "text"}
          disabled={uploading}
        >
          {label || "Attach files"}
          <input
            hidden
            multiple={multiple}
            name={name}
            onChange={handleChange}
            type="file"
            onClick={(event) => {
              (event.target as HTMLInputElement).value = "";
            }}
          />
        </UIButton>
      </UIBox>
      <UITypography variant="caption">{`Max. file size ${FILE_SIZE_LIMIT_MB} MB`}</UITypography>
      {filesTooBig?.length > 0 && (
        <div
          style={{
            overflowWrap: "anywhere",
            marginBottom: "12px",
            marginTop: "8px",
          }}
        >
          <UIAlert severity="error">
            {`${
              filesTooBig?.length > 1 ? "These files exceed" : "This file exceeds"
            } the size limit and can't be uploaded: ${filesTooBig?.join(", ")}`}
          </UIAlert>
        </div>
      )}

      {value?.map((file, index) => (
        <FileItem file={file} index={index} handleRemoveFile={handleRemoveFile} key={file.name} uploading={uploading} />
      ))}
    </>
  );
}

export default FileUpload;
