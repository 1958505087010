import { ReactNode } from "react";
import { createStylesheet, useMediaQuery } from "../utils/styles";
import { UIGrid, UIModal } from "../components";

interface Props {
  children: ReactNode;
  [key: string]: any;
}

function Modaled({ open = true, width = 40, onClose = (props: any) => props.navigate(-1), children, ...rest }: Props) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const close = () => onClose(rest);
  return (
    <UIModal open={open} onClose={close} className={classes.modal}>
      <UIGrid container justifyContent="center" alignContent="center" className={classes.ctr}>
        <UIGrid
          style={{
            width: isSmallScreen ? "95vw" : width + "vw",
            height: "calc(100vh - 57px)",
            overflow: "auto",
            borderRadius: "8px",
          }}
        >
          {children}
        </UIGrid>
      </UIGrid>
    </UIModal>
  );
}

const useStyles = createStylesheet((theme) => ({
  ctr: {
    height: "100%",
  },
  modal: {},
}));

export default Modaled;
