import api from "../_app/api";
import { CommunicationDetail } from "./types";
import { SORT_REQUEST_PARAMETER_NAME, SortRequestParam } from "../_app/components/Table/helpers";
import { convertResponseToPage, isOldResponseFormat } from "../_app/utils/page";
import { Query } from "../filter/types";

export const getCommunications = async (page: number, limit: number, sort?: SortRequestParam, queries: Query[] = []) => {
  const params = new URLSearchParams();

  if (sort) {
    params.append(SORT_REQUEST_PARAMETER_NAME, `${sort.column},${sort.direction}`);
  }
  params.append("page", page.toString());
  params.append("size", limit.toString());
  queries.forEach((query) => {
    params.append(query.id, query.value);
  });

  const { data }: { data: any } = await api({
    method: "GET",
    url: "/communications",
    context_injection: true,

    params,
  });

  if (isOldResponseFormat<CommunicationDetail>(data)) {
    return convertResponseToPage<CommunicationDetail>(data, sort !== null);
  }

  return data;
};

export const getDocumentPresignedUrl = async (communicationId: string) => {
  const { data }: { data: string } = await api({
    method: "GET",
    url: `/communications/${communicationId}/file`,
    context_injection: true,
  });
  return data;
};

export const getExportCsv = async () => {
  return api({
    method: "GET",
    url: "/communications/export",
    context_injection: true,

    responseType: "arraybuffer",
  });
};
