import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import BillLimitAlertsTabBar from "../components/BillLimitAlerts/BillLimitAlertsTabBar";
import BillLimitAlertsUpdateForm from "../components/BillLimitAlerts/BillLimitAlertsUpdateForm";
import { useUpdateBillLimitPrefs } from "../../asset/hooks";
import { useFeedbackAlerts } from "../../_app/hooks";
import { UICard, UIGrid } from "../../_app/components";
import { featureFlagsMap } from "../../feature/utils";
import { createStylesheet } from "../../_app/utils/styles";

export const BillLimitAlertsAccount = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();

  const updateBillLimitPrefs = useUpdateBillLimitPrefs({
    onSuccess: (response: string) => {
      setFeedbackAlertSuccess(response);
      navigate("/bill-limit-alerts-account");
      queryClient.invalidateQueries(["bill-limit-prefs"]);
    },
  });

  const updatePrefs = (sms: boolean, email: boolean, smsValue?: string, emailValue?: string) => {
    updateBillLimitPrefs.mutate({
      operations: [
        {
          sendSms: sms,
          sendEmail: email,
          notifyMobileNumber: smsValue,
          notifyEmailAddress: emailValue,
        },
      ],
    });
  };

  return (
    <Main
      title="Bill Limit Alerts"
      data-cy="bill-limit-alerts-account-page"
      needSelectedAccount
      titleProps={{ className: classes.title }}
      featureFlag={featureFlagsMap.BILL_LIMIT_ALERTS}
    >
      <BillLimitAlertsTabBar selectedTab="bill-limit-alerts-account">
        <UICard>
          <UIGrid>
            <BillLimitAlertsUpdateForm callback={updatePrefs} />
          </UIGrid>
        </UICard>
      </BillLimitAlertsTabBar>
    </Main>
  );
};

const useStyles = createStylesheet(() => ({
  title: {
    marginBottom: 10,
  },
}));

export default BillLimitAlertsAccount;
