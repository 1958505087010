import { useState } from "react";
import { InfoOutlined as InfoIcon } from "../../../../_app/components/icons";
import {
  UIAlert,
  UIAlertTitle,
  UIButton,
  UIDialog,
  UIDialogActions,
  UIDialogContent,
  UIIconButton,
} from "../../../../_app/components";

const WorldwideDataCapInfo = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <UIDialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <UIDialogContent>
          <UIAlert severity="info">
            <UIAlertTitle>Worldwide Data Roaming Legislation</UIAlertTitle>
            Worldwide Legislation from OFCOM means that we will be capping your Worldwide data roaming at £45 ex. VAT per billing
            month and barring you from using any more data abroad for the rest of your billing period. <br />
            <br />
            To ensure your business can continue to enjoy the same level of flexibility at present and prevent us from restricting
            your data usage abroad, please opt out now by uncapping the relevant subscriptions. <br />
            <br />
            Please note that by removing the cap, you will be liable for any data charges incurred including, but not limited to,
            access charges for roaming services as well as out of bundle data roaming charges.
          </UIAlert>
          <UIDialogActions>
            <UIButton onClick={() => setOpen(false)} color="info">
              Close
            </UIButton>
          </UIDialogActions>
        </UIDialogContent>
      </UIDialog>
      <UIIconButton aria-label="delete" onClick={() => setOpen(true)}>
        <InfoIcon />
      </UIIconButton>
    </>
  );
};

export default WorldwideDataCapInfo;
