import { useCostByUsageType, useVerticalBarChartOptions } from "../../hooks";
import { Bar } from "react-chartjs-2";
import GraphWidget from "./GraphWidget";
import { useHasFeatureFlag } from "../../../feature/hooks";
import { Query } from "../../../filter/types";
import { humanize } from "../../../_app/utils/format";
import { useLocation } from "react-router-dom";
import FilterItem from "../../../filter/components/FilterItem";
import AddedFilters from "../../../filter/components/AddedFilters";
import { useAllFilters } from "../../../filter/hooks";
import { formatPrice } from "../../../billing/utils";
import { featureFlagsMap } from "../../../feature/utils";
import { createStylesheet } from "../../../_app/utils/styles";

const featureFlag = featureFlagsMap.USAGE_BY_TYPE_GRAPH;
const PRODUCT_TYPE_FILTER_ID = "81";

interface Props {
  queries: Query[];
}

const CostByUsageType = ({ queries }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasProductParam = queryParams.has(PRODUCT_TYPE_FILTER_ID);
  const {
    data = [],
    isFetching,
    isError,
  } = useCostByUsageType(queries, {
    enabled: useHasFeatureFlag(featureFlag),
  });
  const { data: filtersResponse } = useAllFilters("GRAPHS_TRENDS", {
    enabled: useHasFeatureFlag(featureFlag),
  });
  const productFilter = {
    ...filtersResponse?.find((filter) => filter.id === PRODUCT_TYPE_FILTER_ID),
    options: {
      HIDDEN: false,
    },
    id: PRODUCT_TYPE_FILTER_ID,
    name: "Product",
    valueDisplayFunc: humanize,
  };
  const hasError = isError || data?.length <= 0;

  const options = useVerticalBarChartOptions(
    data.unit,
    undefined,
    (context: any) => {
      return formatPrice({
        gross: context?.raw,
        net: context?.raw,
        vat: 0,
        unit: data.unit,
      });
    },
    {
      scales: {
        xAxes: {
          stacked: true,
        },
        yAxes: {
          stacked: true,
        },
      },
    },
  );

  return (
    <GraphWidget
      title="Cost by Usage Type"
      filter={
        <>
          {!hasProductParam && <FilterItem item={productFilter} />}
          {hasProductParam && <AddedFilters filters={[productFilter]} />}
        </>
      }
      isLoading={isFetching}
      hasError={hasError}
      featureFlag={featureFlag}
      width={12}
    >
      <div className={classes.horizontalBarWrap}>
        <Bar data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = createStylesheet(() => ({
  horizontalBarWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default CostByUsageType;
