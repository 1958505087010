import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import Autocomplete from "../../form/components/Autocomplete";
import UIRichTextEditor from "../../_app/components/UIRichTextEditor";
import { useFeedbackAlerts } from "../../_app/hooks";
import { UIBox, UIButton, UICard, UICardActions, UIGrid, UIMenuItem, UITextField } from "../../_app/components";
import FileUpload from "../../form/components/FileUpload";
import ToggleButtons from "../../form/components/ToggleButtons";
import { featureFlagsMap } from "../../feature/utils";
import { createStylesheet } from "../../_app/utils/styles";

function GridGroup({ children }: any) {
  const classes = useStyles();
  return (
    <UIGrid size={{ xs: 12 }} className={classes.group}>
      <UIGrid container spacing={1}>
        {children}
      </UIGrid>
    </UIGrid>
  );
}

export const AddTicketAdvanced = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    type: "Service Request",
    user_id: "",
    product_id: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    short_desc: "",
    description: "",
    request_type_id: "",
  };
  const [fields, setFields] = useState(initialState as any);
  const options = {
    types: [
      {
        label: "Service Request",
        value: "Service Request",
        info: "A service request should be raised for issues or queries the customer has which are not related to a fault with their service.",
      },
      {
        label: "Incident",
        value: "Incident",
        info: "An incident should be raised whereby the customer is experiencing a fault with their service.",
      },
    ],
    users: [
      { name: "ramy.dahab@aurora.io", id: "1" },
      { name: "jeff.mccarney@aurora.io", id: "2" },
    ],
    products: [
      { name: "Mobile", id: "1" },
      { name: "WLR", id: "2" },
      { name: "Broadband", id: "3" },
    ],
    request_types: [
      { name: "Billing", id: "1" },
      { name: "Handset", id: "2" },
      { name: "Configuration Change", id: "3" },
      { name: "Report Request", id: "4" },
      { name: "General Enquiry", id: "5" },
    ],
    severities: ["Low", "Medium", "High"],
    impact: ["Low", "Medium", "High"],
  };

  const updateFields = (updates: any) => setFields({ ...fields, ...updates });
  const searchUsers = () => null;
  const searchProducts = () => null;
  const searchRequestTypes = () => null;

  const handleChange = (field: string, extra?: any) => (event: any) => {
    let value = "";
    if (event) {
      if (event.target) {
        value = event.target.value;
      } else if (event.value) {
        value = event.value;
      } else {
        value = event;
      }
    }
    updateFields({ [field]: value, ...extra });
  };
  const handleSelectUser = (user: any) => {
    updateFields({ user_id: user.id || "" });
  };
  const handleSelectProduct = (product: any) => {
    updateFields({ product_id: product.id || "" });
  };
  const handleSelectRequestType = (type: any) => {
    updateFields({ request_type_id: type.id || "" });
  };

  const reset = () => setFields(initialState);

  const canSubmit = () => {
    return !Boolean(Object.values(fields)?.filter((val) => !val)?.length);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (canSubmit()) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setFeedbackAlertSuccess("Ticket created");
        reset();
      }, 1000);
    }
  };
  return (
    <Main title="New Ticket" data-cy="add-ticket-advanced-page" isLoading={isLoading} featureFlag={featureFlagsMap.SUPPORT_DC}>
      <form autoComplete="off" onSubmit={handleSubmit} className={classes.ctr}>
        <UICard padding="12px">
          <GridGroup>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <ToggleButtons
                value={fields.type}
                options={options.types}
                onChange={(event: any, value: string) => handleChange("type")(value)}
                buttonProps={{ style: { width: "50%" } }}
                fullWidth
              />
            </UIGrid>
          </GridGroup>

          <GridGroup>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <Autocomplete
                label="Select User"
                loading={false}
                name="user_id"
                onSearch={searchUsers}
                onSelect={(selected: any) => handleSelectUser(selected)}
                options={options.users}
                placeholder="Type at least 3 characters to search"
                value={options.users.find((user: any) => user.id === fields.user_id) || null}
                valueFrom="id"
                textFrom="name"
                required
              />
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <Autocomplete
                label="Select Product"
                loading={false}
                name="product_id"
                onSearch={searchProducts}
                onSelect={(selected: any) => handleSelectProduct(selected)}
                options={options.products}
                placeholder="Type at least 3 characters to search"
                value={options.products.find((product: any) => product.id === fields.product_id) || null}
                valueFrom="id"
                textFrom="name"
                required
              />
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 12 }}>
              <Autocomplete
                label="Select Request Type"
                loading={false}
                name="request_type_id"
                onSearch={searchRequestTypes}
                onSelect={(selected: any) => handleSelectRequestType(selected)}
                options={options.request_types}
                placeholder="Type at least 3 characters to search"
                value={options.request_types.find((type: any) => type.id === fields.request_type_id) || null}
                valueFrom="id"
                textFrom="name"
                required
              />
            </UIGrid>
          </GridGroup>
          <GridGroup>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <UITextField
                fullWidth
                label="First Name"
                name="first_name"
                onChange={handleChange("first_name")}
                required
                value={fields.first_name}
                variant="outlined"
                size="small"
              />
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <UITextField
                fullWidth
                label="Last Name"
                name="last_name"
                onChange={handleChange("last_name")}
                required
                value={fields.last_name}
                variant="outlined"
                size="small"
              />
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <UITextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange("email")}
                required
                value={fields.email}
                variant="outlined"
                size="small"
              />
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <UITextField
                fullWidth
                label="Phone"
                name="telephone"
                onChange={handleChange("telephone")}
                required
                value={fields.telephone}
                variant="outlined"
                size="small"
              />
            </UIGrid>
          </GridGroup>
          <GridGroup>
            <UIGrid size={{ xs: 12 }}>
              <UITextField
                fullWidth
                label="Short Description"
                name="short_desc"
                onChange={handleChange("short_desc")}
                required
                multiline
                value={fields.short_desc}
                variant="outlined"
                size="small"
              />
            </UIGrid>
          </GridGroup>
          <GridGroup>
            <UIGrid size={{ xs: 12 }}>
              <UIRichTextEditor onChange={handleChange("description")} placeholder="Description *" value={fields.description} />
            </UIGrid>
          </GridGroup>
          {fields.type === "Incident" && (
            <GridGroup>
              <UIGrid size={{ xs: 12, md: 6 }}>
                <UITextField
                  fullWidth
                  label="Severity"
                  name="severity"
                  onChange={handleChange("severity")}
                  required
                  size="small"
                  select
                  value={fields.severity}
                  variant="outlined"
                >
                  {options.severities.map((option) => (
                    <UIMenuItem key={`severity-${option}`} value={option}>
                      {option}
                    </UIMenuItem>
                  ))}
                </UITextField>
              </UIGrid>
              <UIGrid size={{ xs: 12, md: 6 }}>
                <UITextField
                  fullWidth
                  label="Impact"
                  name="impact"
                  onChange={handleChange("impact")}
                  required
                  size="small"
                  select
                  value={fields.impact}
                  variant="outlined"
                >
                  {options.impact.map((option) => (
                    <UIMenuItem key={`impact-${option}`} value={option}>
                      {option}
                    </UIMenuItem>
                  ))}
                </UITextField>
              </UIGrid>
            </GridGroup>
          )}
          <UIGrid container spacing={1}>
            <UIGrid size={{ xs: 12 }}>
              <UIBox paddingTop="16px">
                <FileUpload
                  variant="outlined"
                  multiple
                  name="files"
                  onChange={(event: ChangeEvent<HTMLInputElement>, files: Array<File | null>) =>
                    updateFields({ [event.target.name]: files })
                  }
                  value={fields["files"]}
                  setFields={updateFields}
                />
              </UIBox>
            </UIGrid>
          </UIGrid>
          <UICardActions className={classes.actions}>
            <UIButton color="primary" disabled={!canSubmit()} size="medium" type="submit" variant="contained">
              Open New Ticket
            </UIButton>
            <UIButton color="primary" size="medium" type="submit" variant="text" onClick={() => navigate(-1)}>
              Cancel
            </UIButton>
          </UICardActions>
        </UICard>
      </form>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: { width: "100%" },
  group: {
    marginBottom: theme.spacing(3),
  },
  selectedType: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  actions: {
    padding: "0px",
    marginTop: theme.spacing(3),
    justifyContent: "flex-start",
  },
}));

export default AddTicketAdvanced;
