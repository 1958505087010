import { ChangeEvent, useState } from "react";
import PermissionsViewer from "../../permission/components/PermissionsViewer";
import { Permission } from "../../permission/api";
import { useValidation } from "../../form/hooks";
import UIConfirm from "../../_app/components/UIConfirm";
import { Role } from "../types";
import SubmitButtons from "../../form/components/SubmitButtons";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIGrid, UITextField, UITypography } from "../../_app/components";

export interface Props {
  role: any;
  submitLabel?: string;
  isSubmitting?: boolean;
  handleSubmit: (data: Role) => void;
  handleCancel: () => void;
}

export const EditRoleForm = ({ role, submitLabel = "Update", isSubmitting = false, handleSubmit, handleCancel }: Props) => {
  const classes = useStyles();
  const [roleData, setRoleData] = useState(role || {});
  const [submitWarning, setSubmitWarning] = useState(false);

  // useEffect(() => setRoleData(roleData), [roleData]);

  const validationConfig = {
    name: { required: true },
  };
  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, roleData);

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      handleSubmit(roleData);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const val = e.target.value;
    setRoleData({ ...roleData, [key]: val });
    validateSingle(key, val);
  };

  const onPermissionsChange = (updated: Permission) => {
    setRoleData({
      ...roleData,
      permissions: roleData?.permissions?.map((p: Permission) => {
        if (p?.id === updated?.id) return updated;
        return p;
      }),
    });
  };

  const MANAGE_CONTACT_CHECKED = roleData?.permissions
    ?.filter((p: Permission) => p?.code === "ACCOUNT_MANAGE")
    ?.map((perm: Permission) => {
      const manageContactsPerm = perm?.permissions?.filter((childPerm: Permission) => childPerm?.code === "ACCOUNT_CONTACTS")[0]
        ?.value;
      return manageContactsPerm;
    });

  return (
    <UIGrid container data-cy="edit-role-form">
      <UIGrid size={{ xs: 12, md: 4 }}>
        <UITypography variant="h3" gutterBottom>
          Role Name
        </UITypography>
      </UIGrid>
      <UIGrid size={{ xs: 12, md: 8 }}>
        <UITextField
          className={classes.textField}
          name="name"
          label="Role name"
          type="text"
          value={roleData?.name || ""}
          variant="outlined"
          size="small"
          fullWidth
          onChange={onInputChange}
          error={Boolean(validationErrors.name)}
          helperText={validationErrors.name}
        />
      </UIGrid>
      <PermissionsViewer allowDisabling={false} permissions={roleData?.permissions || []} onChange={onPermissionsChange} />
      <SubmitButtons
        onSubmit={role.id ? () => setSubmitWarning(true) : onSubmit}
        onCancel={handleCancel}
        submitLabel={submitLabel}
        isSubmitting={isSubmitting}
      />
      <UIConfirm
        open={submitWarning}
        setOpen={setSubmitWarning}
        onConfirm={onSubmit}
        title={MANAGE_CONTACT_CHECKED[0] === true ? "Are you sure you want to grant the Manage Contacts permission?" : ""}
      >
        {MANAGE_CONTACT_CHECKED[0] === true ? (
          <UIAlert severity="warning">
            Users with access to Manage Contacts can change all the authorised contacts on the account thereby taking full control
            of the account. This includes changing who is authorised to place orders and disconnect services.
          </UIAlert>
        ) : (
          <strong>Changes made will affect all users with this role. Are you sure you want to proceed?</strong>
        )}
      </UIConfirm>
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  textField: {
    marginBottom: theme.spacing(4),
  },
}));

export default EditRoleForm;
