import { createRoot } from "react-dom/client";
import { AuthConfig } from "./modules/auth/types";
import { UserLevel } from "./modules/user-level/types";
import App from "./modules/_app/App";
import config from "./modules/_app/config";

declare global {
  interface Window {
    Cypress: any;
    HubSpotConversations: any;
    hsConversationsOnReady: any;
    authConfig: AuthConfig;
    userLevels: UserLevel[];
  }
}

if (config.configSentry?.enabled && config.configSentry?.dsn) {
  require("./modules/_app/lib/sentry");
}

if (config?.configGoogleTagManager?.gtmId) {
  require("./modules/_app/lib/googletagmanager");
}

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
