import { ChangeEvent } from "react";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIBox, UIFormControlLabel, UISwitch } from "../../../_app/components";

interface Props {
  addSelection: (value?: string) => void;
  value: string | null;
  defaultValue: string;
  label: string;
}

export default function BooleanFilter({ addSelection, value, defaultValue, label }: Props) {
  const classes = useStyles();

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    addSelection(event.target.checked.toString());
  };

  return (
    <UIFormControlLabel
      className={classes.formControlLabel}
      control={
        <UISwitch
          name="booleanFilter"
          size="small"
          checked={JSON.parse(value ?? defaultValue)}
          onChange={handleInput}
          color="primary"
        />
      }
      label={<UIBox className={classes.label}>{label}</UIBox>}
      labelPlacement="start"
    />
  );
}

const useStyles = createStylesheet(() => ({
  formControlLabel: {
    margin: 0,
  },
  label: {
    fontWeight: 600,
    fontSize: "0.95rem",
  },
}));
