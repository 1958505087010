import { ReactNode } from "react";
import { createStylesheet } from "../../../_app/utils/styles";
import { UICard, UIGrid } from "../../../_app/components";

interface ContactCardProps {
  icon: ReactNode;
  children: ReactNode;
}

const ContactCard = ({ icon, children }: ContactCardProps) => {
  const classes = useStyles();

  return (
    <UIGrid size={{ xs: 12, lg: 4 }}>
      <div className={classes.circleWrap}>
        <div className={classes.circle}>{icon}</div>
      </div>
      <UICard variant="outlined" className={classes.info}>
        {children}
      </UICard>
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  circleWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "35px",
  },
  circle: {
    width: "70px",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "35px",
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: "white",
  },
  info: {
    height: "200px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default ContactCard;
