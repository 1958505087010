import { useHasFeatureFlag } from "../../feature/hooks";
import { featureFlagsMap } from "../../feature/utils";
import Tabbed from "../../_app/layouts/Tabbed";

interface Props {
  selectedTab: string;
  children: any;
}

function ManagePaymentsTabBar({ selectedTab, children }: Props) {
  const hasCardFeature = useHasFeatureFlag(featureFlagsMap.CARD_PAYMENT);
  const hasDirectDebitFeature = useHasFeatureFlag(featureFlagsMap.DIRECT_DEBIT);

  return (
    <Tabbed
      selectedTab={selectedTab}
      tabs={[
        {
          value: "payment-type",
          label: "Payment Type",
          hidden: !hasDirectDebitFeature,
        },
        {
          value: "card-payment",
          label: "Make a Card Payment",
          hidden: !hasCardFeature,
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default ManagePaymentsTabBar;
