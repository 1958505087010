import { Delete as DeleteIcon, EditNote as BulkEditIcon } from "../../../../_app/components/icons";
import UIConfirm from "../../../../_app/components/UIConfirm";
import SelectAllHeader from "../../../../_app/components/Table/SelectAllHeader";
import { UIButton, UITooltip } from "../../../../_app/components";
import { useHasFeatureFlag } from "../../../../feature/hooks";
import { featureFlagsMap } from "../../../../feature/utils";

interface UsageAlertTableHeaderProps {
  selectAll: boolean;
  setSelectAll: (value: boolean) => void;
  selectedRows: number[];
  isFetching: boolean;
  showConfirm: boolean;
  setShowConfirm: (value: boolean) => void;
  handleDeleteAction: () => void;
  handleBulkEditAction: () => void;
  disableBulkEdit: boolean;
}

const UsageAlertTableHeader = ({
  selectAll,
  setSelectAll,
  isFetching,
  selectedRows,
  showConfirm,
  setShowConfirm,
  handleDeleteAction,
  handleBulkEditAction,
  disableBulkEdit,
}: UsageAlertTableHeaderProps) => {
  const showTooltip = selectedRows.length > 0 && disableBulkEdit;
  const showBulkEdit = useHasFeatureFlag(featureFlagsMap.BULK_EDIT_USAGE_ALERTS);

  return (
    <SelectAllHeader
      onChange={() => setSelectAll(!selectAll)}
      disabled={isFetching}
      value={selectAll}
      selectedRows={selectedRows || []}
    >
      <UIButton
        variant="outlined"
        size="small"
        color="primary"
        data-cy="delete-usage-alerts-button"
        onClick={() => setShowConfirm(true)}
        disabled={!selectedRows.length}
        startIcon={<DeleteIcon />}
      >
        Delete
      </UIButton>
      {showBulkEdit && (
        <UITooltip title={showTooltip ? "Only CLI mobile alerts can be selected for bulk editing." : ""} arrow>
          <span>
            <UIButton
              variant="outlined"
              size="small"
              color="primary"
              data-cy="bulk-edit-usage-alerts-button"
              onClick={handleBulkEditAction}
              disabled={disableBulkEdit}
              startIcon={<BulkEditIcon />}
            >
              Bulk Edit
            </UIButton>
          </span>
        </UITooltip>
      )}
      <UIConfirm open={showConfirm} setOpen={setShowConfirm} onConfirm={handleDeleteAction}>
        Are you sure you want to delete selected usage alerts?
      </UIConfirm>
    </SelectAllHeader>
  );
};

export default UsageAlertTableHeader;
