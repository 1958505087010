import { UIEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import ManageUsersActionsButton from "../components/ManageUsersActionsButton";
import { useAllUsers, useUserDelete } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { getAssignedAccountsCodeList, getAssignedAccountsCodeNameList } from "../utils";
import UILoader from "../../_app/components/UILoader";
import { Add as AddIcon } from "../../_app/components/icons";
import ManageUsersTabBar from "../components/ManageUsersTabBar";
import { getIsTopLevelContext } from "../../context/utils";
import { userLevelLabels, UserListItem } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UITabPanel, UITooltip } from "../../_app/components";
import UIDataTable, { UIDataTableColumnDef, UIDataTableState } from "../../_app/components/Table/UIDataTable";

export interface TableConfig {
  title: string;
  columns: UIDataTableColumnDef[];
}

const tableConfig: TableConfig = {
  title: "Users",
  columns: ["First Name", "Last Name", "Email", "Role", "User Level", "User Access", { label: " ", name: "Actions" }],
};

export const ManageUsers = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const selectedTab = "users";
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const { data: users, isFetching: isUsersFetching, isError: isUsersError } = useAllUsers(page, rowCount);

  const { mutate: executeUserDelete } = useUserDelete();

  useEffect(() => {
    setPage(users?.number || 0);
  }, [users]);

  const columns = tableConfig.columns;
  const parsed =
    users?.content.map((user: UserListItem) => {
      return [
        user.firstName,
        user.lastName,
        <UITooltip title={user.confirmed ? "Confirmed" : "Unconfirmed"} placement="left" arrow>
          <div>{user.email}</div>
        </UITooltip>,
        user.roleName,
        userLevelLabels[user.userLevel] || "N/A",
        <UITooltip
          title={<span className={classes.accessTooltip}>{getAssignedAccountsCodeNameList(user, "\n") || ""}</span>}
          placement="left-start"
          arrow
        >
          <div>
            {getAssignedAccountsCodeList(user, 3) || ""}
            {(user?.accounts?.length || 0) > 3 && "..."}
          </div>
        </UITooltip>,
        <ManageUsersActionsButton user={user} deleteUser={executeUserDelete} />,
        user.active,
      ];
    }) || [];

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const onTableScroll = (ev: UIEvent<HTMLDivElement>) => {
    ev.currentTarget.click();
  };

  const isTopLevel = getIsTopLevelContext();

  return (
    <Main
      title={
        <>
          Manage Users
          <UIButton
            variant="contained"
            color="primary"
            data-cy="add-user-btn"
            startIcon={<AddIcon />}
            onClick={() => navigate("/user/new")}
          >
            <span className={classes.addBtnText}>Add User</span>
          </UIButton>
        </>
      }
      data-cy="manage-users-page"
      accessPermission={permissionCodes.USERS_MANAGE}
    >
      <ManageUsersTabBar selectedTab={selectedTab} showRoles={!isTopLevel}>
        <UITabPanel className={classes.ctr} value={selectedTab}>
          {isUsersFetching && !isUsersError ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div className={classes.tableCtr} data-cy="users-table" onScroll={onTableScroll}>
              <UIDataTable
                title={tableConfig.title}
                data={parsed}
                columns={columns || []}
                options={{
                  onTableChange: handleTableChange,
                  pagination: true,
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: users?.totalElements,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  sort: false,
                  search: false,
                  serverSide: true,
                  selectableRows: "none",
                  rowHover: true,
                  setTableProps: () => ({ size: "small" }),
                  viewColumns: false,
                  setRowProps: (row) => {
                    return {
                      className: row[7] && classes.disabledRow,
                      "data-cy": `user-id-${row[6].props.user.id}`,
                    };
                  },
                }}
              />
            </div>
          )}
        </UITabPanel>
      </ManageUsersTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    padding: "0",
  },
  tableCtr: {
    "& div::nth-of-type(3)": {
      padding: "0px",
    },
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  disabledRow: {
    color: theme.palette.text.disabled,
  },
  accessTooltip: {
    whiteSpace: "pre-wrap",
  },
  addBtnText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default ManageUsers;
