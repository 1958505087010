import { Authenticator } from "@aws-amplify/ui-react";
import { UITypography } from "../../_app/components";
import Public from "../../_app/layouts/Public";
import { useBrandWelcomeText, useLoginBackground, useLogo, usePoweredByLogo } from "../../brand/hooks";
import SignInRedirect from "../components/SignInRedirect";
import { createStylesheet, useTheme } from "../../_app/utils/styles";

const Login = () => {
  const classes = useStyles();
  const theme = useTheme();
  const welcomeText = useBrandWelcomeText();
  const bgImage = useLoginBackground();
  const logo = useLogo();
  const poweredBy = usePoweredByLogo();
  return (
    <Public
      data-cy="login-page"
      title="Login"
      className={classes.ctr}
      style={{
        backgroundImage: `url("${bgImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.tertiary.dark,
      }}
    >
      <Authenticator
        hideSignUp={true}
        loginMechanisms={["email"]}
        components={{
          Header() {
            return (
              <div className={classes.header} style={{ height: logo ? 200 : 100 }}>
                {Boolean(logo) && <img alt="logo" src={logo} />}
                <UITypography variant="h3" textAlign="center">
                  {welcomeText}
                </UITypography>
              </div>
            );
          },
          Footer() {
            return (
              <div className={classes.footer} style={{ height: poweredBy ? 100 : 0 }}>
                {Boolean(poweredBy) && <img alt="powered-by-logo" src={poweredBy} />}
              </div>
            );
          },
        }}
      >
        {({ user }) => {
          return <SignInRedirect user={user} />;
        }}
      </Authenticator>
    </Public>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",

    "& [data-amplify-authenticator]": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 6,
      border: "none",
    },
    "& [data-amplify-router]": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: "none",
      border: "none",
    },
    "& [data-amplify-form]": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    "& [data-amplify-authenticator] [data-amplify-footer]": {
      paddingBottom: 0,
    },
    "& .amplify-heading": {
      placeSelf: "center",
      fontSize: "1.25rem",
    },
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "inherit",
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    "& > img": {
      display: "block",
      width: "70%",
      height: "60%",
      objectFit: "contain",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "inherit",
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    borderRadius: 5,

    "& > img": {
      display: "block",
      width: "70%",
      height: "100%",
      objectFit: "contain",
    },
  },
}));

export default Login;
