import logo from "../images/direct-debit-logo.png";
import { createStylesheet } from "../../_app/utils/styles";
import { UITypography } from "../../_app/components";

export const PaymentDialogContentUnfilled = () => {
  const classes = useStyles();

  return (
    <div className={classes.unfilledContentWrap} data-cy="payment-details-unfilled">
      <div className={classes.directDebitLogoWrap}>
        <img src={logo} width={120} alt="direct-debit-logo" />
      </div>
      <UITypography variant="h2" gutterBottom>
        Switch to Direct Debit
      </UITypography>
      <UITypography variant="h4">You could save</UITypography>
      <div className={classes.priceWrap}>
        <div className={classes.price}>£90.00</div>
        <UITypography variant="h4">Per year</UITypography>
      </div>
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  unfilledContentWrap: {
    height: "410px",
  },
  directDebitLogoWrap: {
    width: "100%",
    marginBottom: "80px",
  },
  priceWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
  },
  price: {
    fontSize: "85px",
    paddingRight: "10px",
  },
}));
