import { JSX, ReactNode } from "react";
import { createStylesheet } from "../utils/styles";
import { UIBreakpoint, UIButton, UIButtonProps, UIDialog, UIDialogActions, UIDialogContent, UIDialogTitle } from ".";

interface Action {
  label?: string;
  props?: UIButtonProps;
}

interface Props {
  title?: JSX.Element | string;
  children: ReactNode;
  open: boolean;
  maxWidth?: UIBreakpoint;
  actions: Action[];
}

const UISimpleDialog = ({ title, children, open, maxWidth, actions = [] }: Props) => {
  const classes = useStyles();

  return (
    <UIDialog open={open} fullWidth maxWidth={maxWidth ?? "xs"}>
      <div className={classes.dialog}>
        {Boolean(title) && <UIDialogTitle>{title}</UIDialogTitle>}
        <UIDialogContent>{children}</UIDialogContent>
        <UIDialogActions className={classes.buttons}>
          {actions.map((action: Action) => {
            return (
              <UIButton {...(action?.props || {})} key={action.label}>
                {action.label}
              </UIButton>
            );
          })}
        </UIDialogActions>
      </div>
    </UIDialog>
  );
};

const useStyles = createStylesheet((theme) => ({
  dialog: {
    margin: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(4),
    alignItems: "inherit",
  },
}));

export default UISimpleDialog;
