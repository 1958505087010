import { getCachedContextHierarchy } from "../context/utils";
import api from "../_app/api";
import { PaymentDetails } from "./types";

export const getPaymentDetails = async () => {
  const contextId = getCachedContextHierarchy()?.id;
  const { data }: { data: PaymentDetails } = await api({
    method: "GET",
    url: "/payment-details/" + contextId,
    context_injection: true,
  });
  return data;
};

export const postPaymentDetails = async (details: PaymentDetails) => {
  const contextId = getCachedContextHierarchy()?.id;
  const { data }: { data: PaymentDetails } = await api({
    method: "POST",
    url: "/payment-details/" + contextId,
    data: details,
    context_injection: true,
  });
  return data;
};

export const putPaymentDetails = async (details: PaymentDetails) => {
  const contextId = getCachedContextHierarchy()?.id;
  const { data }: { data: PaymentDetails } = await api({
    method: "PUT",
    url: "/payment-details/" + contextId,
    data: details,
    context_injection: true,
  });
  return data;
};
