import { UserLevel } from "../user-level/types";
import { IdValueQuery } from "../_app/api";
import { Asset } from "../asset/types";
import { AccountLevel } from "./utils";

export interface Group {
  id: string;
  name: string;
  userAccessibleAccounts: Account[];
}

export interface Account {
  id: string;
  code?: string;
  name: string;
  tag?: string;
  level: UserLevel;
  parentFilterLevel: number;
  size?: number;
}

export interface GetAccountsParams {
  parent: string;
  tree?: boolean;
  limit?: number;
  offset?: number;
  "search-query"?: string;
}

export interface GetAccountsResponse {
  list: Account[];
  page: number;
  pageSize: number;
  total: number;
}

export interface BillingAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  city: string;
  countries: string[];
  country: string;
  postCode: string;
}

export type BillingAddressUpdateParams = Omit<BillingAddress, "countries">;

export interface BillSettings {
  billEmail: string;
  billType: string;
  status?: string;
}

export interface CostCentre {
  id: string;
  code?: string;
  name: string;
  parent?: string | null;
  subCostCentres?: CostCentre[];
  assets?: Asset[];
}

export type FilterCostCentresResponse = {
  assetsWithoutCostCentre: Asset[];
  costCentresWithAssets: CostCentre[];
};

export type FilterCostCentresParams = {
  search: string;
};

export interface CostCentreAssignParams {
  assetIds?: string[];
  filters?: IdValueQuery[];
  costCentreIds: CostCentre["id"][];
}

export interface AccountHierarchy {
  level: AccountLevel;
  hierarchy: Record<AccountLevelEnum, AccountInfo>;
}

export interface AccountInfo {
  id: string;
  code?: string;
  name: string;
  level: AccountLevelEnum;
}

export enum AccountLevelEnum {
  Group = "GROUP",
  Company = "COMPANY",
  Account = "ACCOUNT",
  SubAccount = "SUB_ACCOUNT",
  Asset = "ASSET",
}
