import { useEffect, useState } from "react";
import { useStore } from "../hooks";
import { createStylesheet } from "../utils/styles";
import { UIAlert, UISnackbar, UISnackbarCloseReason } from "../components";

export default function GlobalAlertsProvider() {
  const { state } = useStore();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const DEFAULT_DURATION = 4000;
  const DEFAULT_ERROR_DURATION = 7000;

  useEffect(() => {
    if (state.error || state.success || state.alert) {
      setOpen(true);
    }
  }, [state]);

  const handleClose = (event?: any, reason?: UISnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  if (state.error) {
    // show error snackbar
    return (
      <UISnackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.error?.duration || DEFAULT_ERROR_DURATION}
      >
        <UIAlert onClose={handleClose} variant="filled" severity="error" data-cy="alert-error">
          {state.error?.message}
        </UIAlert>
      </UISnackbar>
    );
  }
  if (state.success) {
    // show success snackbar
    return (
      <UISnackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.success?.duration || DEFAULT_DURATION}
      >
        <UIAlert onClose={handleClose} variant="filled" severity="success" data-cy="alert-success">
          {state.success?.message}
        </UIAlert>
      </UISnackbar>
    );
  }
  if (state.alert) {
    // show generic snackbar
    return (
      <UISnackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.alert?.duration || DEFAULT_DURATION}
      >
        <UIAlert onClose={handleClose} variant="filled" severity="info" data-cy="alert">
          {state.alert?.message}
        </UIAlert>
      </UISnackbar>
    );
  }
  return null;
}

const useStyles = createStylesheet((theme) => ({
  snackbar: {
    marginTop: "60px",
    right: "13px",
  },
}));
