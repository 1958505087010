import { DeleteSweep as DeleteSweepIcon } from "../../_app/components/icons";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UIBox, UIButton } from "../../_app/components";
import Main from "../../_app/layouts/Main";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import BulkEditConfirmationModal from "../components/UsageAlerts/BulkEditConfirmationModal";
import { SelectAction } from "../components/UsageAlerts/SelectAction";
import { SelectCriteria } from "../components/UsageAlerts/SelectCriteria";
import { useBulkEditAlerts } from "../hooks";
import { BillingAlertBulkEdit, UsageAlert } from "../types";
import { createStylesheet } from "../../_app/utils/styles";

const BulkEditUsageAlerts = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [usageAlertConfig, setUsageAlertConfig] = useState({
    product: "MOBILE",
    level: "CLI",
  } as UsageAlert);
  const [validationErrors, setValidationErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const { mutate: updateBulk, isLoading } = useBulkEditAlerts();

  const handleBack = useCallback(() => navigate("/usage-alerts"), [navigate]);

  useEffect(() => {
    // Redirect to usage alerts page if no alerts are selected or passed in history state
    if (!state?.selectedAlerts || state.selectedAlerts.length === 0) {
      handleBack();
    }
  }, [state, handleBack]);

  const title = "Bulk Edit Usage Alerts";
  const subtitle =
    state?.selectedAlerts?.length === 1 ? "1 usage alert selected" : `${state?.selectedAlerts?.length} usage alerts selected`;

  const discardChanges = () => {
    setUsageAlertConfig({
      product: "MOBILE",
      level: "CLI",
    } as UsageAlert);
  };

  const hasChanges = () => {
    return usageAlertConfig.criteria || usageAlertConfig.alertAction || usageAlertConfig.name;
  };

  const handleSubmit = () => {
    const billingAlertBulkEdit: BillingAlertBulkEdit = {
      alertIds: state.selectedAlerts,
      name: usageAlertConfig?.name,
      criterion: usageAlertConfig.criteria?.[0],
      contacts: usageAlertConfig?.alertAction?.contacts,
      notifyAssetsBySMS: usageAlertConfig?.alertAction?.notifyAssetsBySMS,
      bar: usageAlertConfig?.alertAction?.bar,
    };

    updateBulk(billingAlertBulkEdit);

    setShowConfirm(false);
  };

  const hasError = Object.values(validationErrors).some((value) => value !== undefined);
  const isWithoutNotification =
    usageAlertConfig?.alertAction?.notifyAssetsBySMS === false && usageAlertConfig?.alertAction?.contacts?.length === 0;
  const discardChangesDisabled = !hasChanges();
  const submitDisabled = hasError || !hasChanges() || isWithoutNotification;

  return (
    <Main
      title={title}
      subtitle={subtitle}
      data-cy="add-edit-usage-alert-page"
      featureFlag={featureFlagsMap.BULK_EDIT_USAGE_ALERTS}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount={true}
    >
      <div className={classes.contentCtr}>
        {
          <>
            <SelectCriteria
              isBulkEdit
              usageAlert={usageAlertConfig}
              setAlert={setUsageAlertConfig}
              setErrors={setValidationErrors}
            ></SelectCriteria>
            <SelectAction usageAlert={usageAlertConfig} setAlert={setUsageAlertConfig} bulkEdit></SelectAction>
            <UIBox className={classes.buttonContainer}>
              <UIButton variant="text" onClick={handleBack} data-cy="back">
                Back
              </UIButton>
              <UIBox className={classes.buttonGroup}>
                <UIButton
                  disabled={discardChangesDisabled}
                  variant="outlined"
                  startIcon={<DeleteSweepIcon />}
                  onClick={discardChanges}
                  data-cy="discard-changes"
                >
                  Discard Changes
                </UIButton>
                <UIButton
                  disabled={submitDisabled}
                  variant="contained"
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                  data-cy="submit"
                >
                  Submit
                </UIButton>
              </UIBox>
            </UIBox>
            <BulkEditConfirmationModal
              isProcessing={isLoading}
              onConfirm={handleSubmit}
              showConfirm={showConfirm}
              setShowConfirm={setShowConfirm}
              usageAlertConfig={usageAlertConfig}
            />
          </>
        }
      </div>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  contentCtr: {
    width: "100%",
    paddingTop: 18,
    marginBottom: 10,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
  },
  buttonGroup: {
    display: "flex",
    gap: "16px",
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: "16px",
  },
}));

export default BulkEditUsageAlerts;
