import { useEffect, useState } from "react";
import { UIButton, UILoader, UIPopover, UITypography } from "..";
import UITextInput from "../../../form/components/UITextInput";
import { createStylesheet } from "../../utils/styles";

interface Props {
  anchorEl?: HTMLAnchorElement | null;
  setAnchorEl?: Function;
  title?: string;
  value?: string;
  onSave?: any;
  isUpdating?: boolean;
  type: string;
}

const EditPopup = ({ type, anchorEl, setAnchorEl = () => null, title, value, onSave, isUpdating }: Props) => {
  const classes = useStyles();
  const [newValue, setNewValue] = useState(value);
  const onChange = (val: any) => setNewValue(val);

  useEffect(() => {
    setNewValue(value);
  }, [anchorEl, value]);

  useEffect(() => {
    if (isUpdating === false) {
      setAnchorEl(null);
    }
  }, [isUpdating, setAnchorEl]);

  return (
    <UIPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className={classes.wrap}>
        <UITypography variant="subtitle2" className={classes.title}>
          {title?.toUpperCase()}
        </UITypography>
        {(() => {
          if (type === "TEXT") {
            return <UITextInput value={newValue} title={title} hideLabel onChange={onChange} disabled={isUpdating} />;
          }
          return null;
        })()}
        <div className={classes.buttons}>
          <UIButton
            variant="contained"
            color="primary"
            onClick={() => {
              onSave(newValue);
            }}
            data-cy="confirm-filter-dialog-button"
            disabled={value === newValue}
            startIcon={isUpdating && <UILoader color="inherit" size={18} />}
          >
            Apply{isUpdating && "ing"}
          </UIButton>
          <UIButton onClick={() => setAnchorEl(null)} data-cy="cancel-filter-dialog-button">
            Cancel
          </UIButton>
        </div>
      </div>
    </UIPopover>
  );
};

const useStyles = createStylesheet((theme) => ({
  wrap: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(3),
    gap: theme.spacing(3),
  },
  title: {
    color: theme.palette.grey[300],
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(3),
  },
}));

export default EditPopup;
