import { useState } from "react";
import { CheckCircleRounded as CheckCircleRoundedIcon, RemoveCircle as RemoveCircleIcon } from "../../_app/components/icons";
import BarGroups from "./BarGroups";
import { useMedia, useReset } from "../../_app/hooks";
import { useBarsUpdate } from "../hooks";
import { BarUpdate } from "../types";
import { useMobileNetworks } from "../../asset/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIBox, UIGrid, UILoader, UITypography } from "../../_app/components";
import UISimpleDialog from "../../_app/components/UISimpleDialog";

interface Props {
  assetId?: string;
}

export const BarringDetails = ({ assetId = "" }: Props) => {
  const classes = useStyles();
  const { isBelowSm } = useMedia();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [updates, setUpdates] = useState<BarUpdate[]>([]);
  const [resetKey, reset] = useReset("bars-form");
  const { mutate: executeBarsUpdate, data: successMessage, isLoading, isSuccess, isError, reset: resetUpdate } = useBarsUpdate();
  const { data: networks } = useMobileNetworks([assetId]);

  const onUpdate = () => {
    if (!updates.length) return;
    executeBarsUpdate({
      assetIds: [assetId],
      operations: updates.map((u) => ({
        name: u.name,
        value: u.value,
        network: u.network ?? networks?.[0],
      })),
    });
    onReset();
  };

  const onReset = () => {
    reset();
    setUpdates([]);
  };

  const onClose = () => {
    resetUpdate();
    setShowConfirmDialog(false);
  };

  const getDialogTitle = () => {
    if (isSuccess) return "Your request has been sent";
    if (isError) return "Request failed";
    return "Confirm changes";
  };

  return (
    <div data-cy="barring-details">
      <BarGroups
        assetId={assetId}
        updates={updates}
        setUpdates={setUpdates}
        disableApply={!updates.length}
        resetKey={resetKey}
        onReset={onReset}
        onApply={() => setShowConfirmDialog(true)}
      />
      {Boolean(showConfirmDialog) && (
        <UISimpleDialog
          title={getDialogTitle()}
          open={showConfirmDialog}
          actions={
            Boolean(isSuccess || isError)
              ? [
                  {
                    label: "Close",
                    props: {
                      onClick: onClose,
                      variant: "outlined",
                      color: "primary",
                    },
                  },
                ]
              : [
                  {
                    label: "Cancel",
                    props: {
                      onClick: () => setShowConfirmDialog(false),
                      variant: "outlined",
                      color: "primary",
                      disabled: isLoading,
                    },
                  },
                  {
                    label: "Confirm",
                    props: {
                      onClick: onUpdate,
                      variant: "contained",
                      color: "primary",
                      disabled: isLoading,
                      startIcon: isLoading ? <UILoader color="inherit" size={18} /> : undefined,
                    },
                  },
                ]
          }
          maxWidth="sm"
        >
          <>
            {updates.map((u) => (
              <UIGrid container key={u.label}>
                <UIGrid size={{ xs: isBelowSm ? 6 : 5 }}>
                  <UIBox display="flex" alignItems="center" pb={0.5}>
                    {u.value ? (
                      <>
                        <CheckCircleRoundedIcon className={classes.checkIcon} fontSize="small" />
                        <UITypography variant="h6">Add Bar</UITypography>
                      </>
                    ) : (
                      <>
                        <RemoveCircleIcon className={classes.removeIcon} fontSize="small" />
                        <UITypography variant="h6">Remove Bar</UITypography>
                      </>
                    )}
                  </UIBox>
                </UIGrid>
                <UIGrid size={{ xs: isBelowSm ? 6 : 7 }}>
                  <UITypography noWrap>{u.label}</UITypography>
                </UIGrid>
              </UIGrid>
            ))}
            {isSuccess ? (
              <UIAlert severity="success" className={classes.alert}>
                {successMessage}
              </UIAlert>
            ) : null}
            {isError ? <UIAlert severity="error">Updating bars failed.</UIAlert> : null}
          </>
        </UISimpleDialog>
      )}
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  checkIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  removeIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  alert: {
    alignItems: "center",
  },
}));

export default BarringDetails;
