import { ChangeEvent, useEffect, useState } from "react";
import { Add as AddIcon } from "../../_app/components/icons";
import { UIButton, UIConfirm, UITextField } from "../../_app/components";
import { useStore } from "../../_app/hooks";
import { useCostCentreCreate } from "../hooks";
import { createStylesheet } from "../../_app/utils/styles";

export const NewCostCentre = () => {
  const classes = useStyles();
  const { dispatch } = useStore();
  const [dialog, setDialog] = useState(false);
  const [newCostCentre, setNewCostCentre] = useState({
    id: "",
    name: "",
    parent: "",
    subCostCentres: [],
  });

  const { mutate: executeCostCentreCreate, isSuccess: createSuccess } = useCostCentreCreate();

  useEffect(() => {
    if (createSuccess) {
      setNewCostCentre({ id: "", parent: "", name: "", subCostCentres: [] });
    }
  }, [dispatch, createSuccess]);

  const handleCostCentreSubmit = () => {
    return executeCostCentreCreate({
      ...newCostCentre,
      parent: newCostCentre?.id,
      name: newCostCentre?.name,
    });
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const val = e.target.value;
    setNewCostCentre({ ...newCostCentre, [key]: val });
  };
  return (
    <>
      <UIConfirm
        title="Add Cost Centre"
        onSubmitTitle="Add"
        disableOnSubmit={!newCostCentre?.name}
        open={dialog}
        setOpen={setDialog}
        onConfirm={handleCostCentreSubmit}
      >
        <UITextField
          name="name"
          placeholder="Cost Centre Name"
          type="text"
          value={newCostCentre?.name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          fullWidth
        />
      </UIConfirm>
      <div className={classes.top}>
        <UIButton
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          onClick={() => setDialog(true)}
          data-cy="add-new-cost-centre-button"
        >
          Add New Cost Centre
        </UIButton>
      </div>
    </>
  );
};

const useStyles = createStylesheet(() => ({
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default NewCostCentre;
