import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import ManageStatementTabBar from "../components/ManageStatementTabBar";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import BalanceOverdue from "../../dashboard/widgets/BalanceOverdue";
import { useStatements } from "../hooks";
import { getBulkStatementsFile, getStatementsFile } from "../api";
import { formatCurrency, formatDate, parseStringToDate } from "../../_app/utils/format";
import FiltersCard from "../../filter/components/FiltersCard";
import { useStatementsFilters } from "../../filter/hooks";
import { downloadFile, isEmpty } from "../../_app/utils";
import { featureFlagsMap } from "../../feature/utils";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import { LoadMoreFooter } from "../../_app/components/Table/LoadMoreFooter";
import { CloudDownload as DownloadIcon } from "../../_app/components/icons";
import { useFeedbackAlerts } from "../../_app/hooks";
import { genericError } from "../../_app/utils/text";
import { Query } from "../../filter/types";
import { Statement } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import UIDataTable from "../../_app/components/Table/UIDataTable";
import { UIButton } from "../../_app/components";

export const Statements = () => {
  const classes = useStyles();
  const selectedTab = "statements";
  const location = useLocation();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [filters, setFilters] = useState<Query[]>([]);

  const mapDateRange = (queries: Query[]) => {
    if (queries && queries.length === 2) {
      return [
        {
          id: "from-date",
          value: formatDate(parseStringToDate(queries[0].value, "dd/MM/yyyy"), "yyyy-MM-dd"),
        },
        {
          id: "to-date",
          value: formatDate(parseStringToDate(queries[1].value, "dd/MM/yyyy"), "yyyy-MM-dd"),
        },
      ];
    }
    return [];
  };
  const {
    data: statements,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useStatements(rowCount, mapDateRange(filters));

  const hasPermissionToDownload = Boolean(useHasPermission(permissionCodes.BILLING_DOWNLOAD));

  const renderError = useCallback(
    (e: any) => {
      setFeedbackAlertError(e?.message || error || genericError());
    },
    [error, setFeedbackAlertError],
  );

  const onTableChange = (action: string, state: any) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      values.forEach((query) => {
        filters.push({
          id: key,
          value: query,
        });
      });
    });
    setFilters(filters);
  }, [location]);

  const onBulkDownloadClick = async () => {
    try {
      const data = await getBulkStatementsFile(mapDateRange(filters));
      downloadFile(data, "statements-bulk", "csv");
    } catch (e) {
      renderError(e);
    }
  };

  const parsed = useMemo(() => {
    const onItemDownloadClick = async (item: Statement) => {
      try {
        const data = await getStatementsFile(item?.reference);
        downloadFile(data, item?.reference, "pdf");
      } catch (e) {
        renderError(e);
      }
    };

    const pages: any = statements?.pages?.map((page) => {
      return page?.list?.map((item) =>
        rowParser([
          formatDate(item?.date, "dd/MM/yyyy"),
          item?.type,
          item?.class,
          item?.reference,
          !isEmpty(item?.credit) ? formatCurrency(item.credit) : item.credit,
          !isEmpty(item?.debit) ? formatCurrency(item.debit) : item.debit,
          <UIButton
            variant="contained"
            color="primary"
            data-cy="download"
            size="small"
            onClick={() => onItemDownloadClick(item)}
            startIcon={<DownloadIcon />}
          >
            Download
          </UIButton>,
        ]),
      );
    });
    return [].concat.apply([], pages);
  }, [statements, renderError]);

  return (
    <Main
      title="Statements"
      data-cy="statements-page"
      accessPermission={permissionCodes.BILLING_STATEMENT}
      featureFlag={featureFlagsMap.STATEMENTS}
      needSelectedAccount={true}
    >
      <ManageStatementTabBar selectedTab={selectedTab}>
        <div className={classes.widgetCtr}>
          <BalanceOverdue hasManage={false} />
        </div>
        <div className={classes.filtersCtr} data-cy="statements-filters">
          <FiltersCard
            fetchHook={useStatementsFilters}
            hasReset={false}
            disableDownload={parsed?.length === 0}
            handleDownloadClick={onBulkDownloadClick}
            hasDownload={hasPermissionToDownload}
            downloadFileType="CSV"
          />
        </div>
        {isFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <div className={classes.tableCtr} data-cy="statements-table">
            <UIDataTable
              title={"Statements"}
              columns={["Date", "Type", "Class", "Ref", "Credit", "Debit", { label: " ", name: "Download" }]}
              data={parsed}
              options={{
                onTableChange,
                pagination: true,
                page,
                rowsPerPage: rowCount,
                rowsPerPageOptions: rowCountOptions,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                sort: false,
                search: false,
                serverSide: true,
                selectableRows: "none",
                rowHover: true,
                setTableProps: () => ({ size: "small" }),
                viewColumns: false,
                customFooter: () => {
                  return <LoadMoreFooter hasMore={hasNextPage} disabled={isFetchingNextPage} onChangePage={fetchNextPage} />;
                },
              }}
            />
          </div>
        )}
      </ManageStatementTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  widgetCtr: {
    width: "100%",
    marginBottom: theme.spacing(3),
    "& h2": {
      textAlign: "left",
      paddingTop: theme.spacing(1),
    },
    "& .MuiPaper-root": {
      minHeight: "fit-content",
    },
  },
  filtersCtr: {
    marginBottom: theme.spacing(3),
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "185px",
  },
  tableCtr: {
    "& tr td:last-child": {
      textAlign: "right",
    },
  },
}));

export default Statements;
