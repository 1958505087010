import configDev from "./config.dev";
import configLocal from "./config.local";
import configProduction from "./config.production";
import configUat from "./config.uat";

type Config = {
  configEndpoints: {
    baseEndpoint: string;
  };
  configGoogleTagManager?: {
    gtmId: string;
  };
  configRecaptcha?: {
    siteKey: string;
  };
  configLiveChat?: {
    script: string;
  };
  configIntercom?: {
    enabled: boolean;
  };
  configLogicMonitor?: {
    baseUrl: string;
    apiToken: string;
    jsBundleUrl: string;
  };
  configSentry?: {
    enabled: boolean;
    dsn?: string;
  };
};

export function getConfig() {
  let config: Config = configDev;
  const env = process.env.REACT_APP_STAGE || "";
  if (env.includes("local")) {
    config = configLocal;
  } else if (env.includes("uat")) {
    config = configUat;
  } else if (env.includes("prod")) {
    config = configProduction;
  }
  return config;
}

export default getConfig();
