import { format, parseISO, parse } from "date-fns";
import { capitalize as muiCapitalize } from "@mui/material";

export const formatDate = (date: string | Date | undefined | null, pattern: string = "MMM dd, yyyy") => {
  const rawDate = (typeof date === "string" ? parseISO(date) : date) as Date;
  return date ? format(rawDate, pattern) : "";
};

export const formatLocalTime = (time?: string, pattern: string = "HH:mm") => {
  return time ? format(parseISO(time), pattern) : "";
};

export const parseStringToDate = (date: string, pattern: string) => {
  return date ? parse(date, pattern, new Date()) : null;
};

export const secondsToHms = (secs?: number): string => {
  if (!secs) return "";
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs % 3600) / 60);
  const seconds = Math.floor((secs % 3600) % 60);
  const hh = to2Digits(hours || 0);
  const mm = to2Digits(minutes || 0);
  const ss = to2Digits(seconds || 0);
  return `${hh}:${mm}:${ss}`;
};

export const formatNumber = (num: number | any = 0, decimalPlaces: number, pref = "", suffix = "", cutTrailingZeros = false) => {
  let prefix = pref;
  let value =
    num.toLocaleString("en-GB", {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    }) || "";
  if (value < 0) {
    value = value.replace(/-/g, "");
    prefix = `-${prefix}`;
  }
  if (cutTrailingZeros) {
    value = value.replace(/,0+$/g, "");
  }
  return `${prefix}${value}${suffix}`;
};

export const formatCurrency = (val: string | number, currencyCode: string = "GBP", locales: string | string[] = "en-GB") => {
  const parsed = typeof val === "number" ? val : parseFloat(val);
  const formatter = new Intl.NumberFormat(locales, {
    style: "currency",
    currency: currencyCode,
  });
  return formatter.format(parsed);
};

export const bytesToSize = (size: number | undefined, unit = "Bytes") => {
  if (size === 0 || size === 1) {
    return `${size}\u00A0${unit === "Bytes" ? "Byte" : unit}`;
  }

  if (!size) {
    return "";
  }

  const units = ["Bytes", "KB", "MB", "GB", "TB"];
  const largestUnitIndex = Math.floor(Math.log(size) / Math.log(1024));
  const convertedValue = size / Math.pow(1024, largestUnitIndex);
  const finalUnitIndex = largestUnitIndex + (unit === "Bytes" ? 0 : units.indexOf(unit.toUpperCase())); // Add the selected unit to the largest unit
  const roundedValue =
    finalUnitIndex >= units.indexOf("GB")
      ? Math.round((convertedValue + Number.EPSILON) * 100) / 100
      : Math.round(convertedValue);

  return `${roundedValue}\u00A0${units[finalUnitIndex]}`;
};

export const formatQuantity = (value: number | undefined, unit?: string | undefined): string => {
  if (!value) {
    return "";
  } else if (value && !unit) {
    return value + "";
  }
  switch (unit?.toLocaleLowerCase()) {
    case "seconds":
      return secondsToHms(value);
    case "kb":
      return bytesToSize(value, unit);
    case "count":
      return roundCount(value);
    default:
      return value + "";
  }
};

export const upperCasetoCamelCase = (str: string): string => {
  if (!str) {
    return str;
  }
  return str
    .replace(/(.)/g, function ($1) {
      return $1.toLowerCase();
    })
    .replace(/_(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/_/g, "");
};

export const capitalize = (str?: string) => {
  if (!str) return str;
  return muiCapitalize(str?.toLowerCase());
};

export const humanize = (str?: string, exceptions?: string[]) => {
  if (!str) return str;
  if (exceptions?.includes(str)) return str;
  let i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
  }
  return frags.join(" ");
};

export const to2Digits = (num: any) => {
  if (num === undefined) return "";
  return num?.toLocaleString("en-GB", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const roundCount = (num: any, decimalPlaces: number = 2) => {
  if (num === undefined) return "0";
  if (num >= 1000000000) {
    return formatNumber(num / 1000000000, decimalPlaces) + "G";
  }
  if (num >= 1000000) {
    return formatNumber(num / 1000000, decimalPlaces) + "M";
  }
  if (num >= 1000) {
    return formatNumber(num / 1000, decimalPlaces) + "K";
  }
  return num;
};
