const config = {
  configEndpoints: {
    baseEndpoint: "https://api.hub.affinity.io",
  },
  configGoogleTagManager: {
    gtmId: "GTM-NL6LH2C",
  },
  configRecaptcha: {
    siteKey: "6LcBIpciAAAAALtafLpFeVGgQV0qKVK1KK-Xf9ec",
  },
  configLiveChat: {
    script: "//js.hs-scripts.com/8487018.js?businessUnitId=108691",
  },
  configIntercom: {
    enabled: true,
  },
  configLogicMonitor: {
    baseUrl: "https://dc.api.masro.io/uat",
    apiToken: "bXlhY2NvdW50X3BvcnRhbDp4aXdSeG41YVoweTVeaUI4",
    jsBundleUrl: "https://s3.eu-west-2.amazonaws.com/dc.logic-one.io/js/logicone.js",
  },
  configSentry: {
    enabled: true,
    dsn: "https://940e8a5c1bce4696b557f8300bbe56dc@o240624.ingest.sentry.io/6743829",
  },
};

export default config;
