import { SsoLogout } from "../components/SsoLogout";
import Secondary from "../../_app/layouts/Secondary";
import { useIdentityUser, useJWTLogout } from "../hooks";
import { isCognitoUser } from "../utils";
import { CognitoLogout } from "../components/CognitoLogout";
import { UITypography } from "../../_app/components";
import { createStylesheet } from "../../_app/utils/styles";

const LogoutSignedInUser = () => {
  localStorage.clear();
  if (isCognitoUser()) {
    return <CognitoLogout />;
  } else {
    return <SsoLogout />;
  }
};

const Logout = () => {
  const { data: identityUser, isFetching } = useIdentityUser();
  if (isFetching) return null;
  if (identityUser?.profile.act) return <LogoutImpersonatedUser />;

  return <LogoutSignedInUser />;
};

function LogoutImpersonatedUser() {
  const classes = useStyles();
  useJWTLogout();
  return (
    <Secondary className={classes.ctr}>
      <UITypography>Logging out masqueraded user...</UITypography>
    </Secondary>
  );
}

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default Logout;
