import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from "../../_app/components/icons";
import { filtersIdMap } from "../../filter/utils";
import { BillCategory, BillElement, BillFilter } from "../types";
import { formatPackageName, formatPrice } from "../utils";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UICard,
  UICollapse,
  UIDivider,
  UILink,
  UIList,
  UIListItem,
  UIListItemButton,
  UIListItemText,
} from "../../_app/components";

interface Props {
  cost: BillElement;
  name: string;
  isUnbilled: boolean;
}

export default function BillPackages({ cost, name, isUnbilled }: Props) {
  const classes = useStyles();
  const [openPackage, setOpenPackages] = useState(false);
  const navigate = useNavigate();
  const hasBreakdown = Boolean(cost?.categories?.length);

  const itemProps: any = {
    onClick: () => setOpenPackages(!openPackage),
    className: classes.packageButton,
    id: "package",
    disableGutters: true,
  };

  const formatCostFilter = (filter: BillFilter) => {
    let filterValues: string[] = [];
    Object.keys(filter).map((filterName: string) => {
      let filterValue = filter[filterName];
      if (filterName === filtersIdMap.BILL_PERIOD || filterName === filtersIdMap.SERVICES_BILL_PERIOD) {
        if (isUnbilled) filterValue = "UNBILLED-" + filterValue;
      }
      return filterValues.push(`${filterName}=${filterValue}`);
    });
    return `/${name === "usageCharges" ? "usage-analysis" : "service-analysis"}?${filterValues.join("&")}`;
  };

  return (
    <UIList className={classes.packageList}>
      <UICard isSimpleCard elevation={1}>
        <UIListItemButton {...itemProps}>
          {openPackage ? (
            <KeyboardArrowUpIcon className={`${classes.icon} ${!hasBreakdown && classes.hide}`} />
          ) : (
            <KeyboardArrowDownIcon className={`${classes.icon} ${!hasBreakdown && classes.hide}`} />
          )}
          <UIListItemText primary={formatPackageName(name)} data-cy={`cost_${name}`} />
          <UILink
            underline="hover"
            onClick={() => cost?.frontendAnalysisFilters && navigate(formatCostFilter(cost?.frontendAnalysisFilters))}
            className={classes.link}
          >
            <div className={classes.price}>{formatPrice(cost?.net)}</div>
          </UILink>
        </UIListItemButton>
      </UICard>
      {hasBreakdown && (
        <UICard isSimpleCard elevation={1} className={classes.packageItem}>
          <UICollapse in={openPackage} timeout="auto" unmountOnExit>
            <UIList component="div" disablePadding>
              {cost?.categories.map((sub: BillCategory) => {
                return (
                  <Fragment key={sub.name}>
                    <UIListItem className={classes.packageName} data-cy={`sub_${sub?.name}`}>
                      <UIListItemText primary={sub?.name} />
                      <UILink
                        underline="hover"
                        onClick={() => {
                          sub?.frontendAnalysisFilters && navigate(formatCostFilter(sub?.frontendAnalysisFilters));
                        }}
                        className={classes.link}
                      >
                        <div className={classes.price}>{formatPrice(sub?.net)}</div>
                      </UILink>
                    </UIListItem>
                    <UIDivider />
                  </Fragment>
                );
              })}
            </UIList>
          </UICollapse>
        </UICard>
      )}
    </UIList>
  );
}

const useStyles = createStylesheet((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    color: theme.palette.text.primary,
    paddingTop: "9px",
  },
  hide: {
    opacity: 0,
  },
  packageList: {
    "& .MuiListItem-gutters": {
      paddingRight: theme.spacing(1),
    },
  },
  packageButton: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  packageItem: {
    marginLeft: theme.spacing(3.5),
    marginTop: "1px",
  },
  packageName: {
    fontSize: "14px",
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "bold",
    paddingRight: theme.spacing(1),
  },
  price: {
    paddingRight: theme.spacing(1),
  },
}));
