import SimpleNavWidget from "./SimpleNavWidget";
import { ContactSupport as ContactSupportIcon } from "../../../_app/components/icons";

const HelpLink = ({ ...props }) => {
  return (
    <SimpleNavWidget
      title="Need help?"
      description="Our help section contains helpful guides and information."
      link="/help"
      icon={<ContactSupportIcon />}
      {...props}
    />
  );
};

export default HelpLink;
