import { UIToggleButton, UIToggleButtonGroup, UIToggleButtonGroupProps } from "../../_app/components";
import { createStylesheet } from "../../_app/utils/styles";

export interface Props extends UIToggleButtonGroupProps {
  options: { label: string; value: string }[];
  buttonProps?: any;
  fullWidth?: boolean;
}

export const ToggleButtons = ({ options = [], value, onChange, buttonProps, fullWidth }: Props) => {
  const classes = useStyles();
  return (
    <UIToggleButtonGroup
      exclusive
      onChange={onChange}
      size="small"
      value={value}
      className={fullWidth ? classes.fullWidth : undefined}
    >
      {options.map((option) => {
        return (
          <UIToggleButton key={option.value} value={option.value} className={classes.toggleBtn} {...buttonProps}>
            {option.label}
          </UIToggleButton>
        );
      })}
    </UIToggleButtonGroup>
  );
};

const useStyles = createStylesheet((theme) => ({
  fullWidth: {
    width: "100%",
  },
  toggleBtn: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default ToggleButtons;
