import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import config from "../config";

export const withRecaptcha = (WrappedComponent: any) => (props: any) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config?.configRecaptcha?.siteKey || ""}>
      <WrappedComponent {...props} />
    </GoogleReCaptchaProvider>
  );
};

export default withRecaptcha;
