import { ExpandMore as ExpandMoreIcon } from "../../components/icons";
import UILoader from "../UILoader";
import { createStylesheet } from "../../utils/styles";
import { UITableFooter, UITableRow } from "..";
import UIButton from "../UIButton";

interface Props {
  hasMore: boolean | undefined;
  disabled?: boolean;
  onChangePage?: any;
  variant?: "text" | "contained" | "outlined";
}

export const LoadMoreFooter = ({ disabled, hasMore, onChangePage, variant = "outlined" }: Props) => {
  const classes = useStyles();
  return (
    <UITableFooter className={classes.root}>
      {hasMore && (
        <UITableRow className={classes.row}>
          <td>
            <UIButton
              disabled={disabled}
              startIcon={disabled ? null : <ExpandMoreIcon />}
              onClick={onChangePage}
              variant={variant}
              color="primary"
              data-cy="load_more"
            >
              {disabled ? <UILoader size={18} className={classes.isLoading} /> : "Load More"}
            </UIButton>
          </td>
        </UITableRow>
      )}
    </UITableFooter>
  );
};

const useStyles = createStylesheet((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  isLoading: {
    margin: "3px 38px",
  },
  row: {
    padding: theme.spacing(1.5),
  },
}));
