import { useUsageSummary } from "../../hooks";
import GraphWidget from "./GraphWidget";
import { PeriodParam } from "../../../filter/types";
import { formatPrice } from "../../../billing/utils";
import { featureFlagsMap } from "../../../feature/utils";
import { createStylesheet } from "../../../_app/utils/styles";
import { UITable, UITableBody, UITableCell, UITableRow } from "../../../_app/components";

interface Props {
  period?: PeriodParam;
}

const UsageSummary = ({ period }: Props) => {
  const classes = useStyles();
  const { data = [], isFetching, isError } = useUsageSummary(period);
  const hasError = isError || data?.length <= 0;

  const renderRow = (row: any, isHeader = false) => {
    const isTotals = row?.product === "Totals";
    const klass = isHeader ? classes.rowHeader : isTotals ? classes.rowTotal : classes.row;
    const col1 = row?.product;
    const col2 = `${isHeader ? row?.serviceCharges : formatPrice(row?.serviceCharges)}`;
    const col3 = `${isHeader ? row?.usageCharges : formatPrice(row?.usageCharges)}`;
    return (
      <UITableRow key={row?.product}>
        <UITableCell className={klass}>{col1}</UITableCell>
        <UITableCell className={klass}>{col2}</UITableCell>
        <UITableCell className={klass}>{col3}</UITableCell>
      </UITableRow>
    );
  };

  return (
    <GraphWidget
      title="Bill Summary"
      isLoading={isFetching}
      hasError={hasError}
      featureFlag={featureFlagsMap.BILL_SUMMARY_GRAPH}
      bodyPadding={0}
    >
      <UITable className={classes.table} aria-label="simple table">
        <UITableBody>
          {renderRow(
            {
              product: "Product",
              serviceCharges: "Service Charge",
              usageCharges: "Usage Charges",
            },
            true,
          )}
          {data.map((row) => renderRow(row))}
        </UITableBody>
      </UITable>
    </GraphWidget>
  );
};

const useStyles = createStylesheet((theme) => ({
  table: {},
  row: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  rowHeader: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    fontWeight: "bold",
  },
  rowTotal: {
    padding: `
      ${theme.spacing(1.5)}px
      ${theme.spacing(2)}px
      ${theme.spacing(3)}px
    `,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    borderBottom: "0px",
  },
}));

export default UsageSummary;
