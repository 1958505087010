import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { permissionCodes } from "../../permission/hooks";
import { useLogicMonitor } from "../hooks";
import Secondary from "../../_app/layouts/Secondary";
import Main from "../../_app/layouts/Main";
import advert from "../../logic-monitor/images/lm-advert.png";
import { featureFlagsMap } from "../../feature/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UICard, UICardMedia, UITypography } from "../../_app/components";

interface Props {
  openInTab?: boolean;
}

export const LogicMonitor = ({ openInTab = false }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isReady, isLoading, error, launchInNewTab, iframeProps } = useLogicMonitor();

  useEffect(() => {
    if (isReady && openInTab) {
      launchInNewTab();
      navigate(-1);
    }
  }, [isReady, openInTab, launchInNewTab, navigate]);

  const onExit = () => navigate("/");
  if (error) {
    return (
      <Main
        title="Monitoring"
        data-cy="logic-monitor-page"
        isLoading={isLoading}
        featureFlag={featureFlagsMap.LOGIC_MONITOR}
        accessPermission={permissionCodes.SUPPORT_LOGIC_MONITOR}
      >
        <UICard className={classes.advert}>
          <UICardMedia component="img" image={advert} alt="Monitoring Advert" />
        </UICard>
      </Main>
    );
  }

  return (
    <Secondary
      data-cy="logic-monitor-page"
      isLoading={isLoading}
      featureFlag={featureFlagsMap.LOGIC_MONITOR}
      accessPermission={permissionCodes.SUPPORT_LOGIC_MONITOR}
    >
      {Boolean(isReady && !openInTab) && (
        <div className={classes.ctr}>
          <iframe {...iframeProps} title="Monitoring" className={classes.iframe} />
          <div className={classes.exitSnack}>
            <UITypography variant="h5">
              LogicMonitor is currently active. Please ensure you save changes before exiting the app.
            </UITypography>
            <UIButton variant="contained" className={classes.exitBtn} onClick={onExit}>
              Exit LogicMonitor
            </UIButton>
          </div>
        </div>
      )}
    </Secondary>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
  },
  advert: {
    maxWidth: 800,
    marginTop: theme.spacing(1),
    padding: "0px !important",
    "& > div:first-of-type": {
      padding: "0px !important",
    },
  },
  iframe: {
    width: "100%",
    height: "calc(100% - 64px)",
    border: "none",
  },
  exitSnack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    height: 64,
    color: "#fff",
  },
  exitBtn: {
    backgroundColor: "#36474f",
    color: "#fff",
  },
}));

export default LogicMonitor;
