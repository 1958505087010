import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Main from "../../_app/layouts/Main";
import { useUserAccountAssign, useUserCreate } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { UserAccountAssignRequest, UserCreateUpdateRequest, UserId } from "../types";
import { getUserIdByEmail } from "../api";
import EditUserForm from "../components/EditUserForm";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UIGrid } from "../../_app/components";

export const AddUser = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [fetchingUserId, setFetchingUserId] = useState<boolean>(false);

  const {
    mutate: executeUserCreate,
    error: createError,
    isLoading: userCreateIsLoading,
  } = useUserCreate({
    onSuccess: () => {
      navigate("/users");
    },
  });

  const { mutate: executeUserAssign, isLoading: userAssignIsLoading } = useUserAccountAssign({
    onSuccess: () => {
      navigate("/users");
    },
  });

  const addUser = (user: UserCreateUpdateRequest) => {
    executeUserCreate(user);
  };

  const assignUserToAccount = (userId: UserId, user: UserCreateUpdateRequest) => {
    const userAssignPayload: UserAccountAssignRequest = {
      accountIds: user.accountIds,
      roleId: user.roleId,
      permissions: user.permissions,
    };

    executeUserAssign({ user: userAssignPayload, userId });
  };

  const onSubmit = (user: UserCreateUpdateRequest) => {
    setFetchingUserId(true);
    getUserIdByEmail(user.userDetails.email)
      .then((userId: UserId) => assignUserToAccount(userId, user))
      .catch(() => addUser(user))
      .finally(() => setFetchingUserId(false));
  };

  const user = createError?.config?.data ? { ...JSON.parse(createError.config.data), email: null } : {};

  const isLoading = userCreateIsLoading || userAssignIsLoading || fetchingUserId;

  return (
    <Main title="Add User" data-cy="add-user-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={isLoading}>
      <UICard elevation={1} className={classes.card} padding={"40px"}>
        <UIGrid container>
          <EditUserForm
            user={user}
            newUser={true}
            hasEmailConfirmation
            isSubmitting={isLoading}
            handleSubmit={onSubmit}
            handleCancel={() => navigate(-1)}
          />
        </UIGrid>
      </UICard>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    width: "100%",
  },
}));

export default AddUser;
