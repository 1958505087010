import { Cancel as CancelIcon, CheckCircleRounded as CheckCircleRoundedIcon } from "../../_app/components/icons";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { UIAlert, UIBox, UIButton, UIInputAdornment, UILoader, UIStack, UITextField, UITypography } from "../../_app/components";
import { useFeedbackAlerts } from "../../_app/hooks";
import { associateTotp, disableMfa, enableMfa, verifyTotp } from "../../auth/api";
import { useIdentityUser, useMfaEnabled } from "../../auth/hooks";
import { ERROR, SUCCESS } from "../../auth/types";
import { createStylesheet } from "../../_app/utils/styles";

export const MFASection = () => {
  const classes = useStyles();
  const { setFeedbackAlertSuccess, setFeedbackAlertError } = useFeedbackAlerts();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const { data: user } = useIdentityUser();
  const accessToken = user?.access_token ?? "";
  const { data: hasMfaEnabled, refetch: refetchMfaEnabled } = useMfaEnabled(accessToken);

  const [mfaSwitchedOn, setMfaSwitchedOn] = useState(false);
  useEffect(() => {
    if (!mfaSwitchedOn && hasMfaEnabled) setMfaSwitchedOn(true);
  }, [hasMfaEnabled, mfaSwitchedOn]);

  const [totpSecret, setTotpSecret] = useState("");

  useEffect(() => {
    const fetchTotpSecret = async () => {
      setIsLoading(true);
      const secret = await associateTotp(accessToken);
      if (secret) setTotpSecret(secret);
      else {
        setFeedbackAlertError("Fetching TOTP secret failed.");
        setMfaSwitchedOn(false);
      }
      setIsLoading(false);
    };

    if (mfaSwitchedOn) fetchTotpSecret();
    else {
      setTotpSecret("");
      setOtpCode("");
      setTotpVerification("");
      setMfaConfirmed(false);
    }
  }, [mfaSwitchedOn, user, setFeedbackAlertError, accessToken]);

  const qrCodeValue = useMemo(
    () => `otpauth://totp/Hub:${user?.profile.email}?secret=${totpSecret}&issuer=Hub`,
    [user, totpSecret],
  );

  const [otpCode, setOtpCode] = useState("");
  const [totpVerification, setTotpVerification] = useState("");
  const totpVerified = totpVerification === SUCCESS;
  const totpVerificationError = totpVerification === ERROR;
  const [mfaConfirmed, setMfaConfirmed] = useState(false);

  const handleCodeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value.replace(/\D/g, "").slice(0, 6);
    setOtpCode(code);

    if (code.length >= 6) {
      setIsVerifying(true);
      const verified = await verifyTotp(accessToken, code);
      setTotpVerification(verified ?? "");
      setIsVerifying(false);
      inputRef.current?.focus();
    }
    if (code.length < 6 && Boolean(totpVerification)) setTotpVerification("");
  };

  const toggleMfaState = async () => {
    if (!hasMfaEnabled) setMfaSwitchedOn(true);
    if (hasMfaEnabled) await mfaDisable();
  };

  const mfaDisable = async () => {
    setIsLoading(true);
    const disabled = await disableMfa(accessToken);
    if (disabled) {
      await refetchMfaEnabled();
      setMfaSwitchedOn(false);
      setFeedbackAlertSuccess("MFA successfully disabled.");
    } else setFeedbackAlertError("MFA couldn't be disabled.");
    setIsLoading(false);
  };

  const confirmMfaSetup = async () => {
    setIsLoading(true);
    const confirmed = await enableMfa(accessToken);
    setMfaConfirmed(confirmed);
    if (confirmed) {
      await refetchMfaEnabled();
      setFeedbackAlertSuccess("MFA successfully enabled.");
    } else setFeedbackAlertError("MFA couldn't be enabled, please try again later.");
    setIsLoading(false);
  };

  const resetCodeField = () => {
    setOtpCode("");
    setTotpVerification("");
    inputRef.current?.focus();
  };

  return (
    <UIStack alignItems="flex-start" spacing={3}>
      <UITypography variant="h4">Multi-factor authentication</UITypography>
      {isLoading ? (
        <UIBox display="flex" justifyContent="center" width="100%" pt={1}>
          <UILoader />
        </UIBox>
      ) : (
        <>
          {(!mfaSwitchedOn || hasMfaEnabled || !totpSecret) && (
            <>
              <UIAlert severity="info">
                {hasMfaEnabled
                  ? "You already have MFA switched on for this account. If you no longer want to use it, you can disable it below."
                  : "Multi-factor authentication is currently disabled for this account. You can set it up by clicking the button below."}
              </UIAlert>
              <UIButton data-cy="toggle-mfa-btn" variant="contained" onClick={toggleMfaState} disabled={isLoading}>
                {hasMfaEnabled ? "Disable" : "Enable"}
              </UIButton>
            </>
          )}
          {!hasMfaEnabled && totpSecret && (
            <>
              <UIAlert severity="info">
                Scan the QR code with your preferred authenticator app to start setting up the multi-factor authentication.
              </UIAlert>
              <UIStack display="flex" alignItems="center" spacing={3} width="100%">
                {!mfaConfirmed && (
                  <>
                    <QRCodeSVG value={qrCodeValue} />
                    <div className={classes.verifyField}>
                      <UITypography variant="caption">Enter the code generated by the authenticator to verify:</UITypography>
                      <UITextField
                        inputRef={inputRef}
                        name="code"
                        placeholder="Code"
                        type="text"
                        value={otpCode}
                        onChange={handleCodeChange}
                        variant="outlined"
                        size="small"
                        disabled={totpVerified || isVerifying}
                        InputProps={{
                          endAdornment: (
                            <UIInputAdornment position="end">
                              <div style={{ width: 30 }}>
                                <UIButton isIconButton onClick={resetCodeField} disabled={totpVerified || isVerifying}>
                                  <>
                                    {isVerifying ? (
                                      <UILoader color="inherit" size={20} />
                                    ) : (
                                      <>
                                        {totpVerified && <CheckCircleRoundedIcon color="success" />}
                                        {totpVerificationError && <CancelIcon color="error" />}
                                      </>
                                    )}
                                  </>
                                </UIButton>
                              </div>
                            </UIInputAdornment>
                          ),
                          inputMode: "numeric",
                        }}
                      />
                      {totpVerified && (
                        <UITypography variant="caption" color="success.main">
                          Code verified, confirm setting up the MFA by clicking the button below.
                        </UITypography>
                      )}
                      {totpVerificationError && (
                        <UITypography variant="caption" color="error">
                          Verification failed, please make sure you typed in the correct code.
                        </UITypography>
                      )}
                    </div>
                    <>
                      {totpVerified && (
                        <UIButton
                          data-cy="confirm-button"
                          variant="contained"
                          color="primary"
                          onClick={confirmMfaSetup}
                          disabled={isLoading}
                        >
                          Finish MFA Setup
                        </UIButton>
                      )}
                    </>
                  </>
                )}
              </UIStack>
            </>
          )}
        </>
      )}
    </UIStack>
  );
};

const useStyles = createStylesheet(() => ({
  verifyField: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 3,
    padding: 0,
  },
}));

export default MFASection;
