import api from "../_app/api";
import { PeriodParam, Query } from "../filter/types";
import { formatDate, humanize } from "../_app/utils/format";
import { GraphData, UsageSummaryRecord } from "./types";
import { CHARGE_FREQUENCY, GRAPH_COLORS } from "./utils";

export const getUsageSummary = async (period?: PeriodParam) => {
  const { data }: { data: UsageSummaryRecord[] } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/bill_summary",
    params: { period },
  });
  return data;
};

export const getUsageBreakdown = async (queries: Query[]) => {
  const { data }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/USAGE_COST",
    serialize: true,
    params: { queries },
  });

  return data;
};

export const getServiceChargesFrequency = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/SERVICE_CHARGES_FREQUENCY",
    serialize: true,
    params: { queries },
  });
  let counter = 0;
  const datasets = dataObject?.items.map((chart, index) => {
    return Object.entries(chart.values).map((entry) => {
      const [key, value] = entry;
      const emptyArray = Array(dataObject?.items.length).fill(0);
      emptyArray[index] = value;
      return {
        label: key,
        data: emptyArray,
        backgroundColor: GRAPH_COLORS[counter++],
        maxBarThickness: 80,
      };
    });
  });
  return {
    unit: dataObject?.unit,
    labels: dataObject?.items.map((chart) => CHARGE_FREQUENCY[chart.name as keyof typeof CHARGE_FREQUENCY]),
    datasets: datasets.flat(),
  };
};

export const getCostByUsageType = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/TRENDS_USAGE_COST_BY_USAGE",
    serialize: true,
    params: { queries },
  });
  let colorMap: { [key: string]: string } = {};
  let counter = 0;
  const datasets = dataObject?.items.map((chart, index) => {
    return Object.entries(chart.values).map((entry) => {
      const [key, value] = entry;
      const emptyArray = Array(dataObject?.items.length).fill(0);
      emptyArray[index] = value;
      return {
        label: humanize(key),
        data: emptyArray,
        backgroundColor: key in colorMap ? colorMap[key] : (colorMap[key] = GRAPH_COLORS[counter++]),
        maxBarThickness: 80,
      };
    });
  });
  return {
    unit: dataObject?.unit,
    labels: dataObject?.items.map((chart) => formatDate(chart.name, "MMM/yyyy")),
    datasets: mergeDatasets(datasets),
  };
};

export const getServiceCostByType = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/TRENDS_SERVICE_COST_BY_TYPE",
    serialize: true,
    params: { queries },
  });
  let colorMap: { [key: string]: string } = {};
  let counter = 0;
  const datasets = dataObject?.items.map((chart, index) => {
    return Object.entries(chart.values).map((entry) => {
      const [key, value] = entry;
      const emptyArray = Array(dataObject?.items.length).fill(0);
      emptyArray[index] = value;
      return {
        label: humanize(key),
        data: emptyArray,
        backgroundColor: key in colorMap ? colorMap[key] : (colorMap[key] = GRAPH_COLORS[counter++]),
        maxBarThickness: 80,
      };
    });
  });
  return {
    unit: dataObject?.unit,
    labels: dataObject?.items.map((chart) => formatDate(chart.name, "MMM/yyyy")),
    datasets: mergeDatasets(datasets),
  };
};

export const getCostByProduct = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/TRENDS_USAGE_COST_BY_PRODUCT",
    serialize: true,
    params: { queries },
  });
  let colorMap: { [key: string]: string } = {};
  let counter = 0;
  const datasets = dataObject?.items.map((chart, index) => {
    return Object.entries(chart.values).map((entry) => {
      const [key, value] = entry;
      const emptyArray = Array(dataObject?.items.length).fill(0);
      emptyArray[index] = value;
      const color = key in colorMap ? colorMap[key] : (colorMap[key] = GRAPH_COLORS[counter++]);
      return {
        label: humanize(key),
        data: emptyArray,
        backgroundColor: color,
        borderColor: color,
      };
    });
  });

  return {
    unit: dataObject?.unit,
    labels: dataObject?.items.map((chart) => formatDate(chart.name, "MMM/yyyy")),
    datasets: mergeDatasets(datasets),
  };
};

export const getServiceCostByProduct = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/TRENDS_SERVICE_COST_BY_PRODUCT",
    serialize: true,
    params: { queries },
  });
  let colorMap: { [key: string]: string } = {};
  let counter = 0;
  const datasets = dataObject?.items.map((chart, index) => {
    return Object.entries(chart.values).map((entry) => {
      const [key, value] = entry;
      const emptyArray = Array(dataObject?.items.length).fill(0);
      emptyArray[index] = value;
      const color = key in colorMap ? colorMap[key] : (colorMap[key] = GRAPH_COLORS[counter++]);
      return {
        label: humanize(key),
        data: emptyArray,
        backgroundColor: color,
        borderColor: color,
      };
    });
  });

  return {
    unit: dataObject?.unit,
    labels: dataObject?.items.map((chart) => formatDate(chart.name, "MMM/yyyy")),
    datasets: mergeDatasets(datasets),
  };
};

export const mergeDatasets = (datasets: any[][]) => {
  const values = new Map();
  datasets.forEach((period, index) =>
    period.forEach((value) => {
      const v = values.get(value.label);
      if (v) {
        v.data[index] = value.data[index];
      } else {
        values.set(value.label, value);
      }
    }),
  );
  return Array.from(values.values());
};

export const getCostUser = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/TOP_TEN_COST",
    serialize: true,
    params: { queries },
  });
  const labels = dataObject?.items.map((chart: any) => {
    chart.label = `${chart?.name} - ${chart?.number}`;
    const label = (chart.label = `${chart?.name} - ${chart?.number}`);
    return label;
  });
  const dataset = {
    labels: labels,
    datasets: [
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[0],
        label: "Call - International",
        data: dataObject?.items,
        parsing: {
          yAxisKey: "values.INTERNATIONAL_CALLS",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[1],
        label: "Call - Domestic",
        data: dataObject?.items,
        parsing: {
          yAxisKey: "values.DOMESTIC_CALLS",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[2],
        label: "Data - UK Standard",
        data: dataObject?.items,
        parsing: {
          yAxisKey: "values.NON_ROAMED_DATA",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[3],
        label: "Data - Roamed",
        data: dataObject?.items,
        parsing: {
          yAxisKey: "values.ROAMED_DATA",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[4],
        label: "Event - MMS Message",
        data: dataObject?.items,
        parsing: {
          yAxisKey: "values.MMS",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[5],
        label: "Event - SMS Message",
        data: dataObject?.items,
        parsing: {
          yAxisKey: "values.SMS",
        },
      },
    ],
    unit: dataObject?.unit,
  };
  return dataset;
};

export const getNonGeographic = async () => {
  // @todo - replace with actual api call
  // let { data }: { data: Asset } = await api({
  //   method: "GET",
  //   context_injection: true,
  //   url: "/asset",
  //   params: { id }
  // });

  const dataObject = [
    { name: "Ben Smith", number: 10111011101, other: { premium: 100 } },
    {
      name: "John Doe",
      number: 2202202220,
      other: { callRetrun: 80, audioConference: 10 },
    },
    {
      name: "Jessica Smith",
      number: 321333033,
      other: { sat: 60, premium: 10, audioConference: 10 },
    },
    { name: "Peter Smith", number: 444004440, other: { sc006: 50 } },
    {
      name: "Ben Doe",
      number: 50505050505,
      other: { wifi: 32, audioConference: 8 },
    },
    { name: "Jane Smith", number: 6006060606, other: { premium: 30 } },
    { name: "Jane Doe", number: 7007070707, other: { callRetrun: 25 } },
    {
      name: "Elizabeth Smith",
      number: 8008080808,
      other: { audioConference: 20 },
    },
    { name: "John Snow", number: 9009090909, other: { callRetrun: 15 } },
    { name: "Rick Sanchez", number: 1010101010, other: { sc004: 10 } },
  ];
  const labels = dataObject?.map((chart: any) => {
    chart.label = `${chart?.name} - ${chart?.number}`;
    const label = (chart.label = `${chart?.name} - ${chart?.number}`);
    return label;
  });
  const dataset = {
    labels: labels,
    datasets: [
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[0],
        label: "Premium Rate Text Message",
        data: dataObject,
        parsing: {
          yAxisKey: "other.premium",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[1],
        label: "Call Return",
        data: dataObject,
        parsing: {
          yAxisKey: "other.callRetrun",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[2],
        label: "Sat Inmarsat Mini M Atlantic",
        data: dataObject,
        parsing: {
          yAxisKey: "other.sat",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[3],
        label: "Audio Conference",
        data: dataObject,
        parsing: {
          yAxisKey: "other.audioConference",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[4],
        label: "UK - NGCS SC006",
        data: dataObject,
        parsing: {
          yAxisKey: "other.sc006",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[5],
        label: "UK Mobile Wifi (fw7)",
        data: dataObject,
        parsing: {
          yAxisKey: "other.wifi",
        },
      },
      {
        stack: "a",
        backgroundColor: GRAPH_COLORS[6],
        label: "UK - NGCS SC004",
        data: dataObject,
        parsing: {
          yAxisKey: "other.sc004",
        },
      },
    ],
  };

  return dataset;
};

export const getDataUser = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/TOP_TEN_DATA",
    serialize: true,
    params: { queries },
  });

  const labels = dataObject?.items.map((chart: any) => {
    chart.label = `${chart?.name} - ${chart?.number}`;
    const label = (chart.label = `${chart?.name} - ${chart?.number}`);
    return label;
  });
  const dataset = {
    labels: labels,
    datasets: [
      {
        backgroundColor: GRAPH_COLORS[0],
        label: "Total Data Usage: ",
        data: [...dataObject?.items],
        parsing: {
          yAxisKey: "values.TOTAL_DATA",
        },
      },
    ],
    unit: dataObject?.unit,
  };
  return dataset;
};

export const getCallCount = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/CALL_COUNT",
    serialize: true,
    params: { queries },
  });

  return {
    labels: [""],
    datasets: dataObject?.items
      .map((chart, index) => {
        return Object.entries(chart.values).map((entry) => {
          const [key, value] = entry;
          return {
            label: key,
            data: [value],
            backgroundColor: GRAPH_COLORS[index],
          };
        });
      })
      .flat(),
    unit: dataObject?.unit || "£",
  };
};

export const getCallDuration = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/CALL_DURATION",
    serialize: true,
    params: { queries },
  });

  return {
    labels: [""],
    datasets: dataObject?.items
      .map((chart, index) => {
        return Object.entries(chart.values).map((entry) => {
          const [key, value] = entry;
          return {
            label: key,
            data: [value],
            backgroundColor: GRAPH_COLORS[index],
          };
        });
      })
      .flat(),
    unit: "",
  };
};

export const getCallCost = async (queries: Query[]) => {
  const { data: dataObject }: { data: GraphData } = await api({
    method: "GET",
    context_injection: true,

    url: "/graphs/CALL_COST",
    serialize: true,
    params: { queries },
  });
  return {
    labels: [""],
    datasets: dataObject?.items
      .map((chart, index) => {
        return Object.entries(chart.values).map((entry) => {
          const [key, value] = entry;
          return {
            label: key,
            data: [value],
            backgroundColor: GRAPH_COLORS[index],
          };
        });
      })
      .flat(),
    unit: dataObject?.unit || "£",
  };
};
