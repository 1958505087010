import { ReactElement } from "react";
import { createStylesheet } from "../utils/styles";
import UIButton from "./UIButton";
import { UICard, UICardProps } from ".";

interface Props extends UICardProps {
  actionTitle: string;
  actionFunction?: () => void;
  actionTestId?: string;
  isFetching?: boolean;
  children: ReactElement<any> | string | number;
}

export const SingleActionCard = ({
  children,
  actionTitle,
  actionFunction = () => null,
  actionTestId = "single-action-btn",
  isFetching = false,
  ...props
}: Props) => {
  const classes = useStyles();
  return (
    <UICard
      isFetching={isFetching}
      actions={[
        <UIButton
          variant="contained"
          color="primary"
          onClick={actionFunction}
          className={classes.action}
          data-cy={actionTestId}
          key={actionTestId}
        >
          {actionTitle}
        </UIButton>,
      ]}
      className={`${classes.root} ${props.className}`}
      {...props}
    >
      {children}
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  root: {
    height: "auto",
  },
  action: {
    width: "100%",
  },
}));
