import z from "zod";
import { useFormContext } from "react-hook-form";
import { UIGrid, UITextField, UITypography } from "../_app/components";

interface Props {
  classes: any;
  newUser: boolean;
}

export const userDetailsSchema = (newUser: boolean) => {
  return z
    .object({
      email: z.string().min(1, "Email is required").email("Invalid email"),
      emailConfirm: newUser ? z.string().min(1, "Email confirmation is required") : z.string().nullish(),
      firstName: z.string().min(1, "First name is required"),
      lastName: z.string().min(1, "Last name is required"),
    })
    .refine(
      (data) => {
        // If new user is added emailConfirm should be required and should match email
        return !(newUser && data.email !== data.emailConfirm);
      },
      {
        message: "Email does not match",
        path: ["emailConfirm"],
      },
    );
};

const EditUserDetailsForm = ({ classes, newUser }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <UIGrid size={{ xs: 12, md: 4 }}>
        <UITypography variant="h2" gutterBottom>
          User details
        </UITypography>
      </UIGrid>
      <UIGrid size={{ xs: 12, md: 8 }}>
        <UITextField
          {...register("email")}
          disabled={!newUser}
          className={classes.textField}
          label="Email"
          type="email"
          variant="outlined"
          size="small"
          fullWidth
          error={!!errors?.email}
          helperText={errors?.email?.message as string}
        />
        {newUser && (
          <UITextField
            {...register("emailConfirm")}
            className={classes.textField}
            label="Confirm Email"
            type="email"
            variant="outlined"
            size="small"
            fullWidth
            error={!!errors?.emailConfirm}
            helperText={errors?.emailConfirm?.message as string}
          />
        )}
        <UITextField
          {...register("firstName")}
          className={classes.textField}
          label="First name"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          error={!!errors?.firstName}
          helperText={errors?.firstName?.message as string}
        />
        <UITextField
          {...register("lastName")}
          className={classes.textField}
          label="Last name"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          error={!!errors?.lastName}
          helperText={errors?.lastName?.message as string}
        />
      </UIGrid>
    </>
  );
};

export default EditUserDetailsForm;
