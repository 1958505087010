import { Fragment } from "react";
import { useFooterLinks, useFooterWidget1, useFooterWidget2 } from "../../../brand/hooks";
import { createStylesheet } from "../../utils/styles";
import { UIGrid, UILink, UITypography } from "../../components";

function Footer() {
  const classes = useStyles();
  const links = useFooterLinks();
  const content1 = useFooterWidget1();
  const content2 = useFooterWidget2();

  return (
    <footer className={classes.root} data-cy="footer">
      <UIGrid>
        {links?.map((link: any, i: number) => (
          <Fragment key={link?.label}>
            <UILink variant="caption" color="inherit" href={link?.url || undefined}>
              {link?.label}
            </UILink>
            {i !== links?.length - 1 && <UITypography variant="caption"> | </UITypography>}
          </Fragment>
        ))}
      </UIGrid>
      <UIGrid>
        {Boolean(content1) && (
          <UITypography variant="caption">
            {content1}
            <br />
          </UITypography>
        )}
        {Boolean(content2) && <UITypography variant="caption">{content2}</UITypography>}
      </UIGrid>
    </footer>
  );
}

const useStyles = createStylesheet((theme) => ({
  root: {
    backgroundColor: "#f0f4f8",
    textAlign: "center",
    padding: theme.spacing(2),
    "& > div": {
      padding: "10px",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
    },
  },
}));

export default Footer;
