import { UIChip } from ".";
import { createStylesheet } from "../utils/styles";

const StatusChip = ({ status = "PENDING", ...rest }) => {
  const classes = useStyles();
  let klass = classes.pending;
  if (status.toUpperCase().includes("COMPLETE")) {
    klass = classes.complete;
  }
  if (status.toUpperCase().includes("FAILED")) {
    klass = classes.failed;
  }

  return (
    <UIChip
      className={`${classes.chip} ${klass} ${rest?.className}`}
      label={status?.toLowerCase()}
      data-cy="status-chip"
      size="small"
      {...rest}
    />
  );
};

const useStyles = createStylesheet((theme) => ({
  chip: {
    textTransform: "capitalize",
  },
  pending: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  complete: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  failed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export default StatusChip;
