import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "../../_app/components/icons";
import { UIAlert, UIButton, UITypography } from "../../_app/components";
import { useFeedbackAlerts, useReset } from "../../_app/hooks";
import Main from "../../_app/layouts/Main";
import { capitalize } from "../../_app/utils/format";
import { genericError } from "../../_app/utils/text";
import { useMobileNetworks } from "../../asset/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import BarGroups from "../components/BarGroups";
import BarsUpdateConfirm from "../components/BarsUpdateConfirm";
import { useBarsUpdate } from "../hooks";
import { BarUpdate } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import UISimpleDialog from "../../_app/components/UISimpleDialog";

interface Step {
  id: number;
  title?: string;
  info?: string | ReactElement<any>;
  alert?: string | ReactElement<any>;
  content?: ReactNode;
}

export const BarsUpdate = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLInputElement>(null);
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const location = useLocation();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const singleAssetId = assets?.length === 1 ? assets?.[0].id : "";
  const [updates, setUpdates] = useState<BarUpdate[]>([]);
  const [resetKey, reset] = useReset("bars-form");

  useEffect(() => {
    if ((!assets || !assets?.length) && !filters) {
      setFeedbackAlertError("No mobile number selected");
      navigate({
        pathname: "/mobile-management",
      });
    }
  }, [assets, filters, navigate, setFeedbackAlertError]);

  const {
    data: networksResp,
    error: networksError,
    isFetching,
  } = useMobileNetworks(
    assets?.map((a: any) => a?.id),
    filters,
  );

  const { mutate: executeBarsUpdate, data: successMessage, isLoading: isUpdating } = useBarsUpdate();

  const networks = networksResp || [];

  const onUpdate = () => {
    if (!updates.length) return;
    executeBarsUpdate({
      filters,
      assetIds: assets?.map((assets: any) => assets?.id),
      operations: updates.map((u) => ({
        name: u.name,
        value: u.value,
        network: u.network,
      })),
    });
  };

  const steps: Step[] = networks.map((n, i) => ({
    id: i,
    title: !singleAssetId ? `Manage ${capitalize(n) || "N/A"} Bars` : undefined,
    alert:
      n === "VODAFONE"
        ? "Please be aware when placing International bars, this excludes the Republic of Ireland. Standard international charges apply."
        : undefined,
    info:
      networks.length > 1 ? (
        <span>
          {" "}
          Some of the numbers you selected are on the
          {Boolean(n) ? <img alt={n + " logo"} src={require(`../../_app/images/logo-${n?.toLowerCase()}.png`)} /> : " N/A "}
          network. Please select the changes required to these numbers.
        </span>
      ) : undefined,
    content: (
      <BarGroups
        assetId={singleAssetId}
        network={n}
        resetKey={resetKey}
        updates={updates}
        setUpdates={setUpdates}
        disableApply={isUpdating || !updates?.length}
      />
    ),
  }));

  if (steps?.length) {
    steps?.push({
      id: steps?.length,
      title: "Confirmation",
      content: <BarsUpdateConfirm updates={updates} />,
    });
  }

  const [step, setStep] = useState(0);
  const isLastStep = step === steps?.length - 1;
  const isSecondToLastStep = step === steps?.length - 2;

  const onReset = () => {
    reset();
    setUpdates([]);
    setStep(0);
  };

  useEffect(() => {
    if (contentRef) {
      contentRef?.current?.scrollTo(0, 0);
    }
  }, [step]);

  const computeErrors = () => {
    let error = genericError();
    if (networksError) {
      error = networksError?.data?.message || error;
    } else {
      if (filters) return null;
      if (assets?.length) return null;
      error = "No mobile numbers selected";
    }
    return error;
  };
  const errors = computeErrors();

  return (
    <Main
      title={
        <>
          <div>
            <UITypography variant="h1">{steps[step]?.title || "Manage Bars"}</UITypography>
          </div>
          {Boolean(assets?.length || filters) && <UITypography>({assets?.length || "All"} CLIs selected)</UITypography>}
          {Boolean(updates.length) && (
            <UIButton className={classes.reset} variant="outlined" color="primary" onClick={onReset}>
              Reset
            </UIButton>
          )}
        </>
      }
      titleProps={{ className: classes.title }}
      data-cy="manage-bars-page"
      isLoading={isFetching}
      featureFlag={featureFlagsMap.MOBILE_BARS}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      {
        <>
          <div className={classes.content} ref={contentRef}>
            {Boolean(errors) && (
              <UIAlert severity="error" className={classes.info}>
                {errors}
              </UIAlert>
            )}
            {Boolean(steps[step]?.info) && (
              <UIAlert severity="info" className={classes.info}>
                {steps[step].info}
              </UIAlert>
            )}
            {Boolean(steps[step]?.alert) && (
              <UIAlert severity="warning" className={classes.info}>
                {steps[step].alert}
              </UIAlert>
            )}
            {steps.map((s, i) => {
              return (
                <div
                  style={{
                    display: i === step ? "block" : "none",
                    marginTop: "16px",
                  }}
                  key={s.id}
                >
                  {s.content}
                </div>
              );
            })}
          </div>
          <div className={classes.actions}>
            {step > 0 ? (
              <UIButton
                variant="outlined"
                color="primary"
                disabled={isUpdating}
                onClick={() => setStep(step - 1)}
                startIcon={<ArrowBackIcon />}
              >
                Previous
              </UIButton>
            ) : (
              <div />
            )}
            {step < steps?.length - 2 && (
              <UIButton
                variant="contained"
                color="primary"
                disabled={isUpdating}
                onClick={() => setStep(step + 1)}
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </UIButton>
            )}
            {isSecondToLastStep && (
              <UIButton
                variant="contained"
                color="primary"
                disabled={isUpdating || !updates?.length}
                onClick={() => setStep(step + 1)}
                data-cy="apply-btn"
              >
                Apply
              </UIButton>
            )}
            {isLastStep && (
              <UIButton variant="contained" color="primary" onClick={onUpdate} disabled={!updates?.length} isLoading={isUpdating}>
                Confirm
              </UIButton>
            )}
          </div>
          <UISimpleDialog
            title="Your Request Has Been Sent"
            open={Boolean(successMessage)}
            actions={[
              {
                label: "Close",
                props: { onClick: () => navigate(-1), variant: "outlined" },
              },
            ]}
          >
            {successMessage}
          </UISimpleDialog>
        </>
      }
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  title: {
    display: "flex",
    gap: "10px",
    alignItems: "flex-end",
    marginBottom: "10px",
    maxHeight: "38px",
    marginRight: "48px",
    [theme.breakpoints.down("md")]: {
      "& h1": {
        fontSize: "1.6rem",
      },
    },
  },
  actions: {
    display: "flex",
    width: "100%",
    minHeight: "42px",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
    overflow: "auto",
    marginTop: theme.spacing(1),
  },
  content: {
    width: "100%",
    height: "calc(100vh - 136px)",
    borderRadius: "5px",
    overflow: "auto",
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingBottom: "7px",
  },
  info: {
    width: "100%",
    marginBottom: "5px",
    "& img": {
      objectFit: "contain",
      width: theme.spacing(2),
      margin: "0px 4px -2px",
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiAlert-message": {
        fontSize: "0.7rem",
      },
      "& img": {
        width: "13px",
      },
    },
  },
  reset: {
    marginLeft: "auto",
  },
}));

export default BarsUpdate;
