import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { filtersIdMap } from "../../filter/utils";
import UILoader from "../../_app/components/UILoader";
import { ALL, BILLED, formatPeriodToMonthYear, PERIOD_LENGTH, UNBILLED } from "../utils";
import SelectDropdown from "../../form/components/SelectDropdown";
import ToggleButtons from "../../form/components/ToggleButtons";
import { createStylesheet } from "../../_app/utils/styles";
import { UIBox, UICard, UICardContent, UITypography } from "../../_app/components";

interface Props {
  billedPeriods: string[];
  shownPeriods: string[];
  isUnbilledShown: boolean;
  setIsUnbilledShown: (isUnbilledShown: boolean) => void;
  isFetching: boolean;
  isSuccess: boolean;
}

export default function BillPeriodFilter({
  billedPeriods,
  shownPeriods,
  isUnbilledShown,
  setIsUnbilledShown,
  isFetching,
  isSuccess,
}: Props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [billStatus, setBillStatus] = useState(isUnbilledShown ? UNBILLED : BILLED);
  const [billedPeriod, setBilledPeriod] = useState<string>(
    !isUnbilledShown && shownPeriods.length === 1 && !shownPeriods[0].includes(",") ? shownPeriods[0] : ALL,
  );

  useEffect(() => {
    if (shownPeriods[0]?.includes("UNBILLED")) {
      setBillStatus(UNBILLED);
      setIsUnbilledShown(true);
    }
    if (shownPeriods[0]?.includes(",")) setBilledPeriod(ALL);
    // eslint-disable-next-line
  }, [shownPeriods]);

  useEffect(() => {
    if (!billedPeriod && Boolean(billedPeriods.length)) setBilledPeriod(ALL);
  }, [billedPeriod, billedPeriods.length]);

  useEffect(() => {
    if (!location.pathname.includes("period-details")) {
      if (billStatus === UNBILLED) {
        return navigate(`/bills?${filtersIdMap?.BILL_PERIOD}=${UNBILLED}`);
      }
      if (billStatus === BILLED) {
        let selectedPeriods: string[] = !!billedPeriod && billedPeriod !== ALL ? [billedPeriod] : [];
        if (billedPeriods.length > 1 && billedPeriod === ALL) {
          selectedPeriods = billedPeriods.slice(1, PERIOD_LENGTH + 1);
        }
        const periodParam = selectedPeriods.map((p) => `${filtersIdMap?.BILL_PERIOD}=${p}`).join("&");
        return navigate(`/bills?${periodParam}`);
      }
    }
    // eslint-disable-next-line
  }, [billStatus, billedPeriod, history]);

  const handleChangeBillStatus = (value: string | null) => {
    if (!!value) {
      setBillStatus(value);
      setIsUnbilledShown(value === UNBILLED);
      setBilledPeriod(ALL);
    }
  };

  if (isFetching) {
    return (
      <UIBox margin="auto">
        <UILoader />
      </UIBox>
    );
  } else {
    if (isSuccess) {
      return (
        <UICard raised className={classes.root} elevation={2} data-cy="bill-explorer-period-filter" padding="0px">
          <UICardContent className={classes.content}>
            <div>
              <UITypography variant="caption" paragraph className={classes.filterTitle}>
                Bill Status
              </UITypography>
              <ToggleButtons
                value={billStatus}
                onChange={(_, value) => handleChangeBillStatus(value)}
                options={[
                  { label: "Unbilled", value: UNBILLED },
                  { label: "Billed", value: BILLED },
                ]}
                fullWidth
                buttonProps={{ className: classes.toggleButton }}
              />
            </div>
            {!isUnbilledShown && (
              <div className={classes.filterWrap}>
                <UITypography variant="caption" paragraph className={classes.filterTitle}>
                  Billing Period
                </UITypography>
                <div className={classes.dropdown}>
                  <SelectDropdown
                    name="billedPeriod"
                    value={billedPeriod}
                    data={billedPeriods?.map((value: any) => ({
                      id: value,
                      label: formatPeriodToMonthYear(value),
                      value,
                    }))}
                    infiniteScroll
                    multiple={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setBilledPeriod(e.target.value)}
                    disabled={billedPeriods.length <= 1}
                  />
                </div>
              </div>
            )}
          </UICardContent>
        </UICard>
      );
    } else {
      return null;
    }
  }
}

const useStyles = createStylesheet((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  filterTitle: {
    height: theme.spacing(1.5),
    textTransform: "uppercase",
  },
  filterWrap: {
    width: "100%",
  },
  toggleButton: {
    width: "50%",
    fontWeight: 500,
    borderColor: "#b3b3b3",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&:not(.Mui-selected)": {
      color: theme.palette.text.primary,
    },
  },
  dropdown: {
    width: "100%",
  },
}));
