import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Check as CheckIcon,
  CloudDownload as CloudDownloadIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "../../_app/components/icons";
import { Filter, UsersFilter } from "../types";
import FilterItem from "./FilterItem";
import MyFilters from "./MyFilters";
import AddedFilters from "./AddedFilters";
import MoreFilters from "./MoreFilters";
import UILoader from "../../_app/components/UILoader";
import OptionsButton from "../../form/components/OptionsButton";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UICard, UICardActions, UICardContent, UICardHeader, UICollapse, UITypography } from "../../_app/components";

interface Props {
  fetchHook?: (usage?: string) => any;
  onApply?: () => any;
  myFilters?: {
    fetchHook?: (usage: string) => any;
    deleteHook?: (filter: UsersFilter) => any;
    saveHook?: (name: string) => any;
    addHook?: (filter: UsersFilter) => any;
  };
  usage?: string;
  title?: string;
  hasReset?: boolean;
  hasDownload?: boolean;
  downloadFileType?: string;
  disableApply?: boolean;
  disableDownload?: any;
  handleDownloadClick?: (selected: string) => any;
  className?: string;
  children?: ReactNode;
}

const FiltersCard = ({
  fetchHook = () => ({ data: null, isLoading: null }),
  onApply,
  myFilters,
  usage,
  title = "Filter",
  hasReset = true,
  hasDownload = false,
  downloadFileType = "",
  disableApply = false,
  disableDownload = false,
  handleDownloadClick = () => null,
  className = "",
  children,
}: Props) => {
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  // Reset filters
  const resetFilters = () => {
    const params: string[] = [];
    queryParams.forEach((value, key) => {
      // TODO: preffix check?
      params.push(key);
    });
    params.forEach((key) => {
      queryParams.delete(key);
    });
    navigate({ search: queryParams.toString() }, { replace: true });
    onApply?.();
  };

  const [filters, setFilters] = useState([] as any[]);
  const { data: filtersResponse, isLoading } = fetchHook(usage);

  // Filter toggles
  useEffect(() => {
    if (!filters.length) {
      const queryParams: any = [];
      new URLSearchParams(location.search)?.forEach((value, key) => queryParams.push({ key, value }));

      const output: Filter[] =
        filtersResponse?.map((f: Filter) => {
          const match = queryParams.filter((q: any) => q.key === f.id)?.[0];
          if (match) {
            return {
              ...f,
              value: match.value,
              showToggle: true,
            };
          }
          return f;
        }) || [];
      setFilters(output);
    }
  }, [filtersResponse, location, filters.length]);

  const updateFilterVisibility = (filterId: string, value: boolean) => {
    const newFilters: Filter[] = [];
    filters.forEach((filter: Filter) => {
      const newFilter: Filter = filter;
      if (filter.id === filterId) {
        newFilter.showToggle = value;
      }
      newFilters.push(newFilter);
    });
    setFilters(newFilters);
  };

  const [showLoading, setShowLoading] = useState(false);

  // Card Collapse
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const showHeader = !!title || !!myFilters || hasReset || hasDownload;

  return (
    <UICard className={`${classes.ctr} ${className}`} elevation={1} padding="0px">
      {children}
      {showHeader && (
        <UICardHeader
          className={classes.header}
          title={
            <div className={classes.titleCtr}>
              {Boolean(title) && <UITypography variant="h3">{title}</UITypography>}
              {Boolean(myFilters) && (
                <MyFilters
                  fetchHook={myFilters?.fetchHook}
                  deleteHook={myFilters?.deleteHook}
                  saveHook={myFilters?.saveHook}
                  addHook={myFilters?.addHook}
                  usage={usage}
                />
              )}
            </div>
          }
          action={
            <>
              {hasReset && (
                <UIButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={resetFilters}
                  data-cy="reset-filters-button"
                  className={classes.button}
                >
                  Reset
                </UIButton>
              )}
              {hasDownload && (
                <OptionsButton
                  options={[
                    <UIButton
                      variant="text"
                      size="small"
                      color="primary"
                      data-cy="export-button"
                      disabled={disableDownload}
                      onClick={async () => {
                        setShowLoading(true);
                        await handleDownloadClick(downloadFileType);
                        setShowLoading(false);
                      }}
                      startIcon={showLoading ? <UILoader className={classes.loadingSpinner} /> : <CloudDownloadIcon />}
                    >
                      Export result {downloadFileType ? `as ${downloadFileType.toUpperCase()}` : ""}
                    </UIButton>,
                  ]}
                />
              )}

              {Boolean(myFilters) && (
                <UIButton
                  variant="text"
                  size="small"
                  data-cy="expand-button"
                  onClick={handleExpandClick}
                  className={classes.button}
                  endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  {expanded ? "Hide" : "Show"}
                </UIButton>
              )}
            </>
          }
        />
      )}
      <UICollapse in={expanded} timeout="auto" unmountOnExit>
        <UICardContent className={classes.content} data-cy="visible-filters-list">
          {isLoading && <UILoader />}
          {filters.map((item: Filter) => {
            if (item.showToggle && !item.options?.HIDDEN) {
              return <FilterItem item={item} key={item.id} />;
            }
            return null;
          })}
          <MoreFilters filters={filters} updateFilterVisibility={updateFilterVisibility} />
        </UICardContent>
        <UICardActions className={classes.footer}>
          {Boolean(filters.length) && (
            <>
              <AddedFilters filters={filters} />
              {Boolean(onApply) && (
                <UIButton
                  variant="contained"
                  color="primary"
                  onClick={onApply}
                  data-cy="apply-filters-button"
                  startIcon={<CheckIcon />}
                  disabled={disableApply}
                >
                  Apply
                </UIButton>
              )}
            </>
          )}
        </UICardActions>
      </UICollapse>
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    marginBottom: 24,
  },
  loadingSpinner: {
    margin: 0,
    padding: 0,
    maxWidth: "20px",
    maxHeight: "20px",
  },
  button: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,
    fontSize: "0.95rem",
  },
  header: {
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      "& .MuiCardHeader-action": {
        alignSelf: "center",
      },
    },
    "& .MuiCardHeader-action": {
      margin: 0,
      flex: "0 1 auto",
    },
  },
  titleCtr: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    alignItems: "center",
    padding: `0px ${theme.spacing(2)}`,
  },
  footer: {
    flexDirection: "column",
    alignItems: "flex-end",
    padding: `0px ${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}));

export default FiltersCard;
