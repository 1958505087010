import { ChangeEvent, useState } from "react";
import logo from "../images/direct-debit-logo-black.png";
import { useValidation } from "../../form/hooks";
import { createStylesheet, darken } from "../../_app/utils/styles";
import {
  UIButton,
  UICheckbox,
  UIDialog,
  UIDialogActions,
  UIDialogContent,
  UIFormControlLabel,
  UIGrid,
  UITextField,
  UITypography,
} from "../../_app/components";
import { Close as CloseIcon } from "../../_app/components/icons";

interface DirectDebitDialogProps {
  open: boolean;
  setOpen: Function;
  onContinue: Function;
  accountHolderName: string;
  sortCode: string;
  accountNumber: string;
}

const DirectDebitDialog = ({ open, setOpen, onContinue, accountHolderName, sortCode, accountNumber }: DirectDebitDialogProps) => {
  const classes = useStyles();
  const [showSetupComplete, setShowSetupComplete] = useState(false);
  const [showGaurantee, setShowGaurantee] = useState(false);

  const [name, setAccountHolderName] = useState("");
  const [code, setSortCode] = useState("");
  const [accNumber, setAccountNumber] = useState("");
  const [isAurhorized, setAuthorisedForSetUp] = useState(false);

  const canContinue = Boolean(isAurhorized || showSetupComplete);

  const reset = () => {
    setAccountHolderName("");
    setSortCode("");
    setAccountNumber("");
    setShowSetupComplete(false);
    setShowGaurantee(false);
    setAuthorisedForSetUp(false);
  };

  const validationConfig = {
    accountHolderName: { required: true, maxLength: 18 },
    sortCode: { required: true, minLength: 6, maxLength: 6 },
    accountNumber: { required: true, minLength: 8, maxLength: 8 },
  };

  const { validationErrors, validateAll } = useValidation(validationConfig, {
    accountHolderName: name,
    sortCode: code,
    accountNumber: accNumber,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAuthorisedForSetUp((state) => !state);
  };

  const handleButtonClick = () => {
    if (!showSetupComplete && isAurhorized) {
      const errors = validateAll();
      if (errors.length > 0) {
        return;
      }
      onContinue(name, code, accNumber);
      reset();
    }
    if (showSetupComplete) {
      setOpen(false);
    }
    setShowSetupComplete(!showSetupComplete);
  };

  const handleCloseDialog = () => {
    reset();
    setOpen(false);
  };

  const handleShowGaurantee = () => {
    setShowGaurantee((state) => !state);
  };

  return (
    <UIDialog open={open} onClose={handleCloseDialog} data-cy="direct-debit-dialog" fullWidth maxWidth="xs">
      <UIDialogContent className={classes.dialogContentWrap}>
        <div className={classes.directDebitLogoWrap}>
          <img src={logo} width={80} alt="direct-debit-logo" />
          <CloseIcon className={classes.closeButton} onClick={handleCloseDialog} />
        </div>
        <UITypography variant="h3" gutterBottom data-cy="set_up_typo">
          {showSetupComplete ? "Set up Complete" : "Set up Direct Debit"}
        </UITypography>
        {showSetupComplete ? (
          <></>
        ) : (
          <>
            <UITypography variant="body2">
              This is to cover the monthly cost of services. We’ll take this by Direct Debit on or after the 1st of each month.
            </UITypography>
            <UIButton
              className={classes.guaranteeLine}
              variant="text"
              size="small"
              color="primary"
              onClick={handleShowGaurantee}
              data-cy="back_button"
            >
              {showGaurantee ? "Back to Setup" : "View Direct Debit Guarantee >"}
            </UIButton>
            <div>
              <UIGrid container spacing={1}>
                {showGaurantee ? (
                  <UIGrid size={{ xs: 12 }}>
                    <ul className={classes.guaranteeText}>
                      <li>
                        This Guarantee is offered by all banks and building societies that accept instructions to pay dDirect
                        Debits. The efficiency and security of the scheme is monitored and protected by your own Bank or Building
                        Society.
                      </li>
                      <li>
                        If there are any changes to the amount, date or frequency of your Direct Debit Daisy Communications
                        Limited will notify you 4 working days in adbance of your account being debited or as otherwise agreed. If
                        you request Daisy Communications Limited to collect a payment, confirmation of the amount and date will be
                        given to you at the time of the request.
                      </li>
                      <li>
                        If and error is made in the payment of your Direct Debit by Daisy Communications Limited or your bank or
                        building society you are entitled to a full and immediate refund of the amount paid from your bank or
                        building society.
                      </li>
                      <li>
                        If you receive a refund you are not entitled to, you must pay it back when Daisy Communications Limited
                        asks you to.
                      </li>
                      <li>
                        Tou can cancel a Direct Debit at any time by simply contacting your bank or building society. Written
                        confirmation may be required. Please also notify us.
                      </li>
                    </ul>
                  </UIGrid>
                ) : (
                  <>
                    <UIGrid size={{ xs: 12 }}>
                      <UITextField
                        name="accountHolderName"
                        label="Account holder's name"
                        type="text"
                        value={name}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => setAccountHolderName(e.target.value)}
                        error={Boolean(validationErrors.accountHolderName)}
                        helperText={validationErrors.accountHolderName}
                        inputProps={{
                          maxLength: validationConfig.accountHolderName.maxLength,
                        }}
                      />
                    </UIGrid>
                    <UIGrid size={{ xs: 6 }}>
                      <UITextField
                        name="sortCode"
                        label="Sort code"
                        type="code"
                        value={code}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            let val = e.target.value?.substring(0, 6);
                            setSortCode(val);
                          }
                        }}
                        error={Boolean(validationErrors.sortCode)}
                        helperText={validationErrors.sortCode}
                        inputProps={{
                          maxLength: validationConfig.sortCode.maxLength,
                        }}
                      />
                    </UIGrid>
                    <UIGrid size={{ xs: 6 }}>
                      <UITextField
                        name="accountNumber"
                        label="Account number"
                        value={accNumber}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            let val = e.target.value?.substring(0, 8);
                            setAccountNumber(val);
                          }
                        }}
                        error={Boolean(validationErrors.accountNumber)}
                        helperText={validationErrors.accountNumber}
                        inputProps={{
                          maxLength: validationConfig.accountNumber.maxLength,
                        }}
                      />
                    </UIGrid>
                    <UIGrid size={{ xs: 12 }}>
                      <UIFormControlLabel
                        control={
                          <UICheckbox
                            checked={isAurhorized}
                            onChange={handleChange}
                            name="authorisedForSetUp"
                            color="primary"
                            size="small"
                            data-cy="payment_checkbox"
                          />
                        }
                        label={
                          <UITypography variant="body2" data-cy="checkbox-text">
                            I am authorised to set up a Direct Debit Instruction from this account
                          </UITypography>
                        }
                      />
                    </UIGrid>
                  </>
                )}
              </UIGrid>
            </div>
          </>
        )}
      </UIDialogContent>
      <UIDialogActions>
        <UIButton
          variant="contained"
          color="inherit"
          disabled={!canContinue || showGaurantee}
          className={classes.continueButton}
          onClick={handleButtonClick}
          data-cy="direct-debit-dialog-button"
        >
          {showSetupComplete ? "Back to payment details" : "Continue"}
        </UIButton>
      </UIDialogActions>
    </UIDialog>
  );
};

const useStyles = createStylesheet((theme) => ({
  dialogContentWrap: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  closeButton: {
    float: "right",
    cursor: "pointer",
    color: darken(theme.palette.primary.light, 0.3),
  },
  directDebitLogoWrap: {
    paddingBottom: theme.spacing(4),
    position: "sticky",
    backgroundColor: "#fff",
    top: "0px",
  },
  guaranteeLine: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    padding: 0,
  },
  guaranteeText: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
  continueButton: {
    width: "100%",
    margin: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default DirectDebitDialog;
