import { withWidget } from "./components/Widget";
import { getHours } from "date-fns";
import { useIdentityUser } from "../../auth/hooks";
import { useBrandWelcomeText } from "../../brand/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UITypography } from "../../_app/components";

const Welcome = () => {
  const classes = useStyles();
  const hour = getHours(new Date());
  const { data: identityUser } = useIdentityUser();

  const welcomeText = useBrandWelcomeText();
  const name = identityUser?.profile?.given_name ? `, ${identityUser?.profile?.given_name}` : "";
  return (
    <UICard light={false} height="100%" className={classes.ctr}>
      <div className={classes.contentWrap}>
        <UITypography variant="h1">
          Good {hour < 12 ? " Morning" : hour < 17 ? "Afternoon" : "Evening"}
          {name}!
        </UITypography>
        <UITypography variant="h3">{welcomeText}</UITypography>
      </div>
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    alignItems: "center",
    "& h1": {
      paddingBottom: theme.spacing(2),
    },
    height: "100%",
  },
  contentWrap: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default withWidget(Welcome, "welcome");
