import { ReactNode } from "react";
import { UIBox, UIButton, UIDialog, UIDialogActions, UIDialogContent, UIDialogTitle } from "./index";
import { createStylesheet } from "../utils/styles";

interface Props {
  title?: string;
  children?: ReactNode;
  open: boolean;
  setOpen: Function;
  onConfirm: Function | void | undefined;
  onCancel?: Function | void | undefined;
  disableOnSubmit?: boolean;
  onSubmitTitle?: string;
  onCancelTitle?: string;
  isDelete?: boolean;
  icon?: ReactNode;
  isProcessing?: boolean;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
}

const UIConfirm = ({
  title,
  children,
  open,
  setOpen,
  onConfirm,
  onCancel,
  disableOnSubmit,
  onSubmitTitle,
  onCancelTitle,
  isDelete = false,
  icon,
  isProcessing,
  width = "xs",
}: Props) => {
  const classes = useStyles();

  const handleCancel = () => {
    if (onCancel) onCancel();
    setOpen(false);
  };

  return (
    <UIDialog open={open} onClose={handleCancel} data-cy="confirm-dialog" fullWidth maxWidth={width}>
      <div className={classes.dialog}>
        {Boolean(title) && (
          <UIDialogTitle id="confirm-dialog">
            {icon ? (
              <UIBox className={classes.titleWithIcon}>
                {icon}
                {title}
              </UIBox>
            ) : (
              title
            )}
          </UIDialogTitle>
        )}
        <UIDialogContent>{children}</UIDialogContent>
        <UIDialogActions className={classes.buttons}>
          <UIButton variant="outlined" onClick={handleCancel} className={classes.cancelButton}>
            {onCancelTitle || "Cancel"}
          </UIButton>
          {isDelete ? (
            <UIButton
              data-cy="confirm-dialog-submit"
              variant="contained"
              className={classes.redDeleteButton}
              disabled={disableOnSubmit}
              onClick={() => {
                setOpen(false);
                if (onConfirm) onConfirm();
              }}
            >
              {onSubmitTitle || "Yes"}
            </UIButton>
          ) : (
            <UIButton
              data-cy="confirm-dialog-submit"
              variant="contained"
              disabled={disableOnSubmit}
              isLoading={isProcessing}
              onClick={() => {
                setOpen(false);
                if (onConfirm) onConfirm();
              }}
              color={"primary"}
            >
              {onSubmitTitle || "Yes"}
            </UIButton>
          )}
        </UIDialogActions>
      </div>
    </UIDialog>
  );
};

const useStyles = createStylesheet((theme) => ({
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  dialog: {
    margin: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    padding: "7px 18px",
    marginRight: theme.spacing(1),
  },
  redDeleteButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.error?.main,
  },
}));

export default UIConfirm;
