import AlertAssetSelection from "./AlertAssetSelection";
import { useProductTypes } from "../../hooks";
import { Info as InfoIcon } from "../../../_app/components/icons";
import SelectDropdown from "../../../form/components/SelectDropdown";
import { ProductType } from "../../types";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIAlert, UICard, UIPaper, UITextField, UITooltip, UITypography } from "../../../_app/components";

export const SelectAssets = ({
  hasLevelOptions,
  hasProductOptions,
  usageAlert,
  onInputChange,
  defaultSelected,
  setSelectedAssets,
}: any) => {
  const classes = useStyles();
  const levelOptions = [
    { id: "ACCOUNT", label: "Account", value: "ACCOUNT" },
    { id: "CLI", label: "CLI", value: "CLI" },
  ];

  const { data: productTypes } = useProductTypes({ enabled: hasProductOptions });

  const productOptions =
    productTypes?.map((product: ProductType) => {
      return { id: product.id, label: product.label, value: product.id };
    }) || [];

  const tooltipText = `If you want to be alerted when the whole account exceeds the criteria you specify,
              select 'Account'. If you want to be alerted when each selected
              number exceeds your criteria then select 'CLI'.`;
  return (
    <div>
      <UIPaper className={classes.levelTypeCtr}>
        <div className={classes.tooltip}>
          <UITypography variant="h4" gutterBottom>
            What level of alert do you want to create?
          </UITypography>
          <UITooltip title={tooltipText} placement="right" arrow>
            <InfoIcon color="secondary" data-cy="tooltip-alert" className={classes.infoIcon} />
          </UITooltip>
        </div>

        <div className={classes.selectBox}>
          <UITextField
            required
            fullWidth
            variant="outlined"
            size="small"
            name="name"
            label="Name"
            value={usageAlert.name || ""}
            onChange={onInputChange}
          />
          {Boolean(hasLevelOptions && levelOptions.length) && (
            <SelectDropdown
              required
              name="level"
              label="Select Level"
              value={usageAlert.level || ""}
              data={levelOptions}
              onChange={onInputChange}
            />
          )}
          {hasProductOptions && (
            <SelectDropdown
              required
              name="product"
              label="Select Product Type"
              value={usageAlert.product || ""}
              data={productOptions}
              onChange={onInputChange}
            />
          )}
        </div>
      </UIPaper>
      {usageAlert?.level === "CLI" && usageAlert.product !== undefined && (
        <>
          <UICard className={classes.assetsCard}>
            <UITypography variant="h3" className={classes.title}>
              Select Assets
            </UITypography>
            <UIAlert severity="warning" className={classes.alert}>
              This alert will not be applied to numbers added to the account in the future
            </UIAlert>
            <AlertAssetSelection
              setSelectedAssets={setSelectedAssets}
              defaultSelected={defaultSelected}
              usageAlert={usageAlert}
            />
          </UICard>
        </>
      )}
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  levelTypeCtr: {
    padding: 22,
    margin: 1,
    marginBottom: 15,
  },
  selectBox: {
    display: "flex",
    gap: theme.spacing(1.5),
    maxWidth: "600px",
  },
  tooltip: {
    display: "flex",
    gap: theme.spacing(1.5),
    maxWidth: "400px",
  },
  infoIcon: {
    cursor: "pointer",
    marginTop: "-3px",
  },
  alert: {
    width: "max-content",
    marginBottom: 15,
  },
  assetsCard: {
    marginBottom: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));
