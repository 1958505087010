import { Option } from "../../form/components/SelectDropdown";
import { createStylesheet } from "../../_app/utils/styles";
import { UIChip } from "../../_app/components";

interface Props {
  selectedAccounts: Option[];
}

const SelectedAccountsChips = ({ selectedAccounts }: Props) => {
  const classes = useStyles();

  if (selectedAccounts.length === 0) return null;

  return (
    <div className={classes.chipWrap}>
      {selectedAccounts?.map((account: any, index: number) => (
        <UIChip key={account?.id || index} label={account.label} variant="outlined" className={classes.chip} />
      ))}
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  chipWrap: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default SelectedAccountsChips;
