import api from "../_app/api";
import { Feature, Flag } from "./types";

/**
 * @deprecated Use {@link getEnabledFeatures} instead
 */
export const getFeatureFlags = async () => {
  let { data }: { data: Flag[] } = await api({
    method: "GET",
    url: "/feature-flags",
  });
  const overrides: Flag[] = [];

  return data?.concat(overrides);
};

// New Feature API ST-19392

export const getEnabledFeatures = async () => {
  const { data }: { data: Feature[] } = await api({
    method: "GET",
    url: "/features",
  });

  return data;
};
