import { UICircularProgress, UICircularProgressProps, UILinearProgress } from ".";
import { createStylesheet } from "../utils/styles";

interface Props extends UICircularProgressProps {
  linear?: boolean;
}

export const UILoader = (props: Props) => {
  const classes = useStyles();
  if (props.linear) {
    return <UILinearProgress color="primary" className={`${classes.ctr} ${props.className}`} />;
  }
  return (
    <UICircularProgress data-cy="circular-loader" color="primary" {...props} className={`${classes.ctr} ${props.className}`} />
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {},
}));

export default UILoader;
