import { UIButton, UIGrid } from "../../_app/components";
import { createStylesheet } from "../../_app/utils/styles";

export interface Props {
  onSubmit?: (...args: any[]) => void;
  onCancel?: (...args: any[]) => void;
  isSubmitting?: boolean;
  submitLabel?: string | boolean;
  cancelLabel?: string | boolean;
  disableSubmit?: boolean;
}

export const SubmitButtons = ({
  onSubmit = () => null,
  onCancel = () => null,
  isSubmitting = false,
  submitLabel = "Submit",
  cancelLabel = "Cancel",
  disableSubmit = false,
}: Props) => {
  const classes = useStyles();
  return (
    <UIGrid container direction="row" justifyContent="flex-end" alignItems="center">
      {Boolean(cancelLabel) && (
        <UIButton
          className={classes.cancelButton}
          variant="outlined"
          color="primary"
          onClick={onCancel}
          data-cy="edit-form-cancel"
        >
          {cancelLabel}
        </UIButton>
      )}
      {Boolean(submitLabel) && (
        <UIButton
          className={classes.submitButton}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          isLoading={isSubmitting}
          data-cy="edit-form-submit"
          disabled={disableSubmit}
        >
          {submitLabel}
        </UIButton>
      )}
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  cancelButton: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    padding: "7px 18px",
  },
  submitButton: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
}));

export default SubmitButtons;
