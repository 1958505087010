import { useEffect, useState } from "react";
import { formatDate } from "../../../_app/utils/format";
import FilterBox from "./FilterBox";
import { isBetween } from "../../../_app/utils";
import { createStylesheet } from "../../../_app/utils/styles";
import { AdapterDateFns, LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from "../../../_app/components";

export default function DateRangeFilter({ item, addSelection, selection, setSelection, close, resetSelection, ...props }: any) {
  const PATTERN: string = "dd/MM/yyyy";

  const [showFromPicker, setShowFromPicker] = useState(true);
  const [dateSelectedFrom, setDateSelectedFrom] = useState<Date>(new Date());

  const [showToPicker, setShowToPicker] = useState(false);
  const [dateSelectedTo, setDateSelectedTo] = useState<Date>(new Date());
  const classes = useStyles();

  useEffect(() => {
    const from = formatDateValue(dateSelectedFrom);
    const to = formatDateValue(dateSelectedTo);

    setSelection([from, to]);
  }, [setSelection, dateSelectedTo, dateSelectedFrom]);

  const formatDateValue = (date: any) => {
    if (date) {
      const dateToIso = new Date(date).toISOString();
      const formattedDate = formatDate(dateToIso, PATTERN);
      return formattedDate;
    }
  };

  const handleFromDateChange = (date: any) => {
    setDateSelectedFrom(date);
    setDateSelectedTo(date);
    setShowFromPicker(false);
    setShowToPicker(true);
  };

  const handleToDateChange = (date: any) => {
    setDateSelectedTo(date);
  };

  const handleReset = () => {
    setDateSelectedFrom(new Date());
    setDateSelectedTo(new Date());
    setShowFromPicker(true);
    setShowToPicker(false);
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={handleReset}
        addSelection={addSelection}
        selection={selection}
        close={close}
        showReset
      >
        <div className={classes.filterBody}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <>
              {showFromPicker && (
                <StaticDatePicker
                  maxDate={dateSelectedTo}
                  inputFormat={PATTERN}
                  label="From"
                  renderInput={(props: any) => <div {...props} />}
                  value={dateSelectedFrom}
                  onChange={(date) => handleFromDateChange(date)}
                  disableHighlightToday
                  displayStaticWrapperAs="desktop"
                  autoFocus
                />
              )}
              {showToPicker && (
                <StaticDatePicker
                  minDate={dateSelectedFrom}
                  inputFormat={PATTERN}
                  label="To"
                  renderInput={(props: any) => <div />}
                  value={dateSelectedTo}
                  onChange={(date) => handleToDateChange(date)}
                  disableHighlightToday
                  displayStaticWrapperAs="desktop"
                  autoFocus
                  renderDay={(day: Date, selectedDays: Date[], pickersDayProps: PickersDayProps<Date>) => {
                    const selected = selectedDays[0];
                    const { key, ...props } = pickersDayProps;
                    return (
                      <PickersDay key={key} {...props} selected={day && selected && isBetween(day, dateSelectedFrom, selected)} />
                    );
                  }}
                />
              )}
            </>
          </LocalizationProvider>
        </div>
      </FilterBox>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  filterBody: {
    margin: "0px 0px",
    "& .MuiPickersCalendarHeader-root": {
      marginTop: 0,
    },
  },
}));
