import { ChangeEvent, useEffect, useState } from "react";
import { useValidation } from "../../../form/hooks";
import UIConfirm from "../../../_app/components/UIConfirm";
import { useHasFeatureFlag } from "../../../feature/hooks";
import StatusChip from "../../../_app/components/StatusChip";
import UILoader from "../../../_app/components/UILoader";
import { featureFlagsMap } from "../../../feature/utils";
import { usePaperBillCharge } from "../../hooks";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIButton, UICard, UIGrid, UITextField, UITypography } from "../../../_app/components";

export interface Props {
  handleUpdate: (data: any) => void;
  setOnlyEmail: (value: boolean) => void;
  billSettings: any;
  userLevelName: string;
  isLoading?: boolean;
}

export default function BillingType({
  handleUpdate = () => null,
  setOnlyEmail = () => null,
  billSettings,
  userLevelName = "Account",
  isLoading = false,
}: Props) {
  const classes = useStyles();

  const hasTypeFeature = useHasFeatureFlag(featureFlagsMap.BILL_TYPE);
  const hasTypeUpdateFeature = useHasFeatureFlag(featureFlagsMap.BILL_TYPE_UPDATE);
  const canSwitchType = hasTypeFeature && hasTypeUpdateFeature;
  const isPending = billSettings?.status === "PENDING";

  const hasEmailFeature = useHasFeatureFlag(featureFlagsMap.BILL_NOTIFICATION_EMAIL);
  const canUpdateEmail = useHasFeatureFlag(featureFlagsMap.BILL_NOTIFICATION_EMAIL_UPDATE);

  const [warning, setWarning] = useState(false);
  const paperCharge = usePaperBillCharge();

  const ONLINE_TYPE = "ONLINE";
  const PAPER_TYPE = "PAPER";

  const [billingType, setBillingType] = useState(billSettings?.billType);
  const [requestUpdateBody, setRequestUpdateBody] = useState(billSettings);

  useEffect(() => {
    setBillingType(billSettings?.billType);
    setRequestUpdateBody(billSettings);
  }, [billSettings]);

  const validationConfig = {
    billEmail: { required: true },
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, requestUpdateBody);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const val = event.target.value;
    setRequestUpdateBody({ ...requestUpdateBody, [key]: val });
    validateSingle(key, val);
  };

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      handleUpdate(requestUpdateBody);
    }
  };

  const switchBillingType = () => {
    setOnlyEmail(false);
    onSubmit();
  };

  const updateEmail = () => {
    setOnlyEmail(true);
    onSubmit();
  };

  const isSameAsOrigin = (): boolean => {
    return billSettings?.billEmail === requestUpdateBody?.billEmail;
  };

  const typeOfNextBilling = (billType: string) => {
    if (billType === ONLINE_TYPE) {
      return "paper billing";
    } else {
      return "online billing";
    }
  };

  const typeOfCurrentBilling = (billType: string) => {
    if (billType === ONLINE_TYPE) {
      return "online billing";
    } else {
      return "paper billing";
    }
  };

  const changeBillingType = (cancel: boolean) => {
    setWarning(!cancel);
    if (cancel) {
      setRequestUpdateBody({ ...requestUpdateBody, billType: billingType });
    } else if (billingType === PAPER_TYPE) {
      setRequestUpdateBody({ ...requestUpdateBody, billType: ONLINE_TYPE });
    } else {
      setRequestUpdateBody({ ...requestUpdateBody, billType: PAPER_TYPE });
    }
  };

  const billingTypeMessage = (currentBillingType: string) => {
    if (currentBillingType === ONLINE_TYPE) {
      return `Please note, a charge of ${paperCharge.parsed} per month will applied to your bill for this service`;
    } else {
      return (
        <>
          <UITypography>Do you wish to activate online billing?</UITypography>
          <UITextField
            className={classes.notificationField}
            defaultValue={billSettings?.billEmail}
            name="billEmail"
            label="Email for Notifications"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.billEmail)}
            helperText={validationErrors.billEmail}
            onChange={handleInput}
          />
        </>
      );
    }
  };

  return (
    <UIGrid size={{ xs: 12, md: 6 }}>
      <UIGrid container className={classes.cardContainer}>
        {hasTypeFeature && (
          <UIGrid size={{ xs: 12 }} data-cy="billing-type-settings">
            <UICard elevation={1} className={classes.card}>
              <UITypography variant="h2" gutterBottom>
                My Billing Type Setting
              </UITypography>
              <UITypography className={classes.text}>
                Your {userLevelName?.toLowerCase()} is set to
                <b> {typeOfCurrentBilling(billingType)} </b>
              </UITypography>
              {isLoading && (
                <div className={classes.loading}>
                  <UILoader />
                </div>
              )}
              {canSwitchType && !isLoading && (
                <div className={classes.actions}>
                  <UIButton
                    variant="contained"
                    color="primary"
                    onClick={() => changeBillingType(false)}
                    data-cy="switch-billing-type-button"
                    disabled={isPending}
                  >
                    Switch to {typeOfNextBilling(billingType)}
                  </UIButton>
                  <UIConfirm
                    title={`Activate ${typeOfNextBilling(billingType)}`}
                    open={warning}
                    setOpen={() => changeBillingType(true)}
                    onConfirm={switchBillingType}
                    disableOnSubmit={typeOfNextBilling(billingType) === "online billing" && Boolean(validationErrors.billEmail)}
                    onSubmitTitle="Activate"
                  >
                    {billingTypeMessage(billingType)}
                  </UIConfirm>
                  {isPending && <StatusChip />}
                </div>
              )}
            </UICard>
          </UIGrid>
        )}
        {hasEmailFeature && billingType === ONLINE_TYPE && (
          <UIGrid size={{ xs: 12 }} data-cy="account-billing-type">
            <UICard elevation={1} className={classes.card}>
              <UITypography variant="h2" gutterBottom>
                Billing Notification Email Address
              </UITypography>
              <UITextField
                className={classes.textField}
                name="billEmail"
                type="text"
                defaultValue={billSettings?.billEmail}
                variant="outlined"
                size="small"
                fullWidth
                error={Boolean(validationErrors.postCode)}
                helperText={validationErrors.postCode}
                onChange={handleInput}
                InputProps={{ readOnly: !canUpdateEmail }}
              />
              {canUpdateEmail && (
                <UIButton
                  variant="contained"
                  color="primary"
                  onClick={updateEmail}
                  data-cy="update-billing-type-button"
                  disabled={isSameAsOrigin()}
                >
                  Update
                </UIButton>
              )}
            </UICard>
          </UIGrid>
        )}
      </UIGrid>
    </UIGrid>
  );
}

const useStyles = createStylesheet((theme) => ({
  card: {
    height: "100%",
    padding: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(4),
  },
  notificationField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
  cardContainer: {
    height: "100%",
    gridGap: theme.spacing(2),
  },
  loading: {
    textAlign: "center",
  },
}));
