import { useInfiniteQuery, useQuery } from "react-query";
import { Query } from "../filter/types";
import { AjaxResponse } from "../_app/api";
import { getServiceAnalysis, getServiceAnalysisColumns } from "./api";
import { ServiceAnalysisColumn, ServiceAnalysisResponse } from "./types";
import { SortParam } from "../_app/components/Table/helpers";

export function useGetServiceAnalysisColumns(options = {}) {
  return useQuery<ServiceAnalysisColumn[], AjaxResponse>(["serviceAnalysisColumns"], () => getServiceAnalysisColumns(), {
    ...options,
  });
}

export function useGetServiceAnalysis(limit: number, sort: SortParam[], queries: Query[], columns: string[], options = {}) {
  return useInfiniteQuery<ServiceAnalysisResponse, AjaxResponse>(
    ["serviceAnalysis", { limit, sort, queries, columns }],
    ({ pageParam }) => getServiceAnalysis(pageParam, limit, sort, queries, columns),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      ...options,
    },
  );
}
