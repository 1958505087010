import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useFeedbackAlerts, useStore } from "../../_app/hooks";
import actions from "../../_app/store/Actions";
import { useIdentityUser } from "../../auth/hooks";
import { deleteLogicMonitorToken } from "../../logic-monitor/api";
import { SingleActionCard } from "../../_app/components/SingleActionCard";
import { Group } from "../types";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { createStylesheet } from "../../_app/utils/styles";
import { UIGrid, UITypography } from "../../_app/components";

interface GroupCardProps {
  group: Group;
  lg: any;
}

const GroupCard = ({ group, lg, ...rest }: GroupCardProps) => {
  const { dispatch } = useStore();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const { refreshAccountHierarchy } = useAccountContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: user } = useIdentityUser();
  const userId = user?.profile?.sub;

  const handleSelectGroup = async () => {
    dispatch({
      type: actions.SET_CONTEXT_HIERARCHY,
      payload: {
        ...group,
        userId: userId,
        lastParentId: group?.id,
      },
    });
    setFeedbackAlertSuccess("Selection successful");
    queryClient?.invalidateQueries();
    refreshAccountHierarchy(group.userAccessibleAccounts[0].id);
    deleteLogicMonitorToken();
    navigate("/");
  };

  return (
    <UIGrid size={{ sm: 12, md: 6, lg: lg }} className={classes.cardWrap} {...rest}>
      <SingleActionCard
        height="180px"
        actionTitle="Select"
        actionFunction={handleSelectGroup}
        actionTestId="select-group-button"
        elevation={2}
      >
        <div className={classes.cardContent}>
          <UITypography variant="h4">{group?.name}</UITypography>
        </div>
      </SingleActionCard>
    </UIGrid>
  );
};

const useStyles = createStylesheet(() => ({
  cardWrap: {
    maxWidth: "356px",
  },
  cardContent: {
    margin: "auto",
    textAlign: "center",
  },
}));

export default GroupCard;
