import SimpleNavWidget from "./SimpleNavWidget";
import { PhoneIphone as PhoneIphoneIcon } from "../../../_app/components/icons";

const ManageMobilesLink = ({ ...props }) => {
  return (
    <SimpleNavWidget
      title="Manage Mobiles"
      description="Manage your bars, bill limits and much more."
      link="/mobile-management"
      icon={<PhoneIphoneIcon />}
      {...props}
    />
  );
};

export default ManageMobilesLink;
