import { ReactNode } from "react";
import logo from "../images/direct-debit-logo-black.png";
import { createStylesheet } from "../../_app/utils/styles";
import { UITypography } from "../../_app/components";

interface PaymentDialogContentFilledProps {
  children: ReactNode;
}

export const PaymentDialogContentFilled = ({ children }: PaymentDialogContentFilledProps) => {
  const classes = useStyles();

  return (
    <div className={classes.filledContentWrap} data-cy="payment-details-filled">
      <div className={classes.directDebitLogoWrap}>
        <img src={logo} width={100} alt="direct-debit-logo" />
      </div>
      <UITypography variant="h2" gutterBottom>
        My Direct Debit details
      </UITypography>
      {children}
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  filledContentWrap: {
    height: "410px",
    textAlign: "left",
  },
  directDebitLogoWrap: {
    marginBottom: "32px",
  },
}));
