import UIAttrValue, { UIAttrValueProps } from "../../_app/components/UIAttrValue";
import { createStylesheet } from "../../_app/utils/styles";

const FieldRow = (props: UIAttrValueProps) => {
  const classes = useStyles();
  return (
    <UIAttrValue titleProps={{ className: classes.title }} valueProps={{ className: classes.value }} horizontal {...props} />
  );
};

const useStyles = createStylesheet((theme) => ({
  title: {
    fontWeight: 600,
    minWidth: 160,
  },
  value: {
    width: 720,
  },
}));

export default FieldRow;
