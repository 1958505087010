import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import Main from "../../_app/layouts/Main";
import { useValidation } from "../../form/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { useSimDetails } from "../hooks";
import { Address } from "../../asset/types";
import { isEmail, isMobileNumber } from "../../_app/utils";
import { postcodeValidator } from "postcode-validator";
import { useOrderSim } from "../../asset/hooks";
import { useFeedbackAlerts } from "../../_app/hooks";
import UIAttrValue from "../../_app/components/UIAttrValue";
import {
  UIButton,
  UICard,
  UIFormControl,
  UIGrid,
  UIGridSize,
  UIMenuItem,
  UISelect,
  UITextField,
  UITypography,
} from "../../_app/components";
import SelectDropdown from "../../form/components/SelectDropdown";
import { SimType } from "../types";
import { getSimTypes } from "../utils";
import { createStylesheet } from "../../_app/utils/styles";
import UISimpleDialog from "../../_app/components/UISimpleDialog";

export const SimOrder = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const location = useLocation();
  const asset = location.state?.asset;
  const [esimModal, setEsimModal] = useState(false);
  const [address, setAddress] = useState<Address>({ country: "GB" } as Address);
  const [simType, setSimType] = useState<SimType>(SimType.Triple);
  const countries = useMemo(() => countryList().getData(), []);
  const simTypes = getSimTypes().filter((option) => {
    return option.value === SimType.Esim ? asset?.network !== "VODAFONE" : true;
  });
  const validationConfig = {
    addressLine1: {
      required: true,
      validate: (val: string) => {
        if (val && val.length > 255) return "Maximum length exceeded";
      },
    },
    addressLine2: {
      required: true,
      validate: (val: string) => {
        if (val && val.length > 255) return "Maximum length exceeded";
      },
    },
    addressLine3: { required: false },
    addressLine4: {
      required: true,
      validate: (val: string) => {
        if (val && val.length > 35) return "Maximum length exceeded";
      },
    },
    addressLine5: {
      required: true,
      validate: (val: string) => {
        if (val && val.length > 255) return "Maximum length exceeded";
      },
    },
    country: { required: true },
    postCode: {
      required: true,
      validate: (val: string) => {
        if (!postcodeValidator(val, address?.country)) return "Invalid Postcode";
      },
    },
    email: {
      required: true,
      validate: (val: string) => {
        if (!isEmail(val)) return "Invalid email";
      },
    },
    mobileNumber: {
      required: true,
      validate: (val: string) => {
        if (!isMobileNumber(val)) return "Invalid mobile number";
      },
    },
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, address);

  useEffect(() => {
    if (!asset) {
      setFeedbackAlertError("No mobile number selected");
      navigate("/mobile-management");
    }
  }, [asset, navigate, setFeedbackAlertError]);

  const { mutate: executeOrderSim } = useOrderSim(asset?.id ?? "", simType, {
    onSuccess: (data: any) => {
      setFeedbackAlertSuccess(data);
      navigate("/mobile-management");
    },
    onError: (error: any) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });

  const { data: simDetails } = useSimDetails(asset?.simNumber ?? "", asset?.network ?? "");

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const val = e.target.value;
    setAddress({ ...address, [key]: val });
    validateSingle(key, val);
  };

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      executeOrderSim(address);
    }
  };

  const itemProps = {
    xs: 12 as UIGridSize,
    sm: 6 as UIGridSize,
    md: "auto" as UIGridSize,
  };

  return (
    <Main
      title="Order SIM Card"
      data-cy="sim-order-page"
      isLoading={false}
      featureFlag={featureFlagsMap.SIM_ORDER}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      <>
        <div className={classes.actions} />
        <div className={classes.grid}>
          <UIGrid container data-cy="sim-order" spacing={2}>
            <UIGrid size={{ xs: 12, md: 12 }}>
              <UICard>
                <UIGrid container className={classes.grid} direction="row" spacing={2}>
                  <UIGrid {...itemProps}>
                    <UIAttrValue title={"CLI/Identifier"} value={asset?.cli} />
                  </UIGrid>
                  <UIGrid {...itemProps}>
                    <UIAttrValue title={"Account"} value={asset?.accountCode} />
                  </UIGrid>
                  <UIGrid {...itemProps}>
                    <UIAttrValue title={"Tag"} value={asset?.tag} />
                  </UIGrid>
                  <UIGrid {...itemProps}>
                    <UIAttrValue title={"Current SIM Number"} value={asset?.simNumber} />
                  </UIGrid>
                  <UIGrid {...itemProps}>
                    <UIAttrValue title={"Current SIM Type"} value={simDetails?.simType} />
                  </UIGrid>
                </UIGrid>
              </UICard>
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 8 }}>
              <UICard>
                <UIGrid container className={classes.grid} direction="column">
                  <UIGrid>
                    <UITypography variant="h3" gutterBottom>
                      Select SIM Card Type
                    </UITypography>
                  </UIGrid>
                  <UIGrid>
                    <UIFormControl variant="filled" size="small" className={classes.form}>
                      <UISelect
                        value={simType}
                        variant="outlined"
                        displayEmpty={true}
                        data-cy="select-sim-type"
                        onChange={(event: any) => {
                          const val = event.target.value;
                          if (val === SimType.Esim) setEsimModal(true);
                          setSimType(val);
                        }}
                      >
                        {simTypes.map((option) => (
                          <UIMenuItem value={option.value} key={option.value} data-cy={option.value}>
                            {option.label}
                          </UIMenuItem>
                        ))}
                      </UISelect>
                    </UIFormControl>
                    <UISimpleDialog
                      title="E-Sim"
                      open={esimModal}
                      actions={[
                        {
                          label: "OK",
                          props: {
                            variant: "contained",
                            color: "primary",
                            onClick: () => setEsimModal(false),
                          },
                        },
                      ]}
                    >
                      <strong>Note:</strong> Not all handsets are E-Sim compatible. Please ensure you check before ordering.{" "}
                      <br />
                      <br />
                      More information can be found here: <br />
                      <a href="https://www.o2.co.uk/help/device-and-sim-support/esim" target="_blank" rel="noreferrer">
                        O2 Help & Support
                      </a>
                      <br />
                      <br />
                      Our E-Sims are not compatible with wearables such as smart watches.
                    </UISimpleDialog>
                  </UIGrid>
                </UIGrid>
              </UICard>
            </UIGrid>

            <UIGrid size={{ xs: 12, md: 4 }}>
              <UICard>
                <UIGrid container direction="column" justifyContent="center" alignItems="center" className={classes.fullHeight}>
                  <UIGrid>
                    <UITypography variant="h3">Total Price</UITypography>
                  </UIGrid>
                  <UIGrid>
                    <UITypography variant="h3">£0.00</UITypography>
                  </UIGrid>
                </UIGrid>
              </UICard>
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 8 }}>
              <UICard data-cy="address-card">
                <UITypography variant="h3" gutterBottom>
                  Delivery Address
                </UITypography>
                <UITextField
                  className={classes.label}
                  name="addressLine1"
                  label="Address Line 1"
                  value={address.addressLine1 || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.addressLine1)}
                  helperText={validationErrors.addressLine1}
                  onChange={onInputChange}
                />
                <UITextField
                  className={classes.label}
                  name="addressLine2"
                  label="Address Line 2"
                  value={address.addressLine2 || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.addressLine2)}
                  helperText={validationErrors.addressLine2}
                  onChange={onInputChange}
                />
                <UITextField
                  className={classes.label}
                  name="addressLine3"
                  label="Address Line 3"
                  value={address.addressLine3 || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.addressLine3)}
                  helperText={validationErrors.addressLine3}
                  onChange={onInputChange}
                />
                <UITextField
                  className={classes.label}
                  name="addressLine4"
                  label="City"
                  value={address.addressLine4 || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.addressLine4)}
                  helperText={validationErrors.addressLine4}
                  onChange={onInputChange}
                />
                <UITextField
                  className={classes.label}
                  name="addressLine5"
                  label="County"
                  value={address.addressLine5 || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.addressLine5)}
                  helperText={validationErrors.addressLine5}
                  onChange={onInputChange}
                />
                <UITextField
                  className={classes.label}
                  name="postCode"
                  label="Postcode"
                  value={address.postCode || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.postCode)}
                  helperText={validationErrors.postCode}
                  onChange={onInputChange}
                />
                <SelectDropdown
                  ctrClass={classes.label}
                  name="country"
                  label="Country"
                  value={address.country}
                  data={countries}
                  onChange={onInputChange}
                />
                <UITextField
                  className={classes.label}
                  name="email"
                  label="Email"
                  value={address.email || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.email)}
                  helperText={validationErrors.email}
                  onChange={onInputChange}
                />
                <UITextField
                  className={classes.label}
                  name="mobileNumber"
                  label="Mobile Number"
                  value={address.mobileNumber || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.mobileNumber)}
                  helperText={validationErrors.mobileNumber}
                  onChange={onInputChange}
                />
                <UIButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={onSubmit}
                  className={classes.button}
                  data-cy="submit-btn"
                >
                  Submit
                </UIButton>
              </UICard>
            </UIGrid>
          </UIGrid>
        </div>
      </>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  flexBlock: {
    display: "flex",
  },
  fullHeight: {
    height: "100%",
  },
  pricingCard: {
    padding: theme.spacing(2),
    marginLeft: "auto",
    marginTop: theme.spacing(4),
    width: "400px",
  },
  pricingRow: {
    display: "flex",
    padding: `${theme.spacing(2)} 0`,
    justifyContent: "space-between",
  },
  simInfo: {
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  actions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  grid: {
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "flex-start",
    },
  },
  form: {
    marginTop: theme.spacing(-1.5),
    minWidth: 300,
  },
  label: {
    fontSize: "18px",
    fontWeight: "bold",
    paddingBottom: theme.spacing(3),
    flex: 1,
  },
  value: {
    flex: 1,
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    lineHeight: "27px",
  },
  button: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    width: "fit-content",
  },
  error: {
    paddingLeft: theme.spacing(1.5),
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
}));

export default SimOrder;
