import { useNavigate, useParams } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { useSingleUser, useUserUpdate } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import EditUserForm from "../components/EditUserForm";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UIGrid } from "../../_app/components";

export const EditUser = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const classes = useStyles();
  const { data: user, isFetching: userFetching, isSuccess: userSuccess } = useSingleUser(id ?? "");
  const { mutate: executeUserUpdate, isLoading: isUpdating } = useUserUpdate(id ?? "", {
    onSuccess: () => {
      navigate("/users");
    },
  });

  return (
    <Main title="Edit User" data-cy="edit-user-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={userFetching}>
      {userSuccess && (
        <UICard elevation={1} className={classes.card} padding="40px">
          <UIGrid container>
            <EditUserForm
              user={user}
              userId={id}
              isSubmitting={isUpdating}
              submitLabel="Save Changes"
              handleSubmit={executeUserUpdate}
              handleCancel={() => navigate(-1)}
            />
          </UIGrid>
        </UICard>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    width: "100%",
  },
}));

export default EditUser;
