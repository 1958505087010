import { ComponentType, FC, ReactNode } from "react";
import { useHasFeatureFlag } from "../../../feature/hooks";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIGrid, UIGridSize } from "../../../_app/components";

export interface Props {
  children?: ReactNode;
  md?: UIGridSize;
}

export const withWidget =
  <P extends object>(WrappedComponent: ComponentType<P>, cy?: string, featureFlag?: string, overrides?: any): FC<P & Props> =>
  ({ md = 6, ...props }: Props) => {
    const classes = useStyles();
    const hasFeatureFlag = useHasFeatureFlag(featureFlag);
    if (featureFlag && !hasFeatureFlag) return null;
    return (
      <UIGrid size={{ xs: 12, md: md }} data-cy={cy} className={classes.widget} {...(overrides || {})}>
        <WrappedComponent {...(props as P)} />
      </UIGrid>
    );
  };

const useStyles = createStylesheet((theme) => ({
  widget: {
    "& .widget-button": {
      width: "fit-content",
    },
    "& .MuiPaper-root": {
      minHeight: 240,
    },
  },
}));
