import { ReactNode } from "react";
import { createStylesheet } from "../../utils/styles";
import { UIPaper } from "..";

interface Props {
  children?: ReactNode;
}

const TableHeader = ({ children }: Props) => {
  const classes = useStyles();
  return <UIPaper className={classes.actionsCtr}>{children}</UIPaper>;
};

const useStyles = createStylesheet((theme) => ({
  actionsCtr: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: `${theme.spacing(1)} 26px`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column",
      },
    },
  },
}));

export default TableHeader;
