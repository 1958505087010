import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArcElement, Chart as ChartJS, Legend, registerables, Tooltip } from "chart.js";
import Main from "../../_app/layouts/Main";
import ManageBillDashboardTabs from "../components/ManageBillDashboardTabs";
import UsageCost from "../components/graphs/UsageCost";
import ServiceChargesFrequency from "../components/graphs/ServiceChargesFrequency";
import UsageSummary from "../components/graphs/UsageSummary";
import FiltersCard from "../../filter/components/FiltersCard";
import TopCostAsset from "../components/graphs/TopCostAsset";
import TopDataUser from "../components/graphs/TopDataUser";
import CallCount from "../components/graphs/CallCount";
import { useAllFilters } from "../../filter/hooks";
import { useQueryParam, useQueryParams } from "../../_app/hooks";
import { useBilledPeriods } from "../../billing/hooks";
import CallDuration from "../components/graphs/CallDuration";
import { Query } from "../../filter/types";
import CallCost from "../components/graphs/CallCost";
import Tabbed from "../../_app/layouts/Tabbed";
import { createStylesheet } from "../../_app/utils/styles";
import { UIGrid } from "../../_app/components";
import { Feature } from "../../feature/types";

ChartJS.register(ArcElement, Tooltip, Legend, ...registerables);

export const BillDashMonthly = () => {
  const classes = useStyles();
  const selectedTab = "bill-dashboard-monthly";
  const navigate = useNavigate();
  const [type, setType] = useState("summary");
  const queryParams = useQueryParams();
  const { data: periodList } = useBilledPeriods();
  const period = useQueryParam("70");

  const getParams = useCallback(() => {
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      values.forEach((query) => {
        filters.push({
          id: key,
          value: query,
        });
      });
    });
    return filters;
  }, [queryParams]);

  useEffect(() => {
    if (!period) {
      const defaultPeriod = periodList?.[0];
      if (defaultPeriod) {
        queryParams.append("70", defaultPeriod);
        navigate({ search: queryParams.toString() }, { replace: true });
      }
    }
  }, [periodList, period, queryParams, navigate]);

  return (
    <Main title="Billing Summary" data-cy="bill-dashboard-monthly-page" feature={Feature.BillingDashboard} needSelectedAccount>
      <ManageBillDashboardTabs selectedTab={selectedTab}>
        <UIGrid container direction="row" className={classes.ctr} spacing={0.5}>
          <UIGrid size={{ xs: 12 }}>
            <FiltersCard className={classes.filtersCard} hasReset={false} fetchHook={useAllFilters} usage={"GRAPHS_MONTHLY"} />
          </UIGrid>
          <UIGrid size={{ xs: 12 }}>
            <Tabbed
              selectedTab={type}
              handleTabChange={(_, val) => setType(val)}
              tabs={[
                { value: "summary", label: "Summary" },
                { value: "cli", label: "CLI/Identifiers" },
                { value: "usage", label: "Usage" },
              ]}
            />
          </UIGrid>
          <div className={classes.widgetWrap}>
            <UIGrid container direction="row" spacing={3}>
              {(() => {
                switch (type) {
                  case "summary":
                    return (
                      <>
                        <UsageSummary period={period} />
                        <UsageCost queries={getParams()} />
                        <ServiceChargesFrequency queries={getParams()} />
                      </>
                    );
                  case "cli":
                    return (
                      <>
                        <TopCostAsset queries={getParams()} />
                        <TopDataUser queries={getParams()} />
                      </>
                    );
                  case "usage":
                    return (
                      <>
                        <CallCount queries={getParams()} />
                        <CallDuration queries={getParams()} />
                        <CallCost queries={getParams()} />
                      </>
                    );
                  default:
                    break;
                }
              })()}
            </UIGrid>
          </div>
        </UIGrid>
      </ManageBillDashboardTabs>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {},
  filtersCard: {},
  widgetWrap: {
    width: "100%",
    minHeight: "51vh",
  },
}));

export default BillDashMonthly;
