import api from "../_app/api";
import { parsePermissions } from "../permission/api";
import { GetRolesResponse, Role } from "./types";

export const getAllRoles = async (page?: number, limit?: number) => {
  const { data }: { data: GetRolesResponse } = await api({
    method: "GET",
    url: "/roles",
    context_injection: true,

    params: {
      page,
      size: limit,
    },
  });
  data.list?.forEach((role) => role?.permissions?.map(parsePermissions));
  return data;
};

export const getRoleById = async (id: string) => {
  const { data }: { data: Role } = await api({
    method: "GET",
    url: "/roles/" + id,
    context_injection: true,
  });
  data.permissions = data?.permissions?.map(parsePermissions);
  return data;
};

export const updateRole = async (role: Role) => {
  const { data }: { data: Role } = await api({
    method: "PUT",
    url: `/roles/${role?.id}`,
    context_injection: true,

    data: role,
  });
  return data;
};

export const createRole = async (role: Role) => {
  const { data }: { data: Role } = await api({
    method: "POST",
    url: "/roles",
    context_injection: true,

    data: { ...role },
  });
  return data;
};

export const deleteRole = async (role: Role) => {
  const { data }: { data: Role } = await api({
    method: "DELETE",
    url: `/roles/${role?.id}`,
    context_injection: true,
  });
  return data;
};
