import { Page } from "../api/types";

export function isOldResponseFormat<T>(data: any): data is { list: T[]; page: number; pageSize: number; total: number } {
  return data && typeof data.page === "number" && Array.isArray(data.list);
}

export function convertResponseToPage<T>(
  response: { list: T[]; page: number; pageSize: number; total: number },
  sorted: boolean,
): Page<T> {
  return {
    content: response.list,
    totalPages: Math.ceil(response.total / response.pageSize),
    totalElements: response.total,
    size: response.pageSize,
    number: response.page,
    numberOfElements: response.list.length,
    first: response.page === 0,
    last: response.page === Math.ceil(response.total / response.pageSize) - 1,
    sort: {
      sorted: sorted,
      unsorted: !sorted,
      empty: response.total === 0,
    },
  };
}
