import { CloudDownload as DownloadIcon } from "../../_app/components/icons";
import { useDocumentDownload } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import { useFeedbackAlerts } from "../../_app/hooks";
import { downloadPresignedFile } from "../../_app/utils";
import { UIIconButton, UITooltip } from "../../_app/components";

export interface Props {
  communicationId: string;
  filename: string | null;
}

const DownloadButton = ({ communicationId, filename }: Props) => {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  const { isFetching, refetch } = useDocumentDownload(communicationId, {
    enabled: false,
    cacheTime: 0,
    onSuccess: (data: string) => {
      if (data) downloadPresignedFile(data);
    },
    onError: () => {
      setFeedbackAlertError("Could not download file.");
    },
  });

  if (filename == null) {
    return null;
  }

  return isFetching ? (
    <UILoader />
  ) : (
    <UITooltip title={`Download ${filename}`}>
      <UIIconButton color="primary" onClick={() => refetch()} sx={{ padding: 0 }} data-cy="document-download-link">
        <DownloadIcon />
      </UIIconButton>
    </UITooltip>
  );
};

export default DownloadButton;
