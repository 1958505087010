import { cloneElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { UICard, UIStack, UITypography } from "../../../_app/components";
import { withWidget } from "../components/Widget";
import { createStylesheet } from "../../../_app/utils/styles";

interface Props {
  icon: any;
  title: string;
  link: string;
  children: ReactNode;
}

const NavWidget = ({ icon, title, children, link }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const styledIcon = cloneElement(icon, { className: classes.icon });
  return (
    <UICard onClick={() => navigate(link)} hasHover>
      <UIStack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        {styledIcon}
        <UITypography variant="h3" align="center">
          {title}
        </UITypography>
      </UIStack>
      <div className={classes.ctr}>{children}</div>
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
  },
  icon: {
    fontSize: 55,
    color: theme.palette.primary.main,
  },
}));

export default withWidget(NavWidget, "nav-widget");
