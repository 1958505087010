import React, { useCallback, useEffect, useState } from "react";
import { useIdentityUser } from "../../../auth/hooks";
import { getBrand } from "../../../brand/api";
import { Brand } from "../../../brand/types";
import { DEFAULT_BRAND } from "../../../brand/utils";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useStore } from "../../hooks";
import actions from "../../store/Actions";
import AmplifyThemeProvider from "./AmplifyThemeProvider";
import MUIThemeProvider from "./MUIThemeProvider";

export default function ThemeProvider({ children }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const { state, dispatch } = useStore();
  const { data: user } = useIdentityUser();
  const parentContextId = state.contextHierarchy?.lastParentId;

  const setMetaTags = (brand?: Brand) => {
    const $ = (query: string): any => document.querySelector(query);
    const desc: HTMLMetaElement | null = $('meta[name="description"]');
    const ogTitle: HTMLMetaElement | null = $('meta[property="og:title"]');
    const ogImage: HTMLMetaElement | null = $('meta[property="og:image"]');
    const appleIcon: HTMLLinkElement | null = $('link[rel="apple-touch-icon"]');
    const favicon: HTMLLinkElement | null = $('link[rel="icon"]');

    const title = brand?.name || DEFAULT_BRAND.name;
    const image = brand?.faviconUrl || brand?.logoUrl || "";
    if (desc) desc.content = title;
    if (ogTitle) ogTitle.content = title;
    if (ogImage) ogImage.content = image;
    if (appleIcon) appleIcon.href = image;
    if (favicon) favicon.href = image;
  };

  const setBrand = useCallback(
    (brand?: Brand) => {
      if (brand) {
        dispatch({ type: actions.SET_BRAND, payload: brand });
      }
      setMetaTags(brand);
      setIsLoading(false);
    },
    [dispatch],
  );

  useEffect(() => {
    setIsLoading(true);
    setBrand();
  }, [setBrand]);

  useEffect(() => {
    let isUnmounted = false;
    setIsLoading(true);
    getBrand()
      .then((resp) => {
        if (isUnmounted) return;
        setBrand(resp);
      })
      .catch(() => {
        if (isUnmounted) return;
        setBrand();
      });
    return () => {
      isUnmounted = true;
    };
  }, [parentContextId, setBrand, user?.profile.sub]);

  if (isLoading) return <LoadingOverlay />;
  return (
    <MUIThemeProvider>
      <AmplifyThemeProvider>{children}</AmplifyThemeProvider>
    </MUIThemeProvider>
  );
}
