import { useState } from "react";
import SwitchAccountsDialogLegacy from "../../user-level/components/SwitchAccountsDialogLegacy";
import { useUserLevelMap } from "../../user-level/hooks";
import { UIButton, UIGrid, UITypography } from "../../_app/components";

function AccountSelector() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const userLevels = useUserLevelMap();
  const userLevelName = userLevels?.[30]?.name?.toLowerCase();

  const goToSwitchAccounts = () => {
    setIsDialogOpen(true);
  };

  const isVowel = (c: string) => {
    return ["a", "e", "i", "o", "u"].indexOf(c?.[0]?.toLowerCase()) !== -1;
  };

  return (
    <UIGrid container direction="column" justifyContent="center" alignItems="center" style={{ height: "580px" }}>
      <UITypography variant="h2" gutterBottom>
        Please select {isVowel(userLevelName) ? "an" : "a"} {userLevelName}
      </UITypography>

      <UIButton variant="contained" color="primary" onClick={goToSwitchAccounts} data-cy="select_account_button">
        Select {userLevels?.[30]?.name}
      </UIButton>
      <SwitchAccountsDialogLegacy title={userLevels?.[10]?.name + "s"} open={isDialogOpen} setOpen={setIsDialogOpen} />
    </UIGrid>
  );
}

export default AccountSelector;
