import { ChangeEvent, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PermPhoneMsg as PhoneIcon } from "../../_app/components/icons";
import Main from "../../_app/layouts/Main";
import { useArticleStructure, useArticleTopic } from "../hooks";
import HelpNavMenu from "../components/HelpNavMenu";
import Tabbed from "../../_app/layouts/Tabbed";
import { upperCasetoCamelCase } from "../../_app/utils/format";
import { permissionCodes } from "../../permission/hooks";
import UIHtml from "../../_app/components/UIHtml";
import { onLinkClickHandler, scrollPage } from "../../_app/utils";
import { featureFlagsMap } from "../../feature/utils";
import { ArticleStructure, ArticleTopic } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIButton, UICard, UIGrid, UISkeleton, UITypography } from "../../_app/components";

export const Help = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: articleStructure, isFetching } = useArticleStructure();
  const { category: categoryParam } = useParams<{ category: string }>();
  const [category, setCategory] = useState<string | undefined>(categoryParam);
  const [topics, setTopics] = useState<ArticleTopic[]>([]);
  const [articleId, setArticleId] = useState<ArticleTopic["articleId"] | undefined>("");

  const { data: article } = useArticleTopic(articleId || "", {
    enabled: Boolean(articleId),
  });

  useEffect(() => {
    const matched = articleStructure?.find((cat) => cat?.name === categoryParam);
    const category = matched || articleStructure?.[0];
    setCategory(category?.name);
    setTopics(category?.topics || []);
  }, [categoryParam, articleStructure, category]);

  useEffect(() => {
    setArticleId(topics?.[0]?.articleId);
  }, [topics]);

  const handleTabChange = (event: ChangeEvent<{}>, value: any) => {
    navigate("/help/" + value);
  };

  return (
    <Main
      title={
        <>
          Help Centre
          <UIButton
            variant="contained"
            color="primary"
            data-cy="contact-us-btn"
            startIcon={<PhoneIcon />}
            onClick={() => navigate("/contact-support")}
          >
            Contact Us
          </UIButton>
        </>
      }
      data-cy="support-help-page"
      featureFlag={featureFlagsMap.HELP}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
      isLoading={isFetching}
    >
      <Tabbed
        selectedTab={category || articleStructure?.[0]?.name || "Billing"}
        handleTabChange={handleTabChange}
        tabs={
          articleStructure?.map((tab: ArticleStructure) => ({
            value: tab.name,
            label: tab.name,
            testId: `help-tab-${upperCasetoCamelCase(tab.name)}`,
          })) || []
        }
      >
        <UIAlert severity="info" className={classes.info}>
          For answers to frequently asked questions, access to helpful guides and videos, please visit our Customer Support page.
        </UIAlert>
        <UIGrid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <UIGrid size={{ xs: 12, md: 3 }}>
            <UIGrid container justifyContent="center" alignItems="center">
              <UICard className={classes.topics} data-cy="topics-nav-card" elevation={1}>
                <UITypography variant="h4" className={classes.title}>
                  Topics
                </UITypography>
                {!topics?.length && (
                  <>
                    <UISkeleton className={classes.title} />
                    <UISkeleton className={classes.title} />
                    <UISkeleton className={classes.title} />
                  </>
                )}
                {topics?.map((t: ArticleTopic) => (
                  <HelpNavMenu
                    key={t.articleId}
                    topic={t}
                    selectedArticleId={articleId}
                    handleClick={(id) => {
                      setArticleId(id);
                      if (id !== t.articleId) scrollPage();
                    }}
                  />
                ))}
              </UICard>
            </UIGrid>
          </UIGrid>
          <UIGrid size={{ xs: 12, md: 6 }}>
            {Boolean(article) && (
              <UICard className={classes.article} elevation={1}>
                <UITypography variant="h3" gutterBottom data-cy="article-title">
                  {article?.title}
                </UITypography>
                <UITypography variant="body1" gutterBottom data-cy="article-body" component="div">
                  {article && <UIHtml html={article.body.replace(/\n/g, "").replace(/\r/g, "")} />}
                </UITypography>
                {Boolean(article?.button) && (
                  <UIButton
                    color="primary"
                    variant="contained"
                    data-cy="article-button"
                    href={article?.button.url || ""}
                    onClick={(ev) => onLinkClickHandler(ev, navigate)}
                    data-payload={article?.button.payload ? JSON.stringify(article?.button.payload) : undefined}
                  >
                    {article?.button.label}
                  </UIButton>
                )}
              </UICard>
            )}
          </UIGrid>
        </UIGrid>
      </Tabbed>
      <Outlet />
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  topics: {
    width: "100%",
    height: "auto",
    padding: "0px",
    minHeight: "calc(100vh - 320px)",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
    },
  },
  article: {
    width: "100%",
    padding: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
  info: {
    width: "fit-content",
    marginBottom: theme.spacing(3),
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      "& .MuiAlert-message": {
        fontSize: "0.7rem",
      },
    },
  },
}));

export default Help;
