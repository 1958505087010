import {
  activateSim,
  cancelAutoSwitch,
  getAllAssetsForAutoSwitchCancel,
  getAllSimForActivation,
  getAutoSwitchResults,
  getSimDetails,
  performSimSwap,
  requestAutoSwitch,
} from "./api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import {
  DisconnectedAsset,
  DispatchedAsset,
  GetAutoSwitchResultsResp,
  GetDisconnectionAssetsResponse,
  GetDispatchedAssetsResponse,
  RequestAutoSwitchParams,
  SimDetails,
  SimSwapOperation,
} from "./types";
import { useFeedbackAlerts } from "../_app/hooks";

export function useSimDetails(simNumber: string, network: string, options = {}) {
  return useQuery<SimDetails, AjaxResponse>(["simDetails", simNumber, network], () => getSimDetails(simNumber, network), {
    ...options,
  });
}

export function useRequestAutoSwitch(options = {}) {
  return useMutation<string, AjaxResponse, RequestAutoSwitchParams>(requestAutoSwitch, {
    ...options,
  });
}

export function useCancelAutoSwitch(options = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  const queryClient = useQueryClient();
  return useMutation<RequestAutoSwitchParams, AjaxResponse, any>((assetId: any) => cancelAutoSwitch(assetId), {
    onSuccess: (data, assetId) => {
      queryClient.setQueriesData(["disconnectSimNumbers"], (old: any) => {
        if (old?.list?.length) {
          old.list = old.list.map((item: DisconnectedAsset) => {
            return {
              ...item,
              requestStatus: item.id === assetId ? "PENDING" : item.requestStatus,
            };
          });
        }
        return old;
      });

      setFeedbackAlertSuccess("Your request to cancel the disconnection has been submitted");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useAutoSwitchResults(requestId: string, page: number, limit: number, options = {}) {
  return useQuery<GetAutoSwitchResultsResp, AjaxResponse>(
    ["autoSwitchResults", { requestId, page, limit }],
    () => getAutoSwitchResults(requestId, page, limit),
    { ...options },
  );
}

export function useAllSimForActivation(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetDispatchedAssetsResponse, AjaxResponse>(
    ["dispatchSimNumbers", { page, limit }],
    () => getAllSimForActivation(page, limit),
    {
      ...options,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
    },
  );
}

export function useAllAssetsForAutoSwitchCancel(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetDisconnectionAssetsResponse, AjaxResponse>(
    ["disconnectSimNumbers", { page, limit }],
    () => getAllAssetsForAutoSwitchCancel(page, limit),
    {
      ...options,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
    },
  );
}

export function useSimActivate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<any, AjaxResponse, any>((asset: DispatchedAsset) => activateSim(asset.id, asset.newSimNumber), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["dispatchSimNumbers"]);
      setFeedbackAlertSuccess("Activation request submitted.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useSimSwapUpdate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<any, AjaxResponse, SimSwapOperation[]>(performSimSwap, {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["assets"]);
      setFeedbackAlertSuccess("Your request has been submitted.");
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}
