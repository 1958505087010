import config from "../config";
import { getUrlHost } from "../utils";
import { CspDirective } from "../utils/types";
import { GTM_NONCE } from "./googletagmanager";

export function loadCspTag(addKey?: CspDirective, addVals?: string[]) {
  const includeSubDomains = (list: string[]) => {
    return [...list, ...list.map((domain) => "*." + domain)];
  };
  const whitelist = [
    getUrlHost(config.configEndpoints.baseEndpoint),
    getUrlHost(config.configLogicMonitor?.baseUrl),
    getUrlHost(config.configSentry?.dsn),
    ...includeSubDomains(config.configIntercom?.enabled ? ["intercomcdn.com", "intercom.io"] : []),
    ...includeSubDomains(
      config.configLiveChat?.script
        ? [
            "hubapi.com",
            "hubspot.com",
            "nr-data.net",
            "newrelic.com",
            "hs-scripts.com",
            "hs-analytics.net",
            "hs-banner.com",
            "hsleadflows.net",
            "hsadspixel.net",
            "hsappstatic.net",
            "usemessages.com",
          ]
        : [],
    ),
    ...includeSubDomains([
      "google.com",
      "googleapis.com",
      "googletagmanager.com",
      "google-analytics.com",
      "googleads.g.doubleclick.net",
      "gstatic.com",
      "hotjar.com",
      "hotjar.io",
      "masro.io",
      "dc.masro.io",
    ]),
  ];

  const cspPolicy: Partial<Record<CspDirective, string[]>> = {
    "default-src": ["'self'", "ws:", ...whitelist],
    "script-src": ["'self'", `'nonce-${GTM_NONCE}'`, ...whitelist],
    "style-src": ["'unsafe-inline'", ...whitelist],
    "img-src": ["*", "data:"],
  };

  if (addKey && addVals?.length) {
    cspPolicy[addKey]?.length ? cspPolicy[addKey]?.push(...addVals) : (cspPolicy[addKey] = addVals);
  }
  const cspVals = Object.values(cspPolicy)
    .filter(Boolean)
    .map((v) => v.join(" "));
  const content =
    Object.keys(cspPolicy)
      .map((key, i) => `${key} ${cspVals[i]}`)
      .join("; ") + ";";

  const meta = document.createElement("meta");
  meta.httpEquiv = "Content-Security-Policy";
  meta.content = content;
  document.getElementsByTagName("head")[0].appendChild(meta);
}
