import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import ManageBillingTabBar from "../../billing/components/ManageBillingTabBar";
import FiltersCard from "../../filter/components/FiltersCard";
import Main from "../../_app/layouts/Main";
import { useDeleteFilter, useFilterValues, useSaveFilters, useUserFilters } from "../../filter/hooks";
import { useGetServiceAnalysis, useGetServiceAnalysisColumns } from "../hooks";
import { Query, UsersFilter, UsersFilterValue } from "../../filter/types";
import { getServiceAnalysisFile } from "../api";
import { useFeedbackAlerts, useMemoizedCallback } from "../../_app/hooks";
import { parseColumns, pushValueToRow } from "../../usage-analysis/components/helpers";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { downloadFile, extractFilenameFromHeaders } from "../../_app/utils";
import { LoadMoreFooter } from "../../_app/components/Table/LoadMoreFooter";
import { useServiceAnalysisFilters } from "../../billing/hooks";
import { genericError } from "../../_app/utils/text";
import { rowParser, SortParam } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import { filtersIdMap } from "../../filter/utils";
import { UNBILLED } from "../../billing/utils";
import { ServiceAnalysisColumn } from "../types";
import { SERVICE_BILL_PERIODS_ENDPOINT } from "../../billing/filterConfig";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIBox, UIGrid, UITableSortLabel } from "../../_app/components";
import UIDataTable from "../../_app/components/Table/UIDataTable";

const USAGE: string = "SERVICE_ANALYSIS";
const COLUMNS: string = "SERVICES_COLUMNS";

export const ServiceAnalysis = () => {
  const classes = useStyles();
  const selectedTab = "service-analysis";
  const { search } = useLocation();
  const navigate = useNavigate();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const limit = 25;
  const [sort, setSort] = useState<SortParam[]>([{ col: "CLI", dir: "desc" }]);
  const [columnMissing, setColumnMissing] = useState<boolean>(true);
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const hasPermissionToDownload = Boolean(useHasPermission(permissionCodes.BILLING_DOWNLOAD));

  const { data: periodFilterValues, isFetching: isFilterValuesFetching } = useFilterValues(SERVICE_BILL_PERIODS_ENDPOINT);

  const getParams = useCallback(() => {
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      if (key === filtersIdMap.SERVICES_BILL_PERIOD && value === UNBILLED) {
        periodFilterValues?.pages[0]?.forEach((period: string) => {
          if (period.includes("UNBILLED")) {
            filters.push({
              id: key,
              value: period,
            });
          }
        });
      } else {
        values.forEach((query) => {
          filters.push({
            id: key,
            value: query,
          });
        });
      }
    });
    return filters;
  }, [queryParams, periodFilterValues]);

  // Always apply unbilled filter
  useEffect(() => {
    const params = getParams();
    const periodParams = params?.filter((p) => p?.id === filtersIdMap?.SERVICES_BILL_PERIOD);

    if (queryParams.get(filtersIdMap.SERVICES_BILL_PERIOD) === UNBILLED) queryParams.delete(filtersIdMap.SERVICES_BILL_PERIOD);

    if (!isFilterValuesFetching) {
      const values = periodFilterValues?.pages[0] ?? [];
      if (!periodParams.length && values.length) {
        const periods = values.some((item) => item.includes("UNBILLED"))
          ? values.filter((period) => period.includes("UNBILLED"))
          : [values[0]];
        queryParams.append(filtersIdMap?.SERVICES_BILL_PERIOD, periods.join(","));
      }
    }

    const removeParams: string[] = [];
    queryParams.forEach((value, key) => {
      if (value === "" || value === undefined || value === null) {
        removeParams.push(key);
      }
    });
    removeParams.forEach((key) => {
      queryParams.delete(key);
    });

    if (search.replace("?", "") !== queryParams.toString()) {
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [getParams, navigate, search, periodFilterValues, queryParams, isFilterValuesFetching]);

  const handleDownloadClick = async () => {
    const params = getParams();
    try {
      const response = await getServiceAnalysisFile(params);
      const filename = extractFilenameFromHeaders(response) || "service-analysis-export.csv";
      downloadFile(response?.data, filename);
    } catch (err) {
      setFeedbackAlertError((err as AxiosResponse).data?.message || genericError());
    }
  };

  const { data: tableAllColumns } = useGetServiceAnalysisColumns();
  const [columns, setColumns] = useState<any>([]);
  const [fetchColumns, setFetchColumns] = useState<string[]>([]);

  const customHeader = useCallback(
    (label: string, name: string, sortable: boolean, currentSortCol: string = "CLI") => {
      if (currentSortCol !== name && sortable)
        return (
          <UIBox display="flex">
            {label}
            <span className={classes.labelWrap}>
              <UITableSortLabel active direction="desc" className={classes.inactiveLabel} />
            </span>
          </UIBox>
        );
      else return <>{label}</>;
    },
    [classes.labelWrap, classes.inactiveLabel],
  );

  useEffect(() => {
    const currentColumnsStorage = localStorage.getItem(COLUMNS);
    if (currentColumnsStorage === null) {
      const columns = parseColumns(tableAllColumns, undefined, customHeader);
      if (tableAllColumns && tableAllColumns.length !== 0) {
        setColumns(columns);
        setFetchColumns(columns.map((c: any) => c.code));
        localStorage.setItem(COLUMNS, JSON.stringify(columns));
      }
    } else {
      const storedColumns = JSON.parse(currentColumnsStorage ?? "")
        ?.filter?.((c: any) => c?.code)
        .map((c: any) => {
          const alignRight = c.type !== "TEXT" && c.type !== "DATE" && c.type !== "TIME";
          return {
            ...c,
            options: {
              ...c.options,
              customHeadLabelRender: (p: any) => customHeader(p.label, p.name, p.sort, sort[0].col ?? ""),
              setCellHeaderProps: () => alignRight && { align: "right" },
              setCellProps: () => alignRight && { align: "right" },
            },
          };
        });
      setColumns(storedColumns);
      setFetchColumns(storedColumns.filter((c: any) => c.options?.display).map((c: any) => c.code));
    }
  }, [sort, customHeader, tableAllColumns]);

  const hasPeriodSet = !!getParams().find((param) => param.id === filtersIdMap.SERVICES_BILL_PERIOD);

  const {
    data: serviceAnalysisResponse,
    isLoading: isAnalysisLoading,
    isFetching: isAnalysisFetching,
    isError,
    fetchNextPage: fetchNextAnalysisPage,
    hasNextPage: hasNextAnalysisPage,
  } = useGetServiceAnalysis(limit, sort, getParams(), fetchColumns, {
    enabled: Boolean(fetchColumns.length && getParams().length && columnMissing && hasPeriodSet),
  });

  const handleColumnsChange = (colCode: string) => {
    const currentColumns = JSON.parse(localStorage.getItem(COLUMNS) || "")?.filter?.((c: any) => c?.code);

    const selectedColumns = currentColumns.map((col: any) => {
      const alignRight = col.type !== "TEXT" && col.type !== "DATE" && col.type !== "TIME";
      if (col.code === colCode) {
        col.options.display = !col.options.display;
        const missing = fetchColumns.indexOf(col.code) === -1;
        setColumnMissing(missing);
        if (missing) {
          setFetchColumns(currentColumns.filter((c: any) => c.options?.display).map((c: any) => c.code));
        }
        return {
          ...col,
          options: {
            ...col.options,
            customHeadLabelRender: (p: any) => customHeader(p.label, p.name, p.sort, sort[0].col ?? ""),
            setCellHeaderProps: () => alignRight && { align: "right" },
            setCellProps: () => alignRight && { align: "right" },
          },
        };
      } else {
        return {
          ...col,
          options: {
            ...col.options,
            customHeadLabelRender: (p: any) => customHeader(p.label, p.name, p.sort, sort[0].col ?? ""),
            setCellHeaderProps: () => alignRight && { align: "right" },
            setCellProps: () => alignRight && { align: "right" },
          },
        };
      }
    });
    setColumns(selectedColumns);
    localStorage.setItem(COLUMNS, JSON.stringify(selectedColumns));
  };

  const applyUsersFilter = useMemoizedCallback(
    (usersFilter: UsersFilter) => {
      const params: string[] = [];
      queryParams.forEach((value, key) => {
        params.push(key);
      });
      params.forEach((key) => {
        queryParams.delete(key);
      });

      usersFilter?.filters?.forEach((filter) => {
        let value = "";
        filter?.values?.forEach((v) => {
          value = value === "" ? v : value + "," + v;
        });
        queryParams.append(filter?.filterId, value);
      });
      navigate({ search: queryParams.toString() }, { replace: true });
    },
    [history, queryParams],
  );

  let data: any = [];
  if (serviceAnalysisResponse?.pages) {
    const pages =
      serviceAnalysisResponse?.pages?.map((page: any) => {
        return page?.list?.map((item: any) => {
          const row = [] as any;
          columns?.forEach((column: ServiceAnalysisColumn) => {
            pushValueToRow(row, item, column, applyUsersFilter);
          });
          return rowParser(row);
        });
      }) || [];

    data = [].concat.apply([], pages);
  }

  const { mutate: executeCreateFilter, reset: resetCreateOperation } = useSaveFilters(USAGE);
  const { mutate: executeDeleteFilter, reset: resetDeleteOperation } = useDeleteFilter(USAGE);

  const saveFilters = (name: string) => {
    let filters: UsersFilter = { name: name, filters: [] };
    queryParams.forEach((value, key) => {
      const filter: UsersFilterValue = {
        filterId: key,
        values: value.split(","),
      };
      filters.filters.push(filter);
    });
    resetDeleteOperation();
    executeCreateFilter(filters);
  };

  const deleteFilter = (usersFilter: UsersFilter) => {
    resetCreateOperation();
    executeDeleteFilter(usersFilter?.id);
  };

  const renderNoMatch = () => {
    if (isError) {
      return (
        <UIAlert severity="error" className={classes.error}>
          Data failed to load.
        </UIAlert>
      );
    } else if (isAnalysisLoading || isAnalysisFetching) {
      return (
        <div className={classes.loader}>
          <UILoader />
        </div>
      );
    } else return "Sorry, no matching records found";
  };

  return (
    <Main
      title="Bill Explorer"
      data-cy="service-analysis-page"
      accessPermission={permissionCodes.BILLING_MANAGE}
      needSelectedAccount={true}
    >
      <ManageBillingTabBar selectedTab={selectedTab}>
        <UIGrid>
          <FiltersCard
            title=""
            fetchHook={useServiceAnalysisFilters}
            myFilters={{
              fetchHook: useUserFilters,
              deleteHook: deleteFilter,
              saveHook: saveFilters,
              addHook: applyUsersFilter,
            }}
            usage={USAGE}
            downloadFileType="CSV"
            handleDownloadClick={handleDownloadClick}
            hasDownload={hasPermissionToDownload}
          />
        </UIGrid>
        <div className={classes.tableCtr}>
          <UIDataTable
            key="services"
            title="Services"
            data={data}
            columns={columns || []}
            options={{
              serverSide: true,
              pagination: false,
              onColumnViewChange: handleColumnsChange,
              onColumnSortChange: (col, dir) => setSort([{ col, dir }]),
              customFooter: () => {
                return (
                  <LoadMoreFooter
                    hasMore={hasNextAnalysisPage}
                    disabled={isAnalysisFetching}
                    onChangePage={fetchNextAnalysisPage}
                  />
                );
              },
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              sort: true,
              sortOrder: {
                name: sort[0].col ?? "",
                direction: sort[0].dir ?? "desc",
              },
              search: false,
              selectableRows: "none",
              rowHover: true,
              setTableProps: () => ({
                size: "small",
              }),
              viewColumns: true,
              textLabels: {
                body: {
                  noMatch: renderNoMatch(),
                },
              },
              tableBodyHeight: "50vh",
            }}
          />
        </div>
      </ManageBillingTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    margin: "40px 0",
  },
  error: {
    width: "100%",
    height: "48px",
    margin: "40px 0",
    justifyContent: "center",
  },
  tableCtr: {
    "& .MuiTableSortLabel-icon": {
      "&:not(.inactiveLabel)": {
        color: theme.palette.primary.main,
      },
    },
    '& button[class*="MUIDataTableHeadCell-toolButton"]': {
      marginRight: 0,
    },
  },
  labelWrap: {
    marginBottom: "2px",
  },
  inactiveLabel: {
    "& .MuiTableSortLabel-icon": {
      color: `${theme.palette.grey[200]} !important`,
    },
  },
}));

export default ServiceAnalysis;
