import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { deleteFilter, getAllFilters, getFilterValues, getUsersFilters, saveFilters } from "./api";
import { useCallback } from "react";
import { useFeedbackAlerts, useQueryParams } from "../_app/hooks";
import { Filter, FilterValues, Query, UsersFilter } from "./types";
import { getCachedContextHierarchy } from "../context/utils";

export function useAllFilters(usage: string = "", options = {}) {
  return useQuery<Filter[], AjaxResponse>(["filters", usage], () => getAllFilters(usage), { ...options });
}

export function useFilterValues(resource: string | undefined, search?: string, options = {}) {
  const accountId = getCachedContextHierarchy()?.id;
  return useInfiniteQuery<FilterValues, AjaxResponse>(
    ["filter", { resource, search, accountId }],
    ({ pageParam = 0 }) => getFilterValues(resource, pageParam, search),
    { ...options },
  );
}

export function useUserFilters(usage: string, options = {}) {
  return useQuery<UsersFilter, AjaxResponse>(["usersFilters", usage], () => getUsersFilters(usage), { ...options });
}

export function useSaveFilters(usage: string, options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<UsersFilter, AjaxResponse, any>((filters: UsersFilter) => saveFilters(usage, filters), {
    onSuccess: () => {
      queryClient.invalidateQueries(["usersFilters", usage]);
      setFeedbackAlertSuccess("Filter saved.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || "Failed to save filter.");
    },
    ...options,
  });
}

export function useDeleteFilter(usage: string, options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<string, AjaxResponse, any>((filterId: string) => deleteFilter(usage, filterId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["usersFilters", usage]);
      setFeedbackAlertSuccess("Filter deleted.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || "Failed to delete filter.");
    },
    ...options,
  });
}

const dateRangeFilter = [
  {
    id: "4",
    name: "Date Range",
    options: {},
    resources: null,
    showToggle: true,
    type: "DATE_RANGE",
    values: [],
  },
];

export function useStatementsFilters(options = {}) {
  return useQuery(["statements-filters"], () => dateRangeFilter, { ...options });
}

export function useTriggeredAlertsFilters(options = {}) {
  return useQuery(["triggered-alerts-filters"], () => dateRangeFilter, { ...options });
}

export function useBundleAlertsFilters(options = {}) {
  return useQuery(
    ["bundle-alerts-filters"],
    () => [
      {
        id: "62",
        name: "CLI/Identifier",
        type: "IDENTIFIER",
        resources: "/filters/ALERTS/values/clis",
        showToggle: true,
        values: [],
        options: { SEARCH_BAR: true, BULK_INPUT: true },
      },
    ],
    { ...options },
  );
}

export function useFilterQueries() {
  const queryParams = useQueryParams();
  const getQueries = useCallback(() => {
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      values.forEach((query) => {
        filters.push({
          id: key,
          value: query,
        });
      });
    });
    return filters;
  }, [queryParams]);
  return getQueries();
}
