import { ChangeEvent, FocusEvent, forwardRef, Ref, useMemo } from "react";
import { IMaskInput } from "react-imask";
import { Check as CheckIcon, Error as ErrorIcon } from "../../_app/components/icons";
import { SimInfo } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UILoader, UITextField } from "../../_app/components";

interface MaskedInputProps {
  value: string;
  onChange: (event: { target: { value: string } }) => void;
  mask: string;
}

interface SimNumberInputProps {
  simInfo: SimInfo;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => Promise<void>;
}

const MaskedInput = forwardRef<HTMLElement, MaskedInputProps>(({ mask, value, onChange, ...props }, ref) => {
  return (
    <IMaskInput
      {...props}
      mask={mask}
      value={value}
      onAccept={(value: string) =>
        onChange({
          target: {
            value: value,
          },
        })
      }
      lazy={false}
      inputRef={ref as Ref<HTMLInputElement>}
    />
  );
});

const SimNumberInput = ({ onBlur, simInfo, onChange }: SimNumberInputProps) => {
  const classes = useStyles();

  const mask = useMemo(() => {
    const escapedPrefix = simInfo?.simPrefix?.replace("0", "\\0");
    return escapedPrefix + "0".repeat((simInfo?.simLength ?? 0) - (simInfo?.simPrefix?.length ?? 0));
  }, [simInfo]);

  return (
    <UITextField
      value={simInfo.simNumberNew}
      onBlur={onBlur}
      onChange={onChange}
      className={classes.newSimNumber}
      variant="outlined"
      name={`simPrefix_${simInfo?.cli}`}
      id={`simPrefix_${simInfo?.cli}`}
      size="small"
      helperText={simInfo?.simNumberNewValidation?.error}
      error={Boolean(simInfo?.simNumberNewValidation?.error)}
      data-cy="new-sim-number"
      InputProps={{
        inputComponent: MaskedInput as any,
        inputProps: { mask: mask },
        endAdornment: (() => {
          if (simInfo?.simNumberNewValidation?.isValidating) return <UILoader size="1.25rem" />;
          if (typeof simInfo?.simNumberNewValidation?.isValid !== "boolean") return null;
          if (simInfo?.simNumberNewValidation?.isValid) {
            return <CheckIcon data-cy="check-icon" fontSize="small" color="success" />;
          } else {
            return <ErrorIcon data-cy="error-icon" fontSize="small" color="error" />;
          }
        })(),
      }}
    />
  );
};

const useStyles = createStylesheet((theme) => ({
  newSimNumber: {
    minWidth: "225px",
    textUnderlineOffset: "10px",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: theme.spacing(1),
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "5px",
    },
  },
}));

export default SimNumberInput;
