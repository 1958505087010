import { useState } from "react";
import Main from "../../_app/layouts/Main";
import ManageStatementTabBar from "../components/ManageStatementTabBar";
import { permissionCodes } from "../../permission/hooks";
import { CloudDownload as CloudDownloadIcon, DateRange as DateRangeIcon } from "../../_app/components/icons";
import { getInvoiceFile } from "../api";
import { downloadFile } from "../../_app/utils";
import { useHasFeatureFlag } from "../../feature/hooks";
import { getInvoicesLabel } from "../utils";
import { featureFlagsMap } from "../../feature/utils";
import { createStylesheet } from "../../_app/utils/styles";
import {
  AdapterDateFns,
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
  UIButton,
  UIInputAdornment,
  UITextField,
} from "../../_app/components";

export const Invoices = () => {
  const classes = useStyles();
  const selectedTab = "invoices";
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const hasStatementsFeature = useHasFeatureFlag(featureFlagsMap.STATEMENTS);

  const sharedProps: DatePickerProps<any, any> = {
    renderInput: (props: any) => <UITextField {...props} />,
    value: null,
    onChange: () => null,
    inputFormat: "dd/MM/yyyy",
    autoFocus: true,
    className: classes.input,
    InputProps: {
      endAdornment: (
        <UIInputAdornment position="end">
          <DateRangeIcon />
        </UIInputAdornment>
      ),
    },
  };

  return (
    <Main
      title={getInvoicesLabel(true, hasStatementsFeature)}
      data-cy="invoices-page"
      featureFlag={featureFlagsMap.INVOICES}
      accessPermission={permissionCodes.BILLING_STATEMENT}
      needSelectedAccount={true}
    >
      <ManageStatementTabBar selectedTab={selectedTab}>
        <div className={classes.ctr}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...sharedProps}
              label="From Date"
              value={fromDate}
              onChange={(date) => setFromDate(date)}
              maxDate={toDate}
            />
            <DatePicker {...sharedProps} label="To Date" value={toDate} onChange={(date) => setToDate(date)} minDate={fromDate} />
          </LocalizationProvider>
          <div className={classes.break}>
            <UIButton
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={() => {
                if (!fromDate && !toDate) return;
                getInvoiceFile({
                  fromDate,
                  toDate,
                }).then((data) => downloadFile(data, "invoice", "pdf"));
              }}
            >
              Download
            </UIButton>
          </div>
        </div>
      </ManageStatementTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    gap: "20px",
    padding: theme.spacing(1),
    flexWrap: "wrap",
  },
  input: {
    minWidth: "125px",
    maxWidth: "200px",
    marginBottom: theme.spacing(1),
  },
  break: {
    flexBasis: "100%",
    height: "0",
  },
}));

export default Invoices;
