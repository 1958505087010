import { capitalize, formatDate, formatNumber } from "../_app/utils/format";
import { Price } from "./types";

export const PERIOD_LENGTH = 3;
export const BILLED = "billed";
export const UNBILLED = "unbilled";
export const ALL = "All";

export const getStatementsAndInvoicesLabel = (statements: boolean = false, invoices: boolean = false): string => {
  if (statements && invoices) return "Statements & Invoices";
  if (statements) return "Statements";
  if (invoices) return "Batch Invoice";
  return "";
};

export const getInvoicesLabel = (invoices: boolean = false, statements: boolean = false): string => {
  if (statements && invoices) return "Invoices";
  if (invoices) return "Batch Invoice";
  return "";
};

export const mapInvoiceNumber = (systemInvoiceRef: string) => {
  let invoiceNumbers: any = localStorage.getItem("INVOICE_NUMBERS");
  if (invoiceNumbers) {
    invoiceNumbers = JSON.parse(invoiceNumbers);
    return invoiceNumbers[systemInvoiceRef] ?? systemInvoiceRef;
  }
  return systemInvoiceRef;
};

export const formatPrice = (price: Price | number | undefined = 0, currency = "£") => {
  if (typeof price === "number") {
    return formatNumber(price, 2, currency);
  }
  return formatNumber(price?.net, 2, price?.unit);
};

export const formatPeriodToMonthYear = (period: string | undefined) => {
  try {
    if (period === UNBILLED) return "Unbilled";
    if (period?.includes("UNBILLED")) return `Unbilled (${formatDate(period.slice(9), "MMMM yyyy")})`;
    return period ? formatDate(period, "MMMM yyyy") : period;
  } catch (error) {
    return period;
  }
};

export const formatPackageName = (name: string) =>
  name.replace(/([a-z])([A-Z])|\b(\w)/g, (_: any, p1: string, p2: string, p3: string) => (p1 ? `${p1} ${p2}` : p3.toUpperCase()));

export const formatDownloadFileType = (label: string) => {
  switch (label) {
    case "USAGE_RECORDS":
      return "Usage Records";
    case "SERVICE_RECORDS":
      return "Service Records";
    default:
      return capitalize(label);
  }
};
