import { type JSX, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloudDownload as CloudDownloadIcon, Visibility as VisibilityIcon } from "../../_app/components/icons";
import { formatDate, formatNumber } from "../../_app/utils/format";
import { downloadFile, extractFilenameFromHeaders, TwoWayMap } from "../../_app/utils";
import { useFeedbackAlerts } from "../../_app/hooks";
import { UIAlert, UIButton, UIIconButton, UITooltip, UITypography } from "../../_app/components";
import UILoader from "../../_app/components/UILoader";
import OptionsButton from "../../form/components/OptionsButton";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { getBillFile } from "../api";
import { formatDownloadFileType, formatPeriodToMonthYear } from "../utils";
import { filtersIdMap } from "../../filter/utils";
import { BillDetail } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import UIDataTable from "../../_app/components/Table/UIDataTable";

interface Props {
  period?: string;
  billDetails: BillDetail[] | undefined;
  isFetching: boolean;
  isSuccess: boolean;
  isHeadAccount: boolean;
}

const billFileMap = new TwoWayMap({
  CDR: "Usage Details",
  SDR: "Service Details",
});

export const BillPeriodSummary = ({ period = "", billDetails, isFetching, isSuccess, isHeadAccount }: Props) => {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const classes = useStyles();
  const navigate = useNavigate();
  const hasPermission = useHasPermission(permissionCodes.BILLING_DOWNLOAD);
  const [isDownloading, setIsDownloading] = useState<{
    [type: string]: boolean;
  }>({});

  const memoizedHandleDownload = useCallback(
    async (type: string, systemInvoiceRef: string) => {
      if (!isDownloading[type]) {
        setIsDownloading({ [type]: true });
        const selectedType = billFileMap.revGet(type) || type?.toUpperCase();
        return getBillFile(systemInvoiceRef, selectedType)
          .then((response: any) => {
            const filename = extractFilenameFromHeaders(response) || `bill-analysis-export.${selectedType}`;
            downloadFile(response?.data, filename);
          })
          .catch((err) => {
            setFeedbackAlertError(
              err?.status === 404 ? "There is no usage to download" : err.data.message || "Unable to download file",
            );
          })
          .finally(() => setIsDownloading({ [type]: false }));
      }
    },
    [isDownloading, setFeedbackAlertError],
  );

  const title = (
    <UITypography variant="h1" className={classes.title} data-cy="bill-period" key="bill-period" paragraph>
      {formatPeriodToMonthYear(period.replace("-", ""))?.toLowerCase()}
    </UITypography>
  );

  const getOptions = useCallback(
    (systemInvoiceRef: string, index: number) => {
      const buttons: JSX.Element[] = [];
      billDetails?.[index]?.billOutputTypes?.map((type) => {
        buttons.push(
          <UIButton
            variant="text"
            color="inherit"
            onClick={async () => await memoizedHandleDownload(type, systemInvoiceRef)}
            startIcon={isDownloading[type] && <UILoader color="inherit" size={18} />}
            disabled={isDownloading[type]}
          >
            {`Download ${formatDownloadFileType(type)}`}
          </UIButton>,
        );
        return type;
      });
      return buttons;
    },
    [billDetails, memoizedHandleDownload, isDownloading],
  );

  const isBigScreen = window.innerWidth > 600;

  const columns = useMemo(() => {
    let columns;
    if (isBigScreen) {
      if (isHeadAccount) columns = ["Invoice No.", "Invoice Date", "Net", "VAT", "Total"];
      else columns = ["Net"];
    } else {
      if (isHeadAccount) columns = ["Invoice Date", "Total"];
      else columns = ["Net"];
    }
    if (isHeadAccount) columns.push("Actions");
    return columns;
  }, [isBigScreen, isHeadAccount]);

  const renderRow = useCallback(
    (values: string[], isCancelled: boolean) => {
      return values.map((value: string) => {
        if (isCancelled) {
          return (
            <UITooltip title="Invoice has been cancelled.">
              <div className={classes.cancelledRow}>{value}</div>
            </UITooltip>
          );
        } else return value;
      });
    },
    [classes.cancelledRow],
  );

  const tableData = useMemo(() => {
    if (billDetails) {
      return billDetails.map((billDetail: BillDetail, index: number) => {
        let tableData: (string | JSX.Element)[];
        const invoiceNumber = billDetail?.invoiceNumber ?? <></>;
        const date = formatDate(billDetail?.billDate, "dd MMM yyyy") ?? <></>;
        const netPrice = formatNumber(billDetail?.net, 2, "£") ?? <></>;
        const vat = formatNumber(billDetail?.vat, 2, "£") ?? <></>;
        const totalPrice = formatNumber(billDetail?.gross, 2, "£") ?? <></>;

        if (isBigScreen) {
          if (isHeadAccount) tableData = renderRow([invoiceNumber, date, netPrice, vat, totalPrice], billDetail.cancelled);
          else tableData = tableData = renderRow([netPrice], billDetail.cancelled);
        } else {
          if (isHeadAccount) tableData = renderRow([date, totalPrice], billDetail.cancelled);
          else tableData = renderRow([netPrice], billDetail.cancelled);
        }
        if (isHeadAccount)
          tableData.push(
            <>
              <UIButton
                isIconButton
                data-cy="view-period-details-button"
                color="primary"
                className={classes.viewButton}
                onClick={() => {
                  const period = billDetail.period;
                  navigate(
                    `/bills/period-details/${period}/${billDetail.systemInvoiceRef}?${filtersIdMap?.BILL_PERIOD}=${period}`,
                  );
                }}
              >
                <VisibilityIcon />
              </UIButton>
              {hasPermission ? (
                <OptionsButton
                  renderButton={({ ref, innerRef, ...props }) => (
                    <UIIconButton color="primary" ref={innerRef} {...props}>
                      <CloudDownloadIcon />
                    </UIIconButton>
                  )}
                  options={getOptions(billDetail.systemInvoiceRef, index)}
                  variant="outlined"
                />
              ) : null}
            </>,
          );
        return tableData;
      });
    } else return [];
  }, [navigate, getOptions, billDetails, classes.viewButton, hasPermission, isBigScreen, isHeadAccount, renderRow]);

  if (!period || !billDetails?.length) {
    return (
      <>
        {title}
        <UIAlert className={classes.error} severity="info">
          There are no bills to view, currently.
        </UIAlert>
      </>
    );
  }

  return (
    <>
      {title}
      {isFetching && (
        <div className={classes.progress}>
          <UILoader />
        </div>
      )}
      {!isFetching && isSuccess && (
        <>
          <div className={classes.content}>
            <UIDataTable
              title=""
              data={tableData}
              columns={columns}
              options={{
                elevation: 1,
                download: false,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                viewColumns: false,
                sort: false,
                search: false,
                serverSide: true,
                selectableRowsHeader: false,
                selectableRows: "none",
                setTableProps: () => ({ size: "small" }),
                setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                customFooter: () => null,
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  title: {
    fontWeight: 600,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  cancelledRow: {
    textDecoration: "line-through",
    color: theme.palette.grey[300],
  },
  progress: {
    margin: "auto",
    padding: "50px 0",
    height: "200px",
  },
  error: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  viewButton: {
    minWidth: "fit-content",
  },
  showMoreButton: {
    width: "100%",
  },
  content: {
    paddingBottom: "50px",
    "& .MuiTableCell-root": {
      borderTop: "none",
      whiteSpace: "nowrap",
      "&:last-child": {
        width: "122px",
      },
      [theme.breakpoints.down("md")]: {
        "&:nth-last-of-type(2)": {
          width: "90px",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "&:not(:last-child)": {
          minWidth: "100px",
        },
      },
    },
  },
}));
