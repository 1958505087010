import Tabbed from "../../../_app/layouts/Tabbed";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIAlert } from "../../../_app/components";

interface Props {
  selectedTab: string;
  children: any;
}

const BillLimitAlertsTabBar = ({ selectedTab, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <UIAlert severity="info" className={classes.info}>
        If you have set a bill limit for any of your mobile numbers, we will send notifications to the number when you have nearly
        reached, or have reached your bill limit. We can alert an additional mobile number or email address here.
      </UIAlert>
      <Tabbed
        selectedTab={selectedTab}
        tabs={[
          {
            value: "bill-limit-alerts-cli",
            label: "CLI",
            hidden: false,
          },
          {
            value: "bill-limit-alerts-account",
            label: "Default for Account",
            hidden: false,
          },
        ]}
      >
        {children}
      </Tabbed>
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  wrap: {
    flexDirection: "column",
    width: "100%",
  },
  info: {
    alignItems: "center",
    marginBottom: theme.spacing(1),
    width: "fit-content",
  },
}));

export default BillLimitAlertsTabBar;
