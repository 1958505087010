import { ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createStylesheet, lighten } from "../utils/styles";
import { UIGrid, UITab, UITabContext, UITabList } from "../components";

interface ITab {
  value: string;
  label?: string;
  hidden?: boolean;
  testId?: string;
}

interface Props {
  selectedTab: string;
  tabs: ITab[];
  persistParams?: boolean;
  handleTabChange?: ((event: ChangeEvent<{}>, value: any) => void) | undefined;
  children?: any;
}

function Tabbed({ selectedTab, tabs = [], persistParams, handleTabChange, children }: Props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const onTabChange = (event: ChangeEvent<{}>, value: any) => {
    navigate({
      ...(persistParams ? location : {}),
      pathname: "/" + value,
    });
  };
  const parsedTabs = tabs.filter((t) => !t.hidden);
  return (
    <UITabContext value={selectedTab}>
      <UIGrid size={{ md: 6 }} className={classes.ctr}>
        {parsedTabs.length > 1 && (
          <UITabList onChange={handleTabChange || onTabChange} indicatorColor="primary" textColor="primary" variant="scrollable">
            {parsedTabs.map((tab) => (
              <UITab
                key={tab.value || tab.label}
                value={tab.value}
                label={tab.label || tab.value}
                data-cy={tab.testId || `${tab.value}-tab`}
                className={classes.tab}
              />
            ))}
          </UITabList>
        )}
      </UIGrid>
      {Boolean(children) && (
        <UIGrid size={{ xs: 12, md: 12 }} className={classes.content}>
          {children}
        </UIGrid>
      )}
    </UITabContext>
  );
}

const useStyles = createStylesheet((theme) => ({
  ctr: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  tab: {
    backgroundColor: "#fff",
    minWidth: 200,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.98),
      borderBottom: "2px solid " + lighten(theme.palette.primary.main, 0.35),
    },
  },
  link: {
    textDecoration: "none",
  },
  content: {
    padding: "0px !important",
  },
}));

export default Tabbed;
