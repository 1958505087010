import { ReactNode, useEffect } from "react";
import UILoader from "../components/UILoader";
import { createStylesheet, CssBaseline } from "../utils/styles";

interface SecondaryProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  title?: string;
  isLoading?: boolean;
  className?: string;
}

function Public({ title, isLoading, children, className, ...rest }: SecondaryProps) {
  const classes = useStyles();

  useEffect(() => {
    document.title = title || "";
  }, [title]);

  return (
    <div className={classes.root} {...rest}>
      <CssBaseline />
      <div className={`${classes.main} ${className}`}>
        {isLoading ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  root: {
    minHeight: "100vh",
    width: "100vw",
    backgroundColor: "#ffffff",
  },
  main: {
    minWidth: "100%",
    minHeight: "100vh",
  },
  error: {
    padding: theme.spacing(2),
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flex: 1,
  },
}));

export default Public;
