import { ReactNode } from "react";
import Tabbed from "../../_app/layouts/Tabbed";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert } from "../../_app/components";

interface Props {
  selectedTab: string;
  children: ReactNode;
}

function MobileManagementTabBar({ selectedTab, children }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.ctr}>
      <UIAlert severity="info" className={classes.info}>
        Take control of your mobiles by managing your bars, SIMs and more.
      </UIAlert>
      <Tabbed
        selectedTab={selectedTab}
        persistParams
        tabs={[
          {
            value: "mobile-management",
            label: "Management",
            hidden: false,
          },
          {
            value: "mobile-management-history",
            label: "History",
            hidden: false,
          },
        ]}
      >
        {children}
      </Tabbed>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  ctr: {
    width: "100%",
  },
  info: {
    width: "fit-content",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
}));

export default MobileManagementTabBar;
