import { ReactNode } from "react";
import { ExpandMore as ExpandMoreIcon } from "../components/icons";
import { createStylesheet } from "../utils/styles";
import { UIAccordion, UIAccordionDetails, UIAccordionSummary, UITypography } from ".";

interface Props {
  containerProps?: any;
  headerProps?: any;
  headerLeftProps?: any;
  headerRightProps?: any;
  headerLeft: string | ReactNode;
  headerRight: string | ReactNode;
  bodyProps?: any;
  bodyContent?: string | ReactNode;
  onClick?: Function | void | undefined;
}

function UIStyledAccordion({
  containerProps = {},
  headerProps = {},
  headerLeftProps = {},
  headerRightProps = {},
  headerLeft,
  headerRight,
  bodyProps = {},
  bodyContent,
  onClick,
}: Props) {
  const classes = useStyles();

  return (
    <UIAccordion {...containerProps} className={`${classes.ctr} ${containerProps?.className}`} onChange={onClick}>
      <UIAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...headerProps}
        className={`${classes.header} ${headerProps?.className}`}
      >
        <UITypography component="span" variant="h4" {...headerLeftProps}>
          {headerLeft}
        </UITypography>
        <UITypography component="span" {...headerRightProps}>
          {headerRight}
        </UITypography>
      </UIAccordionSummary>
      <UIAccordionDetails {...bodyProps}>
        <UITypography component="span">{bodyContent}</UITypography>
      </UIAccordionDetails>
    </UIAccordion>
  );
}

const useStyles = createStylesheet((theme) => ({
  ctr: {
    borderRadius: "5px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderLeft: "3px solid " + theme.palette.primary.main,
    "&::before": {
      opacity: "0",
    },
  },
  header: {
    "& > :first-of-type": {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));

export default UIStyledAccordion;
