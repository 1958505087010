import { isEmpty } from "../../utils";
import NA from "../NA";

export interface SortParam {
  col: string | null;
  dir: "asc" | "desc" | null;
}

export const SORT_REQUEST_PARAMETER_NAME = "sort";

export interface SortRequestParam {
  column: string;
  direction: "asc" | "desc";
}

export const rowParser = (row: any, ignoreIndecies: number[] = []) => {
  return row.map((val: any, i: number) => {
    if (ignoreIndecies.includes(i)) return val;
    if (isEmpty(val)) return <NA />;
    return val;
  });
};
