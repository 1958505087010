import { useEffect } from "react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import config from "../config";

Sentry.init({
  dsn: config.configSentry?.dsn,
  environment: process.env.REACT_APP_STAGE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.1,
  ignoreErrors: ["Non-Error promise rejection captured with value: undefined"],
});
