import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Circle as CircleIcon, FilterBAndW as FilterBAndWIcon, Search as SearchIcon } from "../../../_app/components/icons";
import NavWidget from "./NavWidget";
import { useAssetSearch } from "../../../asset/hooks";
import { SearchAsset } from "../../../asset/types";
import { useDebouncedValue } from "../../../_app/hooks";
import { highlightMatch } from "../../utils";
import {
  UIAutocomplete,
  UIBox,
  UIInputAdornment,
  UIListItem,
  UIListItemText,
  UILoader,
  UITextField,
  UITooltip,
  UITypography,
} from "../../../_app/components";
import { createStylesheet } from "../../../_app/utils/styles";

const AssetsSearch: FC = ({ ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebouncedValue(search, 400);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useAssetSearch(debouncedSearch, {
    enabled: debouncedSearch !== "",
  });

  const listboxRef = useRef<HTMLDivElement>(null);

  const options: SearchAsset[] = useMemo(() => data?.pages.flatMap((page) => page.content) || [], [data]);
  const showNoOptionsText = useMemo(
    () => !isLoading && options.length === 0 && debouncedSearch !== "",
    [isLoading, options, debouncedSearch],
  );

  const handleScroll = useCallback(
    (event: Event) => {
      const listboxNode = event.currentTarget as HTMLElement;
      const hitOffset = 50;

      if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - hitOffset) {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage],
  );

  useEffect(() => {
    const listboxNode = listboxRef.current;
    if (listboxNode) {
      listboxNode.addEventListener("scroll", handleScroll);
      return () => listboxNode.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const handleInputChange = useCallback(
    (e: ChangeEvent<{}>, value: string, reason: string) => {
      if (reason === "clear" && !search) {
        setOpen(false);
        setSearch(value);
      } else {
        setSearch(value);
      }
    },
    [search],
  );

  const searchInput = useCallback(
    (params: any) => (
      <UITextField
        {...params}
        onClick={(e) => e.stopPropagation()}
        onFocus={() => setOpen(true)}
        size="small"
        placeholder="Search username, SIM or CLI"
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <UIInputAdornment position="start">
              <SearchIcon />
            </UIInputAdornment>
          ),
        }}
      />
    ),
    [],
  );

  return (
    <NavWidget title="Assets" link="/assets" icon={<FilterBAndWIcon />} {...props}>
      <>
        <UITypography className={classes.desc}>View and manage your individual assets.</UITypography>
        <UIAutocomplete
          onClick={(e) => e.stopPropagation()}
          className={classes.search}
          filterOptions={(x) => x}
          ListboxProps={{
            ref: listboxRef,
            className: classes.listbox,
          }}
          onInputChange={handleInputChange}
          loading={isLoading}
          loadingText={<UILoader size={18} />}
          fullWidth
          //free solo does not allow no options text to be displayed, this is a workaround
          freeSolo={!showNoOptionsText}
          noOptionsText={"No results found"}
          open={open}
          options={options}
          renderInput={searchInput}
          onChange={(event, value) => {
            event.stopPropagation();
            if (value && typeof value !== "string") {
              navigate(`/assets/${(value as SearchAsset).id}`);
            }
          }}
          getOptionLabel={(option) => (typeof option === "string" ? option : option.cli)}
          renderOption={(props, option: SearchAsset) => (
            <UIListItem {...props} component="li" alignItems="flex-start" key={option.id}>
              <UIListItemText
                primary={
                  <UIBox display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={0.5}>
                    <UITypography sx={{ flex: 1 }}>{highlightMatch(option.cli, search)}</UITypography>
                    <UITooltip title="Live asset" placement="right">
                      <UIBox display="flex" alignItems="center">
                        <CircleIcon color={option.live ? "success" : "error"} className={classes.assetLiveIcon} />
                      </UIBox>
                    </UITooltip>
                  </UIBox>
                }
                secondary={
                  <>
                    {option.tag && (
                      <UITypography component="div">
                        <UIBox display="flex" justifyContent="space-between" width="100%">
                          <UITypography noWrap variant="body2" color="textSecondary">
                            Username
                          </UITypography>
                          <UITypography noWrap variant="body2" color="textSecondary">
                            {highlightMatch(option.tag, search)}
                          </UITypography>
                        </UIBox>
                      </UITypography>
                    )}
                    {option.simNumber && (
                      <UITypography component="div">
                        <UIBox display="flex" justifyContent="space-between" width="100%">
                          <UITypography noWrap variant="body2" color="textSecondary">
                            SIM Number
                          </UITypography>
                          <UITypography noWrap variant="body2" color="textSecondary">
                            {highlightMatch(option.simNumber, search)}
                          </UITypography>
                        </UIBox>
                      </UITypography>
                    )}
                  </>
                }
              />
            </UIListItem>
          )}
        />
      </>
    </NavWidget>
  );
};

const useStyles = createStylesheet((theme) => ({
  assetLiveIcon: {
    fontSize: 14,
  },
  search: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textAlign: "center",
    marginTop: theme.spacing(0.5),
    maxWidth: "400px",
  },
  desc: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textAlign: "center",
    marginTop: theme.spacing(0.5),
  },
  listbox: {
    maxHeight: "200px",
    overflow: "auto",
    padding: 0,
    margin: 0,
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
}));

export default AssetsSearch;
