import { useState } from "react";
import UIConfirm from "../../_app/components/UIConfirm";
import { Contact } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIGrid } from "../../_app/components";

interface Props {
  data: Contact;
  deleteAction(data: Contact): void;
  editAction(data: Contact): void;
}

const ManageContactsActionButton = ({ data, deleteAction, editAction }: Props) => {
  const classes = useStyles();
  const [warning, setWarning] = useState(false);

  return (
    <UIGrid className={classes.ctr}>
      {Boolean(data.canDelete) && (
        <UIButton variant="outlined" onClick={() => setWarning(true)} data-cy="delete-contact-button" size="small" color="error">
          Delete
        </UIButton>
      )}
      <UIConfirm
        title="Delete this contact?"
        open={warning}
        setOpen={setWarning}
        onSubmitTitle="Delete"
        disableOnSubmit={!data.canDelete}
        isDelete={true}
        onConfirm={() => {
          if (!data.canDelete) return;
          deleteAction(data);
        }}
      >
        Are you sure you want to delete this contact?
      </UIConfirm>
      <UIButton variant="outlined" color="primary" onClick={() => editAction(data)} data-cy="edit-contact-button" size="small">
        Edit
      </UIButton>
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
}));

export default ManageContactsActionButton;
