import { createStylesheet } from "../../../_app/utils/styles";
import { UIButton, UIGrid, UIList, UITypography } from "../../../_app/components";

export default function FilterBox({
  item,
  resetSelection,
  addSelection,
  selection,
  setSelection,
  close,
  showReset,
  disableApply,
  children,
  ...props
}: any) {
  const classes = useStyles();

  return (
    <div {...props}>
      <UIList disablePadding>
        <UIGrid container direction="row" justifyContent="space-between" alignItems="center" className={classes.filterActions}>
          <div className={classes.titleCtr}>
            <UITypography variant="caption" className={classes.filterTitle}>
              Filter by {item.name}
            </UITypography>
            {item.MAX_COUNT && (
              <UITypography component="p" variant="caption">
                You can select up to {item.MAX_COUNT} item/s
              </UITypography>
            )}
          </div>
          <UIButton
            data-cy="reset-filter-button"
            size="small"
            color="inherit"
            onClick={() => resetSelection()}
            className={showReset ? classes.show : classes.hidden}
          >
            Reset
          </UIButton>
        </UIGrid>
        {children}
        <UIGrid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.filterAdd}>
          <UIButton
            data-cy="add-filter-button"
            variant="contained"
            size="small"
            color="primary"
            onClick={addSelection}
            disabled={disableApply ? disableApply : selection.length < 1}
          >
            Apply
          </UIButton>
          <UIButton data-cy="close-filter-button" size="small" onClick={() => close()} color="inherit">
            Cancel
          </UIButton>
        </UIGrid>
      </UIList>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  filterActions: {
    padding: theme.spacing(2),
  },
  filterAdd: {
    padding: theme.spacing(2),
  },
  filterTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  titleCtr: {
    display: "flex",
    flexDirection: "column",
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "initial",
  },
}));
