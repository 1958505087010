import { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import FiltersDialog from "./FiltersDialog";
import { Delete as DeleteIcon, KeyboardArrowDown as KeyboardArrowDownIcon, Save as SaveIcon } from "../../_app/components/icons";
import { isEmpty } from "../../_app/utils/index";
import { useLocation } from "react-router-dom";
import { UsersFilter } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UIButton,
  UIButtonGroup,
  UIClickAwayListener,
  UIGrow,
  UIList,
  UIListItem,
  UIListItemText,
  UIMenuList,
  UIPaper,
  UIPopper,
  UITextField,
  UITypography,
} from "../../_app/components";

interface Props {
  fetchHook?: (usage: string) => any;
  deleteHook?: (filter: UsersFilter) => any;
  saveHook?: (name: string) => any;
  addHook?: (filter: UsersFilter) => any;
  usage?: string;
}

const MyFilters = ({
  fetchHook = (usage: string) => null,
  deleteHook = (filter: UsersFilter) => null,
  saveHook = (name: string) => null,
  addHook = (filter: UsersFilter) => null,
  usage = "",
}: Props) => {
  const classes = useStyles();

  const usersFilters = fetchHook(usage);

  const [showSaveDialog, setSaveDialogVisible] = useState(false);
  const [showDeleteDialog, setDeleteDialogVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<UsersFilter>();
  const [filterName, setFilterName] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleCreate = () => {
    if (isEmpty(filterName)) {
      setValidationError("Required field");
    } else {
      saveHook(filterName);
      setSaveDialogVisible(false);
      setFilterName("");
    }
  };

  const handleDelete = () => {
    if (selectedFilter) {
      deleteHook(selectedFilter);
    }
    setDeleteDialogVisible(false);
  };

  const handleCancel = () => {
    setSaveDialogVisible(false);
    setDeleteDialogVisible(false);
    setValidationError("");
    setFilterName("");
  };

  const deleteFilter = (filter: UsersFilter) => {
    setDeleteDialogVisible(true);
    setSelectedFilter(filter);
  };

  const addFilter = (filter: UsersFilter) => {
    addHook(filter);
    setOpen(false);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFilterName(val);
  };

  const onKeyPressed = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleCreate();
    }
  };

  const myFilterDataList = [
    <UIList id="users-filters-menu" data-cy="my-filters-items">
      {usersFilters?.data?.length === 0 && (
        <UIListItem className={classes.filterItem} key={"user-filter-0"}>
          <UIListItemText className={classes.filterName} primary="No saved filter" data-cy="no-users-filters" />
        </UIListItem>
      )}

      {usersFilters?.data?.map((filter: UsersFilter, index: any) => (
        <UIListItem
          className={classes.filterItem}
          key={`user-filter-${filter.id || filter.name}`}
          data-cy={"users-filter-" + index}
        >
          <div className={classes.filterBlock}>
            <UIListItemText className={classes.filterName} primary={filter.name} />
            <UIButton
              className={classes.deleteButton}
              variant="text"
              size="small"
              color="primary"
              onClick={(e) => deleteFilter(filter)}
            >
              <DeleteIcon />
            </UIButton>
            <UIButton
              className={classes.addButton}
              variant="text"
              size="small"
              color="primary"
              onClick={(e) => addFilter(filter)}
            >
              Apply
            </UIButton>
          </div>
        </UIListItem>
      ))}
    </UIList>,
  ];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClickButton = () => {
    setOpen(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const location = useLocation();
  const paramsCount = Array.from(new URLSearchParams(location.search)).length;
  return (
    <div className={classes.ctr}>
      <div className={classes.actions}>
        <UIButtonGroup
          variant="contained"
          color="primary"
          size="medium"
          ref={anchorRef}
          aria-label="split button"
          data-cy="my-filters-button"
          className={classes.buttonGroup}
          disableElevation
        >
          <UIButton color="primary" className={classes.button} onClick={handleClickButton}>
            Saved Filters
          </UIButton>
          <UIButton
            color="primary"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
            data-cy="bill-summary-download"
            className={classes.arrowBtn}
          >
            <KeyboardArrowDownIcon fontSize="small" />
          </UIButton>
        </UIButtonGroup>
        <UIPopper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          // disablePortal
          placement="bottom-start"
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <UIGrow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <UIPaper>
                <UIClickAwayListener onClickAway={handleClose}>
                  <UIMenuList id="split-button-menu" data-cy="my-filters-list">
                    {myFilterDataList}
                  </UIMenuList>
                </UIClickAwayListener>
              </UIPaper>
            </UIGrow>
          )}
        </UIPopper>
        <UIButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => setSaveDialogVisible(true)}
          data-cy="add-filters-button"
          disabled={paramsCount === 0}
          startIcon={<SaveIcon />}
        >
          Save Filter
        </UIButton>
      </div>
      <FiltersDialog
        show={showDeleteDialog}
        title="Delete Filter"
        handleCancel={handleCancel}
        handleSubmit={handleDelete}
        cancelLabel="Cancel"
        submitLabel="Delete"
      >
        <UITypography variant="subtitle1">
          Are you sure you want to delete <strong>{selectedFilter?.name}</strong>?
        </UITypography>
      </FiltersDialog>
      <FiltersDialog
        show={showSaveDialog}
        title="Save Filter"
        handleCancel={handleCancel}
        handleSubmit={handleCreate}
        cancelLabel="Cancel"
        submitLabel="Save"
      >
        <UITextField
          name="name"
          label="Filter Name"
          type="text"
          value={filterName}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(validationError)}
          helperText={validationError}
          onChange={onInputChange}
          onKeyPress={onKeyPressed}
        />
      </FiltersDialog>
    </div>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    alignItems: "center",
  },
  buttonGroup: {
    height: "36.45px",
  },
  item: {
    textTransform: "capitalize",
  },
  button: {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  popper: {
    zIndex: 1000,
    minWidth: "190px",
  },
  [theme.breakpoints.up("md")]: {
    filterItem: {
      minWidth: "300px",
    },
  },
  filterBlock: {
    flex: "auto",
  },
  filterName: {
    float: "left",
  },
  deleteButton: {
    float: "right",
  },
  addButton: {
    float: "right",
    fontWeight: 500,
    marginLeft: "50px",
  },
  arrowBtn: {
    padding: 0,
  },
}));

export default MyFilters;
