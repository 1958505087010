import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Add as AddIcon, Download as DownloadIcon } from "../../_app/components/icons";
import Main from "../../_app/layouts/Main";
import FiltersCard from "../../filter/components/FiltersCard";
import { useAllFilters, useFilterQueries } from "../../filter/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import { IdValueQuery } from "../../_app/api";
import UsageAlertsTabBar from "../components/UsageAlerts/UsageAlertsTabBar";
import UsageAlertTable from "../components/UsageAlerts/Table/UsageAlertTable";
import { useExportAllAlerts, useUsageAlerts } from "../hooks";
import { downloadFile, extractFilenameFromHeaders } from "../../_app/utils";
import { UIAlert, UIButton, UILoader, UIStack } from "../../_app/components";
import { createStylesheet } from "../../_app/utils/styles";

const ALERTS_FILTERS = "ALERTS";

export interface SelectedParams {
  alerts?: string[];
  filters?: IdValueQuery[];
  from?: string;
}

export interface SelectedRows {
  index?: string;
  dataIndex?: string;
}

const rowCountOptions = [25, 50, 100];

export const UsageAlerts = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const queries = useFilterQueries();

  const { data: usageAlerts, isFetching, fetchNextPage, hasNextPage } = useUsageAlerts(rowCount, queries);
  const { isFetching: isFileFetching, refetch } = useExportAllAlerts({
    onSuccess: (response: AxiosResponse) => {
      downloadFile(response?.data, extractFilenameFromHeaders(response) ?? "usage-alerts-export.csv");
    },
  });

  return (
    <Main
      title={
        <>
          Usage Alerts
          <UIStack direction={"row"} spacing={1}>
            <UIButton
              variant="text"
              color="primary"
              data-cy="export-usage-alert-button"
              onClick={() => refetch()}
              startIcon={isFileFetching ? <UILoader size={20} /> : <DownloadIcon />}
              disabled={isFileFetching}
            >
              Export all to csv
            </UIButton>
            <UIButton
              variant="contained"
              color="primary"
              data-cy="add-usage-alert-button"
              onClick={() => navigate("/usage-alerts/new")}
              startIcon={<AddIcon />}
            >
              Add Alert
            </UIButton>
          </UIStack>
        </>
      }
      data-cy="support-usage-alerts"
      featureFlag={featureFlagsMap.USAGE_ALERTS}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount
    >
      <div className={classes.wrap}>
        <UIAlert severity="info" className={classes.info}>
          Alerts and bars are a great way to limit the potential of overusage and excess charges. Usage alerts will notify you
          when a user has reached the limit you have set. This can be for calls (mobile and fixed line), data or SMS.
        </UIAlert>
        <UIAlert severity="info" className={classes.info}>
          An autobar can be selected for Usage Alerts which will restrict the use of a mobile number. This can be used to assist
          in management of costs & reduce unexpected "bill shock". We will use reasonable endeavours to apply the autobar, as soon
          as we become aware that the mobile number has breached the usage alert amount. However, in certain circumstances that
          are beyond our control, such as delays in call data being received from the network, overall charges may exceed the set
          limit and are still liable to be paid for under your terms & conditions.
        </UIAlert>
        <UsageAlertsTabBar selectedTab="usage-alerts">
          <FiltersCard fetchHook={useAllFilters} hasReset={true} hasDownload={false} usage={ALERTS_FILTERS} />
          <UsageAlertTable
            result={usageAlerts}
            isFetching={isFetching}
            hasNextPage={Boolean(hasNextPage)}
            fetchNextPage={fetchNextPage}
            rowCount={rowCount}
            onRowCountChange={setRowCount}
          />
        </UsageAlertsTabBar>
      </div>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  wrap: {
    flexDirection: "column",
    width: "100%",
  },
  info: {
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
}));

export default UsageAlerts;
