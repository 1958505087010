import { useState } from "react";
import Main from "../../_app/layouts/Main";
import ManageSimActivationTabBar from "../components/ManageSimActivationTabBar";
import { useAllAssetsForAutoSwitchCancel, useCancelAutoSwitch } from "../hooks";
import { useStore } from "../../_app/hooks";
import StatusChip from "../../_app/components/StatusChip";
import { permissionCodes } from "../../permission/hooks";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import { featureFlagsMap } from "../../feature/utils";
import { formatPrice } from "../../billing/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIButton } from "../../_app/components";
import UIDataTable, { UIDataTableColumnDef, UIDataTableState } from "../../_app/components/Table/UIDataTable";

export interface TableConfig {
  title: string;
  columns: UIDataTableColumnDef[];
}

const tableConfig: TableConfig = {
  title: "",
  columns: [
    "CLI/Identifier",
    "PAC Code",
    "STAC Code",
    "Expiry Date",
    "Auto Switch",
    "Porting Date",
    "Early Termination Fee",
    { label: " ", name: "Actions" },
  ],
};

export const SimDisconnect = () => {
  const classes = useStyles();
  const selectedTab = "sim-disconnect";
  const { state } = useStore();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const accountSelected = Boolean(state.contextHierarchy?.id !== state.contextHierarchy?.lastParentId);

  const { data: assets, isFetching: isAssetFetching } = useAllAssetsForAutoSwitchCancel(page, rowCount, {
    enabled: accountSelected,
  });
  const { mutate: executeCancelAutoSwitch } = useCancelAutoSwitch();

  const parsed =
    assets?.list?.map((u) => {
      return rowParser([
        u.cli,
        u.pacCode,
        u.stacCode,
        u.expiryDate,
        u.autoSwitch ? "Yes" : "No",
        u.portingDate,
        formatPrice(u.fee),
        u.requestStatus === "PENDING" ? (
          <StatusChip />
        ) : (
          <UIButton
            variant="contained"
            color="primary"
            size="small"
            data-cy="disconnect-button"
            onClick={() => {
              executeCancelAutoSwitch(u.id);
            }}
          >
            Cancel
          </UIButton>
        ),
      ]);
    }) || [];

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title="Disconnect Mobiles"
      data-cy="mobile-management-sim-disconnect-page"
      featureFlag={featureFlagsMap.SIM_ACTIVATE}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      needSelectedAccount
    >
      <ManageSimActivationTabBar selectedTab={selectedTab}>
        {isAssetFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <div className={classes.tableCtr}>
            <UIAlert severity="info" className={classes.info}>
              Your pending mobile disconnections will display on this screen. PAC and STAC codes can be provided to your new
              supplier or if you wish to cancel a disconnection, you may do so here.
            </UIAlert>
            <UIDataTable
              title={tableConfig.title}
              data={parsed}
              columns={tableConfig.columns || []}
              options={{
                onTableChange: handleTableChange,
                pagination: true,
                page,
                rowsPerPage: rowCount,
                rowsPerPageOptions: rowCountOptions,
                count: assets?.total,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                sort: false,
                search: false,
                serverSide: true,
                selectableRows: "none",
                rowHover: true,
                setTableProps: () => ({ size: "small" }),
                setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                viewColumns: false,
              }}
            />
          </div>
        )}
      </ManageSimActivationTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "185px",
  },
  bottomCtr: {
    marginTop: theme.spacing(3),
  },
  tableCtr: {
    '& div[class*="MuiToolbar-root"]': {
      borderRadius: "0px",
    },
    '& div[class*="MUIDataTableFilterList-root-"]': {
      display: "none",
    },
    "& tr > td:last-child": {
      textAlign: "center",
    },
  },
  disabledRow: {
    color: theme.palette.text.disabled,
  },
  accessTooltip: {
    whiteSpace: "pre-wrap",
  },
  info: {
    width: "fit-content",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
}));
export default SimDisconnect;
