import { UITypography } from ".";
import { createStylesheet } from "../utils/styles";

const NA = () => {
  const classes = useStyles();
  return (
    <UITypography variant="body2" className={classes.ctr}>
      N/A
    </UITypography>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    color: theme.palette.grey[200],
  },
}));

export default NA;
