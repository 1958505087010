import { ReactNode } from "react";
import { MUIButton, MUIButtonProps, UIIconButton } from ".";
import UILoader from "./UILoader";

export interface UIButtonProps extends MUIButtonProps {
  isIconButton?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  innerRef?: React.Ref<HTMLButtonElement>;
}

export const UIButton = ({ isIconButton = false, isLoading = false, children, ref, innerRef, ...props }: UIButtonProps) => {
  if (isIconButton) return <UIIconButton {...props}>{children}</UIIconButton>;
  return (
    <MUIButton
      ref={innerRef}
      variant="contained"
      color="primary"
      startIcon={isLoading ? <UILoader color="inherit" size={18} /> : undefined}
      {...props}
    >
      {children}
    </MUIButton>
  );
};

export default UIButton;
