import { useMutation, useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getBarById, getBars, getBarsByAssetId, getBarsForClis, updateBars } from "./api";
import { Bar, GetBarsResponse } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";
import { genericError } from "../_app/utils/text";

export function useBarsByAssetId(assetId: string, options = {}) {
  return useQuery<GetBarsResponse, AjaxResponse>(["barringDetails", { assetId }], () => getBarsByAssetId(assetId), {
    ...options,
  });
}
export function useBars(params: any = {}, options = {}) {
  return useQuery<GetBarsResponse["bars"], AjaxResponse>(["bars", params], () => getBars(params), { ...options });
}

export function useSingleBar(barId: string, assetId: string, options = {}) {
  return useQuery<Bar, AjaxResponse>([barId, assetId], () => getBarById(barId, assetId), { ...options });
}

export function useBarsUpdate(options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useMutation<any, AjaxResponse, any>(updateBars, {
    ...options,
    onError: (updateError) => {
      setFeedbackAlertError(updateError?.data?.message || genericError());
    },
  });
}

export function useBarsForClis(clis: any[] = [], options = {}) {
  return useQuery<any, AjaxResponse>(["cliBars", clis], () => getBarsForClis(clis), { ...options });
}
