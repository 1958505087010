import api from "../_app/api";
import { ArticleStructure, HelpArticle } from "./types";

export const getTopicById = async (articleId: string) => {
  const { data }: { data: HelpArticle } = await api({
    method: "GET",
    url: "/help/article/" + articleId,
  });
  return data;
};

export const getArticleStructure = async () => {
  let { data }: { data: ArticleStructure[] } = await api({
    method: "GET",
    url: "/help/article_structure",
  });

  return data;
};
