import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Close as CloseIcon } from "../components/icons";
import { createStylesheet, useMediaQuery } from "../utils/styles";
import { UIDrawer, UIIconButton } from "../components";

interface Props {
  children: ReactNode;
  [key: string]: any;
}

function Drawered({ open = true, anchor = "right", width = 70, onClose, showCloseButton = true, children, ...rest }: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const close = () => {
    if (typeof onClose === "function") onClose(rest);
    else navigate(-1);
  };
  return (
    <UIDrawer anchor={anchor} open={open} onClose={close}>
      {Boolean(showCloseButton) && (
        <UIIconButton className={classes.closeBtn} onClick={close} data-cy="close-btn" size="large">
          <CloseIcon />
        </UIIconButton>
      )}
      <div
        style={{
          width: isSmallScreen ? "95vw" : width + "vw",
        }}
      >
        {children}
      </div>
    </UIDrawer>
  );
}

const useStyles = createStylesheet((theme) => ({
  closeBtn: {
    position: "absolute",
    right: 6,
    top: 13,
    "& svg": {
      fontSize: "2rem",
    },
  },
}));

export default Drawered;
