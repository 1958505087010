import { useEffect, useRef, useState } from "react";
import { ChevronLeft as ChevronLeftIcon } from "../../../_app/components/icons";
import FilterBox from "./FilterBox";
import { useFilterValues } from "../../hooks";
import { Filter, FilterOptions } from "../../types";
import { filtersIdMap } from "../../utils";
import { debounce } from "../../../_app/utils/index";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIAutocomplete, UIBox, UIButton, UIChip, UILoader, UIMenuItem, UITextField } from "../../../_app/components";

interface Props {
  item: Filter;
  options?: Partial<FilterOptions>;
  selection: any;
  setSelection: (value: string[]) => void;
  addSelection: (value?: string) => void;
  resetSelection?: () => void;
  close: () => void;
}

export default function IdentifierFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [bulkView, setBulkView] = useState(false);
  const [bulkValue, setBulkValue] = useState("");
  const inputRef = useRef<HTMLDivElement | null>(null);
  const debouncedSetSearch = debounce((value) => setSearch(value), 500);

  const {
    data: filterResponse,
    fetchNextPage: fetchNextFilterPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  } = useFilterValues(item?.resources, search, {
    getNextPageParam: (lastPage: string[], allPages: string[][]) => {
      if (lastPage.length === 0) return undefined;
      else return allPages.length;
    },
  });

  const [filter, setFilter] = useState<any>();
  useEffect(() => {
    if (filterResponse?.pages?.length) {
      const filterList: any = [];

      filterResponse.pages?.forEach((page: any) => {
        page?.forEach((value: any) => {
          filterList.push(value);
        });
      });

      setFilter(filterList);
    }
  }, [filterResponse]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleMultiSelect = (listItem: any) => {
    if (selection.length && (options?.MAX_COUNT === "1" || options?.MAX_COUNT === 1)) {
      setSelection([listItem]);
    } else if (
      !selection.some((current: any) => current === listItem) &&
      options?.MAX_COUNT &&
      selection.length < options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else if (!selection.some((current: any) => current === listItem) && !options?.MAX_COUNT) {
      setSelection([...selection, listItem]);
    } else {
      let selectionAfter = selection;
      selectionAfter = selectionAfter.filter((current: any) => current !== listItem);
      setSelection([...selectionAfter]);
      if (!selectionAfter.length && resetSelection) resetSelection();
    }
  };

  const isSelected = (listItem: any) => {
    if (
      selection.find((current: any) => {
        if (item.id === filtersIdMap.ACCOUNT || item.id === filtersIdMap.SERVICES_ACCOUNT) {
          return current.includes(listItem.split(" - ")?.[0]);
        }
        return current === listItem;
      })
    ) {
      return true;
    }
    return false;
  };

  const onScroll = (e: any) => {
    const buffer = 2;
    const scrolled = e.target.scrollHeight - e.target.scrollTop;
    const total = e.target.clientHeight + buffer;
    const hitBottom = scrolled <= total;
    if (hitBottom && hasNextPage && !isFetchingNextPage) fetchNextFilterPage();
  };

  const formatValue = (item: any, value: any) => {
    return (
      <UIMenuItem
        key={value}
        onClick={() => handleMultiSelect(value)}
        selected={isSelected(value)}
        className={classes.filterListValue}
      >
        {item.valueDisplayFunc ? item.valueDisplayFunc(value) : value}
      </UIMenuItem>
    );
  };

  useEffect(() => {
    if (bulkView)
      setSelection(
        bulkValue
          .split("\n")
          .map((line) => line.trim())
          .filter(Boolean),
      );
  }, [bulkView, bulkValue, setSelection]);

  return (
    <div {...props}>
      <FilterBox item={item} resetSelection={resetSelection} addSelection={addSelection} selection={selection} close={close}>
        {options?.BULK_INPUT && (
          <UIButton
            className={classes.bulkBtn}
            variant="text"
            color="secondary"
            size="small"
            onClick={() => setBulkView(!bulkView)}
            startIcon={bulkView && <ChevronLeftIcon />}
          >
            {bulkView ? "Back" : "Bulk Input"}
          </UIButton>
        )}
        {bulkView ? (
          <div className={classes.filterBody}>
            <UITextField
              className={classes.bulkInput}
              onChange={(e) => setBulkValue(e.target.value)}
              inputProps={{ maxLength: 1650 }}
              placeholder="Paste your list here, entering one per line up to a maximum of 130."
              value={bulkValue}
              fullWidth
              multiline
              rows={7}
            />
          </div>
        ) : (
          <>
            {options?.SEARCH_BAR && (
              <UIBox maxWidth="350px">
                <UIAutocomplete
                  size="small"
                  className={classes.filterSearch}
                  multiple
                  freeSolo
                  open={false}
                  clearOnBlur={false}
                  options={[]}
                  value={selection}
                  onInputChange={(e, value, reason) => {
                    if (reason === "clear" && !search && resetSelection) return resetSelection();
                    debouncedSetSearch(value);
                  }}
                  renderInput={(params) => (
                    <UITextField
                      {...params}
                      placeholder={"Search " + item.name}
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      inputRef={inputRef}
                      inputProps={{
                        ...params.inputProps,
                        className: classes.input,
                      }}
                    />
                  )}
                  renderTags={(value) =>
                    value.map((option) => (
                      <UIChip
                        key={option}
                        label={option}
                        size="small"
                        variant="outlined"
                        onDelete={() => handleMultiSelect(option)}
                        className={classes.chip}
                      />
                    ))
                  }
                />
              </UIBox>
            )}
            <div key={item?.name} id="scrollableDiv" className={classes.filterBody} onScroll={onScroll}>
              {(!isLoading && !isFetching) || isFetchingNextPage ? filter?.map((value: any) => formatValue(item, value)) : null}
              {!Boolean(filter?.length) && !isLoading ? <div className={classes.loader}>Nothing was found</div> : null}
              {isLoading || isFetching ? (
                <div className={classes.loader}>
                  <UILoader />
                </div>
              ) : null}
            </div>
          </>
        )}
      </FilterBox>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  filterBody: {
    maxHeight: 200,
    overflowY: "auto",
    paddingTop: theme.spacing(1),
    marginInline: theme.spacing(2),
    overscrollBehaviorY: "contain",
  },
  filterSearch: {
    paddingInline: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      paddingRight: `${theme.spacing(3)} !important`,
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
  },
  input: {
    padding: theme.spacing(0.5),
    "& .MuiInputBase-input": {
      width: "100%",
    },
  },
  filterListValue: {
    textTransform: "capitalize",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    flex: 1,
  },
  bulkBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1.25),
  },
  bulkInput: {},
  chip: {
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));
