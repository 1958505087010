import { unassignedCostCentreModel } from "./api";
import { AccountLevelEnum, CostCentre, FilterCostCentresResponse } from "./types";

export const formatCostCentreCode = (code?: string) => {
  if (code === unassignedCostCentreModel.code) {
    return unassignedCostCentreModel.name;
  } else if (code) {
    return code?.replaceAll?.(";", " | ");
  } else {
    return "";
  }
};

export const formatCostCentreOptions = (costCentres: CostCentre[]) => {
  const flattenFunc = (acc: any, cc: CostCentre) => {
    let options = [
      ...acc,
      {
        id: cc?.id,
        label: formatCostCentreCode(cc?.code),
        value: cc?.id,
      },
    ];
    if (cc?.subCostCentres?.length) {
      options = cc?.subCostCentres?.reduce(flattenFunc, options);
    }
    return options;
  };
  return [...flattenFunc([], unassignedCostCentreModel), ...(costCentres?.reduce(flattenFunc, []) || [])];
};

export const formatCCSearchResults = (searchResult?: FilterCostCentresResponse) => {
  const formatted: CostCentre[] = [];
  if (!searchResult) return formatted;
  if (searchResult.assetsWithoutCostCentre?.length)
    formatted.push({ ...unassignedCostCentreModel, assets: searchResult.assetsWithoutCostCentre });
  if (searchResult.costCentresWithAssets) formatted.push(...searchResult.costCentresWithAssets);
  return formatted;
};

export class AccountLevel {
  static readonly Group = new AccountLevel(AccountLevelEnum.Group, "Group", 10);
  static readonly Company = new AccountLevel(AccountLevelEnum.Company, "Company", 20);
  static readonly Account = new AccountLevel(AccountLevelEnum.Account, "Account", 30);
  static readonly SubAccount = new AccountLevel(AccountLevelEnum.SubAccount, "Sub Account", 35);
  static readonly Asset = new AccountLevel(AccountLevelEnum.Asset, "Asset", 50);

  private constructor(
    public readonly value: AccountLevelEnum,
    public readonly label: string,
    public readonly id: number,
  ) {}

  static fromString(level: AccountLevelEnum): AccountLevel | undefined {
    switch (level) {
      case AccountLevelEnum.Group:
        return AccountLevel.Group;
      case AccountLevelEnum.Company:
        return AccountLevel.Company;
      case AccountLevelEnum.Account:
        return AccountLevel.Account;
      case AccountLevelEnum.SubAccount:
        return AccountLevel.SubAccount;
      case AccountLevelEnum.Asset:
        return AccountLevel.Asset;
      default:
        return undefined; // Return undefined if no match is found
    }
  }
}
