import { ArrowBackIos as ArrowBackIosIcon } from "../components/icons";
import { useLocation, useNavigate } from "react-router-dom";
import UIButton from "./UIButton";

export interface Props {
  label?: string;
  btnProps?: any;
}

export const UIBack = ({ label, btnProps }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const text = label ?? "Back" + (location.state?.from ? ` To ${location.state?.from}` : "");
  return (
    <UIButton
      variant="text"
      color="primary"
      onClick={() => {
        navigate(-1);
      }}
      {...btnProps}
    >
      <ArrowBackIosIcon fontSize="small" /> {text}
    </UIButton>
  );
};

export default UIBack;
