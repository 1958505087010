import { UICard, UIGrid, UITypography } from "../../../_app/components";
import { createStylesheet } from "../../../_app/utils/styles";

import type { JSX } from "react";

interface Props {
  title: string;
  children: JSX.Element;
}

export const Details = ({ title, children }: Props) => {
  const classes = useStyles();
  return (
    <UIGrid size={{ xs: 12, md: 6 }}>
      <UICard elevation={3} height="fit-content" padding="8px 8px 0 8px" className={classes.card}>
        <UITypography variant="h3" paragraph>
          {title}
        </UITypography>
        <UIGrid container>
          <UIGrid size={{ xs: 12 }}>
            <UIGrid container>{children}</UIGrid>
          </UIGrid>
        </UIGrid>
      </UICard>
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    height: "auto",
  },
}));

export default Details;
