import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloudDownload as CloudDownloadIcon, Visibility as VisibilityIcon } from "../../_app/components/icons";
import { formatNumber } from "../../_app/utils/format";
import { downloadFile, extractFilenameFromHeaders } from "../../_app/utils";
import { useFeedbackAlerts } from "../../_app/hooks";
import { UIButton, UIIconButton, UITypography } from "../../_app/components";
import UILoader from "../../_app/components/UILoader";
import OptionsButton from "../../form/components/OptionsButton";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { getUsageFile } from "../api";
import { formatPeriodToMonthYear, UNBILLED } from "../utils";
import { filtersIdMap } from "../../filter/utils";
import { UnbilledCost } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import UIDataTable from "../../_app/components/Table/UIDataTable";

interface Props {
  unbilledCosts: UnbilledCost;
  isFetching: boolean;
  isSuccess: boolean;
  isHeadAccount: boolean;
}

export const UnbilledCostsSummary = ({ unbilledCosts, isFetching, isSuccess, isHeadAccount }: Props) => {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const classes = useStyles();
  const navigate = useNavigate();
  const hasPermission = useHasPermission(permissionCodes.BILLING_DOWNLOAD);
  const [isDownloading, setIsDownloading] = useState(false);

  const memoizedHandleDownload = useCallback(async () => {
    if (!isDownloading) {
      setIsDownloading(true);
      return getUsageFile({
        [filtersIdMap?.BILL_PERIOD]: unbilledCosts.billingPeriod,
      })
        .then((response: any) => {
          const filename = extractFilenameFromHeaders(response) || "usage-export.csv";
          downloadFile(response?.data, filename);
        })
        .catch((err) => {
          setFeedbackAlertError(
            err?.status === 404 ? "There is no usage to download" : err.data.message || "Unable to download file",
          );
        })
        .finally(() => setIsDownloading(false));
    }
  }, [unbilledCosts.billingPeriod, isDownloading, setFeedbackAlertError]);

  const title = (
    <UITypography variant="h1" className={classes.title} data-cy="bill-period" key="bill-period" paragraph>
      {`${formatPeriodToMonthYear(unbilledCosts.billingPeriod)} (Unbilled)`}
    </UITypography>
  );

  const getOptions = useCallback(
    () => [
      <UIButton
        variant="text"
        color="inherit"
        onClick={async () => await memoizedHandleDownload()}
        startIcon={isDownloading && <UILoader color="inherit" size={18} />}
        disabled={isDownloading}
      >
        Download usage
      </UIButton>,
    ],
    [memoizedHandleDownload, isDownloading],
  );

  const columns = ["Net", isHeadAccount ? "Actions" : ""];

  const tableData = useMemo(() => {
    if (unbilledCosts) {
      return [
        [
          formatNumber(unbilledCosts?.net, 2, "£") ?? <></>,
          isHeadAccount ? (
            <div>
              <UIButton
                isIconButton
                data-cy="view-costs-details-button"
                color="primary"
                className={classes.viewButton}
                onClick={() => {
                  navigate(
                    `/bills/period-details/${unbilledCosts.billingPeriod}/${unbilledCosts.systemInvoiceRef}?${filtersIdMap?.BILL_PERIOD}=${UNBILLED}`,
                  );
                }}
                disabled={!unbilledCosts.net}
              >
                <VisibilityIcon />
              </UIButton>
              {hasPermission ? (
                <OptionsButton
                  renderButton={({ ref, innerRef, ...props }) => (
                    <UIIconButton color="primary" ref={innerRef} {...props}>
                      <CloudDownloadIcon />
                    </UIIconButton>
                  )}
                  options={getOptions()}
                  variant="outlined"
                />
              ) : null}
            </div>
          ) : (
            <></>
          ),
        ],
      ];
    } else return [];
  }, [navigate, getOptions, unbilledCosts, classes.viewButton, hasPermission, isHeadAccount]);

  return (
    <>
      {title}
      {isFetching && (
        <div className={classes.progress}>
          <UILoader />
        </div>
      )}
      {!isFetching && isSuccess && (
        <>
          <div className={classes.content}>
            <UIDataTable
              title=""
              data={tableData}
              columns={columns}
              options={{
                elevation: 1,
                download: false,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                viewColumns: false,
                sort: false,
                search: false,
                serverSide: true,
                selectableRowsHeader: false,
                selectableRows: "none",
                setTableProps: () => ({ size: "small" }),
                setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                customFooter: () => null,
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  title: {
    fontWeight: 600,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  cancelled: {
    marginLeft: "10px",
    backgroundColor: theme.palette.error?.main,
  },
  progress: {
    margin: "auto",
    padding: "50px 0",
    height: "200px",
  },
  error: {
    width: "100%",
    margin: "36px 20px 0px 20px",
  },
  viewButton: {
    minWidth: "fit-content",
  },
  showMoreButton: {
    width: "100%",
  },
  content: {
    paddingBottom: "50px",
    "& .MuiTableCell-root": {
      borderTop: "none",
      whiteSpace: "nowrap",
      "&:last-child": {
        width: "122px",
      },
      [theme.breakpoints.down("md")]: {
        "&:nth-last-of-type(2)": {
          width: "90px",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "&:not(:last-child)": {
          minWidth: "100px",
        },
      },
    },
  },
}));
